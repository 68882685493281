import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "ପୂର୍ବ",
   "pageNextText": "ପରବର୍ତ୍ତୀ",
   "completeText": "ସଂପୂର୍ଣ୍ଣ",
   "previewText": "ପୂର୍ବାବଲୋକନ",
   "editText": "ସଂପାଦନା କରନ୍ତୁ |",
   "startSurveyText": "ଆରମ୍ଭ କରନ୍ତୁ |",
   "otherItemText": "ଅନ୍ୟ (ବର୍ଣ୍ଣନା)",
   "noneItemText": "କିଛି ନୁହେଁ |",
   "selectAllItemText": "ସବୁ ବାଛ",
   "progressText": "{1} ର ପୃଷ୍ଠା {0} |",
   "indexText": "{1} ର {0} |",
   "panelDynamicProgressText": "{1} ର {0} |",
   "panelDynamicTabTextFormat": "ପ୍ୟାନେଲ୍ {ପ୍ୟାନେଲ୍ ଇଣ୍ଡେକ୍ସ}",
   "questionsProgressText": "{0} / {1} ପ୍ରଶ୍ନର ଉତ୍ତର |",
   "emptySurvey": "ସର୍ବେକ୍ଷଣରେ କ visible ଣସି ଦୃଶ୍ୟମାନ ଉପାଦାନ ନାହିଁ |",
   "completingSurvey": "ସର୍ଭେ ସମାପ୍ତ କରିଥିବାରୁ ଧନ୍ୟବାଦ |",
   "completingSurveyBefore": "ଆମର ରେକର୍ଡଗୁଡିକ ଦର୍ଶାଏ ଯେ ଆପଣ ଏହି ସର୍ଭେ ପୂର୍ବରୁ କରିସାରିଛନ୍ତି |",
   "loadingSurvey": "ଲୋଡିଂ ସର୍ଭେ ...",
   "placeholder": "ଚୟନ କରନ୍ତୁ ...",
   "ratingOptionsCaption": "ଚୟନ କରନ୍ତୁ ...",
   "value": "ମୂଲ୍ୟ",
   "requiredError": "ପ୍ରତିକ୍ରିୟା ଆବଶ୍ୟକ |",
   "requiredErrorInPanel": "ପ୍ରତିକ୍ରିୟା ଆବଶ୍ୟକ: ଅତିକମରେ ଗୋଟିଏ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅ |",
   "requiredInAllRowsError": "ପ୍ରତିକ୍ରିୟା ଆବଶ୍ୟକ: ସମସ୍ତ ଧାଡିରେ ପ୍ରଶ୍ନର ଉତ୍ତର ଦିଅ |",
   "numericError": "ମୂଲ୍ୟ ସାଂଖ୍ୟିକ ହେବା ଉଚିତ୍ |",
   "minError": "ମୂଲ୍ୟ {0 than ରୁ କମ୍ ହେବା ଉଚିତ୍ ନୁହେଁ |",
   "maxError": "ମୂଲ୍ୟ {0 than ରୁ ଅଧିକ ହେବା ଉଚିତ୍ ନୁହେଁ |",
   "textMinLength": "ଦୟାକରି ଅତିକମରେ {0} ବର୍ଣ୍ଣ (ଗୁଡିକ) ପ୍ରବେଶ କରନ୍ତୁ |",
   "textMaxLength": "ଦୟାକରି {0} ବର୍ଣ୍ଣ (ଗୁଡିକ) ରୁ ଅଧିକ ପ୍ରବେଶ କରନ୍ତୁ ନାହିଁ |",
   "textMinMaxLength": "ଦୟାକରି ଅତିକମରେ {0 enter ପ୍ରବେଶ କରନ୍ତୁ ଏବଂ {1} ଅକ୍ଷରରୁ ଅଧିକ ନୁହେଁ |",
   "minRowCountError": "ଦୟାକରି ଅତିକମରେ {0} ଧାଡି (ଗୁଡିକ) ପୁରଣ କରନ୍ତୁ |",
   "minSelectError": "ଦୟାକରି ଅତିକମରେ {0} ବିକଳ୍ପ (ଗୁଡିକ) ଚୟନ କରନ୍ତୁ |",
   "maxSelectError": "ଦୟାକରି {0} ବିକଳ୍ପ (ଗୁଡିକ) ରୁ ଅଧିକ ଚୟନ କରନ୍ତୁ ନାହିଁ |",
   "numericMinMax": "&#39;{0}&#39; ଅତିକମରେ {1 be ଏବଂ ସର୍ବାଧିକ {2 be ହେବା ଉଚିତ |",
   "numericMin": "&#39;{0}&#39; ଅତିକମରେ {1 be ହେବା ଉଚିତ୍ |",
   "numericMax": "&#39;{0}&#39; ସର୍ବାଧିକ {1 be ହେବା ଉଚିତ |",
   "invalidEmail": "ଦୟାକରି ଏକ ବ valid ଧ ଇ-ମେଲ୍ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ |",
   "invalidExpression": "ଅଭିବ୍ୟକ୍ତି: {0} &#39;ସତ୍ୟ&#39; ଫେରାଇବା ଉଚିତ |",
   "urlRequestError": "ଅନୁରୋଧ ତ୍ରୁଟି &#39;{0}&#39; ଫେରସ୍ତ କଲା | {1}",
   "urlGetChoicesError": "ଅନୁରୋଧ ଖାଲି ତଥ୍ୟ ଫେରସ୍ତ କଲା କିମ୍ବା &#39;ପଥ&#39; ସମ୍ପତ୍ତି ଭୁଲ ଅଟେ |",
   "exceedMaxSize": "ଫାଇଲ୍ ଆକାର {0 exceed ରୁ ଅଧିକ ହେବା ଉଚିତ୍ ନୁହେଁ |",
   "noUploadFilesHandler": "ଫାଇଲଗୁଡିକ ଅପଲୋଡ୍ ହୋଇପାରିବ ନାହିଁ | ଦୟାକରି &#39;ଅନ୍ ଅପଲୋଡ୍ ଫାଇଲ୍&#39; ଇଭେଣ୍ଟ ପାଇଁ ଏକ ହ୍ୟାଣ୍ଡେଲର୍ ଯୋଡନ୍ତୁ |",
   "otherRequiredError": "ପ୍ରତିକ୍ରିୟା ଆବଶ୍ୟକ: ଅନ୍ୟ ଏକ ମୂଲ୍ୟ ପ୍ରବେଶ କର |",
   "uploadingFile": "ଆପଣଙ୍କର ଫାଇଲ୍ ଅପଲୋଡ୍ ହେଉଛି | ଦୟାକରି ଅନେକ ସେକେଣ୍ଡ ଅପେକ୍ଷା କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
   "loadingFile": "ଲୋଡିଂ ...",
   "chooseFile": "ଫାଇଲ୍ (ଗୁଡିକ) ବାଛନ୍ତୁ ...",
   "noFileChosen": "କ file ଣସି ଫାଇଲ ଚୟନ ହୋଇନାହିଁ |",
   "filePlaceholder": "ଏଠାରେ ଏକ ଫାଇଲ୍ ଡ୍ରାଗ୍ ଏବଂ ଡ୍ରପ୍ କରନ୍ତୁ କିମ୍ବା ଅପଲୋଡ୍ କରିବାକୁ ଏକ ଫାଇଲ୍ ବାଛିବା ପାଇଁ ନିମ୍ନରେ ଥିବା ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ |",
   "confirmDelete": "ଆପଣ ରେକର୍ଡ ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
   "keyDuplicationError": "ଏହି ମୂଲ୍ୟ ଅନନ୍ୟ ହେବା ଉଚିତ୍ |",
   "addColumn": "ସ୍ତମ୍ଭ ଯୋଡନ୍ତୁ |",
   "addRow": "ଧାଡି ଯୋଡନ୍ତୁ |",
   "removeRow": "ଅପସାରଣ କରନ୍ତୁ |",
   "emptyRowsText": "କ r ଣସି ଧାଡି ନାହିଁ |",
   "addPanel": "ନୂତନ ଯୋଡନ୍ତୁ |",
   "removePanel": "ଅପସାରଣ କରନ୍ତୁ |",
   "showDetails": "ବିବରଣୀ ଦେଖାନ୍ତୁ |",
   "hideDetails": "ବିବରଣୀ ଲୁଚାନ୍ତୁ |",
   "choices_Item": "ଆଇଟମ୍",
   "matrix_column": "ସ୍ତମ୍ଭ",
   "matrix_row": "ଧାଡି",
   "multipletext_itemname": "ପାଠ",
   "savingData": "ଫଳାଫଳଗୁଡିକ ସର୍ଭରରେ ସଞ୍ଚୟ ହେଉଛି ...",
   "savingDataError": "ଏକ ତ୍ରୁଟି ଘଟିଲା ଏବଂ ଆମେ ଫଳାଫଳ ସଞ୍ଚୟ କରିପାରିଲୁ ନାହିଁ |",
   "savingDataSuccess": "ଫଳାଫଳଗୁଡିକ ସଫଳତାର ସହିତ ସଞ୍ଚୟ ହେଲା!",
   "saveAgainButton": "ପୁଣି ଥରେ ଚେଷ୍ଟା କର",
   "timerMin": "ମିନିଟ୍",
   "timerSec": "ସେକେଣ୍ଡ",
   "timerSpentAll": "ଆପଣ ଏହି ପୃଷ୍ଠାରେ {0 and ଏବଂ ସମୁଦାୟ {1 spent ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "timerSpentPage": "ଆପଣ ଏହି ପୃଷ୍ଠାରେ {0 spent ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "timerSpentSurvey": "ଆପଣ ସମୁଦାୟ {0 spent ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "timerLimitAll": "ଆପଣ ଏହି ପୃଷ୍ଠାରେ {1} ର {0} ଏବଂ ସମୁଦାୟ {3} ର {2 spent ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "timerLimitPage": "ଆପଣ ଏହି ପୃଷ୍ଠାରେ {1} ର {0 spent ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "timerLimitSurvey": "ଆପଣ ସମୁଦାୟ {1} ର {0} ଖର୍ଚ୍ଚ କରିଛନ୍ତି |",
   "clearCaption": "ସଫା",
   "signaturePlaceHolder": "ଏଠାରେ ସାଇନ୍ କରନ୍ତୁ |",
   "chooseFileCaption": "ଫାଇଲ୍ ଚୟନ କରନ୍ତୁ |",
   "takePhotoCaption": "ଫଟୋ ନିଅନ୍ତୁ |",
   "photoPlaceholder": "କ୍ୟାମେରା ବ୍ୟବହାର କରି ଫଟୋ ଉଠାଇବାକୁ ନିମ୍ନରେ ଥିବା ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ |",
   "fileOrPhotoPlaceholder": "କ୍ୟାମେରା ବ୍ୟବହାର କରି ଫଟୋ ଅପଲୋଡ୍ କିମ୍ବା ଫଟୋ ଉଠାଇବାକୁ ଏକ ଫାଇଲ୍ ଡ୍ରାଗ୍ ଏବଂ ଡ୍ରପ୍ କିମ୍ବା ଚୟନ କରନ୍ତୁ |",
   "replaceFileCaption": "ଫାଇଲ୍ ବଦଳାନ୍ତୁ |",
   "removeFileCaption": "ଏହି ଫାଇଲ୍ ଅପସାରଣ କରନ୍ତୁ |",
   "booleanCheckedLabel": "ହଁ",
   "booleanUncheckedLabel": "ନା",
   "confirmRemoveFile": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ଫାଇଲ୍ ଅପସାରଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି: {0}?",
   "confirmRemoveAllFiles": "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ସମସ୍ତ ଫାଇଲ୍ ଅପସାରଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
   "questionTitlePatternText": "ପ୍ରଶ୍ନ ଆଖ୍ୟା |",
   "modalCancelButtonText": "ବାତିଲ୍ କରନ୍ତୁ |",
   "modalApplyButtonText": "ପ୍ରୟୋଗ କରନ୍ତୁ |",
   "filterStringPlaceholder": "ଖୋଜିବାକୁ ଟାଇପ୍ କରନ୍ତୁ ...",
   "emptyMessage": "ପ୍ରଦର୍ଶନ କରିବାକୁ କ data ଣସି ତଥ୍ୟ ନାହିଁ |",
   "noEntriesText": "ଏପର୍ଯ୍ୟନ୍ତ କ ent ଣସି ଏଣ୍ଟ୍ରି ନାହିଁ | ଏକ ନୂତନ ଏଣ୍ଟ୍ରି ଯୋଡିବାକୁ ନିମ୍ନରେ ବଟନ୍ କ୍ଲିକ୍ କରନ୍ତୁ |",
   "noEntriesReadonlyText": "କ no ଣସି ଏଣ୍ଟ୍ରି ନାହିଁ |",
   "more": "ଅଧିକ",
   "tagboxDoneButtonCaption": "ଠିକ୍ ଅଛି",
   "selectToRankEmptyRankedAreaText": "ସମସ୍ତ ପସନ୍ଦ ମାନ୍ୟତା ପାଇଛି |",
   "selectToRankEmptyUnrankedAreaText": "ସେମାନଙ୍କୁ ମାନ୍ୟତା ଦେବା ପାଇଁ ଏଠାରେ ପସନ୍ଦଗୁଡିକ ଡ୍ରାଗ୍ ଏବଂ ଡ୍ରପ୍ କରନ୍ତୁ |",
   "ok": "ଠିକ୍ ଅଛି",
   "cancel": "ବାତିଲ୍ କରନ୍ତୁ |"
};


surveyLocalization.locales["or"] = dict;