import { Box, Button, Card, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import useUser from '../../providers/user/user_provider';
import MainLayout from '../../layouts/main/main_layout';
import { ArrowBack, Start } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterIcon from "../../assets/filter_icon.svg";
import AccessDenied from '../../components/access_denied/access_denied';
import DatGridJFileUpload from './components/data_grid/data_grid';
import UploadCsvFile from './components/upload_csv_popup/upload_csv_popup';
import { ExcelUploadedList } from './model/excel_upload_model';
import { useQuery } from 'react-query';
import { downloadExcelApiCall, getExcelUploadData, UploadExcelFile } from '../../services/user_append_service/excel_upload_list';
import { downloadFromBlob } from '../../utils/utils';
import FilterUploadFile from './components/filter_upload_file/filter_upload_file';
import { useFeedback } from '../../providers/feedback/feeedback';
import { queryClient } from '../../providers/query/query';
import Loading from '../../components/loading/loading';

export const fileLabels: { label: string }[] = [
  {
    label: "Sl. No.",
  },
  {
    label: "Document Name",
  },
  {
    label: "Upload Date",
  },
  {
    label: "Uploaded By",
  },
  {
    label: "Status",
  },
  {
    label: "Actions",
  },
];



export default function FileUpload() {
  var bodyFormData = new FormData();
  const [openPopup, setOpenPopup] = useState(false);
  const { hasPermission } = useUser();
  const [page, setPage] = useState(0);
  const [selectedUploadedId, setSelectedUploadedId] = useState<number | null>(
    null
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openfilterModel, setFilterModelOpen] = useState(false)
  const { showSnackbar } = useFeedback();
  const [status, setStatus] = useState<number | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();
  const [isLoadingUpload, setIsloading] = useState(false);

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  const { data: excelUploadList, isLoading } = useQuery(["excelUploadList",
    currentTab,
    page,
    startDate,
    endDate,
    status
  ], async () => {
    let response = await getExcelUploadData(
      {
        page: page + 1,
        size: 8,
        type: currentTab === 0 ? 'edit' : 'add',
        startDate: startDate,
        endDate: endDate,
        status: status
      }
    );
    return response?.data?.data?.data;
  });

  function uploadFile(file: File) {
    setIsloading(true);
    UploadExcelFile({ file: file, type: currentTab === 0 ? 'edit' : 'add' }).then((res) => {
      if (res.status === 200) {
        showSnackbar("Document uploaded successfully");
        queryClient.invalidateQueries({ queryKey: ["excelUploadList"] });
      }
      setIsloading(false);
    }).catch((error) => {
      showSnackbar("Something went wrong");
      setIsloading(false);
    });
    setOpenPopup(false);

  }

  function downloadFile({ taskId }: { taskId: number }) {
    downloadExcelApiCall({ taskId: taskId }).then((response) => {
      downloadFromBlob(response, new Date().valueOf() + ".xlsx");
    })
  }

  useEffect(() => {
    setPage(0);
    setStartDate("");
    setEndDate("");
    setStatus(null);
  }, [currentTab])


  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
          height: "fit-content",
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }

  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  return (
    <MainLayout>
      <Box>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            minHeight: 600,
            mb: 2,
            minWidth: 1200,
          }}
        >
          {hasPermission("Jobs", "Read") ? (
            <Box display="flex" flexDirection={"column"} sx={{ width: "100%" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                p={2}
              >
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Bulk Processing
                  </Typography>
                </Box>
                <Box display={'flex'}>
                  <Box display={"flex"} flexDirection={"row"} alignItems="center">
                    <Typography
                      display="flex"
                      alignItems={"center"}
                      color="#91278F"
                      fontWeight="600"
                      sx={{ borderBottom: "3px solid #91278F" }}
                    >
                      Filters:
                    </Typography>
                    {IconComponent(
                      FilterIcon,
                      "#E9F0ED",
                      () => setFilterModelOpen(true)
                    )}
                  </Box>
                  <Box ml={4}>
                    <Button variant='contained' onClick={() => setOpenPopup(true)}>
                      Upload file
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ borderBottom: 1, borderColor: "#F5E6F5", p: 2, pb: 0 }}
              >
                <Tabs
                  value={currentTab}
                  aria-label="basic tabs example"
                  onChange={handleChange}
                  sx={{
                    "MuiTabs-root css-112qdbf-MuiTabs-root": {
                      borderColor: "#F5E6F5",
                    },
                  }}
                >
                  <Tab
                    label="Edited Customers"
                    sx={{
                      textTransform: "capitalize",
                      color: "#1B001B",
                    }}
                  />
                  <Tab
                    label="New Customers"
                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                  />
                </Tabs>
              </Box>
              {isLoading || isLoadingUpload ? loading() : <DatGridJFileUpload
                label={fileLabels}
                count={excelUploadList?.total || 0}
                data={excelUploadList?.list}
                page={page}
                setPage={setPage}
                setSelectedUserId={setSelectedUploadedId}
                downloadExcel={(taskId) => {
                  downloadFile({ taskId: taskId })
                }}
              />}
            </Box>
          ) : (
            <Box display="flex" flex={1} width="100%" height="100vh">
              <AccessDenied />
            </Box>
          )}
        </Card>
        {openPopup && <UploadCsvFile
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          uploadFile={(file: File) => {
            console.log('inside file', file);

            uploadFile(file)
          }}
          currentTab={currentTab}

        />}
      </Box>
      <FilterUploadFile
        endDate={endDate}
        openModal={openfilterModel}
        resetFilter={() => {
          setStartDate("");
          setEndDate("");
          setPage(0);
          setStatus(null);
        }}
        setEndDate={setEndDate}
        setOpenModal={setFilterModelOpen}
        setStartDate={setStartDate}
        setStatus={setStatus}
      />
    </MainLayout>
  )
}
