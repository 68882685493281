import HttpClient from "../../utils/http";
import {
  choice_family_details,
  choice_family_filter,
  choice_filter,
  choice_user_details,
  choicesList,
  choicesListv2,
  choicesMaster,
  choicesResponseList,
  choicesViewResponse,
  submitted_choices_list,
} from "../constants/api_endpoints";

export function getChoicesList({ 
  size,
  page,
  keyword 
}:{
  size: number | null;
  page: number | null;
  keyword:string;
  }) {
    let url =choicesList + `?size=${size}&page=${page}`;
    if(keyword) url += `&keyword=${keyword}`;
  return HttpClient.get(url);
}

export function getChoiceResponseList({
  size,
  page,
  choiceId,
  ageFrom,
  ageTo,
  dateFrom,
  dateTo,
  gender,
  occupationId,
  incomeId,
  educationId,
  stateId, 
}: {
  size: number | null;
  page: number | null;
  choiceId: number | null;
  ageFrom: number | null;
  ageTo: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  gender: string | null;
  occupationId: number | null;
  incomeId: number | null;
  educationId: number | null;
  stateId: number | null; 
}) {
  let url =
    choicesResponseList + `?size=${size}&page=${page}&choiceId=${choiceId}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (ageFrom && ageTo) url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`;
  if (gender) url += `&gender=${gender}`;
  if (occupationId) url += `&occupationId=${occupationId}`;
  if (incomeId) url += `&incomeId=${incomeId}`;
  if (educationId) url += `&educationId=${educationId}`;
  if (stateId) url += `&stateId=${stateId}`;
  return HttpClient.get(url);
}

export function getChoicesViewResponse(params: Object) {
  return HttpClient.get(choicesViewResponse, { params: params });
}

export function downloadViewChoice({
  choiceAnswersId,
  format,
  choiceId,
}: {
  choiceAnswersId: number | null;
  format: string | null;
  choiceId: number | null;
}) {
  let url = `${choice_user_details}/${choiceId}/submitted/${choiceAnswersId}&format=${format}&download=true`;
  return HttpClient.get(url, { responseType: "blob" });
}

export function downloadChoice({
  choiceAnswersId,
  format,
  choiceId,
}: {
  choiceAnswersId: number | null;
  format: string | null;
  choiceId: number | null;
}) {
  let url =
    choice_user_details +
    `/${choiceId}/submitted/${choiceAnswersId}?type=user&format=${format}&download=true`;
  return HttpClient.get(url, { responseType: "blob" });
}

export function getSubmittedChoices({
  type,
  page,
  size,
  fromAge,
  toAge,
  gender,
  state,
  occupation,
  education,
  currentIncome,
  familyIncome,
  dateFrom,
  dateTo,
  choiceId,
  sortOrder,
  keyword
}: {
  type: string;
  page: number;
  size: number;
  fromAge: number | null;
  toAge: number | null;
  gender: string | null;
  state: number | null;
  occupation: number | null;
  education: number | null;
  currentIncome: number | null;
  familyIncome: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  choiceId: number | null;
  sortOrder: string | null;
  keyword:string;
}) {
  let url = `${submitted_choices_list}/${choiceId}/submitted?type=${type}&page=${page}&size=${size}`;
  if (fromAge && toAge) url += `&fromAge=${fromAge}&toAge=${toAge}`;
  if (gender) url += `&gender=${gender}`;
  if (state) url += `&state=${state}`;
  if (occupation) url += `&occupation=${occupation}`;
  if (education) url += `&education=${education}`;
  if (currentIncome) url += `&currentIncome=${currentIncome}`;
  if (familyIncome) url += `&familyIncome=${familyIncome}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if(keyword!==""){
    url += `&keyword=${keyword}`
  }

  return HttpClient.get(url);
}

export function downloadGetChoiceResponseList({
  page,
  size,
  type,
  fromAge,
  toAge,
  gender,
  state,
  occupation,
  education,
  currentIncome,
  familyIncome,
  dateFrom,
  dateTo,
  format,
  choiceId,
  sortOrder
}: {
  type: string;
  page: number;
  size: number;
  fromAge: number | null;
  toAge: number | null;
  gender: string | null;
  state: number | null;
  occupation: number | null;
  education: number | null;
  currentIncome: number | null;
  familyIncome: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  format: string | null;
  choiceId: number | null;
  sortOrder: string | null;
}) {
  let url = `${submitted_choices_list}/${choiceId}/submitted?type=${type}&format=${format}&download=true&page=${page}&size=${size}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  return HttpClient.get(url, { responseType: "blob" });
}

export function getChoiceUserDetails(id: number, choiceId: number) {
  return HttpClient.get(
    `${choice_user_details}/${choiceId}/submitted/${id}?type=user`
  );
}

export function getChoiceFamilyDetails(id: number, choiceId: number) {
  return HttpClient.get(
    `${choice_family_details}/${choiceId}/submitted/${id}?type=family`
  );
}

export function downloadChoiceFamilyDetails(id: number, choiceId: number) {
  return HttpClient.get(
    `${choice_family_details}/${choiceId}/submitted/${id}?type=family&download=true&format=excel`,
    { responseType: "blob" }
  );
}

export function getMasterData() {
  return HttpClient.get(choicesMaster);
}
export function getFilterData() {
  return HttpClient.get(choice_filter);
}
export function getFamilyFilterData() {
  return HttpClient.get(choice_family_filter);
}

export function submitChoices(payload: object) {
  return HttpClient.post(choicesListv2, payload);
}

export function getChoice(id: number) {
  return HttpClient.get(`${choicesListv2}${id}/eligibility`);
}

export function getChoiceQuestions(id: number) {
  return HttpClient.get(`${choicesListv2}${id}/questions`);
}

export function updateChoiceEligibility(payload: object, id: number) {
  return HttpClient.patch(`${choicesListv2}${id}/eligibility`, payload);
}

export function updateChoiceQuestions(payload: object, id: number) {
  return HttpClient.patch(`${choicesListv2}${id}/questions`, payload);
}
