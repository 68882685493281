import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { CloseOutlined, Done } from "@mui/icons-material";
import useUser from "../../../../providers/user/user_provider";
import { JobListingLabels } from "../../job_listing/job_listing";
import { JobApplicationModel } from "../../model/job_application_model";

interface DataGridInterface {
  labels: JobListingLabels[];
  data: JobApplicationModel[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  setPagination: (val: { page: number; rowsPerPage: number }) => void;
  pagination: { page: number; rowsPerPage: number };
  setSelectedUserId: (val: JobApplicationModel) => void;
  setCloseRejectJobsStatusDialog: (val: boolean) => void;
  setCloseDeleteJobsStatusDialog: (val: boolean) => void;
  updateServiceApiCall: ({
    statusId,
    jobId,
    comments,
  }: {
    statusId: number;
    jobId: number;
    comments: string;
  }) => void;
}

export default function DatGridJobApplication({
  labels,
  data,
  page,
  setPage,
  count,
  setPagination,
  pagination,
  setSelectedUserId,
  updateServiceApiCall,
  setCloseRejectJobsStatusDialog,
  setCloseDeleteJobsStatusDialog,
}: DataGridInterface) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * pagination.rowsPerPage + 1;
  const endRange = Math.min((page + 1) * pagination.rowsPerPage, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  const actionComponent = ({
    status,
    actionOneDisable,
    actionTwoDisable,
    selectedItem,
  }: {
    status: string;
    actionOneDisable?: boolean;
    actionTwoDisable?: boolean;
    selectedItem: JobApplicationModel;
  }) => {
    switch (status) {
      // case "Pending":
      // case "Modified":
      // case "Application Forwarded":
      case "In Review":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <IconButton
              disabled={!hasPermission("Jobs", "Update")}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUserId(selectedItem);
                updateServiceApiCall({
                  statusId: 2,
                  jobId: +selectedItem?.id,
                  comments: "",
                });
              }}
              sx={{
                backgroundColor: "#E9F0ED",
                borderRadius: 3,
                color: "#1E6446",
                opacity: 1,
              }}
            >
              <Done />
            </IconButton>

            <IconButton
              disabled={!hasPermission("Jobs", "Update")}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUserId(selectedItem);
                setCloseRejectJobsStatusDialog(true);
              }}
              sx={{
                backgroundColor: "#FAEDEA",
                borderRadius: 3,
                ml: 2,
                color: "#D14C2E",
                opacity: 1,
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        );
      case "Application Forwarded":
      case "Application Denied":
      case "Rejected":
      case "Shortlisted":
        return (
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <IconButton
              disabled
              sx={{
                backgroundColor: "#E9F0ED",
                borderRadius: 3,
                color: "#000000",
                opacity: actionOneDisable ? 0.4 : 1,
              }}
            >
              <Done />
            </IconButton>

            <IconButton
              disabled
              sx={{
                backgroundColor: "#FAEDEA",
                borderRadius: 3,
                ml: 2,
                color: "#D14C2E",
                opacity: 1,
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
        );
      default:
        break;
    }
  };

  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  function statusColorCodeBasedOnStatus({ status }: { status: string }) {
    if (status === "In Review") {
      return "#91278F";
    } else if (status === "Application Forwarded" || status === "Shortlisted") {
      return "#1E6446";
    } else {
      return "#D14C2E";
    }
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid item flex={index === labels?.length - 1 ? 1.2 : 1}>
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              let statusColorCode = statusColorCodeBasedOnStatus({
                status: item?.applicationStatus,
              });

              return (
                <Box
                  key={index}
                  onClick={(e) => {
                    navigate(`/jobs/jobsApplicationDetails/${item.id}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                      // backgroundColor: "#FAEDEA",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    {gridTypography({ text: index + 1 })}
                    {gridTypography({ text: item?.name })}
                    {gridTypography({ text: item?.education })}
                    {gridTypography({ text: item.dob })}
                    {gridTypography({ text: item?.mobileNumber })}
                    {gridTypography({ text: item?.emailId })}
                    {gridTypography({ text: item?.company })}
                    {gridTypography({ text: item?.state })}
                    {gridTypography({ text: item?.district })}

                    <Typography
                      display="flex"
                      flex={1}
                      fontSize={14}
                      fontWeight="600"
                      color={statusColorCode}
                    >
                      {item?.applicationStatus}
                    </Typography>

                    <Box display="flex" flex={1.2} alignItems={"center"}>
                      {actionComponent({
                        status: item.applicationStatus,
                        actionOneDisable:
                          item.applicationStatus === "Approved" ||
                          item.applicationStatus === "Rejected"
                            ? true
                            : false,
                        actionTwoDisable:
                          item.applicationStatus === "Rejected" ? true : false,
                        selectedItem: item,
                      })}
                      <Box>
                        <img src={ArrowRight} alt="" height={15} width={15} />
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
