import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "മുമ്പത്തെ",
   "pageNextText": "അടുത്തത്",
   "completeText": "പൂർത്തിയാക്കുക",
   "previewText": "പ്രിവ്യൂ",
   "editText": "എഡിറ്റ് ചെയ്യുക",
   "startSurveyText": "ആരംഭിക്കുക",
   "otherItemText": "മറ്റുള്ളവ (വിവരിക്കുക)",
   "noneItemText": "ഒന്നുമില്ല",
   "selectAllItemText": "എല്ലാം തിരഞ്ഞെടുക്കുക",
   "progressText": "പേജ് {0} / {1}",
   "indexText": "{0} / {1}",
   "panelDynamicProgressText": "{0} / {1}",
   "panelDynamicTabTextFormat": "പാനൽ {panelIndex}",
   "questionsProgressText": "{0}/{1} ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകി",
   "emptySurvey": "സർവേയിൽ ദൃശ്യമായ ഘടകങ്ങളൊന്നും അടങ്ങിയിട്ടില്ല.",
   "completingSurvey": "സർവേ പൂർത്തിയാക്കിയതിന് നന്ദി",
   "completingSurveyBefore": "നിങ്ങൾ ഇതിനകം ഈ സർവേ പൂർത്തിയാക്കിയതായി ഞങ്ങളുടെ രേഖകൾ കാണിക്കുന്നു.",
   "loadingSurvey": "സർവേ ലോഡ് ചെയ്യുന്നു...",
   "placeholder": "തിരഞ്ഞെടുക്കുക...",
   "ratingOptionsCaption": "തിരഞ്ഞെടുക്കുക...",
   "value": "മൂല്യം",
   "requiredError": "പ്രതികരണം ആവശ്യമാണ്.",
   "requiredErrorInPanel": "പ്രതികരണം ആവശ്യമാണ്: ഒരു ചോദ്യത്തിനെങ്കിലും ഉത്തരം നൽകുക.",
   "requiredInAllRowsError": "പ്രതികരണം ആവശ്യമാണ്: എല്ലാ വരികളിലും ചോദ്യങ്ങൾക്ക് ഉത്തരം നൽകുക.",
   "numericError": "മൂല്യം സംഖ്യാപരമായിരിക്കണം.",
   "minError": "മൂല്യം {0}-ൽ കുറവായിരിക്കരുത്",
   "maxError": "മൂല്യം {0}-നേക്കാൾ വലുതായിരിക്കരുത്",
   "textMinLength": "ദയവായി കുറഞ്ഞത് {0} പ്രതീകങ്ങളെങ്കിലും നൽകുക.",
   "textMaxLength": "ദയവായി {0} പ്രതീകങ്ങളിൽ കൂടുതൽ നൽകരുത്.",
   "textMinMaxLength": "ദയവായി കുറഞ്ഞത് {0} എങ്കിലും {1} പ്രതീകങ്ങളിൽ കൂടുതൽ നൽകരുത്.",
   "minRowCountError": "ദയവായി കുറഞ്ഞത് {0} വരി(കൾ) പൂരിപ്പിക്കുക.",
   "minSelectError": "ദയവായി കുറഞ്ഞത് {0} ഓപ്‌ഷൻ(കൾ) തിരഞ്ഞെടുക്കുക.",
   "maxSelectError": "{0} ഓപ്‌ഷൻ(കൾ) കൂടുതലൊന്നും തിരഞ്ഞെടുക്കുക.",
   "numericMinMax": "&#39;{0}&#39; കുറഞ്ഞത് {1} ഉം പരമാവധി {2} ഉം ആയിരിക്കണം",
   "numericMin": "&#39;{0}&#39; കുറഞ്ഞത് {1} ആയിരിക്കണം",
   "numericMax": "&#39;{0}&#39; പരമാവധി {1} ആയിരിക്കണം",
   "invalidEmail": "സാധുവായ ഒരു ഇമെയിൽ വിലാസം നൽകുക.",
   "invalidExpression": "പദപ്രയോഗം: {0} &#39;true&#39; നൽകണം.",
   "urlRequestError": "അഭ്യർത്ഥന പിശക് &#39;{0}&#39; നൽകി. {1}",
   "urlGetChoicesError": "അഭ്യർത്ഥന ശൂന്യമായ ഡാറ്റ നൽകി അല്ലെങ്കിൽ &#39;പാത്ത്&#39; പ്രോപ്പർട്ടി തെറ്റാണ്",
   "exceedMaxSize": "ഫയൽ വലുപ്പം {0} കവിയാൻ പാടില്ല.",
   "noUploadFilesHandler": "ഫയലുകൾ അപ്‌ലോഡ് ചെയ്യാൻ കഴിയില്ല. &#39;onUploadFiles&#39; ഇവന്റിനായി ഒരു ഹാൻഡ്‌ലർ ചേർക്കുക.",
   "otherRequiredError": "പ്രതികരണം ആവശ്യമാണ്: മറ്റൊരു മൂല്യം നൽകുക.",
   "uploadingFile": "നിങ്ങളുടെ ഫയൽ അപ്‌ലോഡ് ചെയ്യുന്നു. കുറച്ച് നിമിഷങ്ങൾ കാത്തിരുന്ന് വീണ്ടും ശ്രമിക്കുക.",
   "loadingFile": "ലോഡിംഗ്...",
   "chooseFile": "ഫയൽ(കൾ) തിരഞ്ഞെടുക്കുക...",
   "noFileChosen": "ഒരു ഫയലും തെരഞ്ഞെടുത്തിട്ടില്ല",
   "filePlaceholder": "ഇവിടെ ഒരു ഫയൽ വലിച്ചിടുക അല്ലെങ്കിൽ അപ്‌ലോഡ് ചെയ്യാൻ ഒരു ഫയൽ തിരഞ്ഞെടുക്കുന്നതിന് താഴെയുള്ള ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.",
   "confirmDelete": "നിങ്ങൾക്ക് റെക്കോർഡ് ഇല്ലാതാക്കണോ?",
   "keyDuplicationError": "ഈ മൂല്യം അദ്വിതീയമായിരിക്കണം.",
   "addColumn": "കോളം ചേർക്കുക",
   "addRow": "വരി ചേർക്കുക",
   "removeRow": "നീക്കം ചെയ്യുക",
   "emptyRowsText": "നിരകളൊന്നുമില്ല.",
   "addPanel": "പുതിയത് ചേർക്കുക",
   "removePanel": "നീക്കം ചെയ്യുക",
   "showDetails": "വിശദാംശങ്ങള് കാണിക്കുക",
   "hideDetails": "വിശദാംശങ്ങൾ മറയ്ക്കുക",
   "choices_Item": "ഇനം",
   "matrix_column": "കോളം",
   "matrix_row": "വരി",
   "multipletext_itemname": "വാചകം",
   "savingData": "ഫലങ്ങൾ സെർവറിൽ സംരക്ഷിക്കുന്നു...",
   "savingDataError": "ഒരു പിശക് സംഭവിച്ചു, ഞങ്ങൾക്ക് ഫലങ്ങൾ സംരക്ഷിക്കാനായില്ല.",
   "savingDataSuccess": "ഫലങ്ങൾ വിജയകരമായി സംരക്ഷിച്ചു!",
   "saveAgainButton": "വീണ്ടും ശ്രമിക്കുക",
   "timerMin": "മിനിറ്റ്",
   "timerSec": "സെക്കന്റ്",
   "timerSpentAll": "നിങ്ങൾ ഈ പേജിൽ {0} ചെലവഴിച്ചു, ആകെ {1}.",
   "timerSpentPage": "നിങ്ങൾ ഈ പേജിൽ {0} ചെലവഴിച്ചു.",
   "timerSpentSurvey": "നിങ്ങൾ ആകെ {0} ചെലവഴിച്ചു.",
   "timerLimitAll": "നിങ്ങൾ ഈ പേജിൽ {1} ൽ {0} ചെലവഴിച്ചു, ആകെ {3} ൽ {2} ചെലവഴിച്ചു.",
   "timerLimitPage": "നിങ്ങൾ ഈ പേജിൽ {1} ൽ {0} ചെലവഴിച്ചു.",
   "timerLimitSurvey": "നിങ്ങൾ ആകെ {1} ൽ {0} ചെലവഴിച്ചു.",
   "clearCaption": "ക്ലിയർ",
   "signaturePlaceHolder": "ഇവിടെ ഒപ്പിടുക",
   "chooseFileCaption": "ഫയൽ തിരഞ്ഞെടുക്കുക",
   "takePhotoCaption": "ഫോട്ടോ എടുക്കുക",
   "photoPlaceholder": "ക്യാമറ ഉപയോഗിച്ച് ഫോട്ടോ എടുക്കാൻ താഴെയുള്ള ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുക.",
   "fileOrPhotoPlaceholder": "ക്യാമറ ഉപയോഗിച്ച് അപ്‌ലോഡ് ചെയ്യാനോ ഫോട്ടോ എടുക്കാനോ ഒരു ഫയൽ വലിച്ചിടുക അല്ലെങ്കിൽ തിരഞ്ഞെടുക്കുക.",
   "replaceFileCaption": "ഫയൽ മാറ്റിസ്ഥാപിക്കുക",
   "removeFileCaption": "ഈ ഫയൽ നീക്കം ചെയ്യുക",
   "booleanCheckedLabel": "അതെ",
   "booleanUncheckedLabel": "ഇല്ല",
   "confirmRemoveFile": "ഈ ഫയൽ നീക്കം ചെയ്യണമെന്ന് തീർച്ചയാണോ: {0}?",
   "confirmRemoveAllFiles": "എല്ലാ ഫയലുകളും നീക്കം ചെയ്യണമെന്ന് തീർച്ചയാണോ?",
   "questionTitlePatternText": "ചോദ്യത്തിന്റെ തലക്കെട്ട്",
   "modalCancelButtonText": "റദ്ദാക്കുക",
   "modalApplyButtonText": "അപേക്ഷിക്കുക",
   "filterStringPlaceholder": "തിരയാൻ ടൈപ്പ് ചെയ്യുക...",
   "emptyMessage": "പ്രദർശിപ്പിക്കാൻ ഡാറ്റയില്ല",
   "noEntriesText": "ഇതുവരെ എൻട്രികളൊന്നുമില്ല. ഒരു പുതിയ എൻട്രി ചേർക്കുന്നതിന് ചുവടെയുള്ള ബട്ടണിൽ ക്ലിക്കുചെയ്യുക.",
   "noEntriesReadonlyText": "എൻട്രികൾ ഒന്നുമില്ല.",
   "more": "കൂടുതൽ",
   "tagboxDoneButtonCaption": "ശരി",
   "selectToRankEmptyRankedAreaText": "എല്ലാ തിരഞ്ഞെടുപ്പുകളും റാങ്ക് ചെയ്‌തിരിക്കുന്നു",
   "selectToRankEmptyUnrankedAreaText": "റാങ്ക് ചെയ്യാൻ ചോയ്‌സുകൾ ഇവിടെ വലിച്ചിടുക",
   "ok": "ശരി",
   "cancel": "റദ്ദാക്കുക"
};


surveyLocalization.locales["ml"] = dict;