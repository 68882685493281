import HttpClient from "../../utils/http";
import { rewards, rewards_listing } from "../constants/api_endpoints";

export function getRewardsList({
  rewardFrom,
  rewardTo,
  page,
  size,
  keyword,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  area,
  gender,
  stateId,
  city,
  district
}: {
  district: number | null;
  rewardFrom: number | null;
  rewardTo: number | null;
  page: number;
  size: number;
  keyword: string;
  startDate: string | null;
  endDate: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  area: string | null;
  gender: string | null;
  stateId: number | null;
  city: number | null;
}) {
  let url = `${rewards_listing}?page=${page}&size=${size}`;
  if (district) url += `&district=${district}`;
  if (rewardFrom && rewardTo) url += `&from=${rewardFrom}&to=${rewardTo}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (startDate !== "" && endDate !== "") {
    url += `&dateFrom=${startDate}&dateTo=${endDate}`
  }
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (area !== "") {
    url += `&area=${area}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  if (city !== null) {
    url += `&city=${city}`
  }
  if (stateId !== null) {
    url += `&state=${stateId}`
  }
  return HttpClient.get(url);
}

export function getRewardsListById({ userId }: { userId: number }) {
  let url = `${rewards}/user/${userId} `;
  return HttpClient.get(url);
}

export function getRewardsStatement({
  userId,
  page,
  size,
}: {
  userId: number;
  page: number;
  size: number;
}) {
  let url = `${rewards}/user/${userId}/statement?page=${page}&size=${size}`;
  return HttpClient.get(url);
}

export function redemption({
  userId,
  points,
  comments,
}: {
  userId: number;
  points: number;
  comments: string;
}) {
  let url = `${rewards}/${userId}/redemption`;
  return HttpClient.post(url, {
    points: points,
    comments: comments,
  });
}
export function getRedemptionHistory({
  userId,
  page,
  size,
}: {
  userId: number;
  page: number;
  size: number;
}) {
  let url = `${rewards}/redemption-history/${userId}/redemptions?page=${page}&size=${size}`;
  return HttpClient.get(url);
}

export function updateRedemptionComments({
  userId,
  comments,
}: {
  userId: number;
  comments: string;
}) {
  let url = `${rewards}/redemption/${userId}`;
  return HttpClient.patch(url, {
    comments: comments,
  });
}
