const locales = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "hi",
    name: "Hindi",
  },
  {
    code: "te",
    name: "Telugu",
  },
  {
    code: "ta",
    name: "Tamil",
  },
  {
    code: "bn",
    name: "Bengali",
  },
  {
    code: "kn",
    name: "Kannada",
  },
  {
    code: "ml",
    name: "Malayalam",
  },
  {
    code: "mr",
    name: "Marathi",
  },
  {
    code: "gu",
    name: "Gujarati",
  },
  {
    code: "ur",
    name: "Urdu",
  },
  {
    code: "pa",
    name: "Punjabi",
  },
  {
    code: "or",
    name: "Odia",
  },
  {
    code: "as",
    name: "Assamese",
  },
];

export default locales;
