import React from "react";
import MainLayout from "../../layouts/main/main_layout";
import ComingSoon from "../coming_soon/coming_soon";
import { SurveyJs } from "../../components/surveyjs/surveyjs";

export default function Analytics() {
  return (
    <MainLayout>
      <ComingSoon />
    </MainLayout>
  );
}
