export const commonEndpoint = "v1/admin";
export const commonEndpointV2 = "v2/admin";

export const adminBaseUrl = "v1/admin/users";
export const adminList = `${adminBaseUrl}/`;
export const getAdminPermissions = `${adminBaseUrl}/permissions}`;
export const adminRoles = `${adminBaseUrl}/roles/`;
export const userPermissions = `${adminBaseUrl}/permissions/`;
export const featuresAndPermissions = `${adminBaseUrl}/features-and-permissions/`;

export const needsBaseURL = `v1/admin/needs`;
export const needsList = `${needsBaseURL}/needs-list/`;
export const needDetails = `${needsBaseURL}/need-details/`;
export const needsDownload = `${needsBaseURL}/needs-excel-pdf-convert/`;
export const needStatus = `${needsBaseURL}/needs-status/`;
export const needsFilters = `${needsBaseURL}/needs-filter/`;
export const needsArea = `${needsBaseURL}/areas`;
export const needsBrand = `${needsBaseURL}/brand`;

export const schemesBaseURL = "v1/admin/schemes";
export const schemesMasters = `${schemesBaseURL}/master/`;
export const enquirySchemeList = `${commonEndpoint}/schemes/enquired-schemes-list/`;
export const enquirySchemeListById = `${commonEndpoint}/schemes/enquired/`;
export const schemesList = `${commonEndpoint}/schemes/list`;
export const addSchemeAPI = `${schemesBaseURL}/`;
export const schemeDetails = `${schemesBaseURL}/`;
export const schemeEligibility = `${schemesBaseURL}/eligibility/`;
export const schemes = `${commonEndpoint}/schemes/`;

export const masterSchemes = `${commonEndpoint}/schemes/master/`;
export const stateMaster = `${commonEndpoint}/master/state`;
export const districtMaster = `${commonEndpoint}/master/district/`;
export const cityMaster = `${commonEndpoint}/master/city/`;

export const choicesList = `${commonEndpointV2}/choices/`;
export const choicesListv2 = `${commonEndpointV2}/choices/`;
export const choicesResponseList = `${commonEndpoint}/choices/choices/`;
export const choicesViewResponse = `${commonEndpoint}/choices/choice-basic/`;
export const choicesMaster = `${commonEndpointV2}/choices/masters`;
export const submitted_choices_list = `${commonEndpointV2}/choices/submitted-choices-list`;
export const choice_user_details = `${commonEndpointV2}/choices/users-choice-details`;
export const choice_family_details = `${commonEndpointV2}/choices/family-choice-details`;
export const choice_filter = `${commonEndpointV2}/choices/filter`;
export const choice_family_filter = `${commonEndpointV2}/choices/family-filter`;

export const services = `${commonEndpoint}/services/`;
export const services_details = `${commonEndpoint}/services/service-details/`;
export const status_list = `${commonEndpoint}/services/status-list/`;
export const filter_list_filter = `${commonEndpoint}/services/filters`;

export const jobs = `${commonEndpoint}/jobs/jobs-list`;
export const job_application = `${commonEndpoint}/jobs/job-applications-list`;
export const jobs_application_status = `${commonEndpoint}/jobs/application-status/`;
export const jobs_status = `${commonEndpoint}/jobs/job-status/`;
export const job_filter = `${commonEndpoint}/jobs/filters/`;
export const jobs_application_filter = `${commonEndpoint}/jobs/application/filters`;
export const job = `${commonEndpoint}/jobs/`;
export const job_application_details = `${commonEndpoint}/jobs/application/`;

export const surveyor_list = `${commonEndpoint}/surveyors/list`;
export const surveyors = `${commonEndpoint}/surveyors/`;
export const surveyor_submitted_choice = `${commonEndpoint}/surveyors/submitted-choice-list`;
export const surveyor_download_List = `${commonEndpoint}/surveyors/submitted-choice-list/survey-report-tasks`;
export const surveyor_download=`${commonEndpoint}/surveyors/submitted-choice-list/download-survey-report`
export const surveyor_submitted_choice_details = `${commonEndpoint}/surveyors/submitted-choice-details`;
export const surveyor_submitted_recording = `${commonEndpoint}/surveyors/submitted`;
export const surveyors_filter = `${commonEndpoint}/surveyors/filters`;

export const app_users_list = `${commonEndpoint}/users/app-users/list`;
export const app_users_download_list = `${commonEndpoint}/users/app-users/user-download-tasks-list`;
export const download_users_data = `${commonEndpoint}/users/app-users/download-user-report`;
export const app_users = `${commonEndpoint}/users/app-users/`;

export const rewards_listing = `${commonEndpoint}/rewards/rewards-list`;
export const rewards = `${commonEndpoint}/rewards`;

export const getExcelUploadedList = `${adminBaseUrl}/app-users/excel-upload-list`;
export const uploadexcelFileEndPoint= `${adminBaseUrl}/app-users/add-edit-user/upload`;
export const downloadExcel = `${adminBaseUrl}/app-users/download-excel-file/download`;
export const create_user = `${adminBaseUrl}/app-users/add-new-user`;
export const modify_user = `${adminBaseUrl}/app-users/edit-user`;