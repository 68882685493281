import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { PersonelDetails } from '../../model/create_user';
import { SchemeMaster } from '../../../schemes/models/scheme_master';
import { useNavigate } from 'react-router-dom';

interface props {
    onSubmit: (data: PersonelDetails) => void;
    uid: number | null;
    setUid: (val: number) => void;
    stepperCount: number;
    setStepperCount: (val: number) => void;
    masterDataList: SchemeMaster;
    personalDeatails: PersonelDetails;
    modifyUser?: boolean | undefined
}

export const subscribeToNewsLetter = [
    { id: 1, value: 'Yes', b_value: true },
    { id: 2, value: 'No', b_value: false },

];

export default function PersonalDetails({
    stepperCount,
    onSubmit: _onSubmit,
    setStepperCount,
    masterDataList,
    personalDeatails,
    modifyUser
}
    : props) {
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<PersonelDetails>({
        defaultValues: {
            meritalStatus: {
                id: personalDeatails?.meritalStatus?.id!,
                value: personalDeatails?.meritalStatus?.value!
            },
            familyIncome: {
                id: +personalDeatails?.familyIncome?.id!,
                value: personalDeatails?.familyIncome?.value!
            },
            gender: {
                id: personalDeatails?.gender?.id,
                value: personalDeatails?.gender?.value!
            },
            subscribeToNewsLatter: {
                id: personalDeatails?.subscribeToNewsLatter?.id,
                value: personalDeatails?.subscribeToNewsLatter?.value!
            }
        },
    },);
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<PersonelDetails> = (data) => {
        setStepperCount(stepperCount + 1)
        _onSubmit(data);
    }

    useEffect(() => {
        setValue("dob", personalDeatails?.dob)
        setValue("email", personalDeatails?.email)
        setValue("familyIncome", personalDeatails?.familyIncome)
        setValue("fullName", personalDeatails?.fullName)
        setValue("gender", personalDeatails?.gender)
        setValue("mobileNumber", personalDeatails?.mobileNumber)
        setValue("newsLatterEmailId", personalDeatails?.newsLatterEmailId)
        setValue("subscribeToNewsLatter", personalDeatails?.subscribeToNewsLatter!)
        setValue("meritalStatus", personalDeatails?.meritalStatus!)
    }, [personalDeatails, setValue]);


    const today = new Date().toISOString().split('T')[0];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container mt={3} spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="fullName"
                        control={control}
                        rules={{
                            required: "Please enter full name",
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                fullWidth
                                label="Full Name"
                                placeholder="Enter full name"
                                value={value}
                                {...register("fullName", {
                                })}
                                error={!!errors.fullName}
                                helperText={errors.fullName?.message}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="dob"
                        control={control}
                        rules={{
                            required: "Please enter date of birth",
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                type='date'
                                fullWidth
                                label="Date of birth"
                                placeholder="Please select date of birth"
                                datatype=''
                                {...register("dob", {
                                })}
                                value={value}
                                error={!!errors.dob}
                                helperText={errors.dob?.message}
                                inputProps={{ max: today }}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="gender"
                        control={control}
                        rules={{
                            required: "Please enter gender",
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value?.value === undefined ? null : value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Gender"
                                        error={!!errors.gender}
                                        helperText={errors.gender?.message} />}
                                options={masterDataList?.gender?.filter((item) => !item?.is_aggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}

                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="meritalStatus"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                value={value?.value === undefined ? null : value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Marital Status (Optional)"

                                    />}
                                options={masterDataList?.maritalStatus.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            validate: value =>
                                !value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) || "Invalid email address"
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                fullWidth
                                label="Email (Optional)"
                                placeholder="Enter Email"
                                value={value}
                                onChange={onChange}

                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller

                        name="mobileNumber"
                        control={control}
                        rules={{
                            required: "Please enter Mobile number",
                            pattern: {
                                value: /^[0-9]{10}$/,
                                message: "Phone number must be 10 digits long and numeric"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                disabled={modifyUser}
                                error={!!errors.mobileNumber}
                                helperText={errors.mobileNumber?.message}
                                fullWidth
                                label="Mobile number"
                                placeholder="Enter Mobile number"
                                value={value}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                                    if (newValue.length <= 10) {
                                        onChange(newValue);
                                    }
                                }}
                                inputProps={{ maxLength: 10 }}

                            />
                        )}
                    />
                </Grid>
                {/* <Grid item md={6} lg={6} sm={12} xs={12}></Grid> */}
                {/* <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="familyIncome"
                        control={control}
                        rules={{
                            required: "Please select family income",
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Family Income"
                                        error={!!errors.familyIncome}
                                        helperText={errors.familyIncome?.message}
                                    />
                                )}
                                options={masterDataList?.familyIncome.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="subscribeToNewsLatter"
                        control={control}
                        rules={{
                            required: "Please select subcribe to news letter",
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value?.value === undefined ? null : value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params}
                                    label="Subscribed to Newsletter"
                                    error={!!errors.subscribeToNewsLatter}
                                    helperText={errors.subscribeToNewsLatter?.message} />}
                                options={subscribeToNewsLetter}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                {/* <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="newsLatterEmailId"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.newsLatterEmailId}
                                helperText={errors.newsLatterEmailId?.message}
                                fullWidth
                                label="Newsletter Email ID"
                                placeholder="Enter Newsletter Email ID"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item md={6} lg={6} sm={12} xs={12}>
                </Grid>
                <Grid display={'flex'} item md={12} lg={12} sm={12} xs={12} justifyContent={'end'}>
                    {!!!modifyUser && <Button variant='outlined' sx={{ minWidth: 150 }} onClick={() => {
                        navigate('/users')
                    }}>
                        Cancel
                    </Button>}
                    <Button
                        variant='contained'
                        type="submit"
                        sx={{ ml: 1, minWidth: 150 }}>
                        {!!!modifyUser ? "Proceed" : "Save Changes"}
                    </Button>
                </Grid>
            </Grid >
        </form>
    )
}
