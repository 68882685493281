import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "আগে",
   "pageNextText": "পরবর্তী",
   "completeText": "সম্পূর্ণ",
   "previewText": "পূর্বরূপ",
   "editText": "সম্পাদনা করুন",
   "startSurveyText": "শুরু করুন",
   "otherItemText": "অন্যান্য (বর্ণনা)",
   "noneItemText": "কোনোটিই নয়",
   "selectAllItemText": "সব নির্বাচন করুন",
   "progressText": "{1}টির মধ্যে {0} পৃষ্ঠা",
   "indexText": "{1} এর মধ্যে {0}",
   "panelDynamicProgressText": "{1} এর মধ্যে {0}",
   "panelDynamicTabTextFormat": "প্যানেল {panelIndex}",
   "questionsProgressText": "{0}/{1}টি প্রশ্নের উত্তর দিয়েছেন",
   "emptySurvey": "সমীক্ষায় কোনো দৃশ্যমান উপাদান নেই।",
   "completingSurvey": "জরিপ শেষ করার জন্য আপনাকে ধন্যবাদ",
   "completingSurveyBefore": "আমাদের রেকর্ডগুলি দেখায় যে আপনি ইতিমধ্যে এই সমীক্ষাটি সম্পূর্ণ করেছেন৷",
   "loadingSurvey": "সমীক্ষা লোড হচ্ছে...",
   "placeholder": "নির্বাচন করুন...",
   "ratingOptionsCaption": "নির্বাচন করুন...",
   "value": "মান",
   "requiredError": "প্রতিক্রিয়া প্রয়োজন.",
   "requiredErrorInPanel": "উত্তর প্রয়োজন: কমপক্ষে একটি প্রশ্নের উত্তর দিন।",
   "requiredInAllRowsError": "উত্তর প্রয়োজন: সমস্ত সারিতে প্রশ্নের উত্তর দিন।",
   "numericError": "মান সাংখ্যিক হওয়া উচিত।",
   "minError": "মান {0} এর কম হওয়া উচিত নয়",
   "maxError": "মান {0} এর বেশি হওয়া উচিত নয়",
   "textMinLength": "অনুগ্রহ করে কমপক্ষে {0}টি অক্ষর(গুলি) লিখুন৷",
   "textMaxLength": "অনুগ্রহ করে {0}টির বেশি অক্ষর(গুলি) লিখবেন না৷",
   "textMinMaxLength": "অনুগ্রহ করে কমপক্ষে {0} এবং {1}টির বেশি অক্ষর লিখবেন না।",
   "minRowCountError": "অনুগ্রহ করে কমপক্ষে {0}টি সারি পূরণ করুন।",
   "minSelectError": "অনুগ্রহ করে অন্তত {0}টি বিকল্প নির্বাচন করুন৷",
   "maxSelectError": "অনুগ্রহ করে {0}টির বেশি বিকল্প নির্বাচন করবেন না।",
   "numericMinMax": "&#39;{0}&#39; কমপক্ষে {1} এবং সর্বাধিক {2} হওয়া উচিত",
   "numericMin": "&#39;{0}&#39; কমপক্ষে {1} হওয়া উচিত",
   "numericMax": "&#39;{0}&#39; সর্বোচ্চ {1} হওয়া উচিত",
   "invalidEmail": "একটি বৈধ ইমেইল ঠিকানা লিখুন.",
   "invalidExpression": "অভিব্যক্তি: {0}টি &#39;সত্য&#39; ফেরত দিতে হবে।",
   "urlRequestError": "অনুরোধটি ত্রুটি &#39;{0}&#39; ফিরিয়ে দিয়েছে। {1}",
   "urlGetChoicesError": "অনুরোধটি খালি ডেটা ফেরত দিয়েছে বা &#39;পাথ&#39; বৈশিষ্ট্যটি ভুল",
   "exceedMaxSize": "ফাইলের আকার {0} এর বেশি হওয়া উচিত নয়৷",
   "noUploadFilesHandler": "ফাইল আপলোড করা যাবে না. অনুগ্রহ করে &#39;onUploadFiles&#39; ইভেন্টের জন্য একটি হ্যান্ডলার যোগ করুন।",
   "otherRequiredError": "প্রতিক্রিয়া প্রয়োজন: অন্য মান লিখুন।",
   "uploadingFile": "আপনার ফাইল আপলোড হচ্ছে. কয়েক সেকেন্ড অপেক্ষা করুন এবং আবার চেষ্টা করুন.",
   "loadingFile": "লোড হচ্ছে...",
   "chooseFile": "ফাইল বেছে নিন)...",
   "noFileChosen": "কোনো ফাইল পছন্দ করা হইনি",
   "filePlaceholder": "এখানে একটি ফাইল টেনে আনুন এবং ড্রপ করুন বা আপলোড করার জন্য একটি ফাইল নির্বাচন করতে নীচের বোতামে ক্লিক করুন৷",
   "confirmDelete": "আপনি কি রেকর্ড মুছে দিতে চান?",
   "keyDuplicationError": "এই মান অনন্য হতে হবে.",
   "addColumn": "কলাম যোগ করুন",
   "addRow": "সারি যোগ করুন",
   "removeRow": "অপসারণ",
   "emptyRowsText": "কোন সারি আছে.",
   "addPanel": "নতুন যোগ করুন",
   "removePanel": "অপসারণ",
   "showDetails": "বিস্তারিত দেখাও",
   "hideDetails": "আড়াল বিস্তারিত",
   "choices_Item": "আইটেম",
   "matrix_column": "কলাম",
   "matrix_row": "সারি",
   "multipletext_itemname": "পাঠ্য",
   "savingData": "ফলাফল সার্ভারে সংরক্ষিত হচ্ছে...",
   "savingDataError": "একটি ত্রুটি ঘটেছে এবং আমরা ফলাফলগুলি সংরক্ষণ করতে পারিনি৷",
   "savingDataSuccess": "ফলাফল সফলভাবে সংরক্ষিত হয়েছে!",
   "saveAgainButton": "আবার চেষ্টা কর",
   "timerMin": "মিনিট",
   "timerSec": "সেকেন্ড",
   "timerSpentAll": "আপনি এই পৃষ্ঠায় {0} এবং মোট {1} খরচ করেছেন৷",
   "timerSpentPage": "আপনি এই পৃষ্ঠায় {0} খরচ করেছেন৷",
   "timerSpentSurvey": "আপনি মোট {0} খরচ করেছেন।",
   "timerLimitAll": "আপনি এই পৃষ্ঠায় {1} এর মধ্যে {0} এবং মোট {3}টির মধ্যে {2} খরচ করেছেন৷",
   "timerLimitPage": "আপনি এই পৃষ্ঠায় {1}টির মধ্যে {0} খরচ করেছেন৷",
   "timerLimitSurvey": "আপনি মোট {1} এর মধ্যে {0} খরচ করেছেন৷",
   "clearCaption": "পরিষ্কার",
   "signaturePlaceHolder": "এখানে স্বাক্ষর কর",
   "chooseFileCaption": "নথি নির্বাচন",
   "takePhotoCaption": "ছবি তোল",
   "photoPlaceholder": "ক্যামেরা ব্যবহার করে ছবি তুলতে নিচের বোতামে ক্লিক করুন।",
   "fileOrPhotoPlaceholder": "ক্যামেরা ব্যবহার করে একটি ছবি আপলোড করতে বা তোলার জন্য একটি ফাইল টেনে আনুন বা নির্বাচন করুন।",
   "replaceFileCaption": "ফাইল প্রতিস্থাপন করুন",
   "removeFileCaption": "এই ফাইলটি সরান",
   "booleanCheckedLabel": "হ্যাঁ",
   "booleanUncheckedLabel": "না",
   "confirmRemoveFile": "আপনি কি নিশ্চিত যে আপনি এই ফাইলটি সরাতে চান: {0}?",
   "confirmRemoveAllFiles": "আপনি কি নিশ্চিত যে আপনি সমস্ত ফাইল সরাতে চান?",
   "questionTitlePatternText": "প্রশ্নের শিরোনাম",
   "modalCancelButtonText": "বাতিল করুন",
   "modalApplyButtonText": "আবেদন করুন",
   "filterStringPlaceholder": "অনুসন্ধান করতে টাইপ করুন...",
   "emptyMessage": "কোন তথ্য প্রদর্শন",
   "noEntriesText": "এখনও কোনো এন্ট্রি নেই৷ একটি নতুন এন্ট্রি যোগ করতে নীচের বোতামে ক্লিক করুন৷",
   "noEntriesReadonlyText": "কোন এন্ট্রি আছে.",
   "more": "আরও",
   "tagboxDoneButtonCaption": "ঠিক আছে",
   "selectToRankEmptyRankedAreaText": "সব পছন্দ র্যাঙ্ক করা হয়",
   "selectToRankEmptyUnrankedAreaText": "পছন্দগুলিকে র‍্যাঙ্ক করতে এখানে টেনে আনুন",
   "ok": "ঠিক আছে",
   "cancel": "বাতিল করুন"
};

surveyLocalization.locales["bn"] = dict;