import { ACCESS_TOKEN, REFRESH_TOKEN } from "../constants";

export default class Session {
  static getToken() {
    if (this.isLoggedIn()) {
      return sessionStorage.getItem(ACCESS_TOKEN);
    } else {
      return null;
    }
  }
  static getRefreshToken() {
    if (this.isLoggedIn()) {
      return sessionStorage.getItem(REFRESH_TOKEN);
    } else {
      return null;
    }
  }
  static isLoggedIn(): boolean {
    let accessToken = sessionStorage.getItem(ACCESS_TOKEN);
    let refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
    if (accessToken != null && refreshToken != null) {
      return true;
    } else {
      return false;
    }
  }
  static login(accessToken: string, refreshToken: string) {
    sessionStorage.setItem(ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  static logout() {
    sessionStorage.removeItem(ACCESS_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
  }
}
