import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { EnquiryLabels } from "../../../schemes/enquiries/enquiries_list";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/loading/loading";
import dayjs from "dayjs";
import { SubmittedChoices } from "../../model/submitted_choices";

interface DataGridInterface {
  labels: EnquiryLabels[];
  data: SubmittedChoices[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  isLoading: boolean;
}

export default function DataGridFamilyChoicesResponses({
  labels,
  data,
  page,
  setPage,
  count,
  isLoading,
}: DataGridInterface) {
  const navigate = useNavigate();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid item flex={1}>
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {isLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Loading />
          </Box>
        ) : data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              const originalDate = dayjs(item?.submittedOn);
              const formattedDate = originalDate?.format?.("DD MMM YYYY");
              return (
                <Box
                  onClick={() => {
                    navigate(`/choices/familyResponseDetails/${item?.id}`, {
                      state: {
                        id: item?.choiceId,
                      },
                    });
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "#FAEDEA",
                    },
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.id}
                    </Typography>
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.choiceName}
                    </Typography>
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.name}
                    </Typography>
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.mobileNumber}
                    </Typography>
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.memberName}
                    </Typography>
                    <Typography display="flex" flex={1} fontSize={14}>
                      {item?.relationship}
                    </Typography>
                    <Box display="flex" flex={1}>
                      <Typography flex={1} fontSize={14}>
                        {formattedDate}
                      </Typography>
                      <img src={ArrowRight} alt="" height={15} width={15} />
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={8}
          rowsPerPageOptions={[]}
        />
      </CardActions>
    </>
  );
}
