import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../layouts/main/main_layout";

import { getRoles } from "../../../../services/admin/admin_services";
import { RoleList } from "../../model/admin_roles";
import { useQuery } from "react-query";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    ".css-18918z0-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "#1B001B",
    },
  };
});
export default function ModifyAdminDetails() {
  const navigate = useNavigate();
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [adminRole, setAdminRole] = useState<RoleList>({} as RoleList);
  const [error, setError] = useState(false);

  const { data } = useQuery(["getRoles"], async () => {
    let response = await getRoles();
    return response.data.data.list;
  });

  const modifyAdminApiCall = () => {
    let payload = {
      name: adminName,
      email_id: adminEmailId,
      role_id: adminRole.id,
    };
    //api call
  };
  const submitForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (emailRegex?.test(adminEmailId)) {
      //
    } else {
      setError(true);
    }
  };

  return (
    <MainLayout>
      <Grid container spacing={3} mt={3}>
        <Grid item md={12} sm={12} xs={12} lg={6} xl={12}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box
                  width={200}
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                >
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Modify Admin
                  </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Admin Name"
                      placeholder="Enter Admin name"
                      value={adminName}
                      onChange={(event) => {
                        setAdminName(event?.target.value);
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Email id"
                      placeholder="Enter Email id"
                      value={adminEmailId}
                      onChange={(event) => {
                        setAdminEmailId(event?.target.value);
                      }}
                    />
                    {error && (
                      <FormHelperText sx={{ color: "red", m: 0 }}>
                        Please Enter valid Email
                      </FormHelperText>
                    )}
                  </Box>
                  <Box mt={3}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">Select Role</InputLabel>
                      <Select
                        labelId="role-label"
                        label="Select an Option"
                        fullWidth
                        placeholder="Select an Option"
                        value={adminRole.role}
                      >
                        {data?.map((role: RoleList) => (
                          <MenuItem
                            onClick={() => {
                              setAdminRole(role);
                            }}
                            value={role.role}
                            className={role.role}
                          >
                            {role.role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box display="flex" flexDirection={"row"} mt={2}>
                    <Typography variant="subtitle2">Role access:</Typography>

                    {adminRole?.features?.[0].permissions.map((permission) => {
                      return (
                        <Typography
                          variant="subtitle2"
                          sx={{ color: " #91278F" }}
                          ml={0.5}
                        >
                          {permission.name}{" "}
                        </Typography>
                      );
                    })}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/admins")}
                      sx={{
                        minWidth: 200,
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={
                        adminName && Object?.keys?.(adminRole)?.length
                          ? submitForm
                          : undefined
                      }
                      variant="contained"
                      sx={{
                        display: "flex",
                        color: "#ffffff",
                        opacity:
                          adminName && Object?.keys?.(adminRole)?.length
                            ? 1
                            : 0.5,
                        minWidth: 200,
                      }}
                    >
                      Modify Admin
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
