import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import AccessDenied from "../../../components/access_denied/access_denied";
import MainLayout from "../../../layouts/main/main_layout";
import SchemeLayout from "../../../layouts/schemes/scheme_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { queryClient } from "../../../providers/query/query";
import useUser from "../../../providers/user/user_provider";
import {
  deleteSchemes,
  getSchemes,
  sendNotification,
} from "../../../services/schemes/schemes";
import NotifyUserPopup from "../components/notify_user_popup/notify_user_popup";
import SchemesListing from "../components/schemes_listing.tsx/schemes_listing";
import DeleteScheme from "../delete_scheme/delete_scheme";
import { Scheme } from "../models/schemes";

export default function Schemes() {
  const [deleteScheme, setDeleteScheme] = useState(false);
  const [viewSchemeDetails, setViewSchemeDetails] = useState(false);
  const [selectedSchemeDetails, setSelectedSchemeDetails] = useState<Scheme>(
    {} as Scheme
  );
  const [searchParams, setUrlParam] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(
    searchParams?.has("page") ? parseInt(searchParams.get("page")!) - 1 : 0
  );

  const [notification, setNotification] = useState<{
    [key: string]: {
      title: string;
      description: string;
      enabled: boolean;
      sameAsEnglish: boolean;
    };
  }>({});
  const [sameAsEnglish, setSameAsEnglish] = useState<Object>({
    hi: true,
    te: true,
    ta: true,
    bn: true,
    kn: true,
    ml: true,
    mr: true,
    gu: true,
    ur: true,
    pa: true,
    or: true,
    as: true,
  });
  const [openNotificationPopUp, setOpenNotificationPopup] = useState(false);
  const { showSnackbar } = useFeedback();
  const { hasPermission } = useUser();

  useEffect(() => {
    setUrlParam({ page: (page + 1).toString() });
  }, [page, setUrlParam]);

  const { data: schemes, isLoading } = useQuery(
    ["schemes", searchValue, page],
    async () => {
      let payload = {
        page: page + 1,
        size: 8,
        keyword: searchValue,
      };
      let response = await getSchemes(payload);
      return response.data.data;
    }
  );

  const deleteSchemeApiCall = () => {
    deleteSchemes(selectedSchemeDetails?.schemeId)
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Scheme Deleted Successfully");
          queryClient.invalidateQueries({ queryKey: ["schemes"] });
        }
      })
      .catch((e) => {
        showSnackbar(e?.response?.data?.error?.message);
      });
    setDeleteScheme(false);
  };

  function submitNotification() {
    let payload: { title: string; description: string; locale: string }[] = [];

    Object.keys(notification).map((locale) => {
      if (notification[locale].enabled) {
        payload.push({
          title: notification[locale].title,
          description: notification[locale].sameAsEnglish
            ? notification["en"].description
            : notification[locale].description,
          locale: locale,
        });
      }
    });

    sendNotification(payload, selectedSchemeDetails?.schemeId)
      .then((res) => {
        showSnackbar("Notification Sent Successfully!");
        setNotification({});
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
        setNotification({});
      });
    setOpenNotificationPopup(false);
  }

  return (
    <MainLayout>
      {hasPermission("Schemes", "Read") ? (
        <SchemeLayout>
          <SchemesListing
            searchList={schemes?.list || []}
            deleteScheme={deleteScheme}
            viewSchemeDetails={viewSchemeDetails}
            selectedSchemeDetails={selectedSchemeDetails}
            setSelectedSchemeDetails={setSelectedSchemeDetails}
            setDeleteScheme={setDeleteScheme}
            setViewSchemeDetails={setViewSchemeDetails}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            page={page}
            setPage={setPage}
            count={schemes?.total || 0}
            isLoading={isLoading}
            setNotificationPopUp={setOpenNotificationPopup}
          />
          <DeleteScheme
            deleteScheme={deleteScheme}
            setDeleteScheme={setDeleteScheme}
            selectedSchemeDetails={selectedSchemeDetails}
            deleteSchemeApiCall={deleteSchemeApiCall}
          />
          {openNotificationPopUp && (
            <NotifyUserPopup
              schemeId={selectedSchemeDetails.schemeId.toString()}
              openPopup={openNotificationPopUp}
              setOpenPopup={setOpenNotificationPopup}
              notification={notification}
              setNotification={setNotification}
              sameAsEnglish={sameAsEnglish}
              setSameAsEnglish={setSameAsEnglish}
              submit={submitNotification}
              setOpenNotificationPopup={setOpenNotificationPopup}
            />
          )}
        </SchemeLayout>
      ) : (
        <Box display="flex" flex={1} width="100%" height="100%">
          <AccessDenied />
        </Box>
      )}
    </MainLayout>
  );
}
