import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { LocationDetails } from '../../model/create_user'
import { SchemeMaster } from '../../../schemes/models/scheme_master';
import { useQuery } from 'react-query';
import { getDistrictMasterData } from '../../../../services/master/master_service';

interface props {
    onSubmit: (data: LocationDetails) => void;
    uid: number | null;
    setUid: (val: number) => void;
    stepperCount: number;
    setStepperCount: (val: number) => void;
    masterDataList: SchemeMaster;
    locationDetails: LocationDetails;
    modifyUser?: boolean | undefined
}

export default function LocationDetailsComp({
    stepperCount,
    onSubmit: _onSubmit,
    setStepperCount,
    masterDataList,
    locationDetails,
    modifyUser
}
    : props) {
    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },

    } = useForm<LocationDetails>({
        defaultValues: locationDetails
    });

    const state = watch("state");

    const { data: district } = useQuery(
        ["districtMaster", state],
        async () => {
            if (state) {
                let response = await getDistrictMasterData(state?.id);
                return response?.data?.data?.list;
            }
        }
    );

    const onSubmit: SubmitHandler<LocationDetails> = (data) => {
        setStepperCount(stepperCount + 1)
        _onSubmit(data);
    }


    const genderOptions = [
        { id: 1, value: 'Male' },
        { id: 2, value: 'Female' }
    ];
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container mt={3} spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="houseNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.houseNumber}
                                helperText={errors.houseNumber?.message}
                                fullWidth
                                label="House/Flat/Room No. (Optional)"
                                placeholder="Enter House/Flat/Room No."
                                value={value}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="buildingName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.buildingName}
                                helperText={errors.buildingName?.message}
                                fullWidth
                                label="Building/Chawl Name/No. (Optional)"
                                placeholder="Enter buildingName"
                                value={value}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="streetName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.buildingName}
                                helperText={errors.buildingName?.message}
                                fullWidth
                                label="Street/Road No./Name (Optional)"
                                placeholder="Enter Street/Road No./Name"
                                value={value}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="locality"
                        control={control}
                        rules={{ required: "Please enter Locality/Area*" }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.locality}
                                helperText={errors.locality?.message}
                                fullWidth
                                label="Locality/Area*"
                                placeholder="Enter Locality/Area*"
                                value={value}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="landmark"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.landmark}
                                helperText={errors.landmark?.message}
                                fullWidth
                                label="Landmark (Optional)"
                                placeholder="Enter Landmark (Optional)"
                                value={value}
                                onChange={onChange}
                            />
                        )} />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="state"
                        control={control}
                        rules={{ required: "Please enter state*" }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="State"
                                    error={!!errors.state}
                                    helperText={errors.state?.message}
                                />}
                                options={masterDataList?.state?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => {
                                    onChange(selectedOption)
                                    setValue("district", null)
                                }
                                }

                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="district"
                        control={control}
                        rules={{ required: "Please enter district*" }}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="District"
                                    error={!!errors.district}
                                    helperText={errors.district?.message}
                                />}
                                options={district || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="pincode"
                        control={control}
                        rules={{
                            required: "Please enter Pincode",
                            pattern: {
                                value: /^[0-9]{6}$/,
                                message: "Pincode must be 6 digits long"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                error={!!errors.pincode}
                                helperText={errors.pincode?.message}
                                fullWidth
                                label="PIN Code*"
                                placeholder="Enter PIN Code*"
                                value={value}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '');
                                    if (newValue.length <= 6) {
                                        onChange(newValue);
                                    }
                                }}
                            />
                        )} />
                </Grid>

                <Grid display={'flex'} item md={12} lg={12} sm={12} xs={12} justifyContent={'end'}>
                    {!!!modifyUser && <Button variant='outlined' sx={{ minWidth: 150 }} onClick={() => setStepperCount(stepperCount - 1)}>
                        Back
                    </Button>}
                    <Button
                        variant='contained'
                        type="submit"
                        sx={{ ml: 1, minWidth: 150 }}>
                        {!!!modifyUser ? "Proceed" : "Save Changes"}
                    </Button>
                </Grid>
            </Grid >
        </form>
    )
}
