import { ArrowForwardIos, ChevronRight, ForwardTwoTone } from "@mui/icons-material";
import { Box, Icon, Typography } from "@mui/material";
import React from "react";

export default function Stepper({
  activeStep,
  steps,
}: {
  activeStep: Number;
  steps: string[];
}) {
  return (
    <Box pl={2} display="flex" flexDirection="row" alignItems="center">
      {steps.map((step, index) => (
        <Box display="flex">
          <Typography
            variant="body1"
            fontWeight="600"
            color={activeStep === index ? "primary.main" : "text.primary"}
          >
            {index + 1}. &nbsp;
            {step}
          </Typography>
          {index + 1 < steps.length ? (
            <Box pr={4} pl={4}>
                <Icon><ChevronRight /></Icon>
            </Box>
          ) : null}
        </Box>
      ))}
    </Box>
  );
}
