import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "ಹಿಂದಿನ",
   "pageNextText": "ಮುಂದೆ",
   "completeText": "ಸಂಪೂರ್ಣ",
   "previewText": "ಮುನ್ನೋಟ",
   "editText": "ತಿದ್ದು",
   "startSurveyText": "ಪ್ರಾರಂಭಿಸಿ",
   "otherItemText": "ಇತರೆ (ವಿವರಿಸಿ)",
   "noneItemText": "ಯಾವುದೂ",
   "selectAllItemText": "ಎಲ್ಲವನ್ನು ಆರಿಸು",
   "progressText": "{1} ರಲ್ಲಿ {0} ನೇ ಪುಟ",
   "indexText": "{1} ರಲ್ಲಿ {0}",
   "panelDynamicProgressText": "{1} ರಲ್ಲಿ {0}",
   "panelDynamicTabTextFormat": "ಫಲಕ {panelIndex}",
   "questionsProgressText": "{0}/{1} ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಲಾಗಿದೆ",
   "emptySurvey": "ಸಮೀಕ್ಷೆಯು ಯಾವುದೇ ಗೋಚರ ಅಂಶಗಳನ್ನು ಒಳಗೊಂಡಿಲ್ಲ.",
   "completingSurvey": "ಸಮೀಕ್ಷೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು",
   "completingSurveyBefore": "ನೀವು ಈಗಾಗಲೇ ಈ ಸಮೀಕ್ಷೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ ಎಂದು ನಮ್ಮ ದಾಖಲೆಗಳು ತೋರಿಸುತ್ತವೆ.",
   "loadingSurvey": "ಸಮೀಕ್ಷೆಯನ್ನು ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ...",
   "placeholder": "ಆಯ್ಕೆ ಮಾಡಿ...",
   "ratingOptionsCaption": "ಆಯ್ಕೆ ಮಾಡಿ...",
   "value": "ಮೌಲ್ಯ",
   "requiredError": "ಪ್ರತಿಕ್ರಿಯೆ ಅಗತ್ಯವಿದೆ.",
   "requiredErrorInPanel": "ಪ್ರತಿಕ್ರಿಯೆ ಅಗತ್ಯವಿದೆ: ಕನಿಷ್ಠ ಒಂದು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸಿ.",
   "requiredInAllRowsError": "ಪ್ರತಿಕ್ರಿಯೆ ಅಗತ್ಯವಿದೆ: ಎಲ್ಲಾ ಸಾಲುಗಳಲ್ಲಿ ಪ್ರಶ್ನೆಗಳಿಗೆ ಉತ್ತರಿಸಿ.",
   "numericError": "ಮೌಲ್ಯವು ಸಂಖ್ಯಾತ್ಮಕವಾಗಿರಬೇಕು.",
   "minError": "ಮೌಲ್ಯವು {0} ಗಿಂತ ಕಡಿಮೆಯಿರಬಾರದು",
   "maxError": "ಮೌಲ್ಯವು {0} ಗಿಂತ ಹೆಚ್ಚಿರಬಾರದು",
   "textMinLength": "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ {0} ಅಕ್ಷರ(ಗಳನ್ನು) ನಮೂದಿಸಿ.",
   "textMaxLength": "ದಯವಿಟ್ಟು {0} ಅಕ್ಷರ(ಗಳು) ಗಿಂತ ಹೆಚ್ಚಿನದನ್ನು ನಮೂದಿಸಬೇಡಿ.",
   "textMinMaxLength": "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ {0} ಅನ್ನು ನಮೂದಿಸಿ ಮತ್ತು {1} ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚಿಲ್ಲ.",
   "minRowCountError": "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ {0} ಸಾಲು(ಗಳನ್ನು) ಭರ್ತಿ ಮಾಡಿ.",
   "minSelectError": "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ {0} ಆಯ್ಕೆ(ಗಳನ್ನು) ಆಯ್ಕೆಮಾಡಿ.",
   "maxSelectError": "ದಯವಿಟ್ಟು {0} ಆಯ್ಕೆ(ಗಳು) ಗಿಂತ ಹೆಚ್ಚಿನದನ್ನು ಆಯ್ಕೆ ಮಾಡಬೇಡಿ.",
   "numericMinMax": "&#39;{0}&#39; ಕನಿಷ್ಠ {1} ಮತ್ತು ಹೆಚ್ಚೆಂದರೆ {2} ಆಗಿರಬೇಕು",
   "numericMin": "&#39;{0}&#39; ಕನಿಷ್ಠ {1} ಆಗಿರಬೇಕು",
   "numericMax": "&#39;{0}&#39; ಹೆಚ್ಚೆಂದರೆ {1} ಆಗಿರಬೇಕು",
   "invalidEmail": "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ.",
   "invalidExpression": "ಅಭಿವ್ಯಕ್ತಿ: {0} &#39;ನಿಜ&#39; ಎಂದು ಹಿಂತಿರುಗಿಸಬೇಕು.",
   "urlRequestError": "ವಿನಂತಿಯು &#39;{0}&#39; ದೋಷವನ್ನು ಹಿಂತಿರುಗಿಸಿದೆ. {1}",
   "urlGetChoicesError": "ವಿನಂತಿಯು ಖಾಲಿ ಡೇಟಾವನ್ನು ಹಿಂತಿರುಗಿಸಿದೆ ಅಥವಾ &#39;ಪಾತ್&#39; ಆಸ್ತಿ ತಪ್ಪಾಗಿದೆ",
   "exceedMaxSize": "ಫೈಲ್ ಗಾತ್ರವು {0} ಮೀರಬಾರದು.",
   "noUploadFilesHandler": "ಫೈಲ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗುವುದಿಲ್ಲ. ದಯವಿಟ್ಟು &#39;onUploadFiles&#39; ಈವೆಂಟ್‌ಗಾಗಿ ಹ್ಯಾಂಡ್ಲರ್ ಅನ್ನು ಸೇರಿಸಿ.",
   "otherRequiredError": "ಪ್ರತಿಕ್ರಿಯೆ ಅಗತ್ಯವಿದೆ: ಇನ್ನೊಂದು ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ.",
   "uploadingFile": "ನಿಮ್ಮ ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ಆಗುತ್ತಿದೆ. ದಯವಿಟ್ಟು ಕೆಲವು ಸೆಕೆಂಡುಗಳ ಕಾಲ ನಿರೀಕ್ಷಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
   "loadingFile": "ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
   "chooseFile": "ಫೈಲ್(ಗಳನ್ನು) ಆಯ್ಕೆಮಾಡಿ...",
   "noFileChosen": "ಯಾವುದೇ ಫೈಲ್ ಆಯ್ಕೆ ಮಾಡಿಲ್ಲ",
   "filePlaceholder": "ಇಲ್ಲಿ ಫೈಲ್ ಅನ್ನು ಎಳೆಯಿರಿ ಮತ್ತು ಬಿಡಿ ಅಥವಾ ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಫೈಲ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಕೆಳಗಿನ ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.",
   "confirmDelete": "ನೀವು ದಾಖಲೆಯನ್ನು ಅಳಿಸಲು ಬಯಸುವಿರಾ?",
   "keyDuplicationError": "ಈ ಮೌಲ್ಯವು ಅನನ್ಯವಾಗಿರಬೇಕು.",
   "addColumn": "ಕಾಲಮ್ ಸೇರಿಸಿ",
   "addRow": "ಸಾಲು ಸೇರಿಸಿ",
   "removeRow": "ತೆಗೆದುಹಾಕಿ",
   "emptyRowsText": "ಯಾವುದೇ ಸಾಲುಗಳಿಲ್ಲ.",
   "addPanel": "ಹೊಸದನ್ನು ಸೇರಿಸಿ",
   "removePanel": "ತೆಗೆದುಹಾಕಿ",
   "showDetails": "ವಿವರಗಳನ್ನು ತೋರಿಸು",
   "hideDetails": "ವಿವರಗಳನ್ನು ಮರೆಮಾಡಿ",
   "choices_Item": "ಐಟಂ",
   "matrix_column": "ಅಂಕಣ",
   "matrix_row": "ಸಾಲು",
   "multipletext_itemname": "ಪಠ್ಯ",
   "savingData": "ಫಲಿತಾಂಶಗಳನ್ನು ಸರ್ವರ್‌ನಲ್ಲಿ ಉಳಿಸಲಾಗುತ್ತಿದೆ...",
   "savingDataError": "ದೋಷ ಸಂಭವಿಸಿದೆ ಮತ್ತು ಫಲಿತಾಂಶಗಳನ್ನು ಉಳಿಸಲು ನಮಗೆ ಸಾಧ್ಯವಾಗಲಿಲ್ಲ.",
   "savingDataSuccess": "ಫಲಿತಾಂಶಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ!",
   "saveAgainButton": "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
   "timerMin": "ನಿಮಿಷ",
   "timerSec": "ಸೆಕೆಂಡ್",
   "timerSpentAll": "ನೀವು ಈ ಪುಟದಲ್ಲಿ {0} ಮತ್ತು ಒಟ್ಟು {1} ಖರ್ಚು ಮಾಡಿದ್ದೀರಿ.",
   "timerSpentPage": "ನೀವು ಈ ಪುಟದಲ್ಲಿ {0} ಖರ್ಚು ಮಾಡಿರುವಿರಿ.",
   "timerSpentSurvey": "ನೀವು ಒಟ್ಟು {0} ಖರ್ಚು ಮಾಡಿದ್ದೀರಿ.",
   "timerLimitAll": "ನೀವು ಈ ಪುಟದಲ್ಲಿ {1} ರಲ್ಲಿ {0} ಮತ್ತು ಒಟ್ಟು {3} ರಲ್ಲಿ {2} ಖರ್ಚು ಮಾಡಿದ್ದೀರಿ.",
   "timerLimitPage": "ನೀವು ಈ ಪುಟದಲ್ಲಿ {1} ರಲ್ಲಿ {0} ಖರ್ಚು ಮಾಡಿರುವಿರಿ.",
   "timerLimitSurvey": "ನೀವು ಒಟ್ಟು {1} ರಲ್ಲಿ {0} ಖರ್ಚು ಮಾಡಿದ್ದೀರಿ.",
   "clearCaption": "ಸ್ಪಷ್ಟ",
   "signaturePlaceHolder": "ಇಲ್ಲಿ ರುಜು ಹಾಕಿ",
   "chooseFileCaption": "ಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ",
   "takePhotoCaption": "ಭಾವಚಿತ್ರವನ್ನು ತೆಗಿರಿ",
   "photoPlaceholder": "ಕ್ಯಾಮೆರಾ ಬಳಸಿ ಫೋಟೋ ತೆಗೆಯಲು ಕೆಳಗಿನ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
   "fileOrPhotoPlaceholder": "ಕ್ಯಾಮರಾವನ್ನು ಬಳಸಿಕೊಂಡು ಫೋಟೋವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಅಥವಾ ತೆಗೆದುಕೊಳ್ಳಲು ಫೈಲ್ ಅನ್ನು ಎಳೆಯಿರಿ ಮತ್ತು ಬಿಡಿ ಅಥವಾ ಆಯ್ಕೆಮಾಡಿ.",
   "replaceFileCaption": "ಫೈಲ್ ಅನ್ನು ಬದಲಾಯಿಸಿ",
   "removeFileCaption": "ಈ ಫೈಲ್ ಅನ್ನು ತೆಗೆದುಹಾಕಿ",
   "booleanCheckedLabel": "ಹೌದು",
   "booleanUncheckedLabel": "ಸಂ",
   "confirmRemoveFile": "ಈ ಫೈಲ್ ಅನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ: {0}?",
   "confirmRemoveAllFiles": "ನೀವು ಎಲ್ಲಾ ಫೈಲ್‌ಗಳನ್ನು ತೆಗೆದುಹಾಕಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
   "questionTitlePatternText": "ಪ್ರಶ್ನೆ ಶೀರ್ಷಿಕೆ",
   "modalCancelButtonText": "ರದ್ದುಮಾಡಿ",
   "modalApplyButtonText": "ಅನ್ವಯಿಸು",
   "filterStringPlaceholder": "ಹುಡುಕಲು ಟೈಪ್ ಮಾಡಿ...",
   "emptyMessage": "ಪ್ರದರ್ಶಿಸಲು ಯಾವುದೇ ಡೇಟಾ ಇಲ್ಲ",
   "noEntriesText": "ಇನ್ನೂ ಯಾವುದೇ ನಮೂದುಗಳಿಲ್ಲ. ಹೊಸ ನಮೂದನ್ನು ಸೇರಿಸಲು ಕೆಳಗಿನ ಬಟನ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.",
   "noEntriesReadonlyText": "ಯಾವುದೇ ನಮೂದುಗಳಿಲ್ಲ.",
   "more": "ಇನ್ನಷ್ಟು",
   "tagboxDoneButtonCaption": "ಸರಿ",
   "selectToRankEmptyRankedAreaText": "ಎಲ್ಲಾ ಆಯ್ಕೆಗಳನ್ನು ಶ್ರೇಣೀಕರಿಸಲಾಗಿದೆ",
   "selectToRankEmptyUnrankedAreaText": "ಅವುಗಳನ್ನು ಶ್ರೇಣೀಕರಿಸಲು ಇಲ್ಲಿ ಆಯ್ಕೆಗಳನ್ನು ಎಳೆಯಿರಿ ಮತ್ತು ಬಿಡಿ",
   "ok": "ಸರಿ",
   "cancel": "ರದ್ದುಮಾಡಿ"
};


surveyLocalization.locales["kn"] = dict;