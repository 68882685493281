import {
  DeleteOutline,
  Edit,
  NotificationsNoneOutlined,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Scheme } from "../../models/schemes";
import styled from "@emotion/styled";
import Loading from "../../../../components/loading/loading";
import useUser from "../../../../providers/user/user_provider";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

interface SchemeListing {
  searchList: Scheme[];
  deleteScheme: boolean;
  viewSchemeDetails: boolean;
  selectedSchemeDetails: {};
  searchValue: string;
  setSelectedSchemeDetails: (val: Scheme) => void;
  setDeleteScheme: (val: boolean) => void;
  setViewSchemeDetails: (val: boolean) => void;
  setSearchValue: (val: string) => void;
  count: number;
  page: number;
  setPage: (val: number) => void;
  isLoading: boolean;
  setNotificationPopUp: (val: boolean) => void;
}

export default function SchemesListing({
  searchList,
  deleteScheme,
  viewSchemeDetails,
  selectedSchemeDetails,
  setSelectedSchemeDetails,
  setDeleteScheme,
  setViewSchemeDetails,
  setSearchValue,
  searchValue,
  count,
  page,
  setPage,
  isLoading,
  setNotificationPopUp,
}: SchemeListing) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
      {searchComponent()}
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flex: 1,
        }}
      >
        <Box flex={1} display={"flex"} flexDirection={"column"} pl={2} mt={2}>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              height="100%"
            >
              <Loading />
            </Box>
          ) : searchList?.length === 0 ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <Typography>No Data Available</Typography>
            </Box>
          ) : (
            <Box display={"flex"} flexDirection={"column"}>
              {searchList?.map((item, index) => {
                return schemeItem(item, index);
              })}
            </Box>
          )}
        </Box>
      </CardContent>
      <CardActions>
        <Grid container>
          <Box display="flex" width={"100%"} justifyContent={"flex-end"}>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={8}
              rowsPerPageOptions={[]}
            />
          </Box>
          <Grid
            display="flex"
            width={"100%"}
            flexDirection="row"
            justifyContent="flex-end"
            m={2}
          >
            <Grid display="flex" md={4} sm={4} xs={4} lg={4} xl={4}>
              <Button
                disabled={!hasPermission("Schemes", "Create")}
                onClick={() => navigate("/schemes/add")}
                variant="contained"
                fullWidth
                color="primary"
                sx={{ ml: 2 }}
              >
                Add Schemes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Box>
  );
  function schemeItem(item: Scheme, index: number) {
    return (
      <>
        <Box
          display="flex"
          flexDirection={"row"}
          justifyContent={"space-between"}
          mb={3}
        >
          <Box display="flex" flexDirection={"column"}>
            <Box>
              <Typography fontWeight={"600"}>{item?.name}</Typography>
            </Box>
            <Box display="flex" flexDirection={"row"} flexWrap={"wrap"}>
              <Typography fontSize={14} color="#91278F" mr={1}>
                {item?.governmentType}
              </Typography>

              {item.categories?.map((categoryItem) => {
                return (
                  <>
                    <Typography mr={1} color="#91278F">
                      |
                    </Typography>
                    <Typography fontSize={14} color="#91278F" mr={1}>
                      {categoryItem}
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <IconButton
              onClick={() => {
                navigate(`/schemes/${item.schemeId}`);
              }}
              sx={{
                backgroundColor: "#F5E6F5",
                borderRadius: 3,
                color: "#1B001B",
                height: "fit-content",
              }}
            >
              <Visibility></Visibility>
            </IconButton>
            {hasPermission("Schemes", "Update") && (
              <IconButton
                sx={{
                  backgroundColor: "#F5E6F5",
                  borderRadius: 3,
                  ml: 2,
                  color: "#1B001B",
                  height: "fit-content",
                }}
                onClick={() => {
                  navigate(`/schemes/edit/${item.schemeId}`);
                }}
              >
                <Edit></Edit>
              </IconButton>
            )}

            <IconButton
              onClick={() => {
                setSelectedSchemeDetails(item);
                setNotificationPopUp(true);
              }}
              sx={{
                backgroundColor: "#F5E6F5",
                borderRadius: 3,
                ml: 2,
                color: "#000000",
                height: "fit-content",
              }}
            >
              <NotificationsNoneOutlined></NotificationsNoneOutlined>
            </IconButton>

            {hasPermission("Schemes", "Delete") && (
              <IconButton
                onClick={() => {
                  setDeleteScheme(true);
                  setSelectedSchemeDetails?.(item);
                }}
                sx={{
                  backgroundColor: "#F5E6F5",
                  borderRadius: 3,
                  ml: 2,
                  color: "#000000",
                  height: "fit-content",
                }}
              >
                <DeleteOutline></DeleteOutline>
              </IconButton>
            )}
          </Box>
        </Box>
        {index !== searchList?.length - 1 && (
          <Divider
            sx={{
              display: "flex",
              backgroundColor: "#7C797C",
              opacity: 0.1,
              mb: 3,
            }}
          />
        )}
      </>
    );
  }

  function searchComponent() {
    return (
      <Grid display={"flex"} flexDirection={"row"} container p={2}>
        <Grid md={12} sm={12} xs={12} lg={12} xl={12}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: "#F5E6F5",
              borderRadius: 3,
              borderColor: "transparent",
            }}
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.primary" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }
}
