import { PropsWithChildren, useContext } from "react";
import { createContext } from "react";
import { useQuery } from "react-query";
import { getSchemeMaster } from "../../../services/schemes/schemes";
import { SchemeMaster } from "../models/scheme_master";
import { inflate } from "zlib";

const SchemeContext = createContext<{
  schemeMaster: SchemeMaster | null;
}>({
  schemeMaster: null,
});

export function SchemeProvider({ children }: PropsWithChildren) {
  let { data: schemeMaster } = useQuery<SchemeMaster>(
    "scheme-master",
    async () => {
      let { data } = await getSchemeMaster();
      if(data.data){
        Object.keys(data.data)
        .forEach(master => {
          data.data[master] = data.data[master].sort((x: any,y: any) => {
            if(x.is_aggregate){
              return -1;
            }else{
              return 1;
            }
          });
        });
      }
      return data.data;
    },
    {
      staleTime: Infinity
    }
  );
  return (
    <SchemeContext.Provider value={{ schemeMaster: schemeMaster || null }}>
      {children}
    </SchemeContext.Provider>
  );
}

export default function useScheme() {
  return useContext(SchemeContext);
}
