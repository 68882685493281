import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "ਪਿਛਲਾ",
   "pageNextText": "ਅਗਲਾ",
   "completeText": "ਸੰਪੂਰਨ",
   "previewText": "ਝਲਕ",
   "editText": "ਸੰਪਾਦਿਤ ਕਰੋ",
   "startSurveyText": "ਸ਼ੁਰੂ ਕਰੋ",
   "otherItemText": "ਹੋਰ (ਵਰਣਨ)",
   "noneItemText": "ਕੋਈ ਨਹੀਂ",
   "selectAllItemText": "ਸਾਰਿਆ ਨੂੰ ਚੁਣੋ",
   "progressText": "ਪੰਨਾ {1} ਵਿੱਚੋਂ {0}",
   "indexText": "{1} ਵਿੱਚੋਂ {0}",
   "panelDynamicProgressText": "{1} ਵਿੱਚੋਂ {0}",
   "panelDynamicTabTextFormat": "ਪੈਨਲ {panelIndex}",
   "questionsProgressText": "{0}/{1} ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿੱਤੇ",
   "emptySurvey": "ਸਰਵੇਖਣ ਵਿੱਚ ਕੋਈ ਵੀ ਦਿਖਾਈ ਦੇਣ ਵਾਲੇ ਤੱਤ ਸ਼ਾਮਲ ਨਹੀਂ ਹਨ।",
   "completingSurvey": "ਸਰਵੇਖਣ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
   "completingSurveyBefore": "ਸਾਡੇ ਰਿਕਾਰਡ ਦਿਖਾਉਂਦੇ ਹਨ ਕਿ ਤੁਸੀਂ ਇਹ ਸਰਵੇਖਣ ਪਹਿਲਾਂ ਹੀ ਪੂਰਾ ਕਰ ਲਿਆ ਹੈ।",
   "loadingSurvey": "ਸਰਵੇਖਣ ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
   "placeholder": "ਚੁਣੋ...",
   "ratingOptionsCaption": "ਚੁਣੋ...",
   "value": "ਮੁੱਲ",
   "requiredError": "ਜਵਾਬ ਲੋੜੀਂਦਾ ਹੈ।",
   "requiredErrorInPanel": "ਜਵਾਬ ਦੀ ਲੋੜ ਹੈ: ਘੱਟੋ-ਘੱਟ ਇੱਕ ਸਵਾਲ ਦਾ ਜਵਾਬ ਦਿਓ।",
   "requiredInAllRowsError": "ਜਵਾਬ ਦੀ ਲੋੜ ਹੈ: ਸਾਰੀਆਂ ਕਤਾਰਾਂ ਵਿੱਚ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦਿਓ।",
   "numericError": "ਮੁੱਲ ਸੰਖਿਆਤਮਕ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
   "minError": "ਮੁੱਲ {0} ਤੋਂ ਘੱਟ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
   "maxError": "ਮੁੱਲ {0} ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ",
   "textMinLength": "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ {0} ਅੱਖਰ ਦਰਜ ਕਰੋ।",
   "textMaxLength": "ਕਿਰਪਾ ਕਰਕੇ {0} ਅੱਖਰ(ਅੱਖਰਾਂ) ਤੋਂ ਵੱਧ ਦਰਜ ਨਾ ਕਰੋ।",
   "textMinMaxLength": "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ {0} ਦਰਜ ਕਰੋ ਅਤੇ {1} ਤੋਂ ਵੱਧ ਅੱਖਰ ਨਾ ਦਿਓ।",
   "minRowCountError": "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ {0} ਕਤਾਰਾਂ ਨੂੰ ਭਰੋ।",
   "minSelectError": "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ-ਘੱਟ {0} ਵਿਕਲਪ ਚੁਣੋ।",
   "maxSelectError": "ਕਿਰਪਾ ਕਰਕੇ {0} ਵਿਕਲਪ(ਵਿਕਲਾਂ) ਤੋਂ ਵੱਧ ਨਾ ਚੁਣੋ।",
   "numericMinMax": "&#39;{0}&#39; ਘੱਟੋ-ਘੱਟ {1} ਅਤੇ ਵੱਧ ਤੋਂ ਵੱਧ {2} ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
   "numericMin": "&#39;{0}&#39; ਘੱਟੋ-ਘੱਟ {1} ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
   "numericMax": "&#39;{0}&#39; ਵੱਧ ਤੋਂ ਵੱਧ {1} ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
   "invalidEmail": "ਇੱਕ ਜਾਇਜ ਈਮੇਲ ਪਤਾ ਦਰਜ ਕਰੋ.",
   "invalidExpression": "ਸਮੀਕਰਨ: {0} ਨੂੰ &#39;ਸੱਚ&#39; ਵਾਪਸ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ।",
   "urlRequestError": "ਬੇਨਤੀ ਨੇ ਗਲਤੀ &#39;{0}&#39; ਵਾਪਸ ਕੀਤੀ। {1}",
   "urlGetChoicesError": "ਬੇਨਤੀ ਨੇ ਖਾਲੀ ਡੇਟਾ ਵਾਪਸ ਕੀਤਾ ਜਾਂ &#39;ਪਾਥ&#39; ਵਿਸ਼ੇਸ਼ਤਾ ਗਲਤ ਹੈ",
   "exceedMaxSize": "ਫ਼ਾਈਲ ਦਾ ਆਕਾਰ {0} ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋਣਾ ਚਾਹੀਦਾ।",
   "noUploadFilesHandler": "ਫ਼ਾਈਲਾਂ ਅੱਪਲੋਡ ਨਹੀਂ ਕੀਤੀਆਂ ਜਾ ਸਕਦੀਆਂ। ਕਿਰਪਾ ਕਰਕੇ &#39;onUploadFiles&#39; ਇਵੈਂਟ ਲਈ ਇੱਕ ਹੈਂਡਲਰ ਸ਼ਾਮਲ ਕਰੋ।",
   "otherRequiredError": "ਜਵਾਬ ਦੀ ਲੋੜ ਹੈ: ਕੋਈ ਹੋਰ ਮੁੱਲ ਦਾਖਲ ਕਰੋ।",
   "uploadingFile": "ਤੁਹਾਡੀ ਫ਼ਾਈਲ ਅੱਪਲੋਡ ਹੋ ਰਹੀ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਕਿੰਟ ਉਡੀਕ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
   "loadingFile": "ਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ...",
   "chooseFile": "ਫਾਈਲਾਂ ਚੁਣੋ...",
   "noFileChosen": "ਕੋਈ ਫ਼ਾਈਲ ਨਹੀਂ ਚੁਣੀ ਗਈ",
   "filePlaceholder": "ਇੱਕ ਫ਼ਾਈਲ ਨੂੰ ਇੱਥੇ ਖਿੱਚੋ ਅਤੇ ਛੱਡੋ ਜਾਂ ਅੱਪਲੋਡ ਕਰਨ ਲਈ ਇੱਕ ਫ਼ਾਈਲ ਚੁਣਨ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ।",
   "confirmDelete": "ਕੀ ਤੁਸੀਂ ਰਿਕਾਰਡ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
   "keyDuplicationError": "ਇਹ ਮੁੱਲ ਵਿਲੱਖਣ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ.",
   "addColumn": "ਕਾਲਮ ਸ਼ਾਮਲ ਕਰੋ",
   "addRow": "ਕਤਾਰ ਸ਼ਾਮਲ ਕਰੋ",
   "removeRow": "ਹਟਾਓ",
   "emptyRowsText": "ਕੋਈ ਕਤਾਰਾਂ ਨਹੀਂ ਹਨ।",
   "addPanel": "ਨਵਾਂ ਸ਼ਾਮਲ ਕਰੋ",
   "removePanel": "ਹਟਾਓ",
   "showDetails": "ਵੇਰਵਾ ਦਿਖਾਓ",
   "hideDetails": "ਵੇਰਵਿਆਂ ਨੂੰ ਲੁਕਾਓ",
   "choices_Item": "ਆਈਟਮ",
   "matrix_column": "ਕਾਲਮ",
   "matrix_row": "ਕਤਾਰ",
   "multipletext_itemname": "ਟੈਕਸਟ",
   "savingData": "ਨਤੀਜੇ ਸਰਵਰ &#39;ਤੇ ਸੁਰੱਖਿਅਤ ਕੀਤੇ ਜਾ ਰਹੇ ਹਨ...",
   "savingDataError": "ਇੱਕ ਤਰੁੱਟੀ ਉਤਪੰਨ ਹੋਈ ਅਤੇ ਅਸੀਂ ਨਤੀਜਿਆਂ ਨੂੰ ਸੁਰੱਖਿਅਤ ਨਹੀਂ ਕਰ ਸਕੇ।",
   "savingDataSuccess": "ਨਤੀਜੇ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੇ ਗਏ ਸਨ!",
   "saveAgainButton": "ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
   "timerMin": "ਮਿੰਟ",
   "timerSec": "ਸਕਿੰਟ",
   "timerSpentAll": "ਤੁਸੀਂ ਇਸ ਪੰਨੇ &#39;ਤੇ {0} ਅਤੇ ਕੁੱਲ {1} ਖਰਚ ਕੀਤੇ ਹਨ।",
   "timerSpentPage": "ਤੁਸੀਂ ਇਸ ਪੰਨੇ &#39;ਤੇ {0} ਖਰਚ ਕੀਤਾ ਹੈ।",
   "timerSpentSurvey": "ਤੁਸੀਂ ਕੁੱਲ ਮਿਲਾ ਕੇ {0} ਖਰਚੇ ਹਨ।",
   "timerLimitAll": "ਤੁਸੀਂ ਇਸ ਪੰਨੇ &#39;ਤੇ {1} ਵਿੱਚੋਂ {0} ਅਤੇ ਕੁੱਲ {3} ਵਿੱਚੋਂ {2} ਖਰਚ ਕੀਤੇ ਹਨ।",
   "timerLimitPage": "ਤੁਸੀਂ ਇਸ ਪੰਨੇ &#39;ਤੇ {1} ਵਿੱਚੋਂ {0} ਖਰਚ ਕਰ ਚੁੱਕੇ ਹੋ।",
   "timerLimitSurvey": "ਤੁਸੀਂ ਕੁੱਲ {1} ਵਿੱਚੋਂ {0} ਖਰਚ ਕਰ ਚੁੱਕੇ ਹੋ।",
   "clearCaption": "ਸਾਫ਼",
   "signaturePlaceHolder": "ਇੱਥੇ ਦਸਤਖਤ ਕਰੋ",
   "chooseFileCaption": "ਫਾਈਲ ਚੁਣੋ",
   "takePhotoCaption": "ਫੋਟੋ ਲੈ",
   "photoPlaceholder": "ਕੈਮਰੇ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਫੋਟੋ ਖਿੱਚਣ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ।",
   "fileOrPhotoPlaceholder": "ਕੈਮਰੇ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਫੋਟੋ ਅੱਪਲੋਡ ਕਰਨ ਜਾਂ ਲੈਣ ਲਈ ਇੱਕ ਫ਼ਾਈਲ ਨੂੰ ਖਿੱਚੋ ਅਤੇ ਛੱਡੋ ਜਾਂ ਚੁਣੋ।",
   "replaceFileCaption": "ਫਾਈਲ ਬਦਲੋ",
   "removeFileCaption": "ਇਸ ਫਾਈਲ ਨੂੰ ਹਟਾਓ",
   "booleanCheckedLabel": "ਹਾਂ",
   "booleanUncheckedLabel": "ਨੰ",
   "confirmRemoveFile": "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ &#39;ਤੇ ਇਸ ਫ਼ਾਈਲ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ: {0}?",
   "confirmRemoveAllFiles": "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਤੌਰ &#39;ਤੇ ਸਾਰੀਆਂ ਫਾਈਲਾਂ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
   "questionTitlePatternText": "ਸਵਾਲ ਦਾ ਸਿਰਲੇਖ",
   "modalCancelButtonText": "ਰੱਦ ਕਰੋ",
   "modalApplyButtonText": "ਲਾਗੂ ਕਰੋ",
   "filterStringPlaceholder": "ਖੋਜਣ ਲਈ ਟਾਈਪ ਕਰੋ...",
   "emptyMessage": "ਪ੍ਰਦਰਸ਼ਿਤ ਕਰਨ ਲਈ ਕੋਈ ਡਾਟਾ ਨਹੀਂ ਹੈ",
   "noEntriesText": "ਅਜੇ ਤੱਕ ਕੋਈ ਐਂਟਰੀਆਂ ਨਹੀਂ ਹਨ। ਨਵੀਂ ਐਂਟਰੀ ਜੋੜਨ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ &#39;ਤੇ ਕਲਿੱਕ ਕਰੋ।",
   "noEntriesReadonlyText": "ਕੋਈ ਐਂਟਰੀਆਂ ਨਹੀਂ ਹਨ।",
   "more": "ਹੋਰ",
   "tagboxDoneButtonCaption": "ਠੀਕ ਹੈ",
   "selectToRankEmptyRankedAreaText": "ਸਾਰੀਆਂ ਚੋਣਾਂ ਨੂੰ ਦਰਜਾ ਦਿੱਤਾ ਗਿਆ ਹੈ",
   "selectToRankEmptyUnrankedAreaText": "ਉਹਨਾਂ ਨੂੰ ਦਰਜਾ ਦੇਣ ਲਈ ਚੋਣਾਂ ਨੂੰ ਇੱਥੇ ਖਿੱਚੋ ਅਤੇ ਛੱਡੋ",
   "ok": "ਠੀਕ ਹੈ",
   "cancel": "ਰੱਦ ਕਰੋ"
};


surveyLocalization.locales["pa"] = dict;