import { Shadows, createTheme } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { background, purpleLightActive, purple } from "./colors";
import "./styles.css";
declare module "@mui/material/styles" {
  interface Theme {}
  // allow configuration using `createTheme`
  interface ThemeOptions {}
}

const theme = createTheme({
  palette: {
    primary: {
      light: purpleLightActive,
      main: purple,
    },
    secondary: {
      main: green[500],
    },
    background: {
      default: background,
    },
    common: {},
  },
  typography: {
    fontFamily: `'IBM Plex Sans Devanagari', sans-serif`,
  },
  shadows: [
    "none",
    "rgba(0, 0, 0, 0.08) 0px 1px 4px",
    "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
    "0px 6px 8px 0px rgba(0, 0, 0, 0.25)",
    "0px 15px 52px 15px rgba(50, 59, 82, 0.15)",
    ...Array(20).fill("none"),
  ] as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          paddingRight: 16,
          paddingLeft: 16,
          textTransform: "capitalize",
          fontWeight: 600,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCard: {
      defaultProps: {},
      styleOverrides: {
        root: {
          // boxShadow: "1px 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
          classes: {
            asterisk: "red",
          },
        },
      },
    },
  },
});

export default theme;
