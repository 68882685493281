import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "পূৰ্ৱবৰ্তী",
   "pageNextText": "পৰৱৰ্তী",
   "completeText": "সম্পূৰ্ণ কৰা",
   "previewText": "পূৰ্বদৰ্শন",
   "editText": "সম্পাদনা কৰক",
   "startSurveyText": "আৰম্ভ কৰক",
   "otherItemText": "অন্যান্য (বৰ্ণনা)",
   "noneItemText": "একো নাই",
   "selectAllItemText": "সকলো নিৰ্ব্বাচন কৰক",
   "progressText": "{1} ৰ {0} পৃষ্ঠা",
   "indexText": "{1} ৰ {0}",
   "panelDynamicProgressText": "{1} ৰ {0}",
   "panelDynamicTabTextFormat": "পেনেল {panelIndex}",
   "questionsProgressText": "{0}/{1} প্ৰশ্নৰ উত্তৰ দিলে",
   "emptySurvey": "জৰীপটোত কোনো ধৰণৰ দৃশ্যমান উপাদান নাই।",
   "completingSurvey": "সমীক্ষা সম্পূৰ্ণ কৰাৰ বাবে ধন্যবাদ",
   "completingSurveyBefore": "আমাৰ ৰেকৰ্ডত দেখা গৈছে যে আপুনি ইতিমধ্যে এই জৰীপ সম্পূৰ্ণ কৰিছে।",
   "loadingSurvey": "লোডিং জৰীপ...",
   "placeholder": "চয়ন কৰা...",
   "ratingOptionsCaption": "চয়ন কৰা...",
   "value": "মান",
   "requiredError": "সঁহাৰিৰ প্ৰয়োজন।",
   "requiredErrorInPanel": "সঁহাৰিৰ প্ৰয়োজন: অন্ততঃ এটা প্ৰশ্নৰ উত্তৰ দিয়ক।",
   "requiredInAllRowsError": "সঁহাৰিৰ প্ৰয়োজন: সকলো শাৰীতে প্ৰশ্নৰ উত্তৰ দিয়া।",
   "numericError": "মানটো সংখ্যাগত হ’ব লাগে।",
   "minError": "মান {0} তকৈ কম হ&#39;ব নালাগে।",
   "maxError": "মান {0} তকৈ বেছি হ&#39;ব নালাগে।",
   "textMinLength": "অনুগ্ৰহ কৰি অন্ততঃ {0} আখৰ(সমূহ) দিয়ক।",
   "textMaxLength": "অনুগ্ৰহ কৰি {0} আখৰ(সমূহ)তকৈ অধিক প্ৰৱেশ নকৰিব।",
   "textMinMaxLength": "অনুগ্ৰহ কৰি অন্ততঃ {0} আৰু {1} আখৰতকৈ বেছি নহয়।",
   "minRowCountError": "অনুগ্ৰহ কৰি অন্ততঃ {0} শাৰী(সমূহ) পূৰণ কৰক।",
   "minSelectError": "অনুগ্ৰহ কৰি অন্ততঃ {0} বিকল্প(সমূহ) নিৰ্ব্বাচন কৰক।",
   "maxSelectError": "অনুগ্ৰহ কৰি {0} তকৈ অধিক বিকল্প(সমূহ) নিৰ্ব্বাচন নকৰিব।",
   "numericMinMax": "&#39;{0}&#39; অন্ততঃ {1} আৰু সৰ্বাধিক {2} হ&#39;ব লাগে।",
   "numericMin": "&#39;{0}&#39; অন্ততঃ {1} হ&#39;ব লাগে।",
   "numericMax": "&#39;{0}&#39; সৰ্বাধিক {1} হ&#39;ব লাগে।",
   "invalidEmail": "অনুগ্ৰহ কৰি এটা বৈধ ই-মেইল ঠিকনা দিয়ক।",
   "invalidExpression": "এক্সপ্ৰেচন: {0} এ &#39;true&#39; ঘূৰাই দিব লাগে।",
   "urlRequestError": "অনুৰোধে &#39;{0}&#39; ভুল ঘূৰাই দিলে। {১}",
   "urlGetChoicesError": "অনুৰোধে খালী তথ্য ঘূৰাই দিলে বা &#39;পথ&#39; বৈশিষ্ট্য ভুল",
   "exceedMaxSize": "নথিপত্ৰৰ আকাৰ {0} তকৈ বেছি হ&#39;ব নালাগে ।",
   "noUploadFilesHandler": "ফাইল আপলোড কৰিব নোৱাৰি। অনুগ্ৰহ কৰি &#39;onUploadFiles&#39; ইভেন্টৰ বাবে এটা হেণ্ডলাৰ যোগ কৰক।",
   "otherRequiredError": "সঁহাৰিৰ প্ৰয়োজন: অন্য মান সুমুৱাওক।",
   "uploadingFile": "আপোনাৰ ফাইলটো আপলোড হৈ আছে। অনুগ্ৰহ কৰি কেইবা চেকেণ্ড অপেক্ষা কৰক আৰু পুনৰ চেষ্টা কৰক।",
   "loadingFile": "লোড হচ্ছে...",
   "chooseFile": "ফাইল(সমূহ) বাছক...",
   "noFileChosen": "কোনো ফাইল নিৰ্বাচিত কৰা হোৱা নাই",
   "filePlaceholder": "ইয়াত এটা ফাইল টানি ড্ৰপ কৰক বা আপলোড কৰিবলৈ এটা ফাইল নিৰ্ব্বাচন কৰিবলৈ তলৰ বুটামটো ক্লিক কৰক।",
   "confirmDelete": "আপুনি ৰেকৰ্ডটো মচি পেলাব বিচাৰেনে?",
   "keyDuplicationError": "এই মূল্য অনন্য হ’ব লাগে।",
   "addColumn": "স্তম্ভ যোগ কৰক",
   "addRow": "শাৰী যোগ কৰক",
   "removeRow": "আঁতৰোৱা",
   "emptyRowsText": "শাৰী শাৰী নাই।",
   "addPanel": "নতুন যোগ কৰক",
   "removePanel": "আঁতৰোৱা",
   "showDetails": "বিৱৰণী দেখুওৱা",
   "hideDetails": "বিৱৰণ লুকুৱাওক",
   "choices_Item": "সামগ্ৰী",
   "matrix_column": "স্তম্ভ",
   "matrix_row": "শাৰী",
   "multipletext_itemname": "পাঠ্য",
   "savingData": "ফলাফলসমূহ চাৰ্ভাৰত সংৰক্ষণ কৰা হৈছে...",
   "savingDataError": "এটা ভুল হৈছিল আৰু আমি ফলাফল সংৰক্ষণ কৰিব পৰা নাছিলো।",
   "savingDataSuccess": "ফলাফল সফলতাৰে সংৰক্ষণ কৰা হ’ল!",
   "saveAgainButton": "আকৌ এবাৰ চেষ্টা কৰক",
   "timerMin": "min",
   "timerSec": "sec",
   "timerSpentAll": "আপুনি এই পৃষ্ঠাত {0} আৰু মুঠতে {1} খৰচ কৰিছে।",
   "timerSpentPage": "আপুনি এই পৃষ্ঠাত {0} খৰচ কৰিছে।",
   "timerSpentSurvey": "আপুনি মুঠতে {0} খৰচ কৰিছে।",
   "timerLimitAll": "আপুনি এই পৃষ্ঠাত {1} ৰ {0} আৰু মুঠ {3} ৰ {2} খৰচ কৰিছে।",
   "timerLimitPage": "আপুনি এই পৃষ্ঠাত {1} ৰ {0} খৰচ কৰিছে।",
   "timerLimitSurvey": "আপুনি মুঠ {1} ৰ {0} খৰচ কৰিছে।",
   "clearCaption": "পৰিষ্কাৰ",
   "signaturePlaceHolder": "ইয়াত চহী কৰক",
   "chooseFileCaption": "ফাইল নিৰ্ব্বাচন কৰক",
   "takePhotoCaption": "ফটো লওক",
   "photoPlaceholder": "তলৰ বুটামটোত ক্লিক কৰি কেমেৰা ব্যৱহাৰ কৰি ফটো এখন তুলিব পাৰে।",
   "fileOrPhotoPlaceholder": "কেমেৰা ব্যৱহাৰ কৰি আপলোড বা ফটো তুলিবলৈ এটা ফাইল ড্ৰেগ আৰু ড্ৰপ বা নিৰ্বাচন কৰক।",
   "replaceFileCaption": "ফাইল সলনি কৰক",
   "removeFileCaption": "এই ফাইলটো আঁতৰাওক",
   "booleanCheckedLabel": "হয়",
   "booleanUncheckedLabel": "নহয়",
   "confirmRemoveFile": "আপুনি নিশ্চিত নেকি যে আপুনি এই ফাইলটো আঁতৰাব বিচাৰে: {0}?",
   "confirmRemoveAllFiles": "আপুনি নিশ্চিত নেকি যে আপুনি সকলো ফাইল আঁতৰাব বিচাৰে?",
   "questionTitlePatternText": "প্ৰশ্নৰ শিৰোনাম",
   "modalCancelButtonText": "বাতিল কৰক",
   "modalApplyButtonText": "প্ৰয়োগ কৰক",
   "filterStringPlaceholder": "সন্ধান কৰিবলৈ টাইপ কৰক...",
   "emptyMessage": "প্ৰদৰ্শন কৰিবলৈ কোনো তথ্য নাই",
   "noEntriesText": "এতিয়াও কোনো প্ৰৱেশ নাই।নতুন প্ৰৱেশ যোগ কৰিবলৈ তলৰ বুটামটো ক্লিক কৰক।",
   "noEntriesReadonlyText": "কোনো প্ৰৱেশ নাই।",
   "more": "অধিক",
   "tagboxDoneButtonCaption": "ঠিক আছে",
   "selectToRankEmptyRankedAreaText": "সকলো পছন্দৰ ৰেংকিং কৰা হৈছে",
   "selectToRankEmptyUnrankedAreaText": "ইয়াত পছন্দসমূহ ৰেংক কৰিবলৈ ড্ৰেগ আৰু ড্ৰপ কৰক",
   "ok": "ঠিক আছে",
   "cancel": "বাতিল কৰক"
};


surveyLocalization.locales["as"] = dict;