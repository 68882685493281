import HttpClient from "../../utils/http";
import {
  adminList,
  adminRoles,
  featuresAndPermissions,
  getAdminPermissions,
} from "../constants/api_endpoints";

export function getAdmins() {
  return HttpClient.get(adminList);
}

export function postAdmin(params: Object) {
  return HttpClient.post(adminList, params);
}

export function deleteAdmins(params: Object) {
  return HttpClient.delete(adminList, params);
}

export function getRoles() {
  return HttpClient.get(adminRoles);
}

export function postRoles(params: any) {
  return HttpClient.post(adminRoles, params);
}

export function getPermissions() {
  return HttpClient.get(getAdminPermissions);
}

export function deleteRoles(params: Object) {
  return HttpClient.delete(adminRoles, params);
}

export function updateRole(id: string, params: Object) {
  return HttpClient.patch(adminRoles + id, params);
}

export function getFeaturesAndPermissions() {
  return HttpClient.get(featuresAndPermissions);
}
