import { Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from "../constants";

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, ...rest }) => {
  let token = sessionStorage.getItem(ACCESS_TOKEN);
  return token ? element : <Navigate to="/" />;
};

export default PrivateRoute;
