import { Box, Card, CardContent, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import MainLayout from '../../../layouts/main/main_layout'
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Loading from '../../../components/loading/loading';
import Stepper from '../components/stepper/stepper';
import PersonalDetails from '../components/personal_details/personal_details';
import LocationDetailsComp from '../components/location_details/location_details';
import OtherDetailsComp from '../components/other_details/other_details';
import { useQuery } from 'react-query';
import { getMasterData } from '../../../services/choices/choices_services';
import { SchemeMaster } from '../../schemes/models/scheme_master';
import DurableDetailsComp from '../components/durable_details/durable_details';
import { createUserService } from '../../../services/user_append_service/create_modify_user_service';
import { LocationDetails, OtherDetails, PersonelDetails } from '../model/create_user';
import { useFeedback } from '../../../providers/feedback/feeedback';
import AreaOfInterest from '../components/area_of_interest/area_of_interest';

export default function CreateUser() {
    const navigate = useNavigate();
    const [stepperCount, setStepperCount] = useState(1);
    const [uid, setUid] = useState<number | null>(null);
    const [personalDeatails, setPersonalDetails] = useState<PersonelDetails | null>(null);
    const [locationDetails, setLocationDetails] = useState<LocationDetails | null>(null);
    const [otherDetails, setOtherDetails] = useState<OtherDetails | null>(null);
    const [durableDetails, setDurableDetails] = useState<number[] | null>(null);
    const [areaOfInterest, setAreaOfInterest] = useState<number[] | null>(null);

    const { showSnackbar } = useFeedback();

    const { data: masterDataList } = useQuery<SchemeMaster>(["masterDataList"], async () => {
        let response = await getMasterData();
        return response.data.data || [];
    });

    function createUserApiCall() {
        createUserService({
            address_line1: locationDetails?.houseNumber!,
            address_line2: locationDetails?.buildingName!,
            address_line3: locationDetails?.streetName!,
            area: locationDetails?.locality!,
            areas_of_interest_id: areaOfInterest!,
            district_id: locationDetails?.district?.id!,
            dob: personalDeatails?.dob!,
            durable_ownership_id: durableDetails,
            education_id: otherDetails?.educational?.id!,
            family_income_id: otherDetails?.familyIncome?.id!,
            family_type_id: otherDetails?.familyType?.id!,
            gender: personalDeatails?.gender?.value!,
            house_ownership_id: otherDetails?.ownershipOfHouse?.id!,
            landmark: locationDetails?.landmark!,
            lat: '',
            lon: '',
            marital_status_id: personalDeatails?.meritalStatus?.id!,
            mobile_no: personalDeatails?.mobileNumber!,
            name: personalDeatails?.fullName!,
            occupation_id: otherDetails?.occupational?.id!,
            personal_income_id: otherDetails?.personalIncome?.id!,
            pincode: locationDetails?.pincode!,
            preferred_language: 'en',
            state_id: locationDetails?.state!?.id,
            working_status_id: otherDetails?.workingStatus?.id!,
            is_subscribed: personalDeatails?.subscribeToNewsLatter?.value === 'Yes' ? true : false
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar("User added successfully");
                navigate('/users')
            }
        }).catch((e) => {
            if (+e.response?.status === 500) {
                let jsonParsedData = JSON.parse(e.response?.data?.error?.message);
                showSnackbar(jsonParsedData?.details[0]?.message)
            } else {
                showSnackbar("Something went Wrong");
            }
        });
    }


    let stepperData: { id: number, name: string }[] = [
        {
            id: 1, name: 'Personal Details',
        },
        {
            id: 2, name: 'Location Details',
        },
        {
            id: 3, name: 'Other Details',
        },
        {
            id: 4, name: 'Durable Details',
        },
        {
            id: 5, name: 'Area of interest',
        },
    ]

    function loading() {
        return (
            <Box
                height={300}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Loading />
            </Box>
        );
    }

    function renderCompBasedOnStepper() {
        switch (stepperCount) {
            case 1:
                return <PersonalDetails
                    onSubmit={async (data) => {
                        console.log(data);
                        setPersonalDetails(data)
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    personalDeatails={personalDeatails!}

                />
            case 2:
                return <LocationDetailsComp
                    onSubmit={async (data) => {
                        setLocationDetails(data)
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    locationDetails={locationDetails!}
                />
            case 3:
                return <OtherDetailsComp
                    onSubmit={async (data) => {
                        setOtherDetails(data)
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    otherDetails={otherDetails!}
                />
            case 4:
                return <DurableDetailsComp
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    onSubmit={() => {
                        setStepperCount(stepperCount + 1)
                    }}
                    durableDetails={durableDetails!}
                    setDurableDetails={setDurableDetails}
                />
            case 5:
                return <AreaOfInterest
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    onSubmit={() => {
                        createUserApiCall();
                    }}
                    areaOfInterest={areaOfInterest!}
                    setAreaOfInterest={setAreaOfInterest}
                />
            default:
        }
    }
    return (
        <MainLayout>
            <Grid container spacing={3}>
                <Grid item md={12} lg={10} sm={12} xs={12}>
                    <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box width={200} display="flex" alignItems="center">
                                    <IconButton onClick={() => navigate(-1)}>
                                        <ArrowBack></ArrowBack>
                                    </IconButton>
                                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                                        User Details
                                    </Typography>
                                </Box>
                                <Box
                                    flex={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                ></Box>
                            </Box>
                            <Box mt={2} mb={2}>
                                <Divider />
                            </Box>
                            <Box m={2}>
                                {false ? (
                                    loading()
                                ) : (
                                    <Box>
                                        <Stepper
                                            stepperData={stepperData}
                                            stepperCount={stepperCount}
                                            setStepperCount={setStepperCount}
                                        />
                                        {renderCompBasedOnStepper()}
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </MainLayout>
    )
}
