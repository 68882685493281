import { Box, Typography } from "@mui/material";
import React from "react";

export default function ComingSoon() {
  return (
    <Box
      display={"flex"}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
      width="100%"
      height="100%"
    >
      <Typography variant="h5">Coming Soon !!!</Typography>
    </Box>
  );
}
