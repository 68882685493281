import { Box, Card, Grid, IconButton, Tab, Tabs, InputAdornment, TextField as MuiTextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  downloadSurveyorResponses,
  getSurveyorDownloadList,
  getSurveyorResponses,
  getSurvyorFilterData,
  surveyDownload,
} from "../../../../services/surveyor/surveyor";
import DataGridSurveyorResponses from "../data_grid/data_grid";
import Loading from "../../../../components/loading/loading";
import FilterSubmittedChoiceList from "../filter_submitted_choice_list/filter_submitted_choice_list";
import { SurveyorFilterModel } from "../../model/surveyor_filter_model";
import FilterIcon from "../../../../assets/filter_icon.svg";
import XlsIcon from "../../../../assets/xls_icon.svg";
import { downloadFromBlob } from "../../../../utils/utils";
import MainLayout from "../../../../layouts/main/main_layout";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, Search } from "@mui/icons-material";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../../../constants";
import Sort from "../../../../assets/sort.svg";
import { NeedFilters } from "../../../needs/model/needs_filters";
import { getNeedsFilters } from "../../../../services/needs/needs_service";
import styled from "@emotion/styled";
import DataGridDownloads from "../data_grid_downloads/data_grid_downloads";
import FilterForDownloads from "../filter_for_downloads/filter_for_downloads";

export interface SurveyorResponseLabel {
  label: string;
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function SurveyorChoiceResponseList() {
  const { showSnackbar } = useFeedback();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [pageForDownloads, setPageForDownloads] = useState(0);
  const [openModel, setOpenModal] = useState(false);
  const [openModelForDownloads, setOpenModalForDownloads] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startDateForDownloads, setStartDateForDownloads] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateForDownloads, setEndDateForDownloads] = useState("");
  const [schemeKeyword, setSchemeKeyword] = useState("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [selectedGender, setSelectedGender] = useState<string>("");
  const [occupationId, setOccupationId] = useState<number | null>(null);
  const [educationId, setEducationId] = useState<number | null>(null);
  const [incomeId, setIncomeId] = useState<number | null>(null);
  const [sortInAcceding, setSortInAcceding] = useState(true);
  const [cityId, setCityId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState<number | null>(null)
  const [districtId, setDistrictId] = useState<number | null>(null);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };
  const surveyorResponseLabel: SurveyorResponseLabel[] = [
    {
      label: "UID",
    },
    {
      label: "Full Name",
    },
    {
      label: "Choice Name",
    },
    {
      label: "Contact Number",
    },

    {
      label: "State",
    },
    {
      label: "District",
    },
    {
      label: "Submitted On",
    },
  ];

  const surveyorDownloads: SurveyorResponseLabel[] = [
    {
      label: "SL. No",
    },
    {
      label: "Choice Name",
    },
    {
      label: "Status",
    },
    {
      label: "Remark",
    },

    {
      label: "Processed By",
    },
    {
      label: "Processed At",
    },
    {
      label: "Record Count",
    },
    {
      label: "Action",
    },
  ];

  const { data: surveyorResponseList, isLoading } = useQuery(
    [
      "surveyorResponseList",
      id,
      page,
      categoryId,
      endDate,
      startDate,
      educationId,
      selectedGender,
      occupationId,
      incomeId,
      stateId,
      sortInAcceding,
      cityId,
      searchValue,
      currentTab,
      districtId
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getSurveyorResponses({
        page: page + 1,
        size: 8,
        category: categoryId,
        dateFrom: startDate,
        dateTo: endDate,
        education: educationId,
        gender: selectedGender,
        occupation: occupationId,
        personalIncome: incomeId,
        state: stateId,
        choiceId: +id!,
        sortOrder: sortOrder,
        city: cityId,
        keyword: searchValue,
        districtId: districtId,
      });
      return response.data.data;
    }
  );



  const { data: surveyorDownloadList, } = useQuery(
    [
      "surveyorDownloadList",
      currentTab,
      sortInAcceding,
      status,
      startDateForDownloads,
      endDateForDownloads,
      pageForDownloads
    ],
    async () => {
      console.log(startDateForDownloads, 'startDateForDownloads');

      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getSurveyorDownloadList({
        page: pageForDownloads + 1,
        size: 8,
        category: categoryId,
        dateFrom: startDateForDownloads,
        dateTo: endDateForDownloads,
        education: educationId,
        gender: selectedGender,
        occupation: occupationId,
        personalIncome: incomeId,
        state: stateId,
        choiceId: +id!,
        sortOrder: sortOrder,
        status: status
      });
      return response.data.data?.data;
    }
  );

  function surveyDownloadApiCall(taskId: number) {
    surveyDownload({ taskId: taskId }).then((res) => {
      let extension = ".xlsx";
      downloadFromBlob(res, extension);

    }).catch((e) => { })
  }

  function download() {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";

    downloadSurveyorResponses({
      page: 1,
      size: 1000,
      category: categoryId,
      dateFrom: startDate,
      dateTo: endDate,
      education: educationId,
      gender: selectedGender,
      occupation: occupationId,
      personalIncome: incomeId,
      state: stateId,
      choiceId: +id!,
      sortOrder: sortOrder,
      city: cityId,
    })
      .then((res) => {
        console.log(res.data, 'data');
        showSnackbar(res?.data?.data?.message);

      })
      .catch((e) => {
        console.log(e.response, 'res');
        showSnackbar(e?.response?.data?.error?.message);
      });

  }
  const { data: surveyorMasterData } = useQuery<SurveyorFilterModel>(
    ["surveyorFilterData"],
    async () => {
      let response = await getSurvyorFilterData();
      return response.data.data;
    }
  );

  const { data: needsFilters } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  let statusList: { id: number, value: string }[] = [
    {
      id: 1, value: 'Pending'
    },
    {
      id: 2, value: 'Completed'
    },
    {
      id: 3, value: 'Failed'
    }
  ]

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }



  function searchComponent() {
    return (
      <Box mt={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (
    <MainLayout>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: 600,
          mb: 2,
          p: 2,
        }}
      >
        <Box flexDirection="row" display="flex" alignItems="center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" fontWeight="600">
            Surveyor Responses
          </Typography>
        </Box>
        <Grid item md={12} lg={12} sm={12}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            sx={{
              "MuiTabs-root css-112qdbf-MuiTabs-root": {
                borderColor: "#F5E6F5",
              },
              mt: 2,
            }}
          >
            <Tab
              label="Survey Responses"
              sx={{
                textTransform: "capitalize",
                color: "#1B001B",
              }}
            />
            <Tab
              label="Downloads"
              sx={{ textTransform: "capitalize", color: "#1B001B" }}
            />
          </Tabs>
        </Grid>
        {currentTab === 0 &&
          <Box>

            <>
              {searchComponent()}
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                p={2}
              >
                <Box display={"flex"} flexDirection={"row"}>
                  <Typography display="flex" alignItems={"center"}>
                    Download as:
                  </Typography>
                  {IconComponent(XlsIcon, "#E9F0ED", () => {
                    download();
                  })}
                </Box>
                <Box display={"flex"} flexDirection={"row"}>

                  <Typography
                    display="flex"
                    alignItems={"center"}
                    color="#91278F"
                    fontWeight="600"
                    sx={{ borderBottom: "3px solid #91278F" }}
                  >
                    Filters:
                  </Typography>
                  {IconComponent(FilterIcon, "#E9F0ED", () => {
                    setOpenModal(true);
                  })}
                </Box>
              </Box>
              {isLoading ? <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
              >
                <Loading />
              </Box> : <DataGridSurveyorResponses
                count={surveyorResponseList?.total || 0}
                data={surveyorResponseList?.list || []}
                labels={surveyorResponseLabel}
                setPage={setPage}
                page={page}
              />}
            </>

          </Box>
        }
        {currentTab === 1 &&
          <Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              width={"100%"}
              p={2}
            >

              <Box display={"flex"} flexDirection={"row"}>
                <Box
                  ml={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                >
                  <Typography>Sort By: </Typography>
                  <Box
                    ml={1}
                    display="flex"
                    flexDirection={"row"}
                    alignItems="center"
                  >
                    <Typography color={"#91278F"}>
                      {sortInAcceding ? "New to old" : "Old to New"}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setSortInAcceding(!sortInAcceding);
                      }}
                    >
                      <img src={Sort} alt="" />
                    </IconButton>
                  </Box>
                </Box>
                <Typography
                  display="flex"
                  alignItems={"center"}
                  color="#91278F"
                  fontWeight="600"
                  sx={{ borderBottom: "3px solid #91278F" }}
                  ml={1}
                >
                  Filters:
                </Typography>
                {IconComponent(FilterIcon, "#E9F0ED", () => {
                  setOpenModalForDownloads(true);
                })}
              </Box>
            </Box>
            <DataGridDownloads
              count={surveyorDownloadList?.total || 0}
              data={surveyorDownloadList?.list || []}
              labels={surveyorDownloads}
              setPage={setPageForDownloads}
              page={pageForDownloads}
              downloadResponse={(id) => {
                surveyDownloadApiCall(id);
              }}
            />

          </Box>}
      </Card>

      <FilterSubmittedChoiceList
        districtId={districtId}
        setDistrictId={setDistrictId}
        cities={needsFilters?.cityList || []}
        setCityId={setCityId}
        cityId={cityId}
        genderList={surveyorMasterData?.gender || []}
        openModal={openModel}
        setOpenModal={setOpenModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        schemeKeyword={schemeKeyword}
        setSchemeKeyword={setSchemeKeyword}
        states={surveyorMasterData?.state || []}
        occupation={surveyorMasterData?.occupation || []}
        education={surveyorMasterData?.education || []}
        income={surveyorMasterData?.personalIncome || []}
        occupationId={occupationId}
        setOccupationId={setOccupationId}
        educationId={educationId}
        setEducationId={setEducationId}
        incomeId={incomeId}
        setIncomeId={setIncomeId}
        stateId={stateId}
        setStateId={setStateId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        resetFilter={() => {
          setCategoryId(null);
          setStateId(null);
          setStartDate("");
          setEndDate("");
          setSelectedGender("");
          setOccupationId(null);
          setEducationId(null);
          setPage(0);
          setIncomeId(null);
          setCityId(null);
        }}
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
      />
      <FilterForDownloads
        status={status}
        setStatus={setStatus}
        statusList={statusList}
        cities={needsFilters?.cityList || []}
        setCityId={setCityId}
        cityId={cityId}
        genderList={surveyorMasterData?.gender || []}
        openModal={openModelForDownloads}
        setOpenModal={setOpenModalForDownloads}
        startDate={startDateForDownloads}
        setStartDate={setStartDateForDownloads}
        endDate={endDateForDownloads}
        setEndDate={setEndDateForDownloads}
        schemeKeyword={schemeKeyword}
        setSchemeKeyword={setSchemeKeyword}
        states={surveyorMasterData?.state || []}
        occupation={surveyorMasterData?.occupation || []}
        education={surveyorMasterData?.education || []}
        income={surveyorMasterData?.personalIncome || []}
        occupationId={occupationId}
        setOccupationId={setOccupationId}
        educationId={educationId}
        setEducationId={setEducationId}
        incomeId={incomeId}
        setIncomeId={setIncomeId}
        stateId={stateId}
        setStateId={setStateId}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        resetFilter={() => {
          setCategoryId(null);
          setStateId(null);
          setStartDateForDownloads("");
          setEndDateForDownloads("");
          setSelectedGender("");
          setOccupationId(null);
          setEducationId(null);
          setPageForDownloads(0);
          setIncomeId(null);
          setCityId(null);
          setStatus(null)

        }}
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
      />
    </MainLayout>
  );
}
