import HttpClient from "../../utils/http";
import { app_users, app_users_download_list, app_users_list, download_users_data } from "../constants/api_endpoints";

export function getAppUsers({
  size,
  page,
  ageFrom,
  ageTo,
  gender,
  occupationId,
  incomeId,
  educationId,
  stateId,
  keyword,
  isSubscribed,
  sortOrder,
  startDate,
  endDate,
  city,
  district
}: {
  size: number | null;
  page: number | null;
  ageFrom?: number | null;
  ageTo?: number | null;
  gender?: string | null;
  occupationId?: number | null;
  incomeId?: number | null;
  educationId?: number | null;
  stateId?: number | null;
  keyword?: string;
  isSubscribed: boolean;
  sortOrder: string | null;
  startDate:string | null;
  endDate:string | null;
  city: number | null;
  district: number | null;
}) {
  let url = app_users_list + `?page=${page}&size=${size}`;
  if (ageFrom && ageTo) url += `&fromAge=${ageFrom}&toAge=${ageTo}`;
  if (gender) url += `&gender=${gender}`;
  if (occupationId) url += `&occupation=${occupationId}`;
  if (incomeId) url += `&currentIncome=${incomeId}`;
  if (educationId) url += `&education=${educationId}`;
  if (stateId) url += `&state=${stateId}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (isSubscribed) url += `&isSubscribed=${isSubscribed}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if(startDate!=="" && endDate!=="") url +=`&dateFrom=${startDate}&dateTo=${endDate}`
  if (city !== null) url += `&city=${city}`
   if (district !== null) url += `&district=${district}`
  return HttpClient.get(url);
}

export function getUsersDownloadList({
  page,
  size,
  dateFrom,
  dateTo,
  sortOrder,
  status
}: {
  page: number | null;
  size: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  sortOrder:string;
  status:number | null;
}) {
  console.log(dateFrom,dateTo,'dateFrom');
  
  let url = `${app_users_download_list}?page=${page}&size=${size}`;
  if (dateFrom && dateTo) url += `&fromDate=${dateFrom}&toDate=${dateTo}`;
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }
  if (status) url += `&status=${status}`;
  return HttpClient.get(url);
}

export function downloadAppUsers({
  ageFrom,
  ageTo,
  gender,
  occupationId,
  incomeId,
  educationId,
  stateId,
  keyword,
  format,
  page,
  size,
  isSubscribed,
  sortOrder,
  startDate,
  endDate,
  city
  
}: {
  format: string | null;
  ageFrom?: number | null;
  ageTo?: number | null;
  gender?: string | null;
  occupationId?: number | null;
  incomeId?: number | null;
  educationId?: number | null;
  stateId?: number | null;
  keyword?: string;
  page?: number;
  size?: number;
  isSubscribed: boolean;
  sortOrder: string | null;
  startDate:string | null;
  endDate:string | null;
  city: number | null;
}) {
  let url = `${app_users_list}?page=${page}&size=${size}&download=${true}&format=${format}`;
  if (ageFrom && ageTo) url += `&fromAge=${ageFrom}&toAge=${ageTo}`;
  if (gender) url += `&gender=${gender}`;
  if (occupationId) url += `&occupation=${occupationId}`;
  if (incomeId) url += `&currentIncome=${incomeId}`;
  if (educationId) url += `&education=${educationId}`;
  if (stateId) url += `&state=${stateId}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (isSubscribed) url += `&isSubscribed=${isSubscribed}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if(startDate!=="" && endDate!=="") url +=`&dateFrom=${startDate}&dateTo=${endDate}`
  if (city !== null) url += `&city=${city}`
  return HttpClient.get(url);
}

export function appUsersService({
  userId,
  isBlocked,
  comments,
}: {
  comments: string;
  isBlocked: boolean;
  userId: number | null;
}) {
  let url = `${app_users}${userId}`;

  return HttpClient.patch(url, {
    isBlocked: isBlocked,
    comments: comments,
  });
}

export function deleteAppUser({
  userId,
  comments,
}: {
  comments: string;
  userId: number | null;
}) {
  let url = `${app_users}${userId}`;

  return HttpClient.delete(url, {
    data: {
      comments: comments,
    },
  });
}

export function getAppUsersById({ id }: { id: number | null }) {
  let url = `${app_users}${id}`;
  return HttpClient.get(url);
}

export function downloadAppUsersById({ id }: { id: number | null }) {
  let url = `${app_users}${id}?download=true&format=excel`;
  return HttpClient.get(url, {
    responseType: "blob",
  });
}

export function getAppUsersSummaryById({ id }: { id: number | null }) {
  let url = `${app_users}summary/${id}`;
  return HttpClient.get(url);
}

export function usersDownload({
  taskId, 
}: {
  taskId: number; 
}) {
  return HttpClient.get(
    `${download_users_data}?taskId=${taskId}`
  ,{ responseType: "blob" });
}