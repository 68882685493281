import { ArrowBack } from "@mui/icons-material";
import { Box, Card, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface SurveyorLayoutProps {
  currentTab: number;
  setCurrentTab: (val: number) => void;
}

export default function SurveyorLayout({
  children,
  currentTab,
  setCurrentTab,
}: PropsWithChildren<SurveyorLayoutProps>) {
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: 600,
        mb: 2,
        p: 2,
      }}
    >
      <Box flexDirection="row" display="flex" alignItems="center">
        <IconButton onClick={() => navigate("/reports", { replace: true })}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" fontWeight="600">
          Surveyors
        </Typography>
      </Box>
      <Tabs
        value={currentTab}
        aria-label="basic tabs example"
        onChange={handleChange}
        sx={{
          "MuiTabs-root css-112qdbf-MuiTabs-root": {
            borderColor: "#F5E6F5",
          },
        }}
      >
        <Tab
          label="Surveyor"
          sx={{
            textTransform: "capitalize",
            color: "#1B001B",
          }}
        />
        <Tab
          label="Choice"
          sx={{ textTransform: "capitalize", color: "#1B001B" }}
        />
      </Tabs>
      <Box sx={{ borderBottom: 1, borderColor: "#F5E6F5" }}></Box>
      {children}
    </Card>
  );
}
