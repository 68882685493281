import React, { useRef, useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import JobListing from "./job_listing/job_listing";
import JobApplication from "./job_application/job_application";
import { Box, Card, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FilterIcon from "../../assets/filter_icon.svg";
import XLSIcon from "../../assets/ph_file-xls.svg";
import AccessDenied from "../../components/access_denied/access_denied";
import useUser from "../../providers/user/user_provider";

export default function Jobs() {
  const jobListingRef = useRef<any>();
  const jobApplicationRef = useRef<any>();
  const { hasPermission } = useUser();
  const [openJobListingModal, setOpenJobListingModal] = useState(false);
  const [openJobApplicationModal, setOpenJobApplicationModal] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };
  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
          height: "fit-content",
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }

  function renderComponentBasedOnTabIndex() {
    switch (currentTab) {
      case 0:
        return (
          <JobListing
            openModal={openJobListingModal}
            setOpenModal={setOpenJobListingModal}
            ref={jobListingRef}
          />
        );
      case 1:
        return (
          <JobApplication
            openModal={openJobApplicationModal}
            setOpenModal={setOpenJobApplicationModal}
            ref={jobApplicationRef}
          />
        );
      default:
        break;
    }
  }
  return (
    <MainLayout>
      <Box>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            minHeight: 600,
            mb: 2,
            minWidth: 1200,
          }}
        >
          {hasPermission("Jobs", "Read") ? (
            <Box display="flex" flexDirection={"column"} sx={{ width: "100%" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"100%"}
                p={2}
              >
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Jobs
                  </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"row"} alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Typography>Generate Report:</Typography>
                    <IconButton
                      sx={{ ml: 2 }}
                      onClick={() => {
                        currentTab === 0
                          ? jobListingRef?.current?.select()
                          : jobApplicationRef?.current?.select();
                      }}
                    >
                      <img src={XLSIcon} alt="" />
                    </IconButton>
                  </Box>
                  <Typography
                    display="flex"
                    alignItems={"center"}
                    color="#91278F"
                    fontWeight="600"
                    sx={{ borderBottom: "3px solid #91278F" }}
                  >
                    Filters:
                  </Typography>
                  {IconComponent(
                    FilterIcon,
                    "#E9F0ED",
                    currentTab === 0
                      ? () => setOpenJobListingModal(true)
                      : () => setOpenJobApplicationModal(true)
                  )}
                </Box>
              </Box>
              <Box
                sx={{ borderBottom: 1, borderColor: "#F5E6F5", p: 2, pb: 0 }}
              >
                <Tabs
                  value={currentTab}
                  aria-label="basic tabs example"
                  onChange={handleChange}
                  sx={{
                    "MuiTabs-root css-112qdbf-MuiTabs-root": {
                      borderColor: "#F5E6F5",
                    },
                  }}
                >
                  <Tab
                    label="Job Listing"
                    sx={{
                      textTransform: "capitalize",
                      color: "#1B001B",
                    }}
                  />
                  <Tab
                    label="Job Application "
                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                  />
                </Tabs>
              </Box>
              {renderComponentBasedOnTabIndex()}
            </Box>
          ) : (
            <Box display="flex" flex={1} width="100%" height="100vh">
              <AccessDenied />
            </Box>
          )}
        </Card>
      </Box>
    </MainLayout>
  );
}
