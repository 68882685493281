import {
  Box,
  Card,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { useQuery } from "react-query";
import { getMasterData } from "../../../services/master/master_service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import ChoicesResponseList from "../components/choices_response_list/choices_response_list";
import FamilyResponseList from "../components/family_response_list/family_response_list";

export default function ChoicesResponse() {
  const navigate = useNavigate();
  const [searchParams, setUrlParam] = useSearchParams();
  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  const [currentTab, setCurrentTab] = React.useState(
    searchParams.has("currentTab") ? +searchParams?.get("currentTab")! : 0
  );
  function renderItemBasedOnTheTabSelection() {
    switch (currentTab) {
      case 0:
        return <ChoicesResponseList currentTab={currentTab} />;
      case 1:
        return <FamilyResponseList currentTab={currentTab} />;
      default:
        break;
    }
  }

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  useEffect(() => {
    setUrlParam({ currentTab: currentTab?.toString() });
  }, [currentTab]);

  return (
    <MainLayout>
      <Grid container mt={2}>
        <Grid item md={12} lg={12} sm={12}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              minHeight: 600,
              mb: 2,
              p: 2,
            }}
          >
            <Box flexDirection="row" display="flex" alignItems="center">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" fontWeight="600">
                Choice Responses
              </Typography>
            </Box>
            <Tabs
              value={currentTab}
              aria-label="basic tabs example"
              onChange={handleChange}
              sx={{
                "MuiTabs-root css-112qdbf-MuiTabs-root": {
                  borderColor: "#F5E6F5",
                },
              }}
            >
              <Tab
                label="Responses"
                sx={{
                  textTransform: "capitalize",
                  color: "#1B001B",
                }}
              />
              <Tab
                label="Family responses"
                sx={{ textTransform: "capitalize", color: "#1B001B" }}
              />
            </Tabs>
            {renderItemBasedOnTheTabSelection()}
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
