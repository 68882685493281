import React from "react";
import BaseLayout from "../../layouts/base/base_layout";
import { Box, Typography } from "@mui/material";
import UndrawAccessDenied from "../../assets/background/undraw_cancel_re_pkdm.svg";

export default function AccessDisabled() {
  return (
    <BaseLayout>
      <Box
        width="inherit"
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        
      >
        <img src={UndrawAccessDenied} alt="" width={200} height={200}></img>
        <Box p={4}>
          <Typography variant="body1" align="center">
            Access Disabled
          </Typography>
          <Typography variant="body2" align="center">
            Kindly Get in touch with the Super Admin to get the Access
          </Typography>
        </Box>
      </Box>
    </BaseLayout>
  );
}
