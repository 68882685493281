import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "முந்தைய",
   "pageNextText": "அடுத்தது",
   "completeText": "முழுமை",
   "previewText": "முன்னோட்ட",
   "editText": "தொகு",
   "startSurveyText": "தொடங்கு",
   "otherItemText": "மற்றவை (விவரிக்க)",
   "noneItemText": "இல்லை",
   "selectAllItemText": "அனைத்தையும் தெரிவுசெய்",
   "progressText": "பக்கம் {0} / {1}",
   "indexText": "{1} இல் {0}",
   "panelDynamicProgressText": "{1} இல் {0}",
   "panelDynamicTabTextFormat": "பேனல் {panelIndex}",
   "questionsProgressText": "{0}/{1} கேள்விகளுக்குப் பதிலளித்தார்",
   "emptySurvey": "கணக்கெடுப்பில் காணக்கூடிய கூறுகள் எதுவும் இல்லை.",
   "completingSurvey": "கணக்கெடுப்பை முடித்ததற்கு நன்றி",
   "completingSurveyBefore": "நீங்கள் ஏற்கனவே இந்தக் கருத்துக்கணிப்பை முடித்துவிட்டதாக எங்கள் பதிவுகள் காட்டுகின்றன.",
   "loadingSurvey": "கணக்கெடுப்பை ஏற்றுகிறது...",
   "placeholder": "தேர்ந்தெடு...",
   "ratingOptionsCaption": "தேர்ந்தெடு...",
   "value": "மதிப்பு",
   "requiredError": "பதில் தேவை.",
   "requiredErrorInPanel": "பதில் தேவை: குறைந்தது ஒரு கேள்விக்கு பதிலளிக்கவும்.",
   "requiredInAllRowsError": "பதில் தேவை: அனைத்து வரிசைகளிலும் கேள்விகளுக்கு பதிலளிக்கவும்.",
   "numericError": "மதிப்பு எண்ணாக இருக்க வேண்டும்.",
   "minError": "மதிப்பு {0} ஐ விடக் குறைவாக இருக்கக்கூடாது",
   "maxError": "மதிப்பு {0} ஐ விட அதிகமாக இருக்கக்கூடாது",
   "textMinLength": "குறைந்தது {0} எழுத்து(களை) உள்ளிடவும்.",
   "textMaxLength": "தயவுசெய்து {0} எழுத்துகளுக்கு மேல் உள்ளிட வேண்டாம்.",
   "textMinMaxLength": "குறைந்தது {0} ஐ உள்ளிடவும் மற்றும் {1} எழுத்துகளுக்கு மேல் இல்லை.",
   "minRowCountError": "குறைந்தது {0} வரிசை(களை) நிரப்பவும்.",
   "minSelectError": "குறைந்தது {0} விருப்பங்களை(களை) தேர்ந்தெடுக்கவும்.",
   "maxSelectError": "தயவு செய்து {0} விருப்பங்களை (கள்) விட வேண்டாம் என்பதைத் தேர்ந்தெடுக்கவும்.",
   "numericMinMax": "&#39;{0}&#39; குறைந்தது {1} ஆகவும் அதிகபட்சம் {2} ஆகவும் இருக்க வேண்டும்",
   "numericMin": "&#39;{0}&#39; குறைந்தது {1} ஆக இருக்க வேண்டும்",
   "numericMax": "&#39;{0}&#39; அதிகபட்சம் {1} ஆக இருக்க வேண்டும்",
   "invalidEmail": "சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்.",
   "invalidExpression": "வெளிப்பாடு: {0} &#39;true&#39; என்பதை வழங்க வேண்டும்.",
   "urlRequestError": "கோரிக்கை &#39;{0}&#39; பிழையை அளித்தது. {1}",
   "urlGetChoicesError": "கோரிக்கையானது வெற்று தரவு அல்லது &#39;பாதை&#39; பண்பு தவறானது",
   "exceedMaxSize": "கோப்பு அளவு {0} ஐ விட அதிகமாக இருக்கக்கூடாது.",
   "noUploadFilesHandler": "கோப்புகளைப் பதிவேற்ற முடியாது. &#39;onUploadFiles&#39; நிகழ்வுக்கு ஹேண்ட்லரைச் சேர்க்கவும்.",
   "otherRequiredError": "பதில் தேவை: மற்றொரு மதிப்பை உள்ளிடவும்.",
   "uploadingFile": "உங்கள் கோப்பு பதிவேற்றப்படுகிறது. சில வினாடிகள் காத்திருந்து மீண்டும் முயற்சிக்கவும்.",
   "loadingFile": "ஏற்றுகிறது...",
   "chooseFile": "கோப்பை(களை) தேர்ந்தெடு...",
   "noFileChosen": "கோப்பு தேர்வுசெய்யப்பட வில்லை",
   "filePlaceholder": "ஒரு கோப்பை இங்கே இழுத்து விடுங்கள் அல்லது பதிவேற்றுவதற்கான கோப்பைத் தேர்ந்தெடுக்க கீழே உள்ள பொத்தானைக் கிளிக் செய்யவும்.",
   "confirmDelete": "பதிவை நீக்க வேண்டுமா?",
   "keyDuplicationError": "இந்த மதிப்பு தனிப்பட்டதாக இருக்க வேண்டும்.",
   "addColumn": "நெடுவரிசையைச் சேர்க்கவும்",
   "addRow": "வரிசையைச் சேர்க்கவும்",
   "removeRow": "அகற்று",
   "emptyRowsText": "வரிசைகள் இல்லை.",
   "addPanel": "புதிதாக சேர்க்கவும்",
   "removePanel": "அகற்று",
   "showDetails": "விவரங்களை காட்டு",
   "hideDetails": "விவரங்களை மறை",
   "choices_Item": "பொருள்",
   "matrix_column": "நெடுவரிசை",
   "matrix_row": "வரிசை",
   "multipletext_itemname": "உரை",
   "savingData": "முடிவுகள் சர்வரில் சேமிக்கப்படுகின்றன...",
   "savingDataError": "பிழை ஏற்பட்டது, முடிவுகளைச் சேமிக்க முடியவில்லை.",
   "savingDataSuccess": "முடிவுகள் வெற்றிகரமாகச் சேமிக்கப்பட்டன!",
   "saveAgainButton": "மீண்டும் முயற்சி செய்",
   "timerMin": "நிமிடம்",
   "timerSec": "நொடி",
   "timerSpentAll": "இந்தப் பக்கத்தில் {0} மற்றும் மொத்தமாக {1} செலவு செய்துள்ளீர்கள்.",
   "timerSpentPage": "இந்தப் பக்கத்தில் {0} செலவு செய்துள்ளீர்கள்.",
   "timerSpentSurvey": "நீங்கள் மொத்தமாக {0} செலவு செய்துள்ளீர்கள்.",
   "timerLimitAll": "இந்தப் பக்கத்தில் {1} இல் {0} செலவழித்துள்ளீர்கள், மொத்தம் {3} இல் {2} செலவிட்டீர்கள்.",
   "timerLimitPage": "இந்தப் பக்கத்தில் {1} இல் {0} செலவு செய்துள்ளீர்கள்.",
   "timerLimitSurvey": "மொத்தத்தில் {1} இல் {0} செலவழித்துவிட்டீர்கள்.",
   "clearCaption": "தெளிவு",
   "signaturePlaceHolder": "இங்கே கையப்பம் இடவும்",
   "chooseFileCaption": "கோப்பைத் தேர்ந்தெடுக்கவும்",
   "takePhotoCaption": "புகைப்படம் எடு",
   "photoPlaceholder": "கேமராவைப் பயன்படுத்தி புகைப்படம் எடுக்க கீழே உள்ள பொத்தானைக் கிளிக் செய்யவும்.",
   "fileOrPhotoPlaceholder": "கேமராவைப் பயன்படுத்தி பதிவேற்ற அல்லது புகைப்படம் எடுக்க கோப்பை இழுத்து விடுங்கள் அல்லது தேர்ந்தெடுக்கவும்.",
   "replaceFileCaption": "கோப்பை மாற்றவும்",
   "removeFileCaption": "இந்தக் கோப்பை அகற்று",
   "booleanCheckedLabel": "ஆம்",
   "booleanUncheckedLabel": "இல்லை",
   "confirmRemoveFile": "இந்தக் கோப்பை நிச்சயமாக அகற்ற விரும்புகிறீர்களா: {0}?",
   "confirmRemoveAllFiles": "எல்லா கோப்புகளையும் நிச்சயமாக அகற்ற விரும்புகிறீர்களா?",
   "questionTitlePatternText": "கேள்வி தலைப்பு",
   "modalCancelButtonText": "ரத்து செய்",
   "modalApplyButtonText": "விண்ணப்பிக்கவும்",
   "filterStringPlaceholder": "தேட தட்டச்சு செய்யவும்...",
   "emptyMessage": "காட்டுவதற்கு தரவு இல்லை",
   "noEntriesText": "இதுவரை உள்ளீடுகள் எதுவும் இல்லை. புதிய உள்ளீட்டைச் சேர்க்க கீழே உள்ள பொத்தானைக் கிளிக் செய்யவும்.",
   "noEntriesReadonlyText": "உள்ளீடுகள் எதுவும் இல்லை.",
   "more": "மேலும்",
   "tagboxDoneButtonCaption": "சரி",
   "selectToRankEmptyRankedAreaText": "அனைத்து தேர்வுகளும் தரவரிசைப்படுத்தப்பட்டுள்ளன",
   "selectToRankEmptyUnrankedAreaText": "தேர்வுகளை வரிசைப்படுத்த இங்கே இழுத்து விடுங்கள்",
   "ok": "சரி",
   "cancel": "ரத்து செய்"
};


surveyLocalization.locales["ta"] = dict;