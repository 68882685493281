import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { ChoiceFormInput } from "../../model/choice_form";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ChipsWithAggregation from "../../../../components/chips_with_aggregation/chips_with_aggregation";
import YesOrNoChips from "../../../../components/yes_or_no_chips/yes_or_no_chips";
import { ChoicesMasterModel, Value } from "../../model/choices_master_model";
import { ChoiceDetails } from "../../model/choices_Details";
import { getDistrictMasterData } from "../../../../services/master/master_service";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ChipsWithAggregationForGender from "../../../../components/chips_with_aggregation/chips_with_aggregation_for_gender";

interface Eligibility {
  choicesMasterData?: ChoicesMasterModel;
  compleatedSteps?: number;
  setCompleatedSteps?: (val: number) => void;
  onSubmit?: SubmitHandler<ChoiceFormInput>;
  viewOnly?: boolean;
  choiceDetails: ChoiceDetails | null;
  title?: string;
  eligibiltyData?: ChoiceFormInput | {};
  editChoice?: boolean;
}

export default function EligibilityCriteria({
  choicesMasterData,
  setCompleatedSteps,
  onSubmit,
  viewOnly,
  choiceDetails,
  title,
  eligibiltyData,
  editChoice,
}: Eligibility) {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ChoiceFormInput>({ defaultValues: eligibiltyData });
  const [currentTab, setCurrentTab] = React.useState(0);

  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  useEffect(() => {
    choiceDetails?.eligibility?.educationIds?.length !== 0 &&
    choiceDetails?.eligibility?.durableOwnershipIds?.length !== 0
      ? setCurrentTab(0)
      : setCurrentTab(1);
  }, [choiceDetails]);

  useEffect(() => {
    if (choiceDetails) {
      setValue("surveyValidFrom", choiceDetails?.expiry.validFrom!);
      setValue(
        "differentlyEbledOnly",
        choiceDetails?.eligibility.differentlyAbled!
      );
      setValue("surveyValidTo", choiceDetails?.expiry.validTo!);
      setValue("fromAge", choiceDetails?.eligibility.fromAge!);
      setValue("toAge", choiceDetails?.eligibility.toAge!);
      setValue("eligibleGender", choiceDetails?.eligibility.genders!);
      setValue(
        "eligibleEmploymentStatus",
        choiceDetails?.eligibility.workingStatusIds!
      );
      setValue("eligibleOccupation", choiceDetails?.eligibility.occupationIds!);
      setValue(
        "educationQualification",
        choiceDetails?.eligibility.educationIds!
      );
      setValue("personalIncome", choiceDetails?.eligibility.personalIncomeIds!);
      setValue("familyIncome", choiceDetails?.eligibility.familyIncomeIds!);
      setValue("familyType", choiceDetails?.eligibility.familyTypeIds!);
      setValue("ownershipHouse", choiceDetails?.eligibility.houseOwnershipIds!);
      setValue(
        "durableOwnership",
        choiceDetails?.eligibility.durableOwnershipIds!
          ? choiceDetails?.eligibility.durableOwnershipIds
          : []
      );
      setValue(
        "areasOfInterest",
        choiceDetails?.eligibility.areasOfInterestIds!
      );
      setValue("eligibleCaste", choiceDetails?.eligibility.casteIds!);
      setValue(
        "eligibleMaritalStatus",
        choiceDetails?.eligibility.maritalStatusIds!
      );
      setValue("areaOfResidence", choiceDetails?.eligibility.residenceIds!);
      setValue("minorityOnly", choiceDetails?.eligibility.minority!);
      setValue("bplCardOnly", choiceDetails?.eligibility.bplCategory!);
      setValue("studentsOnly", choiceDetails?.eligibility.student!);
      setValue(
        "differentlyEbledOnly",
        choiceDetails?.eligibility.differentlyAbled!
      );
      setValue("allowImage", choiceDetails?.eligibility.isImageCapture);
      setValue("allowVideoCap", choiceDetails?.eligibility.isVideoCapture);
      setValue("eligibleStates", choiceDetails?.eligibility.stateIds);
      setValue("eligibleDsitricts", choiceDetails?.eligibility.districtIds);
      setValue("vendorsEmailId", choiceDetails?.eligibility.vendorEmailId);
      setValue("nccsGrades", choiceDetails?.eligibility.nccsGrades);
    }
  }, [choiceDetails]);

  const dropdown1Value = watch("eligibleStates");
  const toAgeValue = watch("toAge");
  const validTo = watch("surveyValidTo");

  const { data: district } = useQuery(
    ["districtMaster", dropdown1Value],
    async () => {
      if (dropdown1Value) {
        let response = await getDistrictMasterData(dropdown1Value?.[0]);
        return response?.data?.data?.list;
      }
      setValue("eligibleDsitricts", []);
    }
  );

  function _dateRange({
    title,
    date,
    setDate,
    label,
    error,
    helperText,
  }: {
    title: string;
    date: string | Date;
    setDate: (value: string) => void;
    label: string;
    error: boolean;
    helperText: string | null;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection={"column"}>
          <DatePicker
            disabled={viewOnly}
            label={label}
            format="DD-MM-YYYY"
            value={date ? dayjs(date, "YYYY-MM-DD") : null}
            onChange={(value: Dayjs | null) => {
              setDate(value?.format("YYYY-MM-DD") || "");
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
        </Box>
        {error && (
          <Typography variant="caption" color="error">
            {helperText}
          </Typography>
        )}
      </Box>
    );
  }

  function removeNumberDuplicates(arr: number[]): number[] {
    return arr.reduce((acc, x) => {
      if (!acc.includes(x)) {
        acc.push(x);
      }
      return acc;
    }, [] as number[]);
  }

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit?.(onSubmit!)}>
        <Grid container spacing={3}>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Controller
                name="surveyValidFrom"
                control={control}
                rules={{
                  // required: "adsad",
                  validate: (value: String) => {
                    console.log(value, validTo);
                    console.log(value > validTo);

                    if (value && validTo && value > validTo) {
                      return "Valid From Date should be less than Valid to Date";
                    }
                  },
                }}
                render={({ field: { onChange, value } }) =>
                  _dateRange({
                    title: "Date Range",
                    date: value,
                    setDate: onChange,
                    label: "Survey valid from",
                    helperText: errors?.surveyValidFrom?.message!,
                    error: !!errors.surveyValidFrom!,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Controller
                name="surveyValidTo"
                control={control}
                render={({ field: { onChange, value } }) =>
                  _dateRange({
                    title: "Date Range",
                    date: value,
                    setDate: onChange,
                    label: "Survey valid to",
                    helperText: errors.surveyValidTo?.message!,
                    error: !!errors.surveyValidTo!,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Controller
                name="eligibleStates"
                control={control}
                rules={{ required: "select eligible state" }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={viewOnly}
                    fullWidth
                    multiple
                    options={(choicesMasterData?.state || []).map(
                      (item) => item.id
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      (choicesMasterData?.state || []).find(
                        (item) => item.id === option
                      )?.value || ""
                    }
                    value={value || []}
                    onChange={(_, value, reason, details) => {
                      if (
                        value ||
                        [].find((item) => item === details?.option)
                      ) {
                        let d = choicesMasterData?.state?.filter(
                          (item: any) => {
                            return item.isAggregate === true;
                          }
                        );
                        if (value?.find((itm) => itm === d?.[0]?.id)) {
                          onChange([d?.[0]?.id]);
                        } else {
                          onChange([...value]);
                        }
                      }
                    }}
                    // isOptionEqualToValue={(option, value) =>
                    //   option.id === value.id
                    // }
                    renderOption={(
                      props,
                      option,
                      { selected, index },
                      { value }
                    ) => {
                      let isAllSelected: boolean = !!value?.find(
                        (v) =>
                          (choicesMasterData?.state || []).find(
                            (item) => item.id === v
                          )?.isAggregate || false
                      );

                      let optionDetails = (choicesMasterData?.state || []).find(
                        (item) => item.id === option
                      );

                      return (
                        <>
                          <li {...props}>
                            <Checkbox
                              checked={selected || isAllSelected}
                              value={index}
                            />
                            {optionDetails?.value}
                          </li>
                          {optionDetails?.isAggregate ? <Divider /> : null}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select eligible state*"
                        fullWidth
                        placeholder="Select state*"
                        error={!!errors.eligibleStates}
                        helperText={errors.eligibleStates?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Controller
                name="eligibleDsitricts"
                control={control}
                // rules={{ required: "select eligible Districts" }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={
                      viewOnly ||
                      dropdown1Value?.length !== 1 ||
                      !!dropdown1Value?.find(
                        (v) =>
                          (choicesMasterData?.state || []).find(
                            (item: { id: number; value: string }) =>
                              item.id === v
                          )?.isAggregate
                      )
                    }
                    fullWidth
                    multiple
                    options={(district || []).map(
                      (item: { id: number; value: string }) => item.id
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      (district || []).find(
                        (item: { id: number; value: string }) =>
                          item.id === option
                      )?.value || ""
                    }
                    value={value || []}
                    onChange={(_, value, reason, details) => {
                      let optionSelected = (district || []).find(
                        (item: { id: number; value: string }) =>
                          item.id === details?.option
                      );
                      if (
                        value ||
                        [].find((item) => item === details?.option)
                      ) {
                        onChange([...value]);
                      } else {
                        if (optionSelected) {
                          onChange(
                            removeNumberDuplicates([
                              ...value,
                              optionSelected.id,
                            ])
                          );
                        }
                      }
                    }}
                    renderOption={(
                      props,
                      option,
                      { selected, index },
                      { value }
                    ) => {
                      let isAllSelected: boolean = !!value?.find(
                        (v) =>
                          (district || []).find(
                            (item: { id: number; value: string }) =>
                              item.id === v
                          )?.isAggregate || false
                      );

                      let optionDetails = (district || []).find(
                        (item: { id: number; value: string }) =>
                          item.id === option
                      );

                      return (
                        <>
                          <li {...props}>
                            <Checkbox
                              checked={selected || isAllSelected}
                              value={index}
                            />
                            {optionDetails?.value}
                          </li>
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select eligible districts"
                        fullWidth
                        placeholder="Select districts"
                        error={!!errors.eligibleDsitricts}
                        helperText={errors.eligibleDsitricts?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12} display={"flex"}>
            <Controller
              name="fromAge"
              control={control}
              rules={{
                required: "Please select From age",
                validate: (value: number) => {
                  if (value > toAgeValue) {
                    return "From age should be less than toAge";
                  }
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={viewOnly}
                  type="number"
                  fullWidth
                  label="From age*"
                  {...register("fromAge", {
                    valueAsNumber: true,
                    min: 1,
                    max: 120,
                  })}
                  error={!!errors.fromAge}
                  helperText={errors.fromAge?.message}
                  InputProps={{
                    inputProps: {
                      min: 1,
                      max: 120, // Optionally set a maximum value
                      step: 1, // Optionally set the step value
                      inputMode: "numeric", // Specify input mode as numeric
                    },
                    // Remove the spin buttons (increase and decrease buttons)
                    inputMode: "numeric",
                  }}
                />
              )}
            />

            <Controller
              name="toAge"
              control={control}
              rules={{ required: "Please select To age" }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  disabled={viewOnly}
                  sx={{ ml: 4 }}
                  type="number"
                  fullWidth
                  label="To age*"
                  {...register("toAge", { valueAsNumber: true, max: 120 })}
                  error={!!errors.toAge}
                  helperText={errors.toAge?.message}
                  InputProps={{
                    inputProps: {
                      min: 1, // Optionally set a minimum value
                      max: 120, // Optionally set a maximum value
                      step: 1, // Optionally set the step value
                      inputMode: "numeric", // Specify input mode as numeric
                    },
                    // Remove the spin buttons (increase and decrease buttons)
                    inputComponent: "input",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Eligible Genders*</Typography>
              <Controller
                name="eligibleGender"
                control={control}
                rules={{ required: "Select eligible genders" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregationForGender
                    items={
                      choicesMasterData?.gender?.map((item, index) => ({
                        id: item.id,
                        isAggregate: item?.is_aggregate!,
                        value: item?.value,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.eligibleGender}
                    helperText={errors.eligibleGender?.message}
                    disabled={viewOnly}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="eligibleEmploymentStatus"
              rules={{ required: "Select employment status" }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={viewOnly}
                  fullWidth
                  multiple
                  options={(choicesMasterData?.workingStatus || []).map(
                    (item) => item.id
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    (choicesMasterData?.workingStatus || []).find(
                      (item) => item.id === option
                    )?.value || ""
                  }
                  value={value || []}
                  onChange={(_, value, reason, details) => {
                    if (value || [].find((item) => item === details?.option)) {
                      let d = choicesMasterData?.workingStatus?.filter(
                        (item: any) => {
                          return item.isAggregate === true;
                        }
                      );
                      if (value?.find((itm) => itm === d?.[0]?.id)) {
                        onChange([d?.[0]?.id]);
                      } else {
                        onChange([...value]);
                      }
                    }
                  }}
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (v) =>
                        (choicesMasterData?.workingStatus || []).find(
                          (item) => item.id === v
                        )?.isAggregate || false
                    );

                    let optionDetails = (
                      choicesMasterData?.workingStatus || []
                    ).find((item) => item.id === option);

                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {optionDetails?.value}
                        </li>
                        {optionDetails?.isAggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select eligible Employment Status*"
                      fullWidth
                      placeholder="Select Employment*"
                      error={!!errors.eligibleEmploymentStatus}
                      helperText={errors.eligibleEmploymentStatus?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="eligibleOccupation"
              rules={{ required: "Select occupation" }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={viewOnly}
                  fullWidth
                  multiple
                  options={(choicesMasterData?.occupation || []).map(
                    (item) => item.id
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    (choicesMasterData?.occupation || []).find(
                      (item) => item.id === option
                    )?.value || ""
                  }
                  value={value || []}
                  onChange={(_, value, reason, details) => {
                    if (value || [].find((item) => item === details?.option)) {
                      let d = choicesMasterData?.occupation?.filter(
                        (item: any) => {
                          return item.isAggregate === true;
                        }
                      );
                      if (value?.find((itm) => itm === d?.[0]?.id)) {
                        onChange([d?.[0]?.id]);
                      } else {
                        onChange([...value]);
                      }
                    }
                  }}
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (v) =>
                        (choicesMasterData?.occupation || []).find(
                          (item) => item.id === v
                        )?.isAggregate || false
                    );

                    let optionDetails = (
                      choicesMasterData?.occupation || []
                    ).find((item) => item.id === option);

                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {optionDetails?.value}
                        </li>
                        {optionDetails?.isAggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select eligible occupation*"
                      fullWidth
                      placeholder="Select Occupation*"
                      error={!!errors.eligibleOccupation}
                      helperText={errors.eligibleOccupation?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Controller
                name="personalIncome"
                rules={{ required: "Select personal income" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={viewOnly}
                    fullWidth
                    multiple
                    options={(choicesMasterData?.personalIncome || []).map(
                      (item) => item.id
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      (choicesMasterData?.personalIncome || []).find(
                        (item) => item.id === option
                      )?.value || ""
                    }
                    value={value || []}
                    onChange={(_, value, reason, details) => {
                      if (
                        value ||
                        [].find((item) => item === details?.option)
                      ) {
                        let d = choicesMasterData?.personalIncome?.filter(
                          (item: any) => {
                            return item.isAggregate === true;
                          }
                        );
                        if (value?.find((itm) => itm === d?.[0]?.id)) {
                          onChange([d?.[0]?.id]);
                        } else {
                          onChange([...value]);
                        }
                      }
                    }}
                    renderOption={(
                      props,
                      option,
                      { selected, index },
                      { value }
                    ) => {
                      let isAllSelected: boolean = !!value?.find(
                        (v) =>
                          (choicesMasterData?.personalIncome || []).find(
                            (item) => item.id === v
                          )?.isAggregate || false
                      );

                      let optionDetails = (
                        choicesMasterData?.personalIncome || []
                      ).find((item) => item.id === option);

                      return (
                        <>
                          <li {...props}>
                            <Checkbox
                              checked={selected || isAllSelected}
                              value={index}
                            />
                            {optionDetails?.value}
                          </li>
                          {optionDetails?.isAggregate ? <Divider /> : null}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Personal Income*"
                        fullWidth
                        placeholder="Select  Personal Income*"
                        error={!!errors.personalIncome}
                        helperText={errors.personalIncome?.message}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="familyIncome"
              rules={{ required: "Select family income" }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={viewOnly}
                  fullWidth
                  multiple
                  options={(choicesMasterData?.familyIncome || []).map(
                    (item) => item.id
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    (choicesMasterData?.familyIncome || []).find(
                      (item) => item.id === option
                    )?.value || ""
                  }
                  value={value || []}
                  onChange={(_, value, reason, details) => {
                    if (value || [].find((item) => item === details?.option)) {
                      let d = choicesMasterData?.familyIncome?.filter(
                        (item: any) => {
                          return item.isAggregate === true;
                        }
                      );
                      if (value?.find((itm) => itm === d?.[0]?.id)) {
                        onChange([d?.[0]?.id]);
                      } else {
                        onChange([...value]);
                      }
                    }
                  }}
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (v) =>
                        (choicesMasterData?.familyIncome || []).find(
                          (item) => item.id === v
                        )?.isAggregate || false
                    );

                    let optionDetails = (
                      choicesMasterData?.familyIncome || []
                    ).find((item) => item.id === option);

                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {optionDetails?.value}
                        </li>
                        {optionDetails?.isAggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Family Income*"
                      fullWidth
                      placeholder="Select  Family Income*"
                      error={!!errors.familyIncome}
                      helperText={errors.familyIncome?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="familyType"
              rules={{ required: "Select family type" }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={viewOnly}
                  fullWidth
                  multiple
                  options={(choicesMasterData?.familyType || []).map(
                    (item) => item.id
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    (choicesMasterData?.familyType || []).find(
                      (item) => item.id === option
                    )?.value || ""
                  }
                  value={value || []}
                  onChange={(_, value, reason, details) => {
                    if (value || [].find((item) => item === details?.option)) {
                      let d = choicesMasterData?.familyType?.filter(
                        (item: any) => {
                          return item.isAggregate === true;
                        }
                      );
                      if (value?.find((itm) => itm === d?.[0]?.id)) {
                        onChange([d?.[0]?.id]);
                      } else {
                        onChange([...value]);
                      }
                    }
                  }}
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (v) =>
                        (choicesMasterData?.familyType || []).find(
                          (item) => item.id === v
                        )?.isAggregate || false
                    );

                    let optionDetails = (
                      choicesMasterData?.familyType || []
                    ).find((item) => item.id === option);

                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {optionDetails?.value}
                        </li>
                        {optionDetails?.isAggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Family Type*"
                      fullWidth
                      placeholder="Select Family Type*"
                      error={!!errors.familyType}
                      helperText={errors.familyType?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="ownershipHouse"
              rules={{ required: "Select ownership house" }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={viewOnly}
                  fullWidth
                  multiple
                  options={(choicesMasterData?.houseOwnership || []).map(
                    (item) => item.id
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    (choicesMasterData?.houseOwnership || []).find(
                      (item) => item.id === option
                    )?.value || ""
                  }
                  value={value || []}
                  onChange={(_, value, reason, details) => {
                    if (value || [].find((item) => item === details?.option)) {
                      let d = choicesMasterData?.houseOwnership?.filter(
                        (item: any) => {
                          return item.isAggregate === true;
                        }
                      );
                      if (value?.find((itm) => itm === d?.[0]?.id)) {
                        onChange([d?.[0]?.id]);
                      } else {
                        onChange([...value]);
                      }
                    }
                  }}
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (v) =>
                        (choicesMasterData?.houseOwnership || []).find(
                          (item) => item.id === v
                        )?.isAggregate || false
                    );

                    let optionDetails = (
                      choicesMasterData?.houseOwnership || []
                    ).find((item) => item.id === option);

                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {optionDetails?.value}
                        </li>
                        {optionDetails?.isAggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select House ownership*"
                      fullWidth
                      placeholder="Select House ownership"
                      error={!!errors.ownershipHouse}
                      helperText={errors.ownershipHouse?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={12} lg={12} sm={12}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              sx={{
                "MuiTabs-root css-112qdbf-MuiTabs-root": {
                  borderColor: "#F5E6F5",
                },
                mt: 2,
              }}
            >
              <Tab
                label="Education Qualification And Durable Ownership"
                sx={{
                  textTransform: "capitalize",
                  color: "#1B001B",
                }}
              />
              <Tab
                label="NCCS"
                sx={{ textTransform: "capitalize", color: "#1B001B" }}
              />
            </Tabs>
          </Grid>

          {currentTab === 0 && (
            <>
              <Grid item md={6} lg={6} sm={12}>
                <Controller
                  name="educationQualification"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disabled={viewOnly}
                      fullWidth
                      multiple
                      options={(choicesMasterData?.education || []).map(
                        (item) => item.id
                      )}
                      disableCloseOnSelect
                      getOptionLabel={(option) =>
                        (choicesMasterData?.education || []).find(
                          (item) => item.id === option
                        )?.value || ""
                      }
                      value={value || []}
                      onChange={(_, value, reason, details) => {
                        if (
                          value ||
                          [].find((item) => item === details?.option)
                        ) {
                          let d = choicesMasterData?.education?.filter(
                            (item: any) => {
                              return item.isAggregate === true;
                            }
                          );
                          if (value?.find((itm) => itm === d?.[0]?.id)) {
                            onChange([d?.[0]?.id]);
                          } else {
                            onChange([...value]);
                          }
                        }
                        setValue("nccsGrades", []);
                      }}
                      renderOption={(
                        props,
                        option,
                        { selected, index },
                        { value }
                      ) => {
                        let isAllSelected: boolean = !!value?.find(
                          (v) =>
                            (choicesMasterData?.education || []).find(
                              (item) => item.id === v
                            )?.isAggregate || false
                        );

                        let optionDetails = (
                          choicesMasterData?.education || []
                        ).find((item) => item.id === option);

                        return (
                          <>
                            <li {...props}>
                              <Checkbox
                                checked={selected || isAllSelected}
                                value={index}
                              />
                              {optionDetails?.value}
                            </li>
                            {optionDetails?.isAggregate ? <Divider /> : null}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Education Qualification"
                          fullWidth
                          placeholder="Select Education qualification"
                          error={!!errors.educationQualification}
                          helperText={errors.educationQualification?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} lg={6}>
                <Box>
                  <Typography>Durable Ownership</Typography>
                  <Controller
                    name="durableOwnership"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ChipsWithAggregation
                        disabled={viewOnly}
                        items={
                          choicesMasterData?.durableOwnership?.map(
                            (item, index) => ({
                              id: item.id,
                              value: item.value,
                              isAggregate: item.value === "All" ? true : false,
                            })
                          ) || []
                        }
                        value={value}
                        onChange={(value) => {
                          onChange(value);
                          setValue("nccsGrades", []);
                        }}
                        error={!!errors.durableOwnership}
                        helperText={errors.durableOwnership?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </>
          )}
          {currentTab === 1 && (
            <Grid item md={6} lg={6} sm={12}>
              <Controller
                name="nccsGrades"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disabled={viewOnly}
                    fullWidth
                    multiple
                    options={(choicesMasterData?.nccsGrades || [])
                      .map((item) => item.value)
                      .sort((a, b) => (a === "All" ? -1 : 0))}
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      (choicesMasterData?.nccsGrades || []).find(
                        (item) => item.value === option
                      )?.value || ""
                    }
                    value={value || []}
                    onChange={(_, value, reason, details) => {
                      if (
                        value ||
                        [].find((item) => item === details?.option)
                      ) {
                        let d = choicesMasterData?.nccsGrades?.filter(
                          (item: any) => {
                            return item.isAggregate === true;
                          }
                        );
                        if (value?.find((itm) => itm === d?.[0]?.value)) {
                          onChange([d?.[0]?.value]);
                        } else {
                          onChange([...value]);
                        }
                      }
                      setValue("educationQualification", []);
                      setValue("durableOwnership", []);
                    }}
                    renderOption={(
                      props,
                      option,
                      { selected, index },
                      { value }
                    ) => {
                      let isAllSelected: boolean = !!value?.find(
                        (v) =>
                          (choicesMasterData?.nccsGrades || []).find(
                            (item) => item.value === v
                          )?.isAggregate || false
                      );

                      let optionDetails = (
                        choicesMasterData?.nccsGrades || []
                      ).find((item) => item.value === option);

                      return (
                        <>
                          <li {...props}>
                            <Checkbox
                              checked={selected || isAllSelected}
                              value={index}
                            />
                            {optionDetails?.value}
                          </li>
                          {optionDetails?.isAggregate ? <Divider /> : null}
                        </>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select NCCS Criteria (You can select multiple)"
                        fullWidth
                        placeholder="Select  NCCS Criteria"
                        error={!!errors.nccsGrades}
                        helperText={errors.nccsGrades?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}
          {currentTab === 1 && <Grid item md={6} lg={6} sm={12} />}
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Area of Interest*</Typography>
              <Controller
                name="areasOfInterest"
                control={control}
                rules={{ required: "Select Area of interest details" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    disabled={viewOnly}
                    items={
                      choicesMasterData?.areasOfInterest?.map(
                        (item, index) => ({
                          id: item.id,
                          value: item.value,
                          isAggregate: item.value === "All" ? true : false,
                        })
                      ) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.areasOfInterest}
                    helperText={errors.areasOfInterest?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Eligible Caste*</Typography>
              <Controller
                name="eligibleCaste"
                control={control}
                rules={{ required: "Select Eligible Caste*" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    disabled={viewOnly}
                    items={
                      choicesMasterData?.caste?.map((item, index) => ({
                        id: item.id,
                        value: item.value,
                        isAggregate: item.value === "All" ? true : false,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.eligibleCaste}
                    helperText={errors.eligibleCaste?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}></Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Eligible Marital Status*</Typography>
              <Controller
                name="eligibleMaritalStatus"
                control={control}
                rules={{ required: "Select Marital Status" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    disabled={viewOnly}
                    items={
                      choicesMasterData?.maritalStatus?.map((item, index) => ({
                        id: item.id,
                        value: item.value,
                        isAggregate: item.value === "All" ? true : false,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.eligibleMaritalStatus}
                    helperText={errors.eligibleMaritalStatus?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Area of Residence*</Typography>
              <Controller
                name="areaOfResidence"
                control={control}
                rules={{ required: "Select Area of residence*" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    disabled={viewOnly}
                    items={
                      choicesMasterData?.caste?.map((item, index) => ({
                        id: item.id,
                        value: item.value,
                        isAggregate: item.value === "All" ? true : false,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.areaOfResidence}
                    helperText={errors.areaOfResidence?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Whether eligible for Minorities only?</Typography>
              <Controller
                name="minorityOnly"
                control={control}
                // rules={{
                //   validate: (value) =>
                //     typeof value == "boolean"
                //       ? undefined
                //       : "This field is required",
                // }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.minorityOnly}
                    helperText={errors.minorityOnly?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Whether eligible for BPL category only?</Typography>
              <Controller
                name="bplCardOnly"
                control={control}
                // rules={{
                //   validate: (value) =>
                //     typeof value == "boolean"
                //       ? undefined
                //       : "This field is required",
                // }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.bplCardOnly}
                    helperText={errors.bplCardOnly?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Whether eligible for students only?</Typography>
              <Controller
                name="studentsOnly"
                control={control}
                // rules={{
                //   validate: (value) =>
                //     typeof value == "boolean"
                //       ? undefined
                //       : "This field is required",
                // }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.studentsOnly}
                    helperText={errors.studentsOnly?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>
                Whether eligible for differently abled only?
              </Typography>
              <Controller
                name="differentlyEbledOnly"
                control={control}
                // rules={{
                //   validate: (value) =>
                //     typeof value == "boolean"
                //       ? undefined
                //       : "This field is required",
                // }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.differentlyEbledOnly}
                    helperText={errors.differentlyEbledOnly?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Allow Video Capturing for Surveyors?*</Typography>
              <Controller
                name="allowVideoCap"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.allowVideoCap}
                    helperText={errors.allowVideoCap?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Box>
              <Typography>Allow Image Capturing for Surveyors?*</Typography>
              <Controller
                name="allowImage"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    disabled={viewOnly}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.allowImage}
                    helperText={errors.allowImage?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6} lg={6} sm={12}></Grid>
          <Grid item md={6} lg={6} sm={12}>
            <Controller
              name="vendorsEmailId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  disabled={viewOnly}
                  type="email"
                  fullWidth
                  label="Client’s Email ID"
                  error={!!errors.vendorsEmailId}
                  helperText={errors.vendorsEmailId?.message}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={12}></Grid>
          {!viewOnly && (
            <>
              <Grid item md={6} lg={6} sm={12} alignItems={"flex-end"}>
                <Grid container>
                  <Grid item md={6} lg={6} sm={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                display="flex"
                item
                md={6}
                lg={6}
                sm={12}
                justifyContent={"end"}
              >
                <Grid item md={6} lg={6} sm={12}>
                  <Button fullWidth variant="contained" type="submit">
                    {editChoice ? "Save changes" : "Proceed"}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Box>
  );
}
