import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { deleteRoles, getRoles } from "../../../services/admin/admin_services";
import DeleteRole from "../components/delete_role/delete_role";
import ViewRoleDetails from "../components/view_role_details/view_role_details";
import { RoleList } from "../model/admin_roles";
import RoleListingPage from "../components/role_listing_page/role_listing_page";
import useUser from "../../../providers/user/user_provider";
import AccessDenied from "../../../components/access_denied/access_denied";
import { useQuery } from "react-query";
import { queryClient } from "../../../providers/query/query";

export default function RoleManagement() {
  const [deleteRole, setDeleteRole] = useState(false);

  const [selectedRoleDetails, setSelectedRoleDetails] = useState<RoleList>(
    {} as RoleList
  );
  const [viewSelectedRoleDetails, setViewSelectedRoleDetails] =
    useState<boolean>(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState<RoleList[]>([]);
  const { showSnackbar } = useFeedback();
  const { hasPermission } = useUser();

  const { data: roleList, isLoading } = useQuery(["role"], async () => {
    let res = await getRoles();
    return res.data.data.list;
  });

  function searchFilter() {
    if (searchValue.trim() === "") {
      setSearchList(roleList);
    } else {
      let filteredList = roleList.filter((roles: RoleList) => {
        return (
          roles.role.toLowerCase().includes(searchValue.trim().toLowerCase()) &&
          searchValue !== ""
        );
      });
      setSearchList(filteredList);
    }
  }

  useEffect(() => {
    searchFilter();
  }, [searchValue, roleList]);

  const deleteAdminRolesApiCall = () => {
    setDeleteRole(false);
    let payload = {
      params: {
        roleId: selectedRoleDetails.id,
      },
    };

    deleteRoles(payload)
      .then((res) => {
        if (res.status === 200) {
          showSnackbar(res?.data?.data?.message);
          queryClient.invalidateQueries({ queryKey: ["role"] });
        } else {
          showSnackbar("Something went wrong");
        }
      })
      .catch((e) => {
        setDeleteRole(false);
        showSnackbar("Something went wrong");
      });
  };

  return (
    <MainLayout>
      <Box>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
            }}
          >
            <Loading />
          </Box>
        ) : hasPermission("Users", "Read") ? (
          <RoleListingPage
            searchList={searchList}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setViewSelectedRoleDetails={setViewSelectedRoleDetails}
            setSelectedRoleDetails={setSelectedRoleDetails}
            roleList={roleList}
            setDeleteRole={setDeleteRole}
          />
        ) : (
          <Box display="flex" flex={1} width="100%" height="100%">
            <AccessDenied />
          </Box>
        )}
        <ViewRoleDetails
          viewSelectedRoleDetails={viewSelectedRoleDetails}
          setViewSelectedRoleDetails={setViewSelectedRoleDetails}
          selectedRoleDetails={selectedRoleDetails}
        />
        <DeleteRole
          deleteRole={deleteRole}
          setDeleteRole={setDeleteRole}
          selectedRoleDetails={selectedRoleDetails}
          deleteAdminRolesApiCall={deleteAdminRolesApiCall}
        />
      </Box>
    </MainLayout>
  );
}
