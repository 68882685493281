import React from "react";
import MainLayout from "../../layouts/main/main_layout";
import { Box, Button, Card, Divider, Typography } from "@mui/material";

import {
  admin_report,
  grievance_report,
  jobs_reports,
  respondent_report,
  rewards_reports,
  schemes_report,
  service_report,
} from "../../config";

export default function Reports() {
  let reports = [
    {
      title: "Admin Report",
      link: admin_report,
    },
    {
      title: "Respondent Report",
      link: respondent_report,
    },
    {
      title: "Schemes Report",
      link: schemes_report,
    },
    {
      title: "Grievance Report",
      link: grievance_report,
    },
    {
      title: "Service Report",
      link: service_report,
    },
    {
      title: "Jobs Report",
      link: jobs_reports,
    },
    {
      title: "Rewards Report",
      link: rewards_reports,
    },
  ];
  return (
    <MainLayout>
      <Box>
        <Typography variant="h6" color="#1B001B">
          Click on the below reports to view details
        </Typography>
        <Divider
          sx={{
            display: "flex",
            opacity: 0.5,
            borderBottom: "1px dotted #7C797C",
            mt: 2,
          }}
        />
        <Box mt={2}>
          {reports?.map((reportItem) => {
            return (
              <Card sx={{ mb: 2, borderRadius: 2 }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ p: 2 }}
                >
                  <Typography fontWeight={"600"}>{reportItem.title}</Typography>
                  <a target="_blank" href={reportItem?.link} rel="noreferrer">
                    <Button sx={{ minWidth: 250 }} variant="contained">
                      View Report
                    </Button>
                  </a>
                </Box>
                <Divider
                  sx={{
                    display: "flex",
                    opacity: 0.5,
                    borderBottom: "1px solid #91278F",
                  }}
                />
              </Card>
            );
          })}
        </Box>
      </Box>
    </MainLayout>
  );
}
