import { ChevronRightOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Steppe({ stepperData, stepperCount }:
    {
        stepperData: {
            id: number,
            name: string
        }[],
        stepperCount: number,
        setStepperCount: (val: number) => void
    }) {

    return (
        <Box>
            <Box display={'flex'} flexDirection={'row'}>
                {stepperData.map((item, index) => {
                    return <Box display={'flex'} flexDirection={'row'}>
                        <Typography
                            ml={1}
                            fontWeight={item?.id === stepperCount ? 600 : 400}
                            color={item?.id === stepperCount ? '#91278F' : '#7C797C'}>
                            {item?.id}.
                        </Typography>
                        <Typography
                            fontWeight={item?.id === stepperCount ? 600 :
                                item?.id <= stepperCount ?
                                    600 : 400}
                            color={item?.id === stepperCount ? '#91278F' :
                                item?.id <= stepperCount ?
                                    '#1E6446' : '#7C797C'}
                            mx={2}>
                            {item?.name}
                        </Typography>
                        {index !== stepperData?.length - 1 &&
                            <ChevronRightOutlined />}
                    </Box>
                })}
            </Box>
        </Box>
    )
}
