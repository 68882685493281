import React from 'react'
import { OtherDetails, } from '../../model/create_user';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { SchemeMaster } from '../../../schemes/models/scheme_master';

interface props {
    onSubmit: (data: OtherDetails) => void;
    uid: number | null;
    setUid: (val: number) => void;
    stepperCount: number;
    setStepperCount: (val: number) => void;
    masterDataList: SchemeMaster;
    otherDetails: OtherDetails;
    modifyUser?: boolean | undefined
}

export default function OtherDetailsComp({
    stepperCount,
    onSubmit: _onSubmit,
    uid,
    setUid,
    setStepperCount,
    masterDataList,
    otherDetails,
    modifyUser
}
    : props) {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },

    } = useForm<OtherDetails>({
        defaultValues: otherDetails,
    });

    const onSubmit: SubmitHandler<OtherDetails> = (data) => {
        setStepperCount(stepperCount + 1);
        _onSubmit(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container mt={3} spacing={2}>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="educational"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Educational Qualification (Optional)" />}
                                options={masterDataList?.education?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="workingStatus"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Working Status (Optional)" />}
                                options={masterDataList?.workingStatus?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="occupational"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Occupation (Optional)" />}
                                options={masterDataList?.occupation?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="familyType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Family Type (Optional)" />}
                                options={masterDataList?.familyType?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => {
                                    onChange(selectedOption)
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="ownershipOfHouse"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Ownership of House (Optional)" />}
                                options={masterDataList?.houseOwnership?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="personalIncome"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Personal Income (Optional)" />}
                                options={masterDataList?.personalIncome?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={6} lg={6} sm={12} xs={12}>
                    <Controller
                        name="familyIncome"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                fullWidth
                                disableCloseOnSelect
                                value={value}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => <TextField {...params} label="Family Income (Optional)" />}
                                options={masterDataList?.familyIncome?.filter((item) => !item?.isAggregate) || []}
                                onChange={(_, selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                </Grid>
                <Grid display={'flex'} item md={12} lg={12} sm={12} xs={12} justifyContent={'end'}>
                    {!!!modifyUser && <Button variant='outlined' sx={{ minWidth: 150 }} onClick={() => setStepperCount(stepperCount - 1)}>
                        Back
                    </Button>}
                    <Button
                        variant='contained'
                        type="submit"
                        sx={{ ml: 1, minWidth: 150 }}>
                        {!!!modifyUser ? "Proceed" : "Save Changes"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
