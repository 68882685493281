import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Add, ArrowBack, DeleteOutline } from "@mui/icons-material";
import MainLayout from "../../../layouts/main/main_layout";
import { getMasterData } from "../../../services/choices/choices_services";
import { useQuery } from "react-query";
import MapChoicesPopup from "../components/map_choices_popup/map_choices_popup";
import {
  addServeyors,
  getDetailsOfSurveyor,
  getDetailsOfSurveyorChoices,
  updateDetailsOfSurveyor,
} from "../../../services/surveyor/surveyor";
import {
  getCityMasterData,
  getDistrictMasterData,
} from "../../../services/master/master_service";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { useLocation, useNavigate } from "react-router-dom";
import { SurveyorDetails } from "../model/surveyor_details";
import { Choices } from "../../choices/model/choices";
import locales from "../../../constants/locales";
import dayjs from "dayjs";

interface SurveyorCreateAndModify {
  modifyFlow?: boolean;
}

export default function CreateNewSurveyor({
  modifyFlow,
}: SurveyorCreateAndModify) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data: surveyorDetails } = useQuery<SurveyorDetails>(
    ["surveyor_Details"],
    async () => {
      if (state?.id) {
        let response = await getDetailsOfSurveyor({ id: state.id });
        return response?.data?.data;
      }
    }
  );

  const { data: surveyorChoiceDetails } = useQuery(
    ["surveyor-Choice-Details"],
    async () => {
      if (state?.id) {
        let response = await getDetailsOfSurveyorChoices({
          id: state.id,
          page: 1,
          size: 8,
        });
        return response?.data?.data || [];
      }
    }
  );

  const [agentName, setAgentName] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [_stateId, _setStateId] = useState<number>(0);
  const [_cityId, _setCityId] = useState<number | null>(null);
  const [_districtId, _setDistrictId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedChoicesList, setSelectedChoicesList] = useState<
    Choices[] | []
  >([]);

  const { showSnackbar } = useFeedback();

  const { data: masterDataList } = useQuery(["dataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  const { data: district } = useQuery(
    ["districtMaster", _stateId],
    async () => {
      if (_stateId) {
        let response = await getDistrictMasterData(_stateId);
        return response?.data?.data?.list;
      }
    }
  );

  const { data: city } = useQuery(["cityList", _stateId], async () => {
    if (_stateId) {
      let response = await getCityMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });

  function addNewSurveyor() {
    addServeyors({
      cityId: _cityId,
      choices: selectedChoicesList?.map((item) => item.id) ?? [],
      districtId: _districtId,
      location: location,
      mobileNumber: mobileNumber,
      name: agentName,
      stateId: _stateId,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Surveyor added successfully");
          navigate("/surveyor");
        }
      })
      .catch((e) => {
        if (e.response?.data?.error?.code === 445) {
          showSnackbar("Surveyor already exists");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }
  function modifySurveyor() {
    updateDetailsOfSurveyor({
      cityId: _cityId,
      choices: selectedChoicesList?.map((item) => item.id) ?? [],
      districtId: _districtId,
      location: location,
      mobileNumber:
        surveyorDetails?.mobileNumber !== mobileNumber ? mobileNumber : null,
      name: agentName,
      stateId: _stateId,
      id: state?.id,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Surveyor details modified successfully");
          navigate("/surveyor");
        }
      })
      .catch((e) => {
        if (e.response?.data?.error?.code === 445) {
          showSnackbar("Surveyor already exists");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }

  const divider = () => {
    return (
      <Divider
        sx={{
          display: "flex",
          opacity: 0.5,
          borderBottom: "1px dotted #7C797C",
        }}
      />
    );
  };
  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
    label,
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
    label: string;
  }) {
    return (
      <Box pt={3}>
        <Box display="flex" flexDirection="row">
          <Autocomplete
            fullWidth
            // multiple
            value={
              options?.find((option) => selectedId === option?.id) || {
                id: 0,
                value: "",
              }
            }
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options}
            getOptionLabel={(option) => option?.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                placeholder={placeholder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (modifyFlow) {
      setAgentName(surveyorDetails?.name!);
      setLocation(surveyorDetails?.location!);
      setMobileNumber(surveyorDetails?.mobileNumber!);
      _setStateId(surveyorDetails?.stateId!);
      _setCityId(surveyorDetails?.cityId!);
      _setDistrictId(surveyorDetails?.districtId!);
      setSelectedChoicesList(
        surveyorChoiceDetails?.list?.map(
          (item: {
            choiceId: number;
            languages: string[];
            name: string;
            questions: number;
            validFrom: string;
            validTo: string;
          }) => {
            return {
              id: item?.choiceId,
              name: item.name,
              totalQuestions: item?.questions,
              valid_from: item.validFrom,
              valid_to: item?.validTo,
              languages: item?.languages,
            };
          }
        )
      );
    }
  }, [surveyorDetails, surveyorChoiceDetails, masterDataList]);

  console.log(_stateId, "stateId");

  return (
    <MainLayout>
      <Grid container mt={4}>
        <Grid item lg={8} md={12} sm={12} xs={12} xl={8}>
          <Card sx={{ px: 2, pb: 2 }}>
            <Box display={"flex"} alignItems={"center"} py={2}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={"600"} m={1}>
                {modifyFlow ? "Modify Surveyor details" : "Add New Surveyor"}
              </Typography>
            </Box>
            {divider()}
            <Box mt={3}>
              <TextField
                variant="outlined"
                fullWidth
                value={agentName}
                label="Agent name"
                onChange={(e) => setAgentName(e.target.value)}
                placeholder="Enter Agents Name"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                value={mobileNumber}
                label="Mobile number"
                sx={{ mt: 3 }}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  setMobileNumber(inputValue.slice(0, 10)); // Limit to a maximum of 10 characters
                }}
                placeholder="Enter Mobile Number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                variant="outlined"
                fullWidth
                value={location}
                label="Agent location"
                sx={{ mt: 3 }}
                onChange={(e) => setLocation(e.target.value)}
                rows={4}
                multiline={true}
                placeholder="Enter Agent Working Location"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {_dropdownOption({
                label: "State",
                title: "State",
                placeholder: "Search for State",
                options:
                  masterDataList?.state.filter(
                    (item: {
                      id: number;
                      isAggregate: boolean;
                      platform: number;
                      value: string;
                    }) => !item.isAggregate
                  ) || [],
                selectedId: _stateId,
                onSelect: (id) => {
                  _setStateId(id!);
                },
              })}
              {_dropdownOption({
                label: "City",
                title: "City",
                placeholder: "Search for city",
                options: city || [],
                selectedId: _cityId,
                onSelect: (id) => {
                  _setCityId(id);
                },
              })}
              {_dropdownOption({
                label: "District",
                title: "District",
                placeholder: "Search for district",
                options: district || [],
                selectedId: _districtId,
                onSelect: (id) => {
                  _setDistrictId(id);
                },
              })}
            </Box>
            {selectedChoicesList?.length !== 0 && (
              <Box
                display={"flex"}
                mt={4}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="body2" fontWeight={"600"}>
                  Choices Mapped
                </Typography>
                <Button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={"600"}
                    color="primary.main"
                  >
                    Map more choices
                  </Typography>
                </Button>
              </Box>
            )}

            {selectedChoicesList?.length !== 0 ? (
              selectedChoicesList?.map((choice: any) => {
                return (
                  <>
                    <ListItem
                      sx={{
                        margin: 0,
                        padding: 0,
                        mt: 3,
                      }}
                    >
                      <ListItemText
                        primary={choice?.name}
                        secondary={
                          <Box display="flex" flexDirection="row">
                            <Typography variant="body2">{`Questions:${choice.totalQuestions}`}</Typography>
                            <Typography variant="body2" ml={3}>{`Valid from: ${
                              modifyFlow
                                ? choice.valid_from
                                  ? dayjs(
                                      choice.valid_from,
                                      "YYYY-MM-DD"
                                    ).format("DD-MM-YYYY")
                                  : "-"
                                : choice.validFrom
                                ? dayjs(choice.validFrom, "YYYY-MM-DD").format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"
                            }`}</Typography>
                            <Typography variant="body2" ml={3}>{`Valid to: ${
                              modifyFlow
                                ? choice.valid_to
                                  ? dayjs(choice.valid_to, "YYYY-MM-DD").format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"
                                : choice.validTo
                                ? dayjs(choice.validTo, "YYYY-MM-DD").format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"
                            }`}</Typography>
                            <Typography
                              variant="body2"
                              ml={3}
                              textTransform={"capitalize"}
                            >{`Languages: ${choice?.languages?.map(
                              (item: string) =>
                                locales.filter(
                                  (locale) => locale.code === item
                                )?.[0]?.name
                            )}`}</Typography>
                          </Box>
                        }
                        primaryTypographyProps={{
                          color: "#1B001B",
                        }}
                        secondaryTypographyProps={{
                          color: "#91278F",
                        }}
                      />
                      <ListSubheader sx={{ margin: 0, padding: 0 }}>
                        <IconButton
                          style={{ marginLeft: 16 }}
                          onClick={() => {
                            let filteredList = selectedChoicesList.filter(
                              (item) => item?.id !== choice.id
                            );
                            setSelectedChoicesList(filteredList);
                          }}
                          sx={{
                            backgroundColor: "#E9F0ED",
                            borderRadius: 4,
                            color: "#1B001B",
                          }}
                        >
                          <DeleteOutline></DeleteOutline>
                        </IconButton>
                      </ListSubheader>
                    </ListItem>
                  </>
                );
              })
            ) : (
              <Box
                display="flex"
                flexDirection={"column"}
                mt={3}
                borderRadius={5}
                sx={{ minHeight: 200, border: "1px dashed #91278F" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <IconButton onClick={() => setOpenModal(true)}>
                  <Add sx={{ color: "#91278F" }} />
                </IconButton>
                <Button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  sx={{ color: "#1B001B", fontSize: 18 }}
                >
                  Click here to map choices
                </Button>
              </Box>
            )}

            <Box display="flex" mt={6} justifyContent={"space-between"}>
              <Box>
                <Button onClick={() => navigate(-1)} variant="outlined">
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={modifyFlow ? modifySurveyor : addNewSurveyor}
                  variant="contained"
                  disabled={
                    agentName &&
                    mobileNumber?.length === 10 &&
                    location &&
                    _stateId &&
                    _cityId &&
                    _districtId &&
                    selectedChoicesList?.length !== 0
                      ? false
                      : true
                  }
                >
                  {modifyFlow ? "save changes" : "+ Add Surveyor"}
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <MapChoicesPopup
        openModel={openModal}
        setOpenModal={setOpenModal}
        setSelectedChoicesList={(item) => {
          setSelectedChoicesList(item!);
          setOpenModal(false);
        }}
        selectedChoicesList={selectedChoicesList}
      />
    </MainLayout>
  );
}
