import { useTheme } from "@mui/material";
import React from "react";
import "./loading.css";

export default function Loading() {
  return (
    <div className="spinner">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
}
