import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import React from "react";
import useUser from "../../../providers/user/user_provider";
import { useNavigate } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants";
import { adminLogout } from "../../../services/logout/logout";

export default function Profile() {
  const { userPermissions } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar sx={{ bgcolor: "primary.main" }}>
          {userPermissions?.name?.[0]}
        </Avatar>
        <ArrowDropDownIcon sx={{ color: "text.primary" }} />
      </Button>
      <Menu
        elevation={3}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: { p: 0, m: 0 },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List dense sx={{ width: 300, p: 0, m: 0 }}>
          <ListItem button>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "primary.main" }}>
                {userPermissions?.name?.[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ color: "primary.main" }}
              primary={userPermissions?.name}
              secondary={userPermissions?.emailId}
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar></ListItemAvatar>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                adminLogout()
                  .then((res) => {
                    if (res.status === 200) {
                      window.location.href = res.data.data.redirectUrl;
                    }
                  })
                  .catch((e) => {});
                localStorage.setItem(ACCESS_TOKEN, "");
                localStorage.setItem(REFRESH_TOKEN, "");
                sessionStorage.setItem(ACCESS_TOKEN, "");
                sessionStorage.setItem(REFRESH_TOKEN, "");
              }}
            >
              Logout
            </Button>
          </ListItem>
          {/* userPermissions?.features.map(feature => <ListItem>
            <ListItemText primaryTypographyProps={{color: "primary.main"}} primary={feature.name} secondary={feature.permissions.map(permission => permission.name).join(" | ")}></ListItemText>
          </ListItem>)
          */}
        </List>
        {/* <MenuItem onClick={handleClose}>{userPermissions?.name}</MenuItem> */}
      </Menu>
    </Box>
  );
}
