import {
    Box,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    TablePagination,
    Typography,
} from "@mui/material";
import React from "react";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { SurveyorResponseLabel } from "../surveyor_response_list/surveyor_response_list";
import { SurveyorResponses } from "../../model/surveryor_responses";
import { DownloadOutlined } from "@mui/icons-material";

interface DataGridInterface {
    labels: SurveyorResponseLabel[];
    data: {
        adminEmailId: string,
        adminId: number,
        adminName: string,
        choiceName: string,
        id: number,
        processedAt: string,
        status: string,
        recordCount: number;
        remark: {
            details: [],
            message: string,
            query: string,
        }
    }[];
    count: number;
    page: number;
    setPage: (val: number) => void;
    downloadResponse: (taskId: number) => void
}

export default function DataGridDownloads({
    labels,
    data,
    page,
    setPage,
    count,
    downloadResponse
}: DataGridInterface) {

    const startRange = page * 8 + 1;
    const endRange = Math.min((page + 1) * 8, count);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

    function statusColorCodeBasedOnStatus({ status }: { status: string }) {
        if (status === "Pending"
        ) {
            return "#91278F";
        } else if (status === "Completed") {
            return "#1E6446";
        } else {
            return "#D14C2E";
        }
    }


    return (
        <Box
            display="flex"
            flex={1}
            flexDirection={"column"}
            height={"100%"}
            width={"100%"}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}
            >
                <Grid container direction="row" p={2}>
                    {labels.map((item) => (
                        <Grid item flex={1}>
                            <Typography color="#91278F" fontSize={14}>
                                {item.label}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                {data.length === 0 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        flex={1}
                    >
                        <Typography>No Data Available</Typography>
                    </Box>
                ) : (
                    <Grid container direction="row" mt={3}>
                        {data.map((item, index) => {
                            const originalDate = dayjs(item?.processedAt);
                            const formattedDate = originalDate?.format?.("DD MMM YYYY");
                            return (
                                <Box

                                    display={"flex"}
                                    flexDirection={"column"}
                                    width="100%"

                                >
                                    <Divider
                                        sx={{
                                            display: "flex",
                                            opacity: 0.5,
                                            borderBottom: "1px dotted #7C797C",
                                            mx: 2,
                                        }}
                                    />
                                    <Grid
                                        display="flex"
                                        item
                                        flex={1}
                                        flexDirection={"row"}
                                        my={3}
                                        px={2}
                                    >
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {index + 1}
                                        </Typography>
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {item?.choiceName}
                                        </Typography>
                                        <Typography fontWeight={600} display="flex" flex={1} fontSize={14} color={statusColorCodeBasedOnStatus({
                                            status: item?.status,
                                        })}>
                                            {item?.status}
                                        </Typography>
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {item?.remark?.message ? item?.remark?.message : '-'}
                                        </Typography>
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {item?.adminName}
                                        </Typography>
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {item?.processedAt}
                                        </Typography>
                                        <Typography display="flex" flex={1} fontSize={14}>
                                            {item?.recordCount}
                                        </Typography>
                                        <Box display="flex" flex={1} width={'fit-content'} height={'fit-content'}>
                                            <IconButton disabled={item?.status !== 'Completed'} onClick={() => {
                                                downloadResponse(item?.id)
                                            }}>
                                                <DownloadOutlined />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Box>
                            );
                        })}
                    </Grid>
                )}
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        mx: 2,
                    }}
                >
                    <Typography variant="body2">{customText}</Typography>
                    <TablePagination
                        component="div"
                        labelRowsPerPage={customText}
                        rowsPerPageOptions={[]}
                        count={count}
                        rowsPerPage={8}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </Box>
            </CardActions>
        </Box>
    );
}
