import { Close } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Dialog, IconButton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react'

export default function FilterUploadFile({
    openModal,
    setOpenModal,
    resetFilter,
    endDate,
    setEndDate,
    setStartDate,
    setStatus
}: {
    openModal: boolean;
    setOpenModal: (val: boolean) => void;
    resetFilter: () => void;
    endDate: string;
    setEndDate: (value: string) => void;
    setStartDate: (val: string) => void;
    setStatus: (val: number | null) => void;
}) {
    const [_endDate, _setEndDate] = useState<string>(endDate);
    const [_startDate, _setStartDate] = useState<string>(endDate);
    const [_status, _setStatus] = useState<number | null>(null);

    let statusList: { id: number, value: string }[] = [
        { id: 1, value: 'In Progress' },
        { id: 2, value: 'Completed' },
        { id: 3, value: 'failed' }
    ];
    function _dateRange({
        title,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    }: {
        title: string;
        startDate: string;
        setStartDate: (value: string) => void;
        endDate: string;
        setEndDate: (value: string) => void;
    }) {
        return (
            <Box pt={2} pb={1}>
                <Typography fontSize={16} fontWeight="600">
                    {title}
                </Typography>
                <Box pt={2} pb={2} display="flex" flexDirection={"column"}>
                    <DatePicker
                        label="From Date"
                        format="DD-MM-YYYY"
                        disableFuture
                        value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setStartDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                    <Box p={1}></Box>
                    <DatePicker
                        label="To Date"
                        format="DD-MM-YYYY"
                        disableFuture
                        value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setEndDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                </Box>
            </Box>
        );
    }
    return (
        <Dialog open={openModal}>
            <Box sx={{ minWidth: 400 }}>
                <Card sx={{ px: 2 }}>
                    <CardHeader
                        title="Filter Files"
                        titleTypographyProps={{ variant: "body1", fontWeight: 600 }}
                        action={
                            <IconButton onClick={() => setOpenModal(false)}>
                                <Close sx={{ color: "#323232" }} />
                            </IconButton>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardHeader
                        title="Filter By"
                        titleTypographyProps={{ variant: "body2", fontWeight: 600 }}
                        action={
                            <Button
                                variant="text"
                                sx={{ p: 0 }}
                                onClick={() => {
                                    resetFilter();
                                    setOpenModal(false);
                                }}
                            >
                                Reset
                            </Button>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardContent sx={{ maxHeight: 400, overflow: "auto scroll" }}>
                        {_dateRange({
                            title: "Date Range",
                            setEndDate: _setEndDate,
                            setStartDate: _setStartDate,
                            startDate: _startDate,
                            endDate: _endDate,
                        })}
                        <Typography fontSize={16} fontWeight="600" mt={1}>
                            {"Status"}
                        </Typography>
                        <Box display="flex" flexWrap={"wrap"}>
                            {statusList?.map((item) => (
                                <Button
                                    onClick={() => {
                                        _setStatus(item?.id);
                                    }}
                                    variant={
                                        _status === item?.id ? "contained" : "outlined"
                                    }
                                    sx={{ mr: 1, mb: 1 }}
                                >
                                    {item?.value}
                                </Button>
                            ))}
                        </Box>
                        <Box mt={5} display="flex" justifyContent="space-between">
                            <Box onClick={() => setOpenModal(false)}>
                                <Typography
                                    variant="button"
                                    color="#91278F"
                                    sx={{
                                        borderBottom: "1px solid #990099",
                                        cursor: "pointer",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Box>
                            <Button
                                sx={{ minWidth: 200 }}
                                variant="contained"
                                onClick={() => {
                                    setStatus(_status);
                                    setStartDate(_startDate);
                                    setEndDate(_endDate)
                                    setStatus(_status)
                                    setOpenModal(false);
                                }}
                            >
                                Apply Filters
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    )
}
