import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "پچھلا",
   "pageNextText": "اگلے",
   "completeText": "مکمل",
   "previewText": "پیش نظارہ",
   "editText": "ترمیم",
   "startSurveyText": "شروع کریں۔",
   "otherItemText": "دیگر (بیان کریں)",
   "noneItemText": "کوئی نہیں۔",
   "selectAllItemText": "تمام منتخب کریں",
   "progressText": "صفحہ {0} از {1}",
   "indexText": "{0} از {1}",
   "panelDynamicProgressText": "{1} میں سے {0}",
   "panelDynamicTabTextFormat": "پینل {panelIndex}",
   "questionsProgressText": "{0}/{1} سوالات کے جوابات دیے گئے۔",
   "emptySurvey": "سروے میں کوئی دکھائی دینے والے عناصر شامل نہیں ہیں۔",
   "completingSurvey": "سروے مکمل کرنے کا شکریہ",
   "completingSurveyBefore": "ہمارے ریکارڈ سے پتہ چلتا ہے کہ آپ نے یہ سروے پہلے ہی مکمل کر لیا ہے۔",
   "loadingSurvey": "سروے لوڈ ہو رہا ہے...",
   "placeholder": "منتخب کریں...",
   "ratingOptionsCaption": "منتخب کریں...",
   "value": "قدر",
   "requiredError": "جواب درکار ہے۔",
   "requiredErrorInPanel": "جواب درکار ہے: کم از کم ایک سوال کا جواب دیں۔",
   "requiredInAllRowsError": "جواب درکار ہے: تمام قطاروں میں سوالات کے جواب دیں۔",
   "numericError": "قدر عددی ہونی چاہیے۔",
   "minError": "قدر {0} سے کم نہیں ہونی چاہیے",
   "maxError": "قدر {0} سے زیادہ نہیں ہونی چاہیے",
   "textMinLength": "براہ کرم کم از کم {0} حروف درج کریں۔",
   "textMaxLength": "براہ کرم {0} حروف سے زیادہ درج نہ کریں۔",
   "textMinMaxLength": "براہ کرم کم از کم {0} درج کریں اور {1} سے زیادہ حروف نہ ہوں۔",
   "minRowCountError": "براہ کرم کم از کم {0} قطاریں پُر کریں۔",
   "minSelectError": "براہ کرم کم از کم {0} آپشنز منتخب کریں۔",
   "maxSelectError": "براہ کرم {0} سے زیادہ اختیارات (اختیارات) کو منتخب نہ کریں۔",
   "numericMinMax": "&#39;{0}&#39; کم از کم {1} اور زیادہ سے زیادہ {2} ہونا چاہیے",
   "numericMin": "&#39;{0}&#39; کم از کم {1} ہونا چاہیے",
   "numericMax": "&#39;{0}&#39; زیادہ سے زیادہ {1} ہونا چاہیے",
   "invalidEmail": "برائے مہربانی قابل قبول ای میل ایڈریس لکھیں.",
   "invalidExpression": "اظہار: {0} کو &#39;سچ&#39; لوٹنا چاہیے۔",
   "urlRequestError": "درخواست میں غلطی &#39;{0}&#39; واپس آ گئی۔ {1}",
   "urlGetChoicesError": "درخواست نے خالی ڈیٹا واپس کیا یا &#39;پاتھ&#39; پراپرٹی غلط ہے۔",
   "exceedMaxSize": "فائل کا سائز {0} سے زیادہ نہیں ہونا چاہیے۔",
   "noUploadFilesHandler": "فائلیں اپ لوڈ نہیں کی جا سکتیں۔ براہ کرم &#39;onUploadFiles&#39; ایونٹ کے لیے ایک ہینڈلر شامل کریں۔",
   "otherRequiredError": "جواب درکار ہے: ایک اور قدر درج کریں۔",
   "uploadingFile": "آپ کی فائل اپ لوڈ ہو رہی ہے۔ براہ کرم چند سیکنڈ انتظار کریں اور دوبارہ کوشش کریں۔",
   "loadingFile": "لوڈ ہو رہا ہے...",
   "chooseFile": "فائل (فائلیں) کا انتخاب کریں...",
   "noFileChosen": "کوئی فائل منتخب نہیں کی گئی",
   "filePlaceholder": "فائل کو یہاں گھسیٹ کر چھوڑیں یا اپ لوڈ کرنے کے لیے فائل منتخب کرنے کے لیے نیچے والے بٹن پر کلک کریں۔",
   "confirmDelete": "کیا آپ ریکارڈ کو حذف کرنا چاہتے ہیں؟",
   "keyDuplicationError": "یہ قدر منفرد ہونی چاہیے۔",
   "addColumn": "کالم شامل کریں۔",
   "addRow": "قطار شامل کریں۔",
   "removeRow": "دور",
   "emptyRowsText": "کوئی قطاریں نہیں ہیں۔",
   "addPanel": "نیا شامل کریں",
   "removePanel": "دور",
   "showDetails": "تفصیلات دکھائیں",
   "hideDetails": "تفصیلات چھپائیں۔",
   "choices_Item": "آئٹم",
   "matrix_column": "کالم",
   "matrix_row": "قطار",
   "multipletext_itemname": "متن",
   "savingData": "نتائج سرور پر محفوظ کیے جا رہے ہیں...",
   "savingDataError": "ایک خرابی پیش آگئی اور ہم نتائج محفوظ نہیں کر سکے۔",
   "savingDataSuccess": "نتائج کامیابی سے محفوظ ہو گئے!",
   "saveAgainButton": "دوبارہ کوشش کریں",
   "timerMin": "منٹ",
   "timerSec": "سیکنڈ",
   "timerSpentAll": "آپ نے اس صفحہ پر {0} اور کل {1} خرچ کیے ہیں۔",
   "timerSpentPage": "آپ نے اس صفحہ پر {0} خرچ کیا ہے۔",
   "timerSpentSurvey": "آپ نے کل {0} خرچ کیے ہیں۔",
   "timerLimitAll": "آپ نے اس صفحہ پر {1} میں سے {0} اور کل {3} میں سے {2} خرچ کیے ہیں۔",
   "timerLimitPage": "آپ اس صفحہ پر {1} میں سے {0} خرچ کر چکے ہیں۔",
   "timerLimitSurvey": "آپ نے کل {1} میں سے {0} خرچ کیے ہیں۔",
   "clearCaption": "صاف",
   "signaturePlaceHolder": "یہاں دستخط کریں۔",
   "chooseFileCaption": "فائل کو منتخب کریں۔",
   "takePhotoCaption": "تصویر لو",
   "photoPlaceholder": "کیمرہ استعمال کرتے ہوئے تصویر لینے کے لیے نیچے والے بٹن پر کلک کریں۔",
   "fileOrPhotoPlaceholder": "کیمرہ استعمال کرکے تصویر اپ لوڈ کرنے یا لینے کے لیے فائل کو گھسیٹیں اور چھوڑیں یا منتخب کریں۔",
   "replaceFileCaption": "فائل کو تبدیل کریں۔",
   "removeFileCaption": "اس فائل کو ہٹا دیں۔",
   "booleanCheckedLabel": "جی ہاں",
   "booleanUncheckedLabel": "نہیں",
   "confirmRemoveFile": "کیا آپ واقعی اس فائل کو ہٹانا چاہتے ہیں: {0}؟",
   "confirmRemoveAllFiles": "کیا آپ واقعی تمام فائلوں کو ہٹانا چاہتے ہیں؟",
   "questionTitlePatternText": "سوال کا عنوان",
   "modalCancelButtonText": "منسوخ کریں۔",
   "modalApplyButtonText": "درخواست دیں",
   "filterStringPlaceholder": "تلاش کرنے کے لیے ٹائپ کریں...",
   "emptyMessage": "ڈسپلے کرنے کے لیے کوئی ڈیٹا نہیں ہے۔",
   "noEntriesText": "ابھی تک کوئی اندراج نہیں ہے۔ نیا اندراج شامل کرنے کے لیے نیچے دیئے گئے بٹن پر کلک کریں۔",
   "noEntriesReadonlyText": "کوئی اندراجات نہیں ہیں۔",
   "more": "مزید",
   "tagboxDoneButtonCaption": "ٹھیک ہے",
   "selectToRankEmptyRankedAreaText": "تمام انتخاب کی درجہ بندی کی گئی ہے۔",
   "selectToRankEmptyUnrankedAreaText": "ان کی درجہ بندی کرنے کے لیے انتخاب کو یہاں گھسیٹیں اور چھوڑیں۔",
   "ok": "ٹھیک ہے",
   "cancel": "منسوخ کریں۔"
};


surveyLocalization.locales["ur"] = dict;