import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "मागील",
   "pageNextText": "पुढे",
   "completeText": "पूर्ण",
   "previewText": "पूर्वावलोकन",
   "editText": "सुधारणे",
   "startSurveyText": "सुरू करा",
   "otherItemText": "इतर (वर्णन करा)",
   "noneItemText": "काहीही नाही",
   "selectAllItemText": "सर्व निवडा",
   "progressText": "{1} पैकी {0} पृष्ठ",
   "indexText": "{1} पैकी {0}",
   "panelDynamicProgressText": "{1} पैकी {0}",
   "panelDynamicTabTextFormat": "पॅनेल {panelIndex}",
   "questionsProgressText": "{0}/{1} प्रश्नांची उत्तरे दिली",
   "emptySurvey": "सर्वेक्षणात कोणतेही दृश्यमान घटक नाहीत.",
   "completingSurvey": "सर्वेक्षण पूर्ण केल्याबद्दल धन्यवाद",
   "completingSurveyBefore": "आमचे रेकॉर्ड दाखवतात की तुम्ही हे सर्वेक्षण आधीच पूर्ण केले आहे.",
   "loadingSurvey": "सर्वेक्षण लोड करत आहे...",
   "placeholder": "निवडा...",
   "ratingOptionsCaption": "निवडा...",
   "value": "मूल्य",
   "requiredError": "प्रतिसाद आवश्यक.",
   "requiredErrorInPanel": "प्रतिसाद आवश्यक आहे: किमान एका प्रश्नाचे उत्तर द्या.",
   "requiredInAllRowsError": "प्रतिसाद आवश्यक: सर्व पंक्तींमध्ये प्रश्नांची उत्तरे द्या.",
   "numericError": "मूल्य संख्यात्मक असावे.",
   "minError": "मूल्य {0} पेक्षा कमी नसावे",
   "maxError": "मूल्य {0} पेक्षा जास्त नसावे",
   "textMinLength": "कृपया किमान {0} वर्ण प्रविष्ट करा.",
   "textMaxLength": "कृपया {0} पेक्षा जास्त वर्ण प्रविष्ट करू नका.",
   "textMinMaxLength": "कृपया किमान {0} आणि {1} पेक्षा जास्त वर्ण प्रविष्ट करू नका.",
   "minRowCountError": "कृपया किमान {0} पंक्ती भरा.",
   "minSelectError": "कृपया किमान {0} पर्याय निवडा.",
   "maxSelectError": "कृपया {0} पेक्षा जास्त पर्याय निवडू नका.",
   "numericMinMax": "&#39;{0}&#39; किमान {1} आणि जास्तीत जास्त {2} असावे",
   "numericMin": "&#39;{0}&#39; किमान {1} असावा",
   "numericMax": "&#39;{0}&#39; जास्तीत जास्त {1} असावा",
   "invalidEmail": "कृपया वैध ई-मेल पत्ता प्रविष्ट करा.",
   "invalidExpression": "अभिव्यक्ती: {0} ने &#39;सत्य&#39; परतावे.",
   "urlRequestError": "विनंतीने त्रुटी &#39;{0}&#39; परत केली. {1}",
   "urlGetChoicesError": "विनंतीने रिक्त डेटा परत केला आहे किंवा &#39;पथ&#39; गुणधर्म चुकीचा आहे",
   "exceedMaxSize": "फाइलचा आकार {0} पेक्षा जास्त नसावा.",
   "noUploadFilesHandler": "फाइल्स अपलोड करता येत नाहीत. कृपया &#39;onUploadFiles&#39; इव्हेंटसाठी हँडलर जोडा.",
   "otherRequiredError": "प्रतिसाद आवश्यक आहे: दुसरे मूल्य प्रविष्ट करा.",
   "uploadingFile": "तुमची फाइल अपलोड होत आहे. कृपया काही सेकंद प्रतीक्षा करा आणि पुन्हा प्रयत्न करा.",
   "loadingFile": "लोड करत आहे...",
   "chooseFile": "फाइल निवडा...",
   "noFileChosen": "कोणतीही फाईल निवडलेली नाही",
   "filePlaceholder": "फाइल येथे ड्रॅग आणि ड्रॉप करा किंवा अपलोड करण्यासाठी फाइल निवडण्यासाठी खालील बटणावर क्लिक करा.",
   "confirmDelete": "तुम्हाला रेकॉर्ड हटवायचा आहे का?",
   "keyDuplicationError": "हे मूल्य अद्वितीय असावे.",
   "addColumn": "स्तंभ जोडा",
   "addRow": "पंक्ती जोडा",
   "removeRow": "काढा",
   "emptyRowsText": "पंक्ती नाहीत.",
   "addPanel": "नवीन जोडा",
   "removePanel": "काढा",
   "showDetails": "तपशील दाखवा",
   "hideDetails": "माहिती लपवा",
   "choices_Item": "आयटम",
   "matrix_column": "स्तंभ",
   "matrix_row": "पंक्ती",
   "multipletext_itemname": "मजकूर",
   "savingData": "निकाल सर्व्हरवर सेव्ह केले जात आहेत...",
   "savingDataError": "एक त्रुटी आली आणि आम्ही परिणाम जतन करू शकलो नाही.",
   "savingDataSuccess": "परिणाम यशस्वीरित्या जतन केले गेले!",
   "saveAgainButton": "पुन्हा प्रयत्न करा",
   "timerMin": "मि",
   "timerSec": "सेकंद",
   "timerSpentAll": "तुम्ही या पृष्ठावर {0} आणि एकूण {1} खर्च केले आहेत.",
   "timerSpentPage": "तुम्ही या पृष्ठावर {0} खर्च केले आहेत.",
   "timerSpentSurvey": "तुम्ही एकूण {0} खर्च केले आहेत.",
   "timerLimitAll": "तुम्ही या पृष्ठावर {1} पैकी {0} आणि एकूण {3} पैकी {2} खर्च केले आहेत.",
   "timerLimitPage": "तुम्ही या पृष्ठावर {1} पैकी {0} खर्च केले आहेत.",
   "timerLimitSurvey": "तुम्ही एकूण {1} पैकी {0} खर्च केले आहेत.",
   "clearCaption": "साफ",
   "signaturePlaceHolder": "इथे सही करा",
   "chooseFileCaption": "फाइल निवडा",
   "takePhotoCaption": "छायाचित्र घे",
   "photoPlaceholder": "कॅमेरा वापरून फोटो घेण्यासाठी खालील बटणावर क्लिक करा.",
   "fileOrPhotoPlaceholder": "कॅमेरा वापरून फोटो अपलोड करण्यासाठी किंवा घेण्यासाठी फाइल ड्रॅग आणि ड्रॉप करा किंवा निवडा.",
   "replaceFileCaption": "फाइल बदला",
   "removeFileCaption": "ही फाईल काढा",
   "booleanCheckedLabel": "होय",
   "booleanUncheckedLabel": "नाही",
   "confirmRemoveFile": "तुमची खात्री आहे की तुम्ही ही फाइल काढू इच्छिता: {0}?",
   "confirmRemoveAllFiles": "तुम्हाला खात्री आहे की तुम्ही सर्व फायली काढू इच्छिता?",
   "questionTitlePatternText": "प्रश्नाचे शीर्षक",
   "modalCancelButtonText": "रद्द करा",
   "modalApplyButtonText": "अर्ज करा",
   "filterStringPlaceholder": "शोधण्यासाठी टाइप करा...",
   "emptyMessage": "प्रदर्शित करण्यासाठी कोणताही डेटा नाही",
   "noEntriesText": "अद्याप कोणत्याही एंट्री नाहीत. नवीन एंट्री जोडण्यासाठी खालील बटणावर क्लिक करा.",
   "noEntriesReadonlyText": "कोणत्याही नोंदी नाहीत.",
   "more": "अधिक",
   "tagboxDoneButtonCaption": "ठीक आहे",
   "selectToRankEmptyRankedAreaText": "सर्व निवडी क्रमवारीत आहेत",
   "selectToRankEmptyUnrankedAreaText": "रँक करण्यासाठी निवडी येथे ड्रॅग आणि ड्रॉप करा",
   "ok": "ठीक आहे",
   "cancel": "रद्द करा"
};


surveyLocalization.locales["mr"] = dict;