import { useEffect } from "react";
import MainLayout from "../../layouts/main/main_layout";
import ComingSoon from "../coming_soon/coming_soon";

export default function Dashboard() {
  return (
    <MainLayout>
      {/* <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/6b82c889-fb69-476b-99ec-0bd5f9c329e9/page/p_11z7gmf68c" style={{border: "none"}}></iframe> */}
      {/* <Grid container spacing={3}>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
        <Grid item md={1}>
          <Box sx={{width: '100%',height: '100vh',bgcolor: 'primary.main'}}></Box>
        </Grid>
      </Grid> */}
      <ComingSoon />
    </MainLayout>
  );
}
