import { createContext } from "react";
import { AdminListModel } from "./admin_list";
import { RoleList } from "./admin_roles";

export interface AdminContextProvider {
  userRoleBreadcrumb: string;
  setUserRoleBreadcrumb: (newValue: string) => void;
  viewAdmin: boolean;
  setViewAdmin: (newValue: boolean) => void;
  deleteAdmin: boolean;
  setDeleteAdmin: (newValue: boolean) => void;
  selectedAdminRoles: any;
  setSelectedAdminRoles: (newValue: any) => void;
  adminList: AdminListModel[];
  setAdminList: (newValue: AdminListModel[]) => void;
  selectedAdminDetails: AdminListModel;
  setSelectedAdminDetails: (newValue: AdminListModel) => void;
  deleteAdminApiCall: () => void;
  adminName: string;
  setAdminName: (newValue: string) => void;
  adminEmailId: string;
  setAdminEmailId: (newValue: string) => void;
  adminRole: number;
  setAdminRole: (newValue: number) => void;
  isLoading: boolean;
  searchList: AdminListModel[];
  setSearchList: (newVal: AdminListModel[]) => void;
  searchValue: string;
  setSearchValue: (newVal: string) => void;
  roleList: RoleList[];
}
export const AdminContext = createContext<AdminContextProvider | undefined>(
  undefined
);
