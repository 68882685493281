import { Box, Chip, Typography } from "@mui/material";
import React from "react";

interface Item {
  id: number;
  value: string;
  isAggregate: boolean;
}

export default function ChipsWithAggregationForGender({
  items,
  value,
  onChange,
  error,
  helperText,
  disabled,
}: {
  items: Item[];
  value: string[];
  onChange: (value: string[]) => void;
  error: boolean;
  helperText: string | undefined;
  disabled?: boolean;
}) {
  return (
    <Box>
      <Box mt={1} display="flex" flexWrap="wrap">
        {items.map((item) => (
          <Box pr={1} pb={1}>
            <Chip
              disabled={disabled}
              label={item?.value}
              variant={
                value?.includes(items.filter((i) => i.isAggregate)?.[0]?.value)
                  ? "filled"
                  : value?.includes(item?.value)
                  ? "filled"
                  : "outlined"
              }
              color="primary"
              clickable
              onClick={() => {
                if (item?.isAggregate) {
                  let allItemValue = items.filter((i) => i.isAggregate);
                  onChange([allItemValue?.[0]?.value]);
                  if (
                    value?.includes(
                      items.filter((i) => i.isAggregate)?.[0]?.value
                    )
                  ) {
                    onChange([]);
                  }
                } else {
                  if (
                    value?.includes(
                      items.filter((i) => i.isAggregate)?.[0]?.value
                    )
                  ) {
                    onChange([item?.value]);
                  } else {
                    if (value?.includes(item?.value)) {
                      let updatedValues = value?.filter(
                        (i) => i !== item?.value
                      );
                      onChange(updatedValues);
                    } else {
                      value?.push(item?.value);
                      onChange([...value]);
                    }
                  }
                }
                if (items?.length - 1 === value?.length) {
                  let allItemValue = items.filter((i) => i.isAggregate);
                  onChange([allItemValue?.[0]?.value]);
                }
              }}
            ></Chip>
          </Box>
        ))}
      </Box>
      {error && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
