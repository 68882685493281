import { AttachFileRounded, Close, FileUploadOutlined, LinkOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, ChangeEventHandler, useState } from 'react'
import { useFeedback } from '../../../../providers/feedback/feeedback';

interface Props {
    openPopup: boolean;
    setOpenPopup: (val: boolean) => void;
    uploadFile: (val: File) => void;
    currentTab: number
}

export default function UploadCsvFile({ openPopup, setOpenPopup, uploadFile, currentTab }: Props) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { showSnackbar } = useFeedback()
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const maxFileSize = 10 * 1024 * 1024;
        if (file != null) {
            if (file.size > maxFileSize) {
                showSnackbar("File size exceeds 10 MB");
            } else {
                setSelectedFile(file)
            }

        }
    };
    return (
        <Dialog open={openPopup} sx={{ borderRadius: 16 }}>
            <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h5" fontWeight={600} textAlign={"center"} mt={3}>
                        Upload .xlsx file
                    </Typography>
                    <IconButton onClick={() => setOpenPopup(false)}>
                        <Close />
                    </IconButton>
                </Box>
                <Box mt={3}>
                    <Box
                        height={120}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            border: '1px solid #79747E',
                            borderWidth: 1,
                            borderColor: '#79747E',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 2
                        }}>

                        {!selectedFile ? <>
                            <label htmlFor="fileUpload" className="custom-file-upload">
                                <IconButton component="span">
                                    <AttachFileRounded sx={{ color: '#91278F' }} />
                                </IconButton>
                            </label>
                            <input
                                className="custom-file-upload"
                                id="fileUpload"
                                onChange={handleFileChange}
                                type="file"
                                style={{ display: 'none' }}
                                accept=".xlsx"
                            />
                            <label htmlFor="fileUpload" className="custom-file-upload">
                                <Button component="span">Add from files</Button>
                            </label>
                        </> : <Box display={'flex'} flexDirection={'row'}>
                            <Typography mt={1}>{selectedFile?.name}</Typography>
                            <IconButton onClick={() => setSelectedFile(null)}>
                                <Close />
                            </IconButton>
                        </Box>}


                    </Box>
                </Box>
                <Box mt={2}>
                    <Typography>
                        Note:
                        File format to be in .xlsx and the total size of the uploaded document should not exceed 10MB
                    </Typography>
                </Box>
                {/* {currentTab === 0 && <Box mt={2} >
                    <TextField fullWidth multiline rows={7} label='Reason for edit' />
                </Box>} */}
                <Box display={'flex'} mt={4} justifyContent={'center'}>
                    <Button disabled={!selectedFile} onClick={() => {
                        uploadFile(selectedFile!)
                    }}
                        variant='contained'
                        size='large'
                    >
                        Upload file
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
