import {
  AnalyticsOutlined,
  BuildOutlined,
  EmojiEventsOutlined,
  GroupOutlined,
  GroupsOutlined,
  Logout,
  Person,
  ReportProblemOutlined,
  SpeakerNotesOutlined,
  TocOutlined,
  UploadOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { ReactNode } from "react";
import { purpleLightActive } from "../../../theme/colors";
import { Link, useLocation } from "react-router-dom";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants";
import { adminLogout } from "../../../services/logout/logout";
import { file_uploads } from "../../../routes/routes_path";

const options: Array<{
  label: String;
  activeIcon: ReactNode;
  inActiveicon: ReactNode;
  route: string;
}> = [
    {
      label: "Reports",
      activeIcon: <AnalyticsOutlined />,
      inActiveicon: <AnalyticsOutlined sx={{ color: "#1B001B" }} />,
      route: "/reports",
    },
    {
      label: "Users",
      activeIcon: <GroupsOutlined />,
      inActiveicon: <GroupsOutlined sx={{ color: "#1B001B" }} />,
      route: "/users",
    },
    {
      label: "Schemes",

      activeIcon: <TocOutlined />,
      inActiveicon: <TocOutlined sx={{ color: "#1B001B" }} />,
      route: "/schemes",
    },
    {
      label: "Bulk Processing",
      activeIcon: <UploadOutlined />,
      inActiveicon: <UploadOutlined sx={{ color: "#1B001B" }} />,
      route: file_uploads,
    },
    {
      label: "Needs",
      activeIcon: <ReportProblemOutlined />,
      inActiveicon: <ReportProblemOutlined sx={{ color: "#1B001B" }} />,
      route: "/needs",
    },
    {
      label: "Choices",
      activeIcon: <SpeakerNotesOutlined />,
      inActiveicon: <SpeakerNotesOutlined sx={{ color: "#1B001B" }} />,
      route: "/choices",
    },
    {
      label: "Surveyors (choices)",
      activeIcon: <Person />,
      inActiveicon: <Person sx={{ color: "#1B001B" }} />,
      route: "/surveyor",
    },

    {
      label: "Jobs",
      activeIcon: <WorkOutlineOutlined />,
      inActiveicon: <WorkOutlineOutlined sx={{ color: "#1B001B" }} />,
      route: "/jobs",
    },
    {
      label: "Services",
      activeIcon: <BuildOutlined />,
      inActiveicon: <BuildOutlined sx={{ color: "#1B001B" }} />,
      route: "/services",
    },
    {
      label: "Admins",
      activeIcon: <GroupOutlined />,
      inActiveicon: <GroupOutlined sx={{ color: "#1B001B" }} />,
      route: "/admins",
    },

    {
      label: "Reward Points",
      activeIcon: <EmojiEventsOutlined />,
      inActiveicon: <EmojiEventsOutlined sx={{ color: "#1B001B" }} />,
      route: "/rewards",
    },

    // {
    //   label: "Setting",
    //   activeIcon: <SettingsOutlined />,
    //   inActiveicon: <SettingsOutlined sx={{ color: "#1B001B" }} />,
    //   route: "/settings",
    // },
    {
      label: "Logout",
      activeIcon: <Logout sx={{ transform: "rotate(180deg)" }} />,
      inActiveicon: <Logout sx={{ color: "#1B001B" }} />,
      route: "/logout",
    },
  ];

export default function Drawer() {
  const { pathname } = useLocation();
  return (
    <List
      sx={{
        p: 1,
        width: "100%",
      }}
    >
      {options.map((option, index) => (
        <Link
          to={option?.route === "/logout" ? "" : option.route}
          onClick={() => {
            if (option?.route === "/logout") {
              adminLogout()
                .then((res) => {
                  if (res.status === 200) {
                    window.location.href = res.data.data.redirectUrl;
                  }
                })
                .catch((e) => { });
              localStorage.setItem(ACCESS_TOKEN, "");
              localStorage.setItem(REFRESH_TOKEN, "");
              sessionStorage.setItem(ACCESS_TOKEN, "");
              sessionStorage.setItem(REFRESH_TOKEN, "");
            }
          }}
        >
          <ListItem
            button
            sx={{
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              bgcolor: pathname.includes(option.route)
                ? purpleLightActive
                : "none",
              borderRadius: 6,
              mt: 1,
              mb: 1,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }} color="#1B001B" >
              {pathname.includes(option.route)
                ? option?.inActiveicon
                : option?.activeIcon}
            </ListItemIcon>
            <ListItemText
              secondary={option.label}
              secondaryTypographyProps={{
                align: 'center',
                color: pathname.includes(option.route) ? "#1B001B" : "#7C797C",
                fontWeight: pathname.includes(option.route) ? "600" : "400",
              }}
            ></ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );
}
