import { surveyLocalization } from "survey-core";
import locales from "../../constants/locales";
import './as';
import './bn';
import './gu';
import './kn';
import './ml';
import './mr';
import './or';
import './pa';
import './ta';
import './te';
import './ur';
import './hi';

locales.forEach(locale => {
  surveyLocalization.localeNames[locale.code] = locale.name;
});
