import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tabs as MuiTabs,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useQueries } from "react-query";
import Logo from "../../../../assets/Logo.svg";
import Loading from "../../../../components/loading/loading";
import locales from "../../../../constants/locales";
import { getScheme } from "../../../../services/schemes/schemes";
import { useFeedback } from "../../../../providers/feedback/feeedback";

interface Props {
  schemeId: string;
  openPopup: boolean;
  setOpenPopup: (val: boolean) => void;
  notification: {
    [key: string]: {
      title: string;
      description: string;
      enabled: boolean;
      sameAsEnglish: boolean;
    };
  };
  setNotification: (val: {
    [key: string]: {
      title: string;
      description: string;
      enabled: boolean;
      sameAsEnglish: boolean;
    };
  }) => void;
  setSameAsEnglish: (val: any) => void;
  sameAsEnglish: any;
  submit: () => void;
  setOpenNotificationPopup: (val: boolean) => void;
}

const Tabs = styled(MuiTabs)(({ theme }) => {
  return {
    "MuiTabs-root css-112qdbf-MuiTabs-root": {
      "MuiTabs-scroller MuiTabs-fixed css-jpln7h-MuiTabs-scroller": {
        overflow: "scroll",
      },
    },
  };
});

export default function NotifyUserPopup({
  schemeId,
  openPopup,
  setOpenPopup,
  notification,
  setNotification,
  submit,
  setOpenNotificationPopup,
}: Props) {
  const { showSnackbar } = useFeedback();
  const [currentTab, setCurrentTab] = React.useState<string>("en");
  const handleChange = (event: React.SyntheticEvent, currentTabVal: string) => {
    setCurrentTab(currentTabVal);
  };

  const schemesData = useQueries(
    locales.map((locale) => ({
      queryKey: ["scheme-details", schemeId, locale.code],
      queryFn: async () => {
        let data = await getScheme(schemeId, locale.code);
        return data.data.data;
      },
      staleTime: Infinity,
    }))
  );

  useEffect(() => {
    locales?.map((locale, index) => {
      if (
        schemesData[index]?.status === "success" &&
        schemesData[index]?.data?.details?.name &&
        !notification[locale.code]?.title
      ) {
        notification[locale.code] = notification?.[locale.code] || {};
        notification[locale.code].title =
          schemesData[index]?.data?.details?.name;
        notification[locale.code].sameAsEnglish = true;
        notification[locale.code].enabled = true;
        setNotification({ ...notification });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...schemesData]);

  const isLoading = schemesData.some((a) => a.isLoading);

  function validate() {
    for (let i = 0; i < locales.length; i++) {
      let locale = locales[i];
      if (notification[locale.code].enabled) {
        if (
          !notification[locale.code].title ||
          notification[locale.code].title.length < 0
        ) {
          showSnackbar("Enter a valid title");
          setCurrentTab(locale.code);
          return false;
        } else if (
          (!notification[locale.code]?.sameAsEnglish &&
            (!notification[locale.code]?.description ||
              notification[locale.code]?.description?.length < 0)) ||
          // desc is same as english and english desc is not deined
          (!notification[locale.code]?.sameAsEnglish &&
            (!notification["en"]?.description ||
              notification["en"]?.description?.length < 0))
        ) {
          showSnackbar("Enter a valid description");
          setCurrentTab(locale.code);
          return false;
        }
      }
    }
    return true;
  }

  return (
    <Dialog
      open={openPopup}
      maxWidth="sm"
      sx={{
        overflow: "scroll",
        borderRadius: 16,
      }}
    >
      <DialogContent style={{ borderRadius: 20 }}>
        <ListItem>
          <ListItemText
            primary="Notify User"
            primaryTypographyProps={{ fontWeight: "800" }}
          />
          <ListSubheader sx={{ margin: 0, padding: 0 }}>
            <IconButton
              onClick={() => setOpenPopup(false)}
              sx={{
                color: "#323232",
              }}
            >
              <Close />
            </IconButton>
          </ListSubheader>
        </ListItem>
        {isLoading ? (
          <Box
            m={4}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box mb={2}>
              <Loading />
            </Box>
            <Typography>
              Fetching scheme details in all the languages
            </Typography>
          </Box>
        ) : (
          <Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTabs-scrollButtons.Mui-disabled": {
                    opacity: 0.3,
                  },
                }}
              >
                {locales?.map((locale) => {
                  return (
                    <Tab
                      label={locale?.name}
                      sx={{ textTransform: "capitalize", color: "#1B001B" }}
                      value={locale.code}
                      icon={
                        <Checkbox
                          checked={notification[locale.code]?.enabled || false}
                          onChange={(e, checked) => {
                            notification[currentTab] =
                              notification[currentTab] || {};
                            notification[currentTab].enabled = checked;
                            setNotification({ ...notification });
                          }}
                        />
                      }
                    />
                  );
                })}
              </Tabs>
              <Divider />
            </Box>

            <Box mt={4}>
              <TextField
                label="Title"
                fullWidth
                value={notification?.[currentTab]?.title || ""}
                placeholder="Enter notification title"
                onChange={(e) => {
                  notification[currentTab] = notification[currentTab] || {};
                  notification[currentTab].title = e.target.value;
                  setNotification({ ...notification });
                }}
                sx={{ mb: 2 }}
              />
              {currentTab !== "en" && (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Checkbox
                    checked={notification?.[currentTab]?.sameAsEnglish || false}
                    onChange={(e, checked) => {
                      notification[currentTab] = notification[currentTab] || {};
                      notification[currentTab].sameAsEnglish = checked;
                      setNotification({ ...notification });
                    }}
                    sx={{ p: 0, m: 0 }}
                  />
                  <Typography
                    fontWeight={"600"}
                    variant="body2"
                    ml={2}
                    textAlign={"center"}
                  >
                    Same as English
                  </Typography>
                </Box>
              )}
              {/* <Typography fontWeight={"600"}>Enter Description</Typography> */}
              <TextField
                label="Description"
                disabled={
                  currentTab !== "en" && notification[currentTab]?.sameAsEnglish
                }
                minRows={"4"}
                fullWidth
                multiline
                maxRows={3}
                value={
                  notification[currentTab]?.sameAsEnglish
                    ? notification?.["en"]?.description
                    : notification?.[currentTab]?.description || ""
                }
                placeholder="Enter notification description"
                onChange={(e) => {
                  if (e.target.value.length <= 300) {
                    notification[currentTab] = notification[currentTab] || {};
                    notification[currentTab].description = e.target.value;
                    setNotification({ ...notification });
                  }
                }}
                sx={{ mb: 2 }}
              />
            </Box>
            <Typography variant="body2">Preview</Typography>
            <Box
              my={2}
              p={4}
              sx={{
                background:
                  "linear-gradient(to top right, rgba(235, 245, 255, 0.3) 40%, rgba(243, 246, 249, 0.2) 100%)",
              }}
            >
              <List
                sx={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  border: `1px solid #DAE2ED`,
                  borderRadius: 3,
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <img src={Logo} height={32} width={32} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification?.[currentTab]?.title}
                    secondary={
                      notification?.[currentTab]?.sameAsEnglish
                        ? notification["en"].description
                        : notification?.[currentTab]?.description
                    }
                    secondaryTypographyProps={{
                      whiteSpace: "normal",
                      noWrap: false,
                    }}
                  />
                </ListItem>
              </List>
            </Box>
            <Box mt={4} display="flex" justifyContent="space-between">
              <Box onClick={() => setOpenNotificationPopup(false)}>
                <Typography
                  variant="button"
                  color="#91278F"
                  sx={{
                    borderBottom: "1px solid #990099",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    fontWeight: "600",
                  }}
                >
                  Close
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  if (validate()) {
                    submit();
                  }
                }}
              >
                Send Notification
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
