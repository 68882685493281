import React from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-core/survey.i18n";
import { Alert, Box, Button, Grid } from "@mui/material";
import { SubmitHandler } from "react-hook-form";
import { ChoiceFormInput } from "../../model/choice_form";
import { useNavigate } from "react-router-dom";

interface ChoiceQuestionnaireProps {
  surveyCreator: SurveyCreator;
  submitSurvey: () => void;
  onSubmit: SubmitHandler<ChoiceFormInput>;
  setCompleatedSteps?: (val: number) => void;
  viewOnly?: boolean;
  editChoice?: boolean;
  isSurveyTaken?: boolean;
}

const ChoiceQuestionnaire: React.FC<ChoiceQuestionnaireProps> = ({
  surveyCreator,
  submitSurvey,
  setCompleatedSteps,
  viewOnly,
  editChoice,
  isSurveyTaken,
}) => {
  const navigate = useNavigate();
  return (
    <Box mb={3}>
      {!viewOnly && isSurveyTaken && (
        <Alert severity="error">
          This survey questions cannot be edited because responses have already
          been collected from users.
        </Alert>
      )}
      <SurveyCreatorComponent creator={surveyCreator} key={"surveyCreator"} />
      {!viewOnly && !isSurveyTaken && (
        <Grid container>
          <Grid item md={6} lg={6} sm={12}>
            <Box
              display="flex"
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  editChoice ? navigate(-1) : setCompleatedSteps!(1);
                }}
                fullWidth
              >
                {editChoice ? "Cancel" : "Back"}
              </Button>
              <Button
                variant="contained"
                onClick={submitSurvey}
                fullWidth
                sx={{ ml: 3 }}
              >
                {editChoice ? "Save changes" : "Create choice"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default ChoiceQuestionnaire;
