import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { useFeedback } from "../../../providers/feedback/feeedback";
import {
  deleteAdmins,
  getAdmins,
  getRoles,
} from "../../../services/admin/admin_services";
import DeleteUser from "../components/delete_user/delete_uer";
import UserListingPage from "../components/user_listing_page/user_listing_page";
import { AdminListModel } from "../model/admin_list";
import { AdminContext } from "../model/context_provider";
import ViewUserDetails from "./view_user_details/view_user_details";
import useUser from "../../../providers/user/user_provider";
import AccessDenied from "../../../components/access_denied/access_denied";
import { useQuery } from "react-query";
import { AdminsList } from "../model/admins_list";
import { queryClient } from "../../../providers/query/query";

export function useMyContext() {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
}

export default function UserManagement() {
  const [viewAdmin, setViewAdmin] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  const [userRoleBreadcrumb, setUserRoleBreadcrumb] =
    useState<string>("dashboard/admin");
  const [selectedAdminRoles, setSelectedAdminRoles] = useState({});
  const [adminList, setAdminList] = useState<AdminListModel[]>([]);
  const [selectedAdminDetails, setSelectedAdminDetails] =
    useState<AdminListModel>({} as AdminListModel);
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [adminRole, setAdminRole] = useState(0);

  const [searchList, setSearchList] = useState<AdminListModel[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const { hasPermission } = useUser();
  const { showSnackbar } = useFeedback();

  const { data, isLoading, isError } = useQuery(["adminList"], async () => {
    let response = await getAdmins();
    return response?.data?.data || [];
  });

  const { data: roleList } = useQuery(["role"], async () => {
    let res = await getRoles();
    return res.data.data.list;
  });

  const deleteAdminApiCall = () => {
    let params = { params: { id: selectedAdminDetails.id } };
    deleteAdmins(params)
      .then((res) => {
        setDeleteAdmin(false);
        showSnackbar(res?.data?.data?.message || "");
        queryClient.invalidateQueries({ queryKey: ["adminList"] });
      })
      .catch((e) => {
        setDeleteAdmin(false);
        showSnackbar("Something Went wrong");
      });
  };

  function searchFilter() {
    if (searchValue === "") {
      setSearchList(data);
    } else {
      let filteredList = data.filter((users: AdminsList) => {
        return (
          users?.name
            ?.toLowerCase()
            ?.includes(searchValue.trim().toLowerCase()) && searchValue !== ""
        );
      });
      setSearchList(filteredList);
    }
  }

  useEffect(() => {
    searchFilter();
  }, [searchValue, data]);

  useEffect(() => {
    if (isError) showSnackbar("Something went wrong");
  }, []);

  return (
    <AdminContext.Provider
      value={{
        userRoleBreadcrumb,
        setUserRoleBreadcrumb,
        viewAdmin,
        setViewAdmin,
        setDeleteAdmin,
        deleteAdmin,
        selectedAdminRoles,
        setSelectedAdminRoles,
        adminList,
        setAdminList,
        selectedAdminDetails,
        setSelectedAdminDetails,
        deleteAdminApiCall,
        adminName,
        setAdminName,
        adminRole,
        setAdminRole,
        adminEmailId,
        setAdminEmailId,
        isLoading,
        searchList,
        setSearchList,
        searchValue,
        setSearchValue,
        roleList,
      }}
    >
      <MainLayout>
        {hasPermission("Users", "Read") ? (
          <Box>
            <UserListingPage />
          </Box>
        ) : (
          <Box display="flex" flex={1} width="100%" height="100%">
            <AccessDenied />
          </Box>
        )}
        <DeleteUser />
        <ViewUserDetails />
      </MainLayout>
    </AdminContext.Provider>
  );
}
