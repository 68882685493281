import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import {
  Box,
  Grid,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import React, { useImperativeHandle, useState } from "react";
import DatGridJobListing from "../components/data_grid_job_listing/data_grid_job_listing";
import { JobListingModel } from "../model/job_listing_model";
import FilterJobListing from "../components/filer_job_listing/filter_job_listing";
import { getMasterData, getStateMasterData } from "../../../services/master/master_service";
import { useQuery } from "react-query";
import {
  getJobList,
  getFilters,
  downloadJobListingService,
  updateJobStatus,
  deleteJob,
} from "../../../services/jobs/jobs";
import { downloadFromBlob } from "../../../utils/utils";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { queryClient } from "../../../providers/query/query";
import Loading from "../../../components/loading/loading";
import UpdateJobsStatusToReject from "../components/update_jobs_status/update_jobs_status_popup";
import UpdateJobsStatusToDelete from "../components/update_jobs_status/update_jobs_status_popup";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../../constants";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export interface JobListingLabels {
  label: string;
}

function JobListing(
  props: {
    openModal: boolean;
    setOpenModal: (val: boolean) => void;
  },
  ref: any
) {
  const { openModal, setOpenModal } = props;

  const [stateId, setStateId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [experienceRequired, setExperienceRequired] = useState<number | null>(
    null
  );
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [closeRejectJobStatusDialog, setCloseRejectJobStatusDialog] =
    useState<boolean>(false);
  const [closeDeleteJobStatusDialog, setCloseDeleteJobStatusDialog] =
    useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState<JobListingModel | null>(
    null
  );
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [jobType, setJobType] = useState<number | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { showSnackbar } = useFeedback();
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [gender, setGender] = useState("");

  useImperativeHandle(ref, () => ({
    select: () => {
      downloadJobListingExcel();
    },
  }));

  const jobListingLabels: JobListingLabels[] = [
    {
      label: "Sl. No.",
    },
    {
      label: "Job Type",
    },
    {
      label: "Job role/Position",
    },
    {
      label: "Company/Person",
    },
    {
      label: "State",
    },
    {
      label: "City",
    },
    {
      label: "District",
    },
    {
      label: "Ex. Required",
    },
    {
      label: "Job Contact no.",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const { data: jobList, isLoading } = useQuery(
    [
      "JobList",
      searchValue,
      page,
      cityId,
      districtId,
      districtId,
      experienceRequired,
      searchValue,
      stateId,
      selectedStatus,
      jobType,
      categoryId,
      startDate,
      endDate,
      ageFrom,
      gender
    ],
    async () => {
      let response = await getJobList({
        page: page + 1,
        size: 8,
        city: cityId,
        district: districtId,
        experience: experienceRequired,
        keyword: searchValue,
        state: stateId,
        status: selectedStatus,
        jobType: jobType,
        category: categoryId,
        startDate: startDate,
        endDate: endDate,
        ageFrom: ageFrom,
        ageTo: ageTo,
        gender: gender,
      });
      return response?.data?.data?.data;
    }
  );



  const { data: filterList } = useQuery(["filtersList"], async () => {
    let response = await getFilters();
    return response?.data?.data;
  });

  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });



  function downloadJobListingExcel() {
    if (jobList?.total <= 1000) {
      downloadJobListingService({
        page: 1,
        size: 1000,
        city: cityId,
        district: districtId,
        keyword: searchValue,
        state: stateId,
        status: selectedStatus,
        jobType: jobType,
        experience: experienceRequired,
        format: "excel",
        category: categoryId,
        startDate: startDate,
        endDate: endDate,
        ageFrom: ageFrom,
        ageTo: ageTo,
        gender: gender,
      }).then((response) => {
        downloadFromBlob(response, new Date().valueOf() + ".xlsx");
      });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
  }

  function updateServiceApiCall({
    statusId,
    jobId,
    comments,
  }: {
    statusId: number;
    jobId: number;
    comments: string;
  }) {
    updateJobStatus({
      comment: comments,
      statusId: statusId,
      jobId: +jobId!,
    })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Job status updated successfully");
          queryClient.invalidateQueries({ queryKey: ["JobList"] });
        }
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
  }

  function deleteJobApiCall({ comments }: { comments: string }) {
    deleteJob({ jobId: +selectedJobId?.id!, comment: comments })
      .then((res) => {
        if (res.status === 200) {
          showSnackbar("Job removed successfully");
          queryClient.invalidateQueries({ queryKey: ["JobList"] });
        }
      })
      .catch((e) => {
        showSnackbar("Something went wrong");
      });
    setCloseDeleteJobStatusDialog(false);
  }
  function searchComponent() {
    return (
      <Grid display={"flex"} flexDirection={"row"} container p={2} mt={2}>
        <Grid md={12} sm={12} xs={12} lg={12} xl={12}>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setPage(0);
              setSearchValue(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: "#F5E6F5",
              borderRadius: 3,
              borderColor: "transparent",
            }}
            placeholder="Search by Company Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.primary" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Box height="100%" flex={1}>
      {searchComponent()}
      {isLoading ? (
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          height="100%"
        >
          <Loading />
        </Box>
      ) : (
        <DatGridJobListing
          labels={jobListingLabels}
          data={jobList?.list || []}
          count={jobList?.total || 0}
          page={page}
          setPage={setPage}
          setSelectedUserId={setSelectedJobId}
          updateServiceApiCall={updateServiceApiCall}
          setCloseRejectJobsStatusDialog={setCloseRejectJobStatusDialog}
          setCloseDeleteJobsStatusDialog={setCloseDeleteJobStatusDialog}
        />
      )}
      <FilterJobListing
        genderList={masterDataList?.gender}
        ageFrom={ageFrom}
        setAgeFrom={setAgeFrom}
        setAgeTo={setAgeTo}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        openModal={openModal}
        setOpenModal={setOpenModal}
        states={masterDataList?.state || []}
        stateId={stateId}
        setStateId={setStateId}
        cityId={cityId}
        setCityId={setCityId}
        districtId={districtId}
        setDistrictId={setDistrictId}
        resetFilter={() => {
          setStateId(null);
          setSelectedStatus(null);
          setCityId(null);
          setDistrictId(null);
          setJobType(null);
          setExperienceRequired(null);
          setPage(0);
          setCategoryId(null);
          setStartDate("");
          setEndDate("");
        }}
        statusList={filterList?.status}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        setJobType={setJobType}
        setExperience={setExperienceRequired}
        jobTypeList={filterList?.jobType || []}
        experienceRequired={filterList?.workExperience}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        category={filterList?.categories}
        ageTo={ageTo}
        gender={gender}
        setGender={setGender} />
      {closeRejectJobStatusDialog && (
        <UpdateJobsStatusToReject
          open={closeRejectJobStatusDialog}
          onDialogClose={() => {
            setCloseRejectJobStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            updateServiceApiCall({
              statusId: 4,
              comments: comments,
              jobId: selectedJobId?.id!,
            });
            setCloseRejectJobStatusDialog(false);
          }}
          title={"Reject Job listing"}
          subtitle={"Reason for rejection:"}
        />
      )}
      {closeDeleteJobStatusDialog && (
        <UpdateJobsStatusToDelete
          open={closeDeleteJobStatusDialog}
          onDialogClose={() => {
            setCloseDeleteJobStatusDialog(false);
          }}
          updateServiceStatus={(comments) => {
            deleteJobApiCall({
              comments: comments,
            });
            setCloseRejectJobStatusDialog(false);
          }}
          title={"Delete Job listing"}
          subtitle={"Reason for Deletion:"}
        />
      )}
    </Box>
  );
}

export default React.forwardRef(JobListing);
