import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import useUser from "../../../../providers/user/user_provider";

export default function StatusMenu({
  status,
  updatedStatus,
  onStatusChange,
}: {
  status: string;
  updatedStatus: string | null;
  onStatusChange: (status: string) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { hasPermission } = useUser();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function isStatusClosed() {
    return status === "Closed";
  }

  function isStatusInProgress() {
    return status === "In Progress";
  }

  return (
    <div>
      <Typography color="primary.main">Status</Typography>

      {isStatusClosed() ? (
        <Typography color="success.main" fontWeight={"600"}>
          Closed
        </Typography>
      ) : (
        <Button
          sx={{
            color: "text.primary",
            textTransform: "capitalize",
            p: 0,
            m: 0,
          }}
          id="basic-button"
          aria-controls={open ? "status-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disabled={isStatusClosed() || !hasPermission("Needs", "Update")}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              fontWeight={700}
              color={
                !isStatusClosed()
                  ? isStatusInProgress()
                    ? orange
                    : "text.primary"
                  : "success.main"
              }
            >
              {updatedStatus || status}
            </Typography>
            {isStatusClosed() ? null : (
              <IconButton>
                <KeyboardArrowDownRounded />
              </IconButton>
            )}
          </Box>
        </Button>
      )}
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ elevation: 3 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled
          selected={status === "Submitted"}
          onClick={() => {
            onStatusChange("Submitted");
            handleClose();
          }}
        >
          Submitted
        </MenuItem>
        <MenuItem
          selected={status === "In Progress"}
          disabled={status === "In Progress" || status === "Closed"}
          onClick={() => {
            onStatusChange("In Progress");
            handleClose();
          }}
        >
          In Progress
        </MenuItem>
        <MenuItem
          selected={status === "Closed"}
          disabled={status === "Closed"}
          onClick={() => {
            onStatusChange("Closed");
            handleClose();
          }}
        >
          Closed
        </MenuItem>
      </Menu>
    </div>
  );
}
