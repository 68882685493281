import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import FullLogo from "../../assets/full-logol.svg";
import AmiTeamImage from "../../assets/images/ami-team.png";
import { loginUrl } from "../../config";
import AuthLayout from "../../layouts/auth/auth_layout";
import { useEffect } from "react";
import { ACCESS_TOKEN } from "../../constants";
import { useNavigate } from "react-router-dom";

var items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!",
  },
  {
    name: "Random Name #2",
    description: "Hello World!",
  },
];

export default function Auth() {
  const navigate = useNavigate();
  useEffect(() => {
    let token = sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
      navigate("/reports");
    }
  }, []);
  return (
    <AuthLayout>
      <Grid
        zIndex={999}
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item md={3.5} sm={3.5} lg={3.5}>
          <Card
            sx={{
              width: "100%",
              borderRadius: 2,
              m: 1,
              backgroundColor: "white",
            }}
            elevation={2}
          >
            <CardContent
              sx={{
                backgroundColor: "white",
                zIndex: 999,
                pr: 0,
                pl: 0,
                pt: 0,
              }}
            >
              <Box
                height={500}
                display="flex"
                flexDirection="column"
                sx={{ bgcolor: "white" }}
              >
                <img
                  alt=""
                  src={AmiTeamImage}
                  height="100%"
                  width="100%"
                  style={{
                    flex: 1,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Box
                  pt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={FullLogo} width={200} alt="" />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <Card
            sx={{ width: "100%", borderRadius: 2, m: 1, bgcolor: "white" }}
            elevation={2}
          >
            <CardContent>
              <Box
                height={300}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Box p={2}>
                  <img src={FullLogo} width={300} />
                </Box>
                <Box
                  p={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography variant="h6" fontWeight={600}>
                    Welcome
                  </Typography>
                  <Typography variant="body2" color="#646464">
                    To access the admin portal please click on the below log in
                    button.
                  </Typography>
                </Box>
                <Box p={2}>
                  <a href={loginUrl}>
                    <Button variant="contained" sx={{ pl: 4, pr: 4 }}>
                      Log in as admin
                    </Button>
                  </a>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

function Item(props: any) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </Paper>
  );
}
