import HttpClient from "../../utils/http";
import {
  filter_list_filter,
  services,
  services_details,
  status_list,
} from "../constants/api_endpoints";

export function getServiceList({
  page,
  size,
  state,
  city,
  district,
  category,
  status,
  keyword,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender,
  sortOrder
}: {
  page: number;
  size: number;
  state: number | null;
  city: number | null;
  district: number | null;
  category: number | null;
  status: number | null;
  keyword: string | null;
  startDate:string | null;
  endDate:string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
  sortOrder:string
}) {
  let url = services + `?size=${size}&page=${page}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (category) url += `&category=${category}`;
  if (status) url += `&status=${status}`;
  if (keyword) url += `&keyword=${keyword}`;
   if(startDate!=="" && endDate!==""){
    url +=`&dateFrom=${startDate}&dateTo=${endDate}`
   }
   if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }
  return HttpClient.get(url);
}

export function getServiceDetails({ serviceId }: { serviceId: number }) {
  let url = services_details + `${serviceId}`;
  return HttpClient.get(url);
}

export function updateService({
  statusId,
  comment,
  serviceId,
}: {
  statusId: number | null;
  comment: string | null;
  serviceId: number | null;
}) {
  let url = services + `${serviceId}`;
  return HttpClient.patch(url, {
    statusId: statusId?.toString(),
    comment: comment,
  });
}

export function deleteService({
  serviceId,
  comment,
}: {
  serviceId: number | null;
  comment: string;
}) {
  let url = services + `${serviceId}`;
  return HttpClient.delete(url, {
    data: { comment: comment },
  });
}

export function getStatusList() {
  return HttpClient.get(status_list);
}

export function getFilterList() {
  return HttpClient.get(filter_list_filter, {
    headers: {
      locale: "en",
    },
  });
}

export function downloadService({
  page,
  size,
  state,
  city,
  district,
  category,
  status,
  format,
  // keyword,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender,
  sortOrder
}: {
  state: number | null;
  city: number | null;
  district: number | null;
  category: number | null;
  status: number | null;
  format: string;
  // keyword: string;
  page: number | null;
  size: number | null;
  startDate:string | null;
  endDate:string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
  sortOrder:string
}) {
  let url = `${services}?download=true&format=${format}&page=${page}&size=${size}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (category) url += `&category=${category}`;
  if (status) url += `&status=${status}`;
  // if (keyword) url += `&keyword=${keyword}`;
  if(startDate!=="" && endDate!==""){
    url +=`&dateFrom=${startDate}&dateTo=${endDate}`
   }
   if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }

  return HttpClient.get(url, {
    responseType: "blob",
  });
}

export function downloadServiceDetails(id: string, format: string) {
  return HttpClient.get(
    `${services_details}${id}?download=true&format=${format}`,
    { responseType: "blob" }
  );
}
