import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { AdminListModel } from "../../model/admin_list";
import { useMyContext } from "../../user_management/user_management";

export default function DeleteUser() {
  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });

  const {
    setDeleteAdmin,
    deleteAdmin,
    deleteAdminApiCall,
    selectedAdminDetails,
    setSelectedAdminDetails,
  } = useMyContext();

  return (
    <Dialog open={deleteAdmin} sx={{ borderRadius: 16 }}>
      <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
        <Typography variant="h5" fontWeight={600} textAlign={"center"} mt={3}>
          Are you sure you want to delete <br /> this user?
        </Typography>
        <Typography
          variant="body1"
          fontWeight={600}
          textAlign={"center"}
          mt={3}
        >
          {selectedAdminDetails?.name}
        </Typography>
        <Typography
          variant="body1"
          mt={1}
          fontWeight={600}
          textAlign={"center"}
          color="#91278F"
        >
          {selectedAdminDetails.role_name}
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          mt={3}
        >
          <Button
            onClick={() => {
              setDeleteAdmin(false);
            }}
            sx={{
              border: "1px solid #91278F",
              borderRadius: 2,
              fontWeight: 600,
              textTransform: "capitalize",
              minWidth: 150,
              height: 50,
              color: "#000000",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteAdminApiCall?.();
              // setSelectedAdminDetails({} as AdminListModel);
            }}
            sx={{
              display: "flex",
              textTransform: "capitalize",
              backgroundColor: "#D14C2E",
              color: "#ffffff",
              fontWeight: 600,
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#D14C2E", // Remove hover background color
              },
              minWidth: 150,
            }}
          >
            Delete
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
