import { Close } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { RewardsDetailsModel } from "../../model/reward_details_model";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function RedeemPopup({
  open,
  onDialogClose,
  updateRewards,
  rewardsList,
}: {
  open: boolean;
  onDialogClose: () => void;
  updateRewards: (remarks: string, points: number) => void;
  rewardsList: RewardsDetailsModel | null;
}) {
  const [comments, setComments] = React.useState("");
  const [rewardPoints, setRewardPoint] = React.useState(
    rewardsList?.currentPoints || ""
  );
  function submit() {
    updateRewards(comments, +rewardPoints!);
    setComments("");
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"space-between"}
          >
            <Typography fontWeight="600">{"Clear Points"}</Typography>
            <Close onClick={onDialogClose} sx={{ cursor: "pointer" }} />
          </Box>
          <Typography
            id="transition-modal-title"
            variant="body1"
            fontSize={14}
            fontWeight={600}
            mt={3}
          >
            {"Points to be cleared"}
          </Typography>
          <TextField
            placeholder="Enter reward points"
            onChange={(e) => {
              if (+e.target.value >= 0) setRewardPoint(e.target.value);
            }}
            value={rewardPoints}
            type="number"
          />
          <Box pb={2}>
            <Typography
              id="transition-modal-title"
              variant="body1"
              fontSize={14}
              fontWeight={600}
              mt={3}
            >
              {"Internal note"}
            </Typography>
          </Box>
          <TextField
            multiline
            minRows="5"
            fullWidth
            value={comments}
            onChange={({ target: { value } }) => {
              setComments(value);
            }}
          ></TextField>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={onDialogClose}>Cancel</Button>
            <Button
              variant="contained"
              onClick={submit}
              disabled={!comments || !+rewardPoints}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
