import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../layouts/main/main_layout";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { getRoles, postAdmin } from "../../../../services/admin/admin_services";
import { RoleList } from "../../model/admin_roles";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    ".css-18918z0-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
      color: "#1B001B",
    },
  };
});
export default function AddNewAdmin() {
  const navigate = useNavigate();
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [adminRole, setAdminRole] = useState<RoleList>({} as RoleList);
  const [roles, setRoles] = useState<RoleList[]>([]);
  const [error, setError] = useState(false);
  const { showSnackbar } = useFeedback();

  const getAdminRoles = () => {
    getRoles()
      .then((res) => {
        setRoles(res.data?.data?.list || []);
        showSnackbar("Details Fetched successfully");
      })
      .catch((e) => {
        console.log(e?.response);
      });
  };

  const addAdminApiCall = () => {
    let payload = {
      name: adminName,
      email_id: adminEmailId,
      role_id: adminRole.id,
    };

    postAdmin(payload)
      .then((res) => {
        showSnackbar("Admin Added Successfully");
        navigate("/admins");
      })
      .catch((e) => {
        if (e.response.status === 445 && e.response.data.error.code === 445) {
          showSnackbar("User already exist !");
        } else if (
          e.response.status === 403 &&
          e.response.data.error.code === 403
        ) {
          showSnackbar("User don't have permission");
        } else if (e?.response?.data?.error?.message) {
          showSnackbar(e?.response?.data?.error?.message);
        } else {
          showSnackbar("Something went wrong");
        }
      });
  };

  useEffect(() => {
    getAdminRoles();
  }, []);

  const submitForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (emailRegex?.test(adminEmailId)) {
      addAdminApiCall?.();
    } else {
      setError(true);
    }
  };

  return (
    <MainLayout>
      <Grid container spacing={3} mt={3}>
        <Grid item md={12} sm={12} xs={12} lg={6} xl={12}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Add New Admin
                  </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Admin Name"
                      placeholder="Enter Admin name"
                      value={adminName}
                      onChange={(event) => {
                        setAdminName(event?.target.value);
                      }}
                      required
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      fullWidth
                      label="Email ID"
                      placeholder="Enter Email id"
                      value={adminEmailId}
                      onChange={(event) => {
                        setAdminEmailId(event?.target.value);
                      }}
                      required
                    />
                    {error && (
                      <FormHelperText sx={{ color: "red", m: 0 }}>
                        Please Enter valid Email
                      </FormHelperText>
                    )}
                  </Box>
                  <Box mt={3}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">
                        Select Role<span style={{ color: "#D14C2E" }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="role-label"
                        label="Select an Option"
                        fullWidth
                        placeholder="Select an Option"
                        value={adminRole.role}
                      >
                        {roles.map((role) => (
                          <MenuItem
                            onClick={() => {
                              setAdminRole(role);
                            }}
                            value={role.role}
                            className={role.role}
                          >
                            {role.role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    mt={2}
                    flexWrap={"wrap"}
                  >
                    <Typography variant="subtitle2">Role access:</Typography>
                    {adminRole?.features?.map((feature) => {
                      return (
                        <Box display={"flex"} flexDirection={"row"}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: " #91278F" }}
                            ml={0.5}
                          >
                            {feature.name}
                            {"-"}
                          </Typography>
                          {feature?.permissions.map((permission) => {
                            return (
                              <Box>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ color: " #91278F" }}
                                  ml={0.5}
                                >
                                  {permission.name}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      onClick={() => navigate("/admins")}
                      sx={{
                        border: "1px solid #91278F",
                        borderRadius: 2,
                        fontWeight: 600,
                        textTransform: "capitalize",
                        minWidth: 200,
                        color: "#91278F",
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={submitForm}
                      disabled={
                        adminName && Object?.keys?.(adminRole)?.length
                          ? false
                          : true
                      }
                      sx={{
                        minWidth: 200,
                      }}
                      variant="contained"
                    >
                      Add Admin
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
