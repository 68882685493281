import { BorderColorRounded } from "@mui/icons-material";
import { Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function DownloadPopup({
  open,
  onDialogClose,
  onSubmit,
}: {
  open: boolean;
  onDialogClose: () => void;
  onSubmit: (fromPage: number | null, toAge: number | null) => void;
}) {
  const [fromPage, setFromPage] = React.useState<string | null>(null);
  const [toPage, setToPage] = React.useState<string | null>(null);

  useEffect(() => {}, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      // closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box display={"flex"} flexDirection={"row"}>
            <Typography mr={1}>From Page</Typography>
            <TextField
              type="number"
              value={fromPage}
              onChange={({ target: { value } }) => {
                setFromPage(value);
              }}
            />
            <Typography ml={2} mr={1}>
              To Page
            </Typography>
            <TextField
              value={toPage}
              onChange={({ target: { value } }) => {
                setToPage(value);
              }}
            />
          </Box>
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button onClick={onDialogClose}>Close</Button>
            <Button
              variant="contained"
              disabled={+fromPage! && +toPage! ? false : true}
              onClick={() => onSubmit(+fromPage!, +toPage!)}
            >
              {"Download"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
