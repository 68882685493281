import { CloseOutlined, Done, EditOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RedemptionHistory } from "../../model/redemption_history";
import dayjs from "dayjs";
import useUser from "../../../../providers/user/user_provider";

export default function RedemptionHistoryComp({
  history,
  onSubmit,
}: {
  history: RedemptionHistory;
  onSubmit: (id: number, comments: string) => void;
}) {
  const [isRedemptionEditable, setIsRedemptioEditable] = useState(false);
  const [comments, setComments] = useState(history?.systemComment || "");
  const originalDate = dayjs?.(history?.updatedAt);
  const formattedDate = originalDate?.format?.("DD MMM YYYY HH:MM A");
  const { hasPermission } = useUser();
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="subtitle1">{history.description}</Typography>
          <Typography variant="subtitle2" color="#7C797C" mt={1}>
            {formattedDate}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              color: "#D14C2E",
            }}
          >
            {"-"}
            {history?.points}
          </Typography>
          <Typography variant="subtitle2" color="#7C797C">
            {"Reward Points"}
          </Typography>
        </Box>
      </Box>

      {isRedemptionEditable ? (
        <TextField
          label={"Internal note"}
          placeholder="Enter internal comments"
          sx={{ mt: 2 }}
          fullWidth
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={!comments}
                  onClick={() => {
                    onSubmit(242, comments);
                    setIsRedemptioEditable(false);
                  }}
                  sx={{
                    backgroundColor: "#E9F0ED",
                    borderRadius: 3,
                    color: "#1E6446",
                    opacity: 1,
                  }}
                >
                  <Done />
                </IconButton>

                <IconButton
                  onClick={(e) => setIsRedemptioEditable(false)}
                  sx={{
                    backgroundColor: "#FAEDEA",
                    borderRadius: 3,
                    ml: 2,
                    color: "#D14C2E",
                    opacity: 1,
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Box display={"flex"} mt={1}>
          <Typography fontWeight={"600"} variant="subtitle2" color="#91278F">
            Internal Note :
          </Typography>
          <Typography variant="subtitle2" color="#7C797C" ml={1}>
            {history?.systemComment}
          </Typography>
          <IconButton
            disabled={!hasPermission("Rewards", "Update")}
            sx={{ m: 0, p: 0, ml: 2 }}
            onClick={() => setIsRedemptioEditable(true)}
          >
            <EditOutlined sx={{ width: 16, height: 16 }} />
          </IconButton>
        </Box>
      )}
      <Divider sx={{ backgroundColor: "#F5E6F5", my: 3 }} />
    </Box>
  );
}
