import styled from "@emotion/styled";
import {
  Add,
  ArrowBack,
  DeleteOutline,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RoleList } from "../../model/admin_roles";
import useUser from "../../../../providers/user/user_provider";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

interface RoleListingParams {
  searchList: RoleList[];
  searchValue: string;
  setSearchValue: (val: string) => void;
  setViewSelectedRoleDetails: (val: boolean) => void;
  setSelectedRoleDetails: (val: RoleList) => void;
  roleList: RoleList[];
  setDeleteRole: (val: boolean) => void;
}

export default function RoleListingPage({
  searchValue,
  searchList,
  setSearchValue,
  setViewSelectedRoleDetails,
  setSelectedRoleDetails,
  roleList,
  setDeleteRole,
}: RoleListingParams) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: 2,
        m: 1,
        minHeight: 550,
      }}
      elevation={1}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flex: 1,
        }}
      >
        <Box display="flex" flexDirection="column" flex={1} sx={{ pl: 1 }}>
          <Box flexDirection="row" display="flex" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack></ArrowBack>
            </IconButton>
            <Typography variant="h6" fontWeight="600">
              Manage Roles and Access
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              mt: 2,
            }}
          >
            <Grid container spacing={3} sx={{ flex: 1 }}>
              <Grid
                display="flex"
                item
                md={12}
                sm={12}
                xs={12}
                lg={12}
                xl={12}
                sx={{ flex: 1 }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  py={1}
                  pl={1}
                  width="100%"
                  sx={{ flex: 1 }}
                >
                  <Grid
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    container
                  >
                    <Grid md={4} sm={4} xs={4} lg={4} xl={4}>
                      <TextField
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                        size="small"
                        fullWidth
                        sx={{
                          backgroundColor: "#F5E6F5",
                          borderRadius: 3,
                          borderColor: "transparent",
                        }}
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search sx={{ color: "text.primary" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  {searchList?.length === 0 ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      flex={1}
                    >
                      <Typography>No Data Available</Typography>
                    </Box>
                  ) : (
                    <Box display={"flex"} my={3} flexDirection={"column"}>
                      {searchList?.map((item, index) => {
                        return (
                          <>
                            <Box
                              display="flex"
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              my={2}
                            >
                              <Box display="flex" flexDirection={"column"}>
                                <Box>
                                  <Typography
                                    fontWeight={"600"}
                                    sx={{ opacity: item?.isActive ? 1 : 0.5 }}
                                  >
                                    {item.role}
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection={"row"}
                                  flexWrap={"wrap"}
                                >
                                  {item?.features?.map((feature) => {
                                    return (
                                      <Box
                                        display="flex"
                                        flexDirection={"row"}
                                        flexWrap={"wrap"}
                                      >
                                        <Typography
                                          fontSize={14}
                                          color="#91278F"
                                          mr={1}
                                        >
                                          {feature.name + " - "}
                                        </Typography>
                                        {feature?.permissions?.map(
                                          (permissions, index) => {
                                            return (
                                              <Typography
                                                fontSize={14}
                                                color="#91278F"
                                                mr={1}
                                              >
                                                {permissions.name}
                                              </Typography>
                                            );
                                          }
                                        )}
                                        {index !==
                                          feature?.permissions?.length - 1 && (
                                          <Typography mr={1} color="#91278F">
                                            |
                                          </Typography>
                                        )}
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection={"row"}>
                                <IconButton
                                  onClick={() => {
                                    if (item?.isActive) {
                                      setViewSelectedRoleDetails(true);
                                      setSelectedRoleDetails?.(item);
                                    }
                                  }}
                                  sx={{
                                    backgroundColor: "#E9F0ED",
                                    borderRadius: 4,
                                    color: "#1B001B",
                                    height: "fit-content",
                                    opacity: item?.isActive ? 1 : 0.4,
                                  }}
                                >
                                  <Visibility></Visibility>
                                </IconButton>
                                {hasPermission("Users", "Update") && (
                                  <IconButton
                                    onClick={() => {
                                      if (item?.isActive) {
                                        let featuresAndPermissionObject: any =
                                          [];
                                        let permissionObject: any = [];
                                        item?.features?.map((featureItem) => {
                                          permissionObject = [];
                                          featureItem?.permissions?.map(
                                            (permissions) => {
                                              permissionObject.push(
                                                permissions.id
                                              );
                                            }
                                          );
                                          featuresAndPermissionObject.push({
                                            featureId: featureItem.id,
                                            permission: permissionObject,
                                          });
                                        });

                                        navigate(
                                          "/admins/manageRolesAndAccess/modifyRole",
                                          {
                                            state: {
                                              features:
                                                featuresAndPermissionObject,
                                              roleName: item.role,
                                              roleId: item.id,
                                            },
                                          }
                                        );
                                      }
                                    }}
                                    sx={{
                                      backgroundColor: "#F5E6F5",
                                      borderRadius: 4,
                                      ml: 2,
                                      color: "#1B001B",
                                      height: "fit-content",
                                      opacity: item?.isActive ? 1 : 0.4,
                                    }}
                                  >
                                    <Edit></Edit>
                                  </IconButton>
                                )}
                                {hasPermission("Users", "Delete") && (
                                  <IconButton
                                    onClick={() => {
                                      if (item?.isActive) {
                                        setDeleteRole(true);
                                        setSelectedRoleDetails?.(item);
                                      }
                                    }}
                                    sx={{
                                      backgroundColor: "#FAEDEA",
                                      borderRadius: 4,
                                      ml: 2,
                                      color: "#D14C2E",
                                      height: "fit-content",
                                      opacity: item?.isActive ? 1 : 0.4,
                                    }}
                                  >
                                    <DeleteOutline></DeleteOutline>
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                            {index !== roleList.length - 1 && (
                              <Divider
                                sx={{
                                  display: "flex",
                                  backgroundColor: "#7C797C",
                                  opacity: 0.1,
                                }}
                              />
                            )}
                          </>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid
            display="flex"
            width={"100%"}
            flexDirection="row"
            justifyContent="space-between"
            m={2}
          >
            <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
              <Button
                onClick={() => navigate("/reports", { replace: true })}
                fullWidth
                sx={{
                  border: "1px solid #91278F",
                  borderRadius: 2,
                  fontWeight: 600,
                  textTransform: "capitalize",
                  color: "#000000",
                }}
              >
                Back To Reports
              </Button>
            </Grid>

            <Grid md={2} sm={4} xs={4} lg={2} xl={2}>
              <Box>
                <Button
                  onClick={() => {
                    navigate("addAdminRole");
                  }}
                  disabled={!hasPermission("Users", "Create")}
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  <Add fontSize={"inherit"} /> Add Admin Role
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
