import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Feature, UserPermissions } from "../../models/user_permissions";
import { getUserPermissions } from "../../services/user/permissions";
import Session from "../../utils/session";

interface UserContextInterface {
  userPermissions: UserPermissions | null;
  getFeaturePermissions: ((feature: string) => Feature | null) | null;
  hasPermission: (feature: string, permission: string) => boolean;
  accessToken: string | null;
  setAccessToken: (feature: string, permission: string) => void;
  refreshToken: string | null;
  setRefreshToken: (feature: string, permission: string) => void;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextInterface>({
  userPermissions: null,
  getFeaturePermissions: () => null,
  hasPermission: () => false,
  accessToken: null,
  setAccessToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  login: () => {},
  logout: () => {},
});

export function UserProvider({ children }: PropsWithChildren) {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  useEffect(() => {
    
    if (Session.isLoggedIn()) {
      setAccessToken(Session.getToken());
      setRefreshToken(Session.getRefreshToken());
    } else {
      
      setAccessToken(null);
      setRefreshToken(null);
    }
  }, []);

  const { data: userPermissions } = useQuery<UserPermissions>(
    "permissions",
    async () => {
      let { data } = await getUserPermissions();
      return data.data as UserPermissions;
    },
    {
      enabled: accessToken != null,
    }
  );

  function getFeaturePermissions(feature: string): Feature | null {
    if (userPermissions != null) {
      return userPermissions.features.find(
        (_feature) => _feature.name === feature
      )!;
    } else {
      return null;
    }
  }

  function hasPermission(feature: string, permission: string) {
    let _feature = getFeaturePermissions(feature);
    return _feature?.permissions.find(
      (_permission) => _permission.name === permission
    )
      ? true
      : false;
  }

  function login(accessToken: string, refreshToken: string) {
    Session.login(accessToken, refreshToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  }

  function logout() {
    Session.logout();
    setAccessToken(null);
    setRefreshToken(null);
  }

  return (
    <UserContext.Provider
      value={{
        userPermissions: userPermissions || null,
        getFeaturePermissions: getFeaturePermissions,
        hasPermission: hasPermission,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        login,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default function useUser() {
  return useContext(UserContext);
}
