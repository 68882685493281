import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getCityMasterData,
  getDistrictMasterData,
} from "../../../../services/master/master_service";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { MasterSchemeInterface } from "../../../schemes/family_enquiries/family_enquiry_list";

interface FilterServicesInterface {
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  states: { id: number; value: string }[];
  // city: { id: number; value: string }[];
  // district: { id: number; value: string }[];
  category: { id: number; name: string }[];
  stateId: number | null;
  setStateId: (val: number | null) => void;
  cityId: number | null;
  setCityId: (val: number | null) => void;
  districtId: number | null;
  setDistrictId: (val: number | null) => void;
  categoryId: number | null;
  setCategoryId: (val: number | null) => void;
  resetFilter: () => void;
  statusList: { id: number; name: string }[];
  selectedStatus: number | null;
  setSelectedStatus: (val: number | null) => void;
  setPage: (val: number) => void;
  endDate: string;
  setEndDate: (value: string) => void;
  startDate: string;
  setStartDate: (value: string) => void;
  ageFrom: number | null;
  setAgeFrom: (value: number | null) => void;
  ageTo: number | null
  setAgeTo: (value: number | null) => void;
  gender: string;
  genderList: MasterSchemeInterface[];
  setGender: (value: string) => void;
}

export default function FilterServices({
  openModal,
  setOpenModal,
  cityId,
  setCityId,
  districtId,
  setDistrictId,
  states,
  category,
  stateId,
  setStateId,
  categoryId,
  setCategoryId,
  resetFilter,
  statusList,
  selectedStatus,
  setSelectedStatus,
  setPage,
  endDate,
  setEndDate,
  setStartDate,
  ageFrom,
  setAgeFrom,
  ageTo,
  setAgeTo,
  gender,
  genderList,
  setGender
}: FilterServicesInterface) {
  const [_stateId, _setStateId] = useState<number | null>(null);
  const [_categoryId, _setCategoryId] = useState<number | null>(null);
  const [_city, _setCity] = useState<number | null>(null);
  const [_district, _setDistrict] = useState<number | null>(null);
  const [_selectedStatus, _setSelectedStatus] = useState<number | null>(null);
  const [_endDate, _setEndDate] = useState<string>(endDate);
  const [_startDate, _setStartDate] = useState<string>(endDate);
  const [_ageFrom, _setAgeFrom] = useState<number | null>(null);
  const [_ageTo, _setAgeTo] = useState<number | null>(null);
  const [_selectedGender, _setSelectedGender] = useState<string>("");

  useEffect(() => {
    if (_endDate != null && _startDate != null && _startDate > _endDate) {
      _setEndDate("");
    }
  }, [_startDate]);

  const { data: city } = useQuery(["cityList", _stateId], async () => {
    if (_stateId) {
      let response = await getCityMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });
  const { data: district } = useQuery(["districtList", _stateId], async () => {
    if (_stateId) {
      let response = await getDistrictMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });

  function _dateRange({
    title,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  }: {
    title: string;
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection={"column"}>
          <DatePicker
            label="Start Date"
            format="DD-MM-YYYY"
            disableFuture
            value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
            onChange={(value: Dayjs | null) => {
              setStartDate(value?.format("YYYY-MM-DD") || "");
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
          <Box p={1}></Box>
          <DatePicker
            label="End Date"
            format="DD-MM-YYYY"
            disableFuture
            value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
            onChange={(value: Dayjs | null) => {
              setEndDate(value?.format("YYYY-MM-DD") || "");
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
        </Box>
      </Box>
    );
  }

  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
  }: {
    title: string;
    placeholder: string;
    options: { id: number; value: string }[] | undefined; // Make options potentially undefined
    selectedId: number | null;
    onSelect: (id: number | null) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            fullWidth
            value={
              selectedId == null
                ? { id: null, value: "" }
                : options?.find((option) => selectedId === option?.id)
            }
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options || []}
            getOptionLabel={(option) => option?.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={placeholder}
                placeholder={placeholder}
              />
            )}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Dialog open={openModal}>
      <Box sx={{ minWidth: 400 }}>
        <Card sx={{ px: 2 }}>
          <CardHeader
            title="Filter Enquiries"
            titleTypographyProps={{ variant: "body1", fontWeight: 600 }}
            action={
              <IconButton onClick={() => setOpenModal(false)}>
                <Close sx={{ color: "#323232" }} />
              </IconButton>
            }
          >
            <Typography></Typography>
          </CardHeader>
          <CardHeader
            title="Filter By"
            titleTypographyProps={{ variant: "body2", fontWeight: 600 }}
            action={
              <Button
                variant="text"
                sx={{ p: 0 }}
                onClick={() => {
                  resetFilter();
                  _setStartDate("");
                  _setEndDate("");
                  _setCategoryId(null);
                  _setStateId(null);
                  _setSelectedStatus(null);
                  _setCity(null);
                  _setDistrict(null);
                  setOpenModal(false);
                  _setAgeFrom(null);
                  _setAgeTo(null);
                  _setSelectedGender("");
                }}
              >
                Reset
              </Button>
            }
          >
            <Typography></Typography>
          </CardHeader>
          <CardContent sx={{ maxHeight: 400, overflow: "auto scroll" }}>
            {_dropdownOption({
              title: "State",
              placeholder: "Search for State",
              options: states,
              selectedId: _stateId,
              onSelect: (id) => {
                _setStateId(id);
                _setCity(null);
                _setDistrict(null);
              },
            })}
            {_dropdownOption({
              title: "District",
              placeholder: "Search for District",
              options: district,
              selectedId: _district,
              onSelect: (id) => {
                _setDistrict(id);
              },
            })}

            {_dropdownOption({
              title: "City",
              placeholder: "Search for City",
              options: city,
              selectedId: _city,
              onSelect: (id) => {
                _setCity(id);
              },
            })}

            {_dropdownOption({
              title: "Category",
              placeholder: "Search for Category",
              options: category.map((item) => {
                return { id: item.id, value: item.name };
              }),
              selectedId: _categoryId,
              onSelect: (id) => {
                _setCategoryId(id);
              },
            })}
            {_dateRange({
              title: "Date Range",
              setEndDate: _setEndDate,
              setStartDate: _setStartDate,
              startDate: _startDate,
              endDate: _endDate,
            })}
            {/* <Box display="flex" flexDirection={"row"} mt={2}>
              <TextField
                value={_ageFrom}
                fullWidth
                sx={{ mr: 2 }}
                label="From Age"
                placeholder="set from age"
                onChange={(e) => {
                  _setAgeFrom(+e.target.value);
                }}
              />
              <TextField
                value={_ageTo}
                fullWidth
                sx={{ mr: 2 }}
                label="To Age"
                placeholder="set To age"
                onChange={(e) => {
                  _setAgeTo(+e.target.value);
                }}
              />
            </Box> */}
            {/* <Divider sx={{ mt: 2, mb: 1 }} /> */}
            <Typography mt={2} fontSize={16} fontWeight="600" mb={1}>
              Gender
            </Typography>
            <Box>
              {genderList?.map((gender) => {
                return (
                  <Button
                    onClick={() => {
                      if (_selectedGender === gender?.value) {
                        _setSelectedGender("");
                      } else {
                        _setSelectedGender(gender?.value);
                      }
                    }}
                    variant={
                      _selectedGender === gender.value
                        ? "contained"
                        : "outlined"
                    }
                    sx={{ mr: 1, mb: 1 }}
                  >
                    {gender?.value}
                  </Button>
                );
              })}
            </Box>
            <Typography fontSize={16} fontWeight="600" mt={1}>
              {"Status"}
            </Typography>

            <Box display="flex" flexWrap={"wrap"}>
              {statusList?.map((item) => (
                <Button
                  onClick={() => {
                    _setSelectedStatus(item?.id);
                  }}
                  variant={
                    _selectedStatus === item?.id ? "contained" : "outlined"
                  }
                  sx={{ mr: 1, mb: 1 }}
                >
                  {item?.name}
                </Button>
              ))}
            </Box>

            <Box mt={5} display="flex" justifyContent="space-between">
              <Box onClick={() => setOpenModal(false)}>
                <Typography
                  variant="button"
                  color="#91278F"
                  sx={{
                    borderBottom: "1px solid #990099",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  Cancel
                </Typography>
              </Box>
              <Button
                sx={{ minWidth: 200 }}
                variant="contained"
                onClick={() => {
                  setCategoryId(_categoryId);
                  setCityId(_city);
                  setDistrictId(_district);
                  setStateId(_stateId);
                  setSelectedStatus(_selectedStatus);
                  setOpenModal(false);
                  setPage(0);
                  setStartDate(_startDate);
                  setEndDate(_endDate);
                  setAgeFrom(_ageFrom);
                  setAgeTo(_ageTo);
                  setGender(_selectedGender);
                }}
              >
                Apply Filters
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
}
