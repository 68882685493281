import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { orange } from "@mui/material/colors";
import useUser from "../../../../providers/user/user_provider";

export default function StatusMenu({
  status,
  updatedStatus,
  onStatusChange,
}: {
  status: string;
  updatedStatus: string | null;
  onStatusChange: (status: string) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { hasPermission } = useUser();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Typography color="primary.main">Status</Typography>
      {status === "Approved" || status === "Rejected" ? (
        <Box>
          <Typography
            fontWeight="600"
            color={status === "Approved" ? "#1E6446" : "#D14C2E"}
          >
            {status}
          </Typography>
        </Box>
      ) : (
        <>
          <Button
            sx={{
              color: "text.primary",
              textTransform: "capitalize",
              p: 0,
              m: 0,
            }}
            id="basic-button"
            aria-controls={open ? "status-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            disabled={!hasPermission("Services", "Update")}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                fontWeight={700}
                color={"text.primary"}
              >
                {updatedStatus || status}
              </Typography>
              <IconButton>
                <KeyboardArrowDownRounded />
              </IconButton>
            </Box>
          </Button>
          <Menu
            id="status-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{ elevation: 3 }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              disabled={status === "In Progress"}
              selected={status === "Pending" || status === "Modified"}
              onClick={() => {
                if (status === "Modified") {
                  onStatusChange("Modified");
                } else {
                  onStatusChange("Pending");
                }

                handleClose();
              }}
            >
              {status === "Modified" ? "Modified" : "Pending"}
            </MenuItem>
            <MenuItem
              selected={status === "In Progress"}
              onClick={() => {
                onStatusChange("In Progress");
                handleClose();
              }}
            >
              In Progress
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
}
