import HttpClient from "../../utils/http";
import {
  surveyor_download,
  surveyor_download_List,
  surveyor_list,
  surveyor_submitted_choice,
  surveyor_submitted_choice_details,
  surveyor_submitted_recording,
  surveyors,
  surveyors_filter,
} from "../constants/api_endpoints";

export function serveyorList({
  page,
  size,
  keyword,
}: {
  page: number;
  size: number;
  keyword: string;
}) {
  let url = `${surveyor_list}?page=${page}&size=${size}&keyword=${keyword}`;
  return HttpClient.get(url);
}

export function addServeyors({
  name,
  mobileNumber,
  location,
  stateId,
  cityId,
  districtId,
  choices,
}: {
  name: string | null;
  mobileNumber: string | null;
  location: string | null;
  stateId: number | null;
  cityId: number | null;
  districtId: number | null;
  choices: number[];
}) {
  let payload = {
    name: name,
    mobileNumber: mobileNumber,
    location: location,
    stateId: stateId,
    cityId: cityId,
    districtId: districtId,
    choices: choices,
  };

  return HttpClient.post(surveyors, payload);
}

export function getDetailsOfSurveyor({ id }: { id: number }) {
  return HttpClient.get(`${surveyors}${id}`);
}

export function getDetailsOfSurveyorChoices({
  id,
  page,
  size,
}: {
  id: number;
  page: number;
  size: number;
}) {
  return HttpClient.get(`${surveyors}${id}/choices?page=${page}&size=${size}`);
}

type PayloadType = {
  name: string | null;
  mobileNumber: string | null;
  location: string | null;
  stateId: number | null;
  cityId: number | null;
  districtId: number | null;
  choices: number[];
};

export function updateDetailsOfSurveyor({
  id,
  name,
  mobileNumber,
  location,
  stateId,
  cityId,
  districtId,
  choices,
}: {
  id: number;
  name: string | null;
  mobileNumber: string | null;
  location: string | null;
  stateId: number | null;
  cityId: number | null;
  districtId: number | null;
  choices: number[];
}) {
  let payload: PayloadType = {
    name: name,
    mobileNumber: mobileNumber,
    location: location,
    stateId: stateId,
    cityId: cityId,
    districtId: districtId,
    choices: choices,
  };
  payload = Object.fromEntries(
    Object.entries(payload).filter(([_, value]) => value !== null)
  ) as PayloadType;
  return HttpClient.patch(`${surveyors}${id}`, payload);
}

export function removeSurveyor({ id }: { id: number | null }) {
  return HttpClient.delete(`${surveyors}${id}`);
}

export function getSurveyorResponses({
  page,
  size,
  occupation,
  state,
  category,
  personalIncome,
  dateFrom,
  dateTo,
  gender,
  education,
  choiceId,
  sortOrder,
  city,
  keyword,
  districtId
}: {
  page: number | null;
  size: number | null;
  occupation: number | null;
  education: number | null;
  state: number | null;
  category: number | null;
  personalIncome: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  gender: string | null;
  choiceId: number;
  sortOrder: string | null;
  city: number | null;
  keyword:string,
  districtId:null|number
}) {
  let url = `${surveyor_submitted_choice}/${choiceId}?page=${page}&size=${size}`;
  if (gender) url += `&gender=${gender}`;
  if (occupation) url += `&occupation=${occupation}`;
  if (education) url += `&education=${education}`;
  if (state) url += `&state=${state}`;
  if (category) url += `&category=${category}`;
  if (personalIncome) url += `&personalIncome=${personalIncome}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if (city) url += `&city=${city}`;
  if(keyword!==""){
    url += `&keyword=${keyword}`
  }
  if (districtId !== null) {
    url += `&district=${districtId}`
  }
  return HttpClient.get(url);
}

export function getSurveyorDownloadList({
  page,
  size,
  occupation,
  state,
  category,
  personalIncome,
  dateFrom,
  dateTo,
  gender,
  education,
  choiceId,
  sortOrder,
  status
}: {
  page: number | null;
  size: number | null;
  occupation: number | null;
  education: number | null;
  state: number | null;
  category: number | null;
  personalIncome: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  gender: string | null;
  choiceId: number;
  sortOrder:string;
  status:number | null;
}) {
  console.log(dateFrom,dateTo,'dateFrom');
  
  let url = `${surveyor_download_List}?choiceId=${choiceId}&page=${page}&size=${size}`;
  if (gender) url += `&gender=${gender}`;
  if (occupation) url += `&occupation=${occupation}`;
  if (education) url += `&education=${education}`;
  if (state) url += `&state=${state}`;
  if (category) url += `&category=${category}`;
  if (personalIncome) url += `&personalIncome=${personalIncome}`;
  if (dateFrom && dateTo) url += `&fromDate=${dateFrom}&toDate=${dateTo}`;
  if (sortOrder) {
    url += `&sortOrder=${sortOrder}`;
  }
  if (status) url += `&status=${status}`;
  return HttpClient.get(url);
}

export function surveyDownload({
  taskId, 
}: {
  taskId: number; 
}) {
  return HttpClient.get(
    `${surveyor_download}?taskId=${taskId}`
  ,{ responseType: "blob" });
}

export function getSubmitteSurveyordDetails({
  choiceId,
  submittedId,
}: {
  choiceId: number;
  submittedId: number;
}) {
  return HttpClient.get(
    `${surveyor_submitted_choice_details}/${choiceId}/${submittedId}`
  );
}

export function downloadSubmittedResponse({
  choiceId,
  submittedId,
  format,
}: {
  choiceId: number;
  submittedId: number;
  format: string;
}) {
  let url = `${surveyor_submitted_choice_details}/${choiceId}/${submittedId}?format=${format}&download=true`;
  return HttpClient.get(url, { responseType: "blob" });
}

export function getSubmitteSurveyordAudio({
  id,
  type,
}: {
  id: number;
  type: string;
}) {
  return HttpClient.get(
    `${surveyor_submitted_choice_details}/${id}/recording?type${type}`
  );
}
export function getSurvyorFilterData() {
  return HttpClient.get(surveyors_filter);
}

export function downloadSurveyorResponses({
  occupation,
  state,
  category,
  personalIncome,
  dateFrom,
  dateTo,
  gender,
  education,
  choiceId,
  page,
  size,
  sortOrder,
  city
}: {
  occupation: number | null;
  education: number | null;
  state: number | null;
  category: number | null;
  personalIncome: number | null;
  dateFrom: string | null;
  dateTo: string | null;
  gender: string | null;
  choiceId: number | null;
  page: number | null;
  size: number | null;
  sortOrder: string | null;
  city: number | null;
}) {
  let url = `${surveyor_submitted_choice}/${choiceId}?download=true&format=excel&size=${size}&page=${page}`;
  if (gender) url += `&gender=${gender}`;
  if (occupation) url += `&occupation=${occupation}`;
  if (education) url += `&education=${education}`;
  if (state) url += `&state=${state}`;
  if (category) url += `&category=${category}`;
  if (personalIncome) url += `&personalIncome=${personalIncome}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if (city) url += `&city=${city}`;
  return HttpClient.get(url,  );
}

export function downloadAudioVdoOfSubmittedChoice({
  submittedId,
  choiceId,
  type,
}: {
  submittedId: number | null;
  choiceId: number | null;
  type: string | null;
}) {
  let url = `${surveyor_submitted_recording}/${choiceId}/${submittedId}/recording?type=${type}`;
  return HttpClient.get(url,{
    responseType:'blob'
  });
}
