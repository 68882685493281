import React from "react";
import MainLayout from "../../layouts/main/main_layout";
import ComingSoon from "../coming_soon/coming_soon";

export default function Settings() {
  return (
    <MainLayout>
      <ComingSoon />
    </MainLayout>
  );
}
