import { AxiosResponse } from "axios";

export function downloadFromBlob(
  response: AxiosResponse,
  defaultFileName: string
) {
  const contentDisposition = response.headers["content-disposition"];
  const regexp = /filename[^;=\n]*=["']?([^;\n"']+)/;
  let filename = regexp.exec(contentDisposition)?.[1]?.trim() || defaultFileName;
  
  // Clean up the filename to remove any unwanted characters
  filename = filename.replace(/[^\w\-. ]/g, '');
  
  console.log(filename, "filename");
  
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  let a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename!;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


export interface ChoicesEnumInterface {
  CREATE_CHOICE: string;
  VIEW_CHOICE: string;
  EDIT_CHOICE: string;
}

export const ChoicesEnum: ChoicesEnumInterface = {
  CREATE_CHOICE: "CreateChoice",
  VIEW_CHOICE: "ViewChoice",
  EDIT_CHOICE: "EditChoice",
};
