import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  InputAdornment,
  TextField as MuiTextField,
  Typography,
  Checkbox,
  CardActions,
  TablePagination,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getChoicesList } from "../../../../services/choices/choices_services";
import { Choices } from "../../../choices/model/choices";

interface MapChoicesPopupInterface {
  openModel: boolean;
  setOpenModal: (val: boolean) => void;
  setSelectedChoicesList: (item: Choices[] | null) => void;
  selectedChoicesList: Choices[];
}

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function MapChoicesPopup({
  openModel,
  setOpenModal,
  setSelectedChoicesList,
  selectedChoicesList,
}: MapChoicesPopupInterface) {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [__selectedChoicesList, __setSelectedChoicesList] = useState<
    Choices[] | null
  >([]);

  const isSelected = (item: Choices) => {
    // Check if the item is in the selected list
    return __selectedChoicesList?.some(
      (surveyList) => surveyList.id === item.id
    );
  };

  const handleCheckboxClick = (item: Choices) => {
    let newList: any = [];
    if (isSelected(item)) {
      newList = __selectedChoicesList?.filter(
        (surveyList) => surveyList.id !== item.id
      );
    } else {
      newList = [...__selectedChoicesList!, item];
    }

    __setSelectedChoicesList(newList);
  };

  const { data: choicesListData } = useQuery(
    ["choices-Listing", page, searchValue],
    async () => {
      let params = { page: page + 1, size: 8, keyword: searchValue };
      let response = await getChoicesList(params);
      return response?.data?.data || [];
    }
  );

  console.log(choicesListData?.list?.[0]?.languages, "language");

  useEffect(() => {
    if (openModel) {
      __setSelectedChoicesList(selectedChoicesList);
    }
  }, [openModel]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Dialog open={openModel} PaperProps={{ sx: { borderRadius: "15px" } }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          px: 2,
          width: 450,
          borderRadius: 10,
        }}
      >
        <CardHeader
          title=" Select choices to map to this surveyor"
          titleTypographyProps={{
            display: "flex",
            variant: "body1",
            fontWeight: 600,
            justifyContent: "center",
            mt: 1,
          }}
        ></CardHeader>

        <CardContent>
          <Box>
            <TextField
              value={searchValue}
              onChange={(e) => {
                setPage(0);
                setSearchValue(e.target.value);
              }}
              size="small"
              fullWidth
              sx={{
                backgroundColor: "#F5E6F5",
                borderRadius: 3,
                borderColor: "transparent",
              }}
              placeholder="Search by choice name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "text.primary" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ maxHeight: 300, overflow: "auto" }}>
            {choicesListData?.list?.map((item: any) => {
              return (
                <Box mt={2}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                  >
                    <Checkbox
                      onChange={() => handleCheckboxClick(item)}
                      sx={{ color: "primary.main" }}
                      checked={
                        __selectedChoicesList?.filter(
                          (item2) => item2.id === item.id
                        ).length !== 0
                          ? true
                          : false
                      }
                    />
                    <Typography
                      variant="body1"
                      fontWeight={"600"}
                      textTransform={"capitalize"}
                      ml={2}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box display={"flex"} ml={7} flexWrap="wrap">
                    <Typography color="primary.main" variant="body2">
                      Questions:
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary.main"
                      fontWeight={"600"}
                      ml={1}
                    >
                      {item.totalQuestions}
                    </Typography>
                    {item?.languages?.length !== 0 && (
                      <>
                        <Typography color="primary.main" variant="body2" ml={3}>
                          language:
                        </Typography>
                        <Box display={"flex"} flexDirection={"row"} mb={1}>
                          {item?.languages?.map((language: string) => {
                            return (
                              <Typography
                                color="primary.main"
                                variant="body2"
                                textTransform={"capitalize"}
                                ml={1}
                                mr={1}
                                fontWeight={"600"}
                              >
                                {language}
                              </Typography>
                            );
                          })}
                        </Box>
                      </>
                    )}
                    {item?.validFrom && (
                      <>
                        <Typography color="primary.main" variant="body2" mr={1}>
                          Valid from:{" "}
                          <span
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {item?.validFrom}
                          </span>
                        </Typography>
                      </>
                    )}
                    {item?.validTo && (
                      <>
                        <Typography color="primary.main" variant="body2" ml={1}>
                          Valid to:{" "}
                          <span
                            style={{
                              fontWeight: "600",
                            }}
                          >
                            {item?.validTo}
                          </span>
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Divider
                    sx={{
                      border: "1px dotted #7C797C",
                      opacity: 0.5,
                      mt: 2,
                      mx: 2,
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              mx: 2,
            }}
          >
            <TablePagination
              component="div"
              // labelRowsPerPage={customText}
              rowsPerPageOptions={[]}
              count={choicesListData?.total || []}
              rowsPerPage={8}
              page={page || 0}
              onPageChange={handleChangePage}
            />
          </Box>
        </CardActions>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button
            sx={{ minWidth: 200 }}
            variant="contained"
            onClick={() => setSelectedChoicesList(__selectedChoicesList)}
          >
            Map choices
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
}
