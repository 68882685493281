import HttpClient from "../../utils/http";
import {
  SchemeDetailsInputs,
  SchemeEligibilityInputs,
  SchemeOtherDetailsInputs,
} from "../../views/schemes/models/scheme_form";
import {
  addSchemeAPI,
  enquirySchemeList,
  enquirySchemeListById,
  schemeDetails,
  schemeEligibility,
  schemesBaseURL,
  schemes,
  schemesList,
  schemesMasters,
} from "../constants/api_endpoints";

export function getEnquiredSchemes({
  type,
  dateFrom,
  dateTo,
  page,
  size,
  schemeKeyword,
  state,
  category,
  sortOrder,
  districtId,
  gender,
  ageTo,
  ageFrom,
  keyword,
  governmentTypeId
}: {
  type: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  page: number | null;
  size: number | null;
  schemeKeyword: string | null;
  state: number | null;
  category: number | null;
  sortOrder: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
  districtId: number | null;
  keyword: string,
  governmentTypeId:number | null
}) {
  let url = enquirySchemeList + `?size=${size}&page=${page}&type=${type}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (schemeKeyword) url += `&name=${schemeKeyword}`;
  if (state) url += `&state=${state}`;
  if (category) url += `&category=${category}`;
  if (sortOrder) url += `&sortOrder=${sortOrder}`;
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (districtId !== null) {
    url += `&district=${districtId}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  if(keyword!==""){
    url += `&keyword=${keyword}`
  }
  if(governmentTypeId){
    url+=`&governmentTypeId=${governmentTypeId}`
  }


  return HttpClient.get(url);
}

export function getEnquiriesById(id: string, payload: Object) {
  return HttpClient.get(enquirySchemeListById + id, { params: payload });
}

export function downloadEnquiries({
  type,
  dateFrom,
  dateTo,
  page,
  size,
  schemeKeyword,
  state,
  category,
  format,
  sortOrder,
  gender,
  ageTo,
  ageFrom,
  districtId,
  governmentTypeId
}: {
  type: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  page: number | null;
  size: number | null;
  schemeKeyword: string | null;
  state: number | null;
  category: number | null;
  format: string;
  sortOrder: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
  districtId: number | null;
  governmentTypeId:number | null
}) {
  let url = enquirySchemeList + `?download=true&format=${format}&type=${type}&size=${size}&page=${page}`;
  if (dateFrom && dateTo) url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  if (schemeKeyword) url += `&name=${schemeKeyword}`;
  if (state) url += `&state=${state}`;
  if (category) url += `&category=${category}`;
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (districtId !== null) {
    url += `&district=${districtId}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  if(governmentTypeId){
    url+=`&governmentTypeId=${governmentTypeId}`
  }
  return HttpClient.get(url, { responseType: "blob" });
}

export function getSchemes(payload: Object) {
  return HttpClient.get(schemesList, { params: payload });
}

export function downloadEnquiry(id: string, format: string, type: string) {
  return HttpClient.get(
    `${enquirySchemeListById}${id}?format=${format}&type=${type}`,
    { responseType: "blob" }
  );
}

export function getSchemeMaster() {
  return HttpClient.get(schemesMasters);
}

export function addScheme(
  schemeDetails: SchemeDetailsInputs,
  otherDetails: SchemeOtherDetailsInputs,
  eligibilityDetails: SchemeEligibilityInputs
) {
  return HttpClient.post(addSchemeAPI, {
    details: {
      name: schemeDetails.schemeName,
      details: otherDetails.details,
      benefits: otherDetails.benefits,
      exclusions: otherDetails.exclusions,
      processDetails: otherDetails.applicationProcessDetails,
      processModeId: otherDetails.applicationProcess,
      eligibility: otherDetails.eligibility,
      documentsRequired: otherDetails.documentRequired,
      summary: otherDetails?.summary,
      governmentTypeId: schemeDetails.governmentTypeId,
      categoryIds: schemeDetails.typeOfCategory.map((item) => item.id),
    },
    eligibility: {
      genders: eligibilityDetails.genders,
      fromAge: eligibilityDetails.fromAge,
      toAge: eligibilityDetails.toAge,
      stateIds: eligibilityDetails.state.map((item) => item.id),
      residenceIds: eligibilityDetails.residence,
      casteIds: eligibilityDetails.caste,
      workingStatusIds: eligibilityDetails.workingStatus.map((item) => item.id),
      occupationIds: eligibilityDetails.occupation.map((item) => item.id),
      maritalStatusIds: eligibilityDetails.maritalStatus,
      familyIncomeIds: eligibilityDetails.familyIncome.map((item) => item.id),
      differentlyAbled: eligibilityDetails.differentlyAbled,
      minority: eligibilityDetails.minority,
      bplCategory: eligibilityDetails.bplCategory,
      student: eligibilityDetails.student,
      startsAt: new Date("2000-01-01").toUTCString(),
      endsAt: new Date("3000-01-01").toUTCString(),
    },
  });
}

export function getScheme(id: string, locale: string) {
  return HttpClient.get(`${schemeDetails}${id}`, {
    headers: {
      locale: locale,
    },
  });
}

export function updateSchemeDetails(
  id: string,
  locale: string,
  otherDetails: SchemeOtherDetailsInputs
) {
  return HttpClient.patch(
    `${schemeDetails}${id}`,
    {
      details: otherDetails.details,
      benefits: otherDetails.benefits,
      exclusions: otherDetails.exclusions,
      process_details: otherDetails.applicationProcessDetails,
      eligibility: otherDetails.eligibility,
      documents_required: otherDetails.documentRequired,
      process_mode_id: otherDetails.applicationProcess,
    },
    {
      headers: {
        locale: locale,
      },
    }
  );
}

export function updateSchemeEligibility(
  id: string,
  eligibility: SchemeEligibilityInputs
) {
  return HttpClient.patch(`${schemeEligibility}${id}`, {
    gender: eligibility.genders,
    from_age: eligibility.fromAge,
    to_age: eligibility.toAge,
    state_ids: eligibility.state.map((item) => item.id),
    residence_ids: eligibility.residence,
    caste_ids: eligibility.caste,
    working_status_ids: eligibility.workingStatus.map((item) => item.id),
    occupation_ids: eligibility.occupation.map((item) => item.id),
    marital_status_ids: eligibility.maritalStatus,
    family_income_ids: eligibility.familyIncome.map((item) => item.id),
    differently_abled: eligibility.differentlyAbled,
    minority: eligibility.minority,
    bpl_category: eligibility.bplCategory,
  });
}

export function deleteSchemes(id: number) {
  return HttpClient.delete(schemes + id);
}

export function sendNotification(
  body: { description: string; locale: string }[],
  schemeId: number
) {
  return HttpClient.post(`${schemes}notification/${schemeId}`, { list: body });
}
