import HttpClient from "../../utils/http";
import { create_user, modify_user } from "../constants/api_endpoints";


export function createUserService({
    name,
  gender ,
  mobile_no ,
//   preferred_language ,
  dob ,
  marital_status_id ,
  address_line1 ,
  address_line2,
  address_line3 ,
  landmark ,
  area ,
  district_id ,
  state_id ,
  durable_ownership_id ,
  areas_of_interest_id ,
  pincode ,
  education_id ,
  working_status_id ,
  occupation_id ,
  family_type_id ,
  house_ownership_id ,
  personal_income_id ,
  family_income_id,
  is_subscribed
//   lat ,
//   lon
  }: {
  name: string | null,
  gender: string | null,
  mobile_no: number | null,
  preferred_language: string | null,
  dob: string | null,
  marital_status_id: number | null,
  address_line1: string | null,
  address_line2: string | number | null,
  address_line3:string,
  landmark: string | null,
  area: string | null,
  district_id: number | null,
  state_id: number | null,
  durable_ownership_id: number[] | null,
  areas_of_interest_id:number[] | null,
  pincode: number | null,
  education_id: number | null,
  working_status_id:number | null,
  occupation_id: number | null,
  family_type_id:number | null,
  house_ownership_id: number | null,
  personal_income_id: number | null,
  family_income_id:number | null,
  lat: string | null,
  lon: string | null,
  is_subscribed:boolean;
  }) {
    // let url = `${create_user}/?type=${type}&page=${page}&size=${size}`;
    return HttpClient.post(create_user,{
        "name": name,
        "gender": gender,
        "mobileNo": mobile_no,
        "preferredLanguage": "en",
        "dob": dob,
        "maritalStatusId": marital_status_id,
        "addressLine1": address_line1,
        "addressLine2":address_line2,
        "addressLine3":address_line3,
        "landmark": landmark,
        "area": area,
        "districtId": district_id,
        "stateId": state_id,
        "durableOwnershipId": durable_ownership_id,
        "areasOfInterest":areas_of_interest_id,
        "pincode": pincode,
        "educationId":education_id,
        "workingStatusId": working_status_id,
        "occupationId": occupation_id,
        "familyTypeId":family_type_id,
        "houseOwnershipId": house_ownership_id,
        "personalIncomeId":personal_income_id,
        "familyIncomeId": family_income_id,
        "lat": "",
        "lon": "",
        "isSubscribed":is_subscribed
      }
      );
  }

  export function modifyUserService({
    name,
  gender ,
  mobile_no ,
//   preferred_language ,
  dob ,
  marital_status_id ,
  address_line1 ,
  address_line2,
  address_line3 ,
  landmark ,
  area ,
  district_id ,
  state_id ,
  durable_ownership_id ,
  areas_of_interest_id ,
  pincode ,
  education_id ,
  working_status_id ,
  occupation_id ,
  family_type_id ,
  house_ownership_id ,
  personal_income_id ,
  family_income_id,
  is_subscribed,
  id,
  systemComments
//   lat ,
//   lon
  }: {
  name: string | null,
  gender: string | null,
  mobile_no: number | null,
  preferred_language: string | null,
  dob: string | null,
  marital_status_id: number | null,
  address_line1: string | null,
  address_line2: string | number | null,
  address_line3:string,
  landmark: string | null,
  area: string | null,
  district_id: number | null,
  state_id: number | null,
  durable_ownership_id: number[] | null,
  areas_of_interest_id:number[] | null,
  pincode: number | null,
  education_id: number | null,
  working_status_id:number | null,
  occupation_id: number | null,
  family_type_id:number | null,
  house_ownership_id: number | null,
  personal_income_id: number | null,
  family_income_id:number | null,
  lat: string | null,
  lon: string | null,
  is_subscribed:boolean;
  id:number;
  systemComments:string
  }) {
    let url = `${modify_user}/${id}`;
    return HttpClient.patch(url,{
        "name": name,
        "gender": gender,
        "mobileNo": mobile_no?.toString(),
        "preferredLanguage": "en",
        "dob": dob,
        "maritalStatusId": marital_status_id,
        "addressLine1": address_line1,
        "addressLine2":address_line2,
        "addressLine3":address_line3,
        "landmark": landmark,
        "area": area,
        "districtId": district_id,
        "stateId": state_id,
        "durableOwnershipId": durable_ownership_id,
        "areasOfInterest":areas_of_interest_id,
        "pincode": pincode,
        "educationId":education_id,
        "workingStatusId": working_status_id,
        "occupationId": occupation_id,
        "familyTypeId":family_type_id,
        "houseOwnershipId": house_ownership_id,
        "personalIncomeId":personal_income_id,
        "familyIncomeId": family_income_id,
        "lat": "",
        "lon": "",
        "isSubscribed":is_subscribed,
        "systemComments":systemComments
      }
      );
  }