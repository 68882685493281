import { Box, Button, Checkbox, Grid, Typography } from '@mui/material'
import React from 'react';
import { SchemeMaster } from '../../../schemes/models/scheme_master';

interface props {
    onSubmit: () => void;
    stepperCount: number;
    setStepperCount: (val: number) => void;
    masterDataList: SchemeMaster;
    durableDetails: number[] | null;
    setDurableDetails: (val: number[]) => void;
    modifyUser?: boolean | undefined
}


export default function DurableDetailsComp({
    stepperCount,
    onSubmit,
    setStepperCount,
    masterDataList,
    durableDetails,
    setDurableDetails,
    modifyUser
}
    : props) {

    return (
        <Box>
            <Grid container mt={3} spacing={2}>
                {masterDataList.durableOwnership?.filter((data) => !data?.isAggregate).map((item) => {
                    return <Grid item md={6} lg={6} sm={12} xs={12}>
                        <Box
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{ border: '1px solid #7C797C', borderRadius: 3 }}
                            p={2}>
                            <Typography>
                                {item?.value}
                            </Typography>
                            <Checkbox
                                checked={!!durableDetails?.includes(item?.id)}
                                onClick={() => {
                                    if (durableDetails !== null) {
                                        if (durableDetails?.includes(item?.id)) {
                                            setDurableDetails(durableDetails.filter((i) => !item?.id))
                                        } else {
                                            setDurableDetails([...durableDetails!, item?.id])
                                        }
                                    } else {
                                        setDurableDetails([item?.id]);
                                    }
                                }} />
                        </Box>
                    </Grid>
                })}
                <Grid mt={2} display={'flex'} item md={12} lg={12} sm={12} xs={12} justifyContent={'end'}>
                    {!!!modifyUser && <Button
                        variant='outlined'
                        sx={{ minWidth: 150 }}
                        onClick={() => setStepperCount(stepperCount - 1)}
                    >
                        Back
                    </Button>}
                    <Button
                        variant='contained'
                        onClick={onSubmit}
                        sx={{ ml: 1, minWidth: 150 }}>
                        {!!!modifyUser ? "Proceed" : "Save Changes"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
