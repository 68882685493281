import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "અગાઉના",
   "pageNextText": "આગળ",
   "completeText": "પૂર્ણ",
   "previewText": "પૂર્વાવલોકન",
   "editText": "સંપાદિત કરો",
   "startSurveyText": "શરૂઆત",
   "otherItemText": "અન્ય (વર્ણન કરો)",
   "noneItemText": "કોઈ નહિ",
   "selectAllItemText": "બધા પસંદ કરો",
   "progressText": "{1} માંથી {0} પૃષ્ઠ",
   "indexText": "{1} માંથી {0}",
   "panelDynamicProgressText": "{1} માંથી {0}",
   "panelDynamicTabTextFormat": "પેનલ {panelIndex}",
   "questionsProgressText": "{0}/{1} પ્રશ્નોના જવાબ આપ્યા",
   "emptySurvey": "સર્વેક્ષણમાં કોઈપણ દૃશ્યમાન ઘટકો શામેલ નથી.",
   "completingSurvey": "સર્વેક્ષણ પૂર્ણ કરવા બદલ આભાર",
   "completingSurveyBefore": "અમારા રેકોર્ડ્સ દર્શાવે છે કે તમે આ સર્વેક્ષણ પૂર્ણ કરી લીધું છે.",
   "loadingSurvey": "સર્વેક્ષણ લોડ કરી રહ્યું છે...",
   "placeholder": "પસંદ કરો...",
   "ratingOptionsCaption": "પસંદ કરો...",
   "value": "મૂલ્ય",
   "requiredError": "પ્રતિભાવ જરૂરી.",
   "requiredErrorInPanel": "પ્રતિસાદ આવશ્યક છે: ઓછામાં ઓછા એક પ્રશ્નનો જવાબ આપો.",
   "requiredInAllRowsError": "પ્રતિસાદ જરૂરી: તમામ પંક્તિઓમાં પ્રશ્નોના જવાબ આપો.",
   "numericError": "મૂલ્ય સંખ્યાત્મક હોવું જોઈએ.",
   "minError": "મૂલ્ય {0} કરતાં ઓછું ન હોવું જોઈએ",
   "maxError": "મૂલ્ય {0} કરતા વધારે ન હોવું જોઈએ",
   "textMinLength": "કૃપા કરીને ઓછામાં ઓછા {0} અક્ષર(અક્ષરો) દાખલ કરો.",
   "textMaxLength": "કૃપા કરીને {0} અક્ષર(અક્ષરો) કરતાં વધુ દાખલ કરશો નહીં.",
   "textMinMaxLength": "કૃપા કરીને ઓછામાં ઓછા {0} અને {1} કરતાં વધુ અક્ષરો દાખલ કરો.",
   "minRowCountError": "કૃપા કરીને ઓછામાં ઓછી {0} પંક્તિ(ઓ) ભરો.",
   "minSelectError": "કૃપા કરીને ઓછામાં ઓછા {0} વિકલ્પ(ઓ) પસંદ કરો.",
   "maxSelectError": "કૃપા કરીને {0} વિકલ્પ(ઓ) કરતાં વધુ પસંદ કરશો નહીં.",
   "numericMinMax": "&#39;{0}&#39; ઓછામાં ઓછું {1} અને વધુમાં વધુ {2} હોવું જોઈએ",
   "numericMin": "&#39;{0}&#39; ઓછામાં ઓછું {1} હોવું જોઈએ",
   "numericMax": "&#39;{0}&#39; વધુમાં વધુ {1} હોવો જોઈએ",
   "invalidEmail": "કૃપા કરીને માન્ય ઈ-મેલ સરનામું દાખલ કરો.",
   "invalidExpression": "અભિવ્યક્તિ: {0} એ &#39;true&#39; પરત કરવું જોઈએ.",
   "urlRequestError": "વિનંતીએ ભૂલ &#39;{0}&#39; પરત કરી. {1}",
   "urlGetChoicesError": "વિનંતીએ ખાલી ડેટા પરત કર્યો અથવા &#39;પાથ&#39; ગુણધર્મ ખોટો છે",
   "exceedMaxSize": "ફાઇલનું કદ {0} થી વધુ ન હોવું જોઈએ.",
   "noUploadFilesHandler": "ફાઇલો અપલોડ કરી શકાતી નથી. કૃપા કરીને &#39;onUploadFiles&#39; ઇવેન્ટ માટે હેન્ડલર ઉમેરો.",
   "otherRequiredError": "પ્રતિસાદ આવશ્યક છે: બીજું મૂલ્ય દાખલ કરો.",
   "uploadingFile": "તમારી ફાઇલ અપલોડ થઈ રહી છે. કૃપા કરીને થોડીવાર રાહ જુઓ અને ફરી પ્રયાસ કરો.",
   "loadingFile": "લોડ કરી રહ્યું છે...",
   "chooseFile": "ફાઇલ(ઓ) પસંદ કરો...",
   "noFileChosen": "કોઈ ફાઇલ પસંદ કરી નથી",
   "filePlaceholder": "ફાઇલને અહીં ખેંચો અને છોડો અથવા અપલોડ કરવા માટે ફાઇલ પસંદ કરવા માટે નીચેના બટન પર ક્લિક કરો.",
   "confirmDelete": "શું તમે રેકોર્ડ કાઢી નાખવા માંગો છો?",
   "keyDuplicationError": "આ મૂલ્ય અનન્ય હોવું જોઈએ.",
   "addColumn": "કૉલમ ઉમેરો",
   "addRow": "પંક્તિ ઉમેરો",
   "removeRow": "દૂર કરો",
   "emptyRowsText": "ત્યાં કોઈ પંક્તિઓ નથી.",
   "addPanel": "નવો ઉમેરો",
   "removePanel": "દૂર કરો",
   "showDetails": "વિગતો બતાવો",
   "hideDetails": "વિગતો છુપાવો",
   "choices_Item": "વસ્તુ",
   "matrix_column": "કૉલમ",
   "matrix_row": "પંક્તિ",
   "multipletext_itemname": "ટેક્સ્ટ",
   "savingData": "પરિણામો સર્વર પર સાચવવામાં આવી રહ્યાં છે...",
   "savingDataError": "એક ભૂલ આવી અને અમે પરિણામો સાચવી શક્યાં નથી.",
   "savingDataSuccess": "પરિણામો સફળતાપૂર્વક સાચવવામાં આવ્યા હતા!",
   "saveAgainButton": "ફરીથી પ્રયત્ન કરો",
   "timerMin": "મિનિટ",
   "timerSec": "સેકન્ડ",
   "timerSpentAll": "તમે આ પૃષ્ઠ પર {0} અને કુલ {1} ખર્ચ્યા છે.",
   "timerSpentPage": "તમે આ પૃષ્ઠ પર {0} ખર્ચ્યા છે.",
   "timerSpentSurvey": "તમે કુલ {0} ખર્ચ્યા છે.",
   "timerLimitAll": "તમે આ પૃષ્ઠ પર {1} માંથી {0} અને કુલ {3} માંથી {2} ખર્ચ્યા છે.",
   "timerLimitPage": "તમે આ પૃષ્ઠ પર {1} માંથી {0} ખર્ચ્યા છે.",
   "timerLimitSurvey": "તમે કુલ {1}માંથી {0} ખર્ચ્યા છે.",
   "clearCaption": "ચોખ્ખુ",
   "signaturePlaceHolder": "અહીં સહી કરો",
   "chooseFileCaption": "ફાઇલ પસંદ કરો",
   "takePhotoCaption": "ફોટો પાડ",
   "photoPlaceholder": "કેમેરાનો ઉપયોગ કરીને ફોટો લેવા માટે નીચેના બટન પર ક્લિક કરો.",
   "fileOrPhotoPlaceholder": "કેમેરાનો ઉપયોગ કરીને ફોટો અપલોડ કરવા અથવા લેવા માટે ખેંચો અને છોડો અથવા ફાઇલ પસંદ કરો.",
   "replaceFileCaption": "ફાઇલ બદલો",
   "removeFileCaption": "આ ફાઈલ દૂર કરો",
   "booleanCheckedLabel": "હા",
   "booleanUncheckedLabel": "ના",
   "confirmRemoveFile": "શું તમે ખરેખર આ ફાઇલને દૂર કરવા માંગો છો: {0}?",
   "confirmRemoveAllFiles": "શું તમે ખરેખર બધી ફાઇલો દૂર કરવા માંગો છો?",
   "questionTitlePatternText": "પ્રશ્ન શીર્ષક",
   "modalCancelButtonText": "રદ કરો",
   "modalApplyButtonText": "અરજી કરો",
   "filterStringPlaceholder": "શોધવા માટે ટાઇપ કરો...",
   "emptyMessage": "પ્રદર્શિત કરવા માટે કોઈ ડેટા નથી",
   "noEntriesText": "હજી સુધી કોઈ એન્ટ્રી નથી. નવી એન્ટ્રી ઉમેરવા માટે નીચેના બટન પર ક્લિક કરો.",
   "noEntriesReadonlyText": "ત્યાં કોઈ પ્રવેશો નથી.",
   "more": "વધુ",
   "tagboxDoneButtonCaption": "બરાબર",
   "selectToRankEmptyRankedAreaText": "બધી પસંદગીઓ ક્રમાંકિત છે",
   "selectToRankEmptyUnrankedAreaText": "પસંદગીઓને ક્રમ આપવા માટે તેમને અહીં ખેંચો અને છોડો",
   "ok": "બરાબર",
   "cancel": "રદ કરો"
};


surveyLocalization.locales["gu"] = dict;