import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Value } from "../../models/scheme_master";
import useScheme from "../../providers/scheme_provider";
import { SchemeDetailsInputs } from "../../models/scheme_form";
import { useNavigate } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SchemeDetails({
  schemeDetails,
  onSubmit: _onSubmit,
}: {
  schemeDetails: SchemeDetailsInputs;
  onSubmit: (data: SchemeDetailsInputs) => void;
}) {
  const navigate = useNavigate();
  const { schemeMaster } = useScheme();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemeDetailsInputs>({
    defaultValues: schemeDetails,
  });

  const onSubmit: SubmitHandler<SchemeDetailsInputs> = (data) =>
    _onSubmit(data);

  return (
    <Box p={2}>
      <Box mt={2} mb={2}>
        <Typography variant="body1" fontWeight={700}>
          Scheme Details
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <TextField
              fullWidth
              label="Enter Scheme Name*"
              error={!!errors.schemeName}
              helperText={errors.schemeName?.message}
              {...register("schemeName", { required: "Enter the scheme name" })}
            ></TextField>
          </Grid>
          <Grid item md={6}>
            <TextField
              select
              fullWidth
              label="Type of Government*"
              defaultValue={null}
              error={!!errors.governmentTypeId}
              helperText={errors.governmentTypeId?.message}
              {...register("governmentTypeId", {
                required: "Select the type of govt.",
              })}
            >
              {schemeMaster?.schemeGovernment?.map((govt) => (
                <MenuItem value={govt.id}>{govt.value}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={12}>
            <Controller
              name="typeOfCategory"
              control={control}
              rules={{ required: "Select category" }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  options={schemeMaster?.schemeCategory || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, value) => onChange(value)}
                  value={value}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.value}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.typeOfCategory}
                      helperText={errors.typeOfCategory?.message}
                      label="Type of Category*"
                      fullWidth
                      placeholder="Select type of categories"
                    />
                  )}
                />
              )}
            ></Controller>
          </Grid>
          {/* <Grid item md={12}>
            <FormControlLabel
              label="Mark scheme as top scheme"
              control={<Checkbox {...register("topScheme")} />}
            />
          </Grid> */}
        </Grid>

        <Box mt={2} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            sx={{ pl: 6, pr: 6 }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button type="submit" variant="contained" sx={{ pl: 6, pr: 6 }}>
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
