export const baseUrl = "https://api-uat.axismyindia.in/";

export const loginUrl = `${baseUrl}v1/admin/users/auth/login/`;

export const logoutUrl = `${baseUrl}v1/admin/users/auth/logout/`;

export const admin_report =
  "https://lookerstudio.google.com/u/0/reporting/08e719c8-1a3f-4ac6-bd54-8c27eb1d0c0e/page/p_a20032j29c/edit";
export const respondent_report =
  "https://lookerstudio.google.com/reporting/aefd5e17-88f7-4d83-9240-dc1ed91213f6/page/p_6kqgzkajbd";
export const schemes_report =
  "https://lookerstudio.google.com/u/0/reporting/0e3e9ab2-7cc4-4aab-be8f-acff0298eac9/page/p_iim7dyc58c";
export const grievance_report =
  "https://lookerstudio.google.com/reporting/109b3999-8efe-42d3-878a-947c9ae670f8/page/p_5mliqsc68c";
export const service_report =
  "https://lookerstudio.google.com/u/0/reporting/749a5ebe-d28c-4862-96a6-5ec9da460026/page/p_t0obgp2qbd";
export const jobs_reports =
  "https://lookerstudio.google.com/u/0/reporting/29bf6a97-cf9f-419e-8cc8-1f24069bf5e9/page/p_5jpljoz0bd";
export const rewards_reports =
  "https://lookerstudio.google.com/u/0/reporting/742ec0d9-a3d1-4c87-9d38-3c73113bc696/page/EgOlD";
