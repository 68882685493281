import { Box, Typography } from "@mui/material";
import UndrawAccessDenied from "../../assets/background/undraw_cancel_re_pkdm.svg";
import React from "react";

export default function AccessDenied() {
  return (
    <Box
      width="inherit"
      height="inherit"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img src={UndrawAccessDenied} alt="" width={200} height={200}></img>

      <Box p={4}>
        <Typography variant="body1" align="center">
          Access Denied
        </Typography>
        <Typography variant="body2" align="center">
          Kindly Get in touch with the Super Admin to get the Access
        </Typography>
      </Box>
    </Box>
  );
}
