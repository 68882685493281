import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RewardsListingLabels } from "../../rewards";
import { RewardsListingModel } from "../../model/rewards_listing";

interface DataGridInterface {
  labels: RewardsListingLabels[];
  data: RewardsListingModel[];
  count: number;
  page: number;
  setPage: (val: number) => void;
}

export default function DatGridRewards({
  labels,
  data,
  page,
  setPage,
  count,
}: DataGridInterface) {
  const navigate = useNavigate();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid item flex={1}>
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={(e) => {
                    navigate(`/rewards/rewardsDetails/${item?.userId}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                      // backgroundColor: "#FAEDEA",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    {gridTypography({ text: index + 1 })}
                    {gridTypography({ text: item?.name })}
                    {gridTypography({ text: item?.mobileNo })}
                    {gridTypography({ text: item.dob })}
                    {gridTypography({ text: item?.gender })}
                    {gridTypography({ text: item?.maritalStatus })}
                    {gridTypography({ text: item?.state })}
                    {gridTypography({ text: item?.district })}
                    {gridTypography({ text: item?.points })}
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
