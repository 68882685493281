import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface ChoiceLayoutProps {
  currentTab: number;
  setCurrentTab: (val: number) => void;
}

export default function ChoicesLayout({
  children,
  currentTab,
  setCurrentTab,
}: PropsWithChildren<ChoiceLayoutProps>) {
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
  };

  return (
    <Grid container mt={2}>
      <Grid item md={12} lg={12} sm={12}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            minHeight: 600,
            mb: 2,
            p: 2,
          }}
        >
          <Box flexDirection="row" display="flex" alignItems="center">
            <IconButton onClick={() => navigate("/reports", { replace: true })}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" fontWeight="600">
              Choice Responses
            </Typography>
          </Box>
          <Tabs
            value={currentTab}
            aria-label="basic tabs example"
            onChange={handleChange}
            sx={{
              "MuiTabs-root css-112qdbf-MuiTabs-root": {
                borderColor: "#F5E6F5",
              },
            }}
          >
            <Tab
              label="Choices"
              sx={{
                textTransform: "capitalize",
                color: "#1B001B",
              }}
            />
            {/* <Tab
              label="Responses"
              sx={{
                textTransform: "capitalize",
                color: "#1B001B",
              }}
            />
            <Tab
              label="Family responses"
              sx={{ textTransform: "capitalize", color: "#1B001B" }}
            /> */}
          </Tabs>
          <Box sx={{ borderBottom: 1, borderColor: "#F5E6F5" }}></Box>
          {children}
        </Card>
      </Grid>
    </Grid>
  );
}
