import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { EnquiryLabels } from "../../../schemes/enquiries/enquiries_list";
import { useNavigate } from "react-router-dom";
import ArrowRight from "../../../../assets/arrow_right.svg";
import { DeleteOutlined } from "@mui/icons-material";
import { AppUsersList } from "../../model/app_users_list_model";
import useUser from "../../../../providers/user/user_provider";

interface DataGridInterface {
  labels: EnquiryLabels[];
  data: AppUsersList[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  setBlockAppUserDialog: (val: boolean) => void;
  blockAppUserDialog: boolean;
  setSelectedUser: (val: AppUsersList) => void;
  setDeletePopUp?: (val: boolean) => void;
}

export default function DatGridUsers({
  labels,
  data,
  page,
  setPage,
  count,
  setBlockAppUserDialog,
  blockAppUserDialog,
  setSelectedUser,
  setDeletePopUp,
}: DataGridInterface) {
  const { hasPermission } = useUser();
  const navigate = useNavigate();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;

  function cardItem({ text }: { text: string }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Grid container direction="row" p={2}>
          {labels.map((item, index) => (
            <Grid item flex={index === labels?.length - 1 ? 1.5 : 1}>
              <Typography color="#91278F" fontSize={14}>
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data.map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    navigate(`/users/userDetails/${item.id}`);
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: "#FAEDEA",
                    },
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    {cardItem({ text: item?.id?.toString() })}
                    {cardItem({ text: item?.name?.toString() })}
                    {cardItem({ text: item?.mobileNumber })}
                    {cardItem({ text: item?.dob })}
                    {cardItem({ text: item?.gender })}
                    {cardItem({ text: item?.maritalStatus })}
                    {cardItem({ text: item?.state })}

                    {cardItem({ text: item?.district })}
                    {cardItem({ text: item?.isSubscribed ? "Yes" : "No" })}

                    <Box
                      display="flex"
                      flex={1.5}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Button
                        disabled={!hasPermission("Users", "Update")}
                        variant="contained"
                        onClick={(e) => {
                          e.stopPropagation();
                          setBlockAppUserDialog(!blockAppUserDialog);
                          setSelectedUser(item);
                        }}
                        sx={{
                          bgcolor: item?.isBlocked ? "#1E6446" : "primary.main",
                          minWidth: 130,
                        }}
                      >
                        {item?.isBlocked ? "Unblock user" : "Block User"}
                      </Button>
                      <IconButton
                        disabled={!hasPermission("Users", "Delete")}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeletePopUp?.(true);
                          setSelectedUser(item);
                        }}
                        sx={{
                          opacity: hasPermission("Users", "Delete") ? 1 : 0.5,
                        }}
                      >
                        <DeleteOutlined color={"primary"} />
                      </IconButton>

                      <img src={ArrowRight} alt="" height={15} width={15} />
                    </Box>
                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mx: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
