import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useQuery } from "react-query";
import {
  getArea,
  getBrand,
  getNeedsFilters,
} from "../../../../services/needs/needs_service";
import { NeedFilters, Option } from "../../model/needs_filters";
import { useEffect, useState } from "react";
import { getDistrictMasterData } from "../../../../services/master/master_service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

let statusList = [
  { _id: 1, name: "Submitted" },
  { _id: 2, name: "In Progress" },
  { _id: 3, name: "Closed" },
];

export default function NeedFiltersModel({
  open,
  onDialogClose,
  concerningToId,
  subcategoryId,
  areasId,
  status,
  cityId,
  setCityId,
  setStatus,
  setConcerningToId,
  setSubcategoryId,
  setAreasId,
  stateId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  resetFilters,
  brandId,
  setBrandId,
  setStateId,
  states,
  setDistrictId,
  districtId
}: {
  open: boolean;
  onDialogClose: () => void;
  concerningToId: number | null;
  subcategoryId: number | null;
  areasId: number | null;
  status: number | null;
  cityId: number | null;
  stateId: number | null;
  setCityId: (value: number | null) => void;
  setConcerningToId: (value: number | null) => void;
  setSubcategoryId: (value: number | null) => void;
  setAreasId: (value: number | null) => void;
  setStatus: (value: number | null) => void;
  startDate: string | null;
  setStartDate: (value: string | null) => void;
  endDate: string | null;
  setEndDate: (value: string | null) => void;
  setStateId: (val: number | null) => void;
  resetFilters: () => void;
  brandId: number | null;
  setBrandId: (val: number | null) => void;
  states: { id: number; value: string }[];
  setDistrictId: (val: number | null) => void;
  districtId: number | null;
}) {
  const { data: needsFilters } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  const [_concerningToId, _setConcerningToId] = useState<number | null>(
    concerningToId
  );
  const [_subcategoryId, _setSubcategoryId] = useState<number | null>(
    subcategoryId
  );
  const [_areasId, _setAreasId] = useState<number | null>(areasId);
  const [_status, _setStatus] = useState<number | null>(status);
  const [_cityId, _setCityId] = useState<number | null>(cityId);
  const [_stateId, _setStateId] = useState<number | null>(stateId);
  const [_startDate, _setStartDate] = useState<string | null>(startDate);
  const [_endDate, _setEndDate] = useState<string | null>(endDate);
  const [areas, setAreas] = useState([]);
  const [_brandId, _setBrandId] = useState<number | null>(null);
  const [brands, setBrands] = useState([]);
  const [_district, _setDistrict] = useState<number | null>(districtId);

  const { data: district } = useQuery(["districtList", _stateId], async () => {
    if (_stateId) {
      let response = await getDistrictMasterData(_stateId);
      return response?.data?.data?.list;
    }
  });

  const getAreaApiCall = () => {
    getArea({
      categoryId: _concerningToId,
      subcategoryId: _subcategoryId,
    }).then((res) => {
      setAreas(res.data.data.list);
    });
  };

  const getBrandsApiCall = () => {
    getBrand({
      categoryId: _concerningToId,
      subcategoryId: _subcategoryId,
    }).then((res) => {
      setBrands(res.data.data.list);
    });
  };

  useEffect(() => {
    if (_endDate != null && _startDate != null && _startDate > _endDate)
      _setEndDate(null);
  }, [_startDate]);

  useEffect(() => {
    if (_subcategoryId != null) {
      getAreaApiCall();
      getBrandsApiCall();
    }
  }, [_subcategoryId]);

  function submit() {
    setConcerningToId(_concerningToId);
    setSubcategoryId(_subcategoryId);
    setAreasId(_areasId);
    setStatus(_status);
    setCityId(_cityId);
    setStartDate(_startDate);
    setEndDate(_endDate);
    setBrandId(_brandId);
    setStateId(_stateId);
    setDistrictId(_district)
    onDialogClose();
  }

  function _chipsOption({
    title,
    options,
    selectedId,
    onSelect,
  }: {
    title: string;
    options: { id: number; name: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection="row" flexWrap={"wrap"}>
          {options.map((option) => (
            <Chip
              variant={selectedId === option.id ? "filled" : "outlined"}
              color="primary"
              label={option.name}
              clickable
              onClick={() => {
                onSelect(selectedId === option.id ? null : option.id);
              }}
              sx={{ mr: 1, mb: 1 }}
            />
          ))}
        </Box>
      </Box>
    );
  }

  function _dropdownOption({
    title,
    placeholder,
    options,
    selectedId,
    onSelect,
    disabled,
  }: {
    title: string;
    placeholder: string;
    options: { id: number; name: string }[];
    selectedId: number | null;
    onSelect: (id: number | null) => void;
    disabled?: boolean;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex" flexDirection="row">
          <Autocomplete
            disabled={disabled}
            fullWidth
            // multiple
            value={options.find((option) => selectedId == option.id)}
            onChange={(e, value) => {
              onSelect(value?.id || null);
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            // defaultValue={[{ title: "The Godfather", year: 1972 }]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={placeholder}
                placeholder={placeholder}
              />
            )}
          />
        </Box>
      </Box>
    );
  }

  function _dateRange({
    title,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  }: {
    title: string;
    startDate: string | null;
    setStartDate: (value: string | null) => void;
    endDate: string | null;
    setEndDate: (value: string | null) => void;
  }) {
    return (
      <Box pt={2} pb={1}>
        <Typography fontSize={16} fontWeight="600">
          {title}
        </Typography>
        <Box pt={1} pb={2} display="flex">
          <DatePicker
            label="Start Date"
            format="DD-MM-YYYY"
            disableFuture
            value={startDate ? dayjs(startDate, "DD-MM-YYYY") : null}
            onChange={(value: Dayjs | null) => {
              setStartDate(value?.format("DD-MM-YYYY") || null);
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
          <Box p={1}></Box>
          <DatePicker
            label="End Date"
            format="DD-MM-YYYY"
            disableFuture
            value={endDate ? dayjs(endDate, "DD-MM-YYYY") : null}
            onChange={(value: Dayjs | null) => {
              setEndDate(value?.format("DD-MM-YYYY") || null);
            }}
            slotProps={{ desktopPaper: { elevation: 3 } }}
            minDate={dayjs(startDate, "DD-MM-YYYY")}
            sx={{
              "& .MuiSvgIcon-root": {
                fill: "#990099", // Replace with your desired color
              },
            }}
          />
        </Box>
      </Box>
    );
  }

  function getSubCategories(): Option[] {
    return _concerningToId
      ? needsFilters?.categories?.find(
        (category) => category.category._id === _concerningToId
      )?.subcategory || []
      : [];
  }

  function getAreas(): Option[] {
    return _concerningToId
      ? needsFilters?.categories?.find(
        (category) => category.category._id === _concerningToId
      )?.areas || []
      : [];
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style} display="flex" flexDirection="column">
          <Box
            pb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={18}
              fontWeight={600}
            >
              Filter Needs
            </Typography>
            <IconButton onClick={onDialogClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            pb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={16}
              fontWeight={600}
            >
              Filter By
            </Typography>
            <Typography
              color="primary.main"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={resetFilters}
            >
              Reset
            </Typography>
          </Box>
          <Divider />
          <Box flex={1} overflow="auto scroll">
            {_chipsOption({
              title: "Category",
              selectedId: _concerningToId,
              options:
                needsFilters?.categories?.map((cat) => {
                  return { id: cat.category._id, name: cat.category.name };
                }) || [],
              onSelect: (id) => {
                _setConcerningToId(id);
                _setSubcategoryId(null);
                _setAreasId(null);
                setAreas([]);
              },
            })}

            {_concerningToId && getSubCategories().length > 0
              ? _chipsOption({
                title: _concerningToId === 2 ? "Company" : "Sub Category",
                selectedId: _subcategoryId,
                options: getSubCategories()?.map((option) => {
                  return { id: option._id, name: option.name };
                }),
                onSelect: (id) => _setSubcategoryId(id),
              })
              : null}

            {_dropdownOption({
              disabled: areas?.length === 0,
              title: "Areas",
              options:
                areas.map((option: { id: number; value: string }) => {
                  return { id: option.id, name: option.value };
                }) || [],
              placeholder: "Select Areas",
              selectedId: _areasId,
              onSelect: (a) => {
                _setAreasId(a);
              },
            })}
            {_concerningToId === 2 &&
              _dropdownOption({
                disabled: areas?.length === 0,
                title: "Brands",
                options:
                  brands.map((option: { id: number; value: string }) => {
                    return { id: option.id, name: option.value };
                  }) || [],
                placeholder: "Select Brands",
                selectedId: _brandId,
                onSelect: (a) => {
                  _setBrandId(a);
                },
              })}

            <Divider />
            {_dropdownOption({
              title: "State",
              placeholder: "Select State",
              selectedId: _stateId,
              onSelect: (id) => {
                _setStateId(id);
              },
              options:
                states?.map((city) => {
                  return { id: city.id, name: city.value };
                }) || [],
            })}
            {_dropdownOption({
              title: "District",
              placeholder: "Search for District",
              options: district?.map((item: { id: number, value: string }) => {
                return { id: item?.id, name: item?.value }
              }) || [],
              selectedId: _district,
              onSelect: (id) => {
                _setDistrict(id);
              },
            })}

            <Divider />
            {_dropdownOption({
              title: "City",
              placeholder: "Select City",
              selectedId: _cityId,
              onSelect: (id) => {
                _setCityId(id);
              },
              options:
                needsFilters?.cityList.map((city) => {
                  return { id: city._id, name: city.name };
                }) || [],

            })}

            {_chipsOption({
              title: "Status",
              selectedId: _status,
              options: statusList.map((status) => {
                return { id: status._id, name: status.name };
              }),
              onSelect: (id) => {
                _setStatus(id);
              },
            })}
            {_dateRange({
              title: "Date Range",
              setEndDate: _setEndDate,
              setStartDate: _setStartDate,
              startDate: _startDate,
              endDate: _endDate,
            })}
          </Box>
          <Divider />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Box onClick={onDialogClose}>
              <Typography
                variant="button"
                color="#91278F"
                sx={{
                  borderBottom: "1px solid #990099",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
              >
                Close
              </Typography>
            </Box>
            <Button variant="contained" onClick={submit}>
              Apply Filters
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
