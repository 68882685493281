import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { PointsStatement } from "../../rewards/model/points_statement";
import dayjs from "dayjs";

export default function RewardStatement({
  pointHistory,
}: {
  pointHistory: PointsStatement;
}) {
  const originalDate = dayjs?.(pointHistory?.updatedAt);
  const formattedDate = originalDate?.format?.("DD MMM YYYY HH:MM A");
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        mb={2}
      >
        <Box>
          <Typography variant="subtitle1">
            {pointHistory.description}
          </Typography>
          <Typography variant="subtitle2" color="#7C797C" mt={0.5}>
            {formattedDate}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: pointHistory.redemption ? "#D14C2E" : "#1E6446",
            }}
          >
            {pointHistory.redemption && "-"}
            {pointHistory?.points}
          </Typography>
          <Typography variant="subtitle2" color="#7C797C">
            {"Reward Points"}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: "#F5E6F5", my: 3 }} />
    </Box>
  );
}
