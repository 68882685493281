import { Box, Chip, Typography } from "@mui/material";
import React from "react";

export default function YesOrNoChips({
  value,
  onChange,
  error,
  helperText,
  disabled,
}: {
  value: boolean | undefined | null;
  onChange: (value: boolean | null) => void;
  error: boolean;
  helperText: string | undefined;
  disabled?: boolean;
}) {
  return (
    <Box>
      <Box mt={1} display="flex" flexWrap={"wrap"}>
        <Chip
          disabled={disabled}
          label={"Yes"}
          variant={value === true ? "filled" : "outlined"}
          color="primary"
          clickable
          onClick={() => {
            if (value) {
              onChange(null);
            } else {
              onChange(true);
            }
          }}
        ></Chip>
        <Box pr={1}></Box>
        <Chip
          disabled={disabled}
          label={"No"}
          variant={value === false ? "filled" : "outlined"}
          color="primary"
          clickable
          onClick={() => {
            if (value === false) {
              onChange(null);
            } else {
              onChange(false);
            }
          }}
        ></Chip>
      </Box>
      {error && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
