import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";
import React from "react";

interface StepperInterface {
  stepperValues: { id: number; label: string }[];
  completedSteps: number;
}

export default function Stepper({
  stepperValues,
  completedSteps,
}: StepperInterface) {
  console.log(completedSteps);

  return (
    <Box display="flex" flexDirection="row">
      {stepperValues?.map((item, index) => {
        return (
          <Box display="flex" flexDirection="row">
            <Typography>{item?.id}.</Typography>
            <Typography
              ml={2}
              mr={4}
              color={completedSteps === 2 ? "#1E6446" : "#91278F"}
            >
              {item.label}
            </Typography>
            {index !== stepperValues.length - 1 && (
              <Box mr={4}>
                <ArrowForwardIosIcon sx={{ height: 16, width: 16 }} />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
