import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import AttachmentIcon from "../../../assets/attachment.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack, DownloadOutlined } from "@mui/icons-material";
import MainLayout from "../../../layouts/main/main_layout";
import PdfIcon from "../../../assets/pdf_icon.svg";
import XlsIcon from "../../../assets/xls_icon.svg";
import { useQuery } from "react-query";
import {
  downloadEnquiry,
  getEnquiriesById,
} from "../../../services/schemes/schemes";
import { downloadFromBlob } from "../../../utils/utils";
import Loading from "../../../components/loading/loading";
import { purpleLight } from "../../../theme/colors";

export default function FamilyEnquiryDetails() {
  let { id } = useParams();
  let navigate = useNavigate();

  const { data: schemeDetails, isLoading } = useQuery(
    ["familyEnquiry"],
    async () => {
      let payload = {
        type: "family",
      };
      let response = await getEnquiriesById(id!, payload);
      return response.data.data;
    }
  );

  const downloadEnquiryApiCall = (format: string) => {
    downloadEnquiry(id!, format, "family")
      .then((res) => {
        let extension = format === "pdf" ? ".pdf" : ".xlsx";
        downloadFromBlob(res, extension);
      })
      .catch((e) => {});
  };

  function divider() {
    return (
      <Divider
        sx={{
          display: "flex",
          opacity: 0.5,
          borderBottom: "1px dotted #7C797C",
        }}
      />
    );
  }

  const itemList = (
    title1?: string,
    subtitle1?: string,
    title2?: string,
    subtitle2?: string
  ) => {
    return (
      <Grid display={"flex"} flexDirection={"row"} container mt={3}>
        {title1 && (
          <Grid item md={6} sm={6} xs={6} lg={8} xl={6}>
            <Box>
              <Typography mb={1} variant="subtitle2" sx={{ color: "#91278F" }}>
                {title1}
              </Typography>
              <Typography>{subtitle1 ? subtitle1 : "-"}</Typography>
            </Box>
          </Grid>
        )}
        {title2 && (
          <Grid item md={6} sm={6} xs={6} lg={4} xl={6}>
            <Box>
              <Typography mb={1} variant="subtitle2" sx={{ color: "#91278F" }}>
                {title2}
              </Typography>
              <Typography>{subtitle2 ? subtitle2 : "-"}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }

  return (
    <MainLayout>
      <Grid container mt={4}>
        <Grid item lg={8} md={12} sm={12} xs={12} xl={8}>
          {isLoading ? (
            <Card
              sx={{
                display: "flex",
                minHeight: 500,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Loading />
              </Box>
            </Card>
          ) : (
            <Card sx={{ px: 2, pb: 2 }}>
              <Box display={"flex"} alignItems={"center"} py={2}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight={"600"} m={1}>
                  Family Enquiry Details
                </Typography>
              </Box>
              {divider()}
              {itemList(
                "Reference number",
                schemeDetails?.enquiryId,
                "Primary user Full Name",
                schemeDetails?.userName
              )}
              {itemList(
                "Primary user Contact Number",
                schemeDetails?.mobileNumber
              )}
              {itemList(
                "Relationship with primary user",
                schemeDetails?.relationship,
                "Family member Name",
                schemeDetails?.memberName
              )}
              {itemList(
                "Family member Date of birth",
                schemeDetails?.memberDob,
                "Family member gender",
                schemeDetails?.memberGender
              )}
              {itemList(
                "Family member Occupation",
                schemeDetails?.memberOccupation,
                "Family member Contact number",
                schemeDetails?.memberMobileNumber
              )}
              <Box mt={4}>{divider()}</Box>
              {itemList(
                "Scheme Enquired",
                schemeDetails?.name,
                "Government",
                schemeDetails?.governmentType
              )}
              <Grid item md={12} mt={1}>
                <Typography color="primary.main">
                  Supporting Documents
                </Typography>
                <List dense={true}>
                  {schemeDetails?.uploadedDocuments?.map(
                    (
                      document: { file: string; fileName: string },
                      index: any
                    ) => {
                      return (
                        <ListItem
                          sx={{
                            border: `1px solid ${purpleLight}`,
                            borderRadius: 4,
                            mb: 1,
                          }}
                        >
                          <ListItemIcon>
                            <img src={AttachmentIcon} alt="" />
                          </ListItemIcon>
                          <ListItemText primary={"Document"}></ListItemText>
                          <ListItemIcon>
                            <a
                              href={"data:image/png;base64," + document?.file}
                              target="_blank"
                              download={document.fileName}
                              rel="noreferrer"
                            >
                              <IconButton color="primary">
                                <DownloadOutlined />
                              </IconButton>
                            </a>
                          </ListItemIcon>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </Grid>
              <Box mt={1}>
                <Button
                  sx={{ p: 0 }}
                  variant="text"
                  onClick={() => navigate("/schemes")}
                >
                  View All Scheme details
                </Button>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="#91278F">
                  Categories
                </Typography>
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"}>
                  {schemeDetails?.categories?.map((item: any) => {
                    return (
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        mr={1}
                        p={1}
                        borderRadius={3}
                        sx={{ backgroundColor: "#F5E6F5" }}
                        mb={1}
                      >
                        <Typography variant="body1">{item}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={"row"}
                mt={1}
                alignItems={"center"}
              >
                <Typography variant="subtitle2">Generate Report: </Typography>
                {IconComponent(PdfIcon, "#E9F0ED", () =>
                  downloadEnquiryApiCall("pdf")
                )}
                {IconComponent(XlsIcon, "#FAEDEA", () =>
                  downloadEnquiryApiCall("excel")
                )}
              </Box>
              <Box mt={3}>
                <Button onClick={() => navigate(-1)} variant="outlined">
                  Back To Family Enquiries
                </Button>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}
