import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LocationDetails, OtherDetails, PersonelDetails } from '../model/create_user';
import { useFeedback } from '../../../providers/feedback/feeedback';
import { useQuery } from 'react-query';
import { SchemeMaster, Value } from '../../schemes/models/scheme_master';
import { getMasterData } from '../../../services/choices/choices_services';
import { modifyUserService } from '../../../services/user_append_service/create_modify_user_service';
import { Box, Card, CardContent, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import Loading from '../../../components/loading/loading';
import PersonalDetails, { subscribeToNewsLetter } from '../components/personal_details/personal_details';
import LocationDetailsComp from '../components/location_details/location_details';
import OtherDetailsComp from '../components/other_details/other_details';
import DurableDetailsComp from '../components/durable_details/durable_details';
import MainLayout from '../../../layouts/main/main_layout';
import { ArrowBack } from '@mui/icons-material';
import { AppUsersDetailsModel } from '../model/app_users_model';
import { getAppUsersById } from '../../../services/app_users/app_users';
import ConfirmationPopupForModifyingUser from '../components/popup_for_modifying_user/confirmation_popup';
import { queryClient } from '../../../providers/query/query';
import AreaOfInterest from '../components/area_of_interest/area_of_interest';

export default function ModifyUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [stepperCount, setStepperCount] = useState(1);
    const [uid, setUid] = useState<number | null>(null);
    const [personalDeatails, setPersonalDetails] = useState<PersonelDetails | null>(null);
    const [locationDetails, setLocationDetails] = useState<LocationDetails | null>(null);
    const [otherDetails, setOtherDetails] = useState<OtherDetails | null>(null);
    const [durableDetails, setDurableDetails] = useState<number[] | null>(null);
    const [currentTab, setCurrentTab] = React.useState(0);
    const { showSnackbar } = useFeedback();
    const [confirmationPopup, setConfirmationPopupOpen] = useState<boolean>(false);
    const [areaOfInterest, setAreaOfInterest] = useState<number[] | null>(null);

    const { data: masterDataList } = useQuery<SchemeMaster>(["masterDataList"], async () => {
        let response = await getMasterData();
        return response.data.data || [];
    });

    const { data: appUsersDetails, isLoading } = useQuery<AppUsersDetailsModel>(
        ["app-Users-details"],
        async () => {
            let response = await getAppUsersById({
                id: +id!,
            });
            return response?.data?.data;
        }
    );


    function modifyUserApiCall({ sysCom }: { sysCom: string }) {
        modifyUserService({
            address_line1: locationDetails?.houseNumber!,
            address_line2: locationDetails?.buildingName!,
            address_line3: locationDetails?.streetName!,
            area: locationDetails?.locality!,
            areas_of_interest_id: areaOfInterest!,
            district_id: locationDetails?.district?.id === 0 ? null : locationDetails?.district?.id!,
            dob: personalDeatails?.dob!,
            durable_ownership_id: durableDetails,
            education_id: otherDetails?.educational?.id === 0 ? null : otherDetails?.educational?.id!,
            family_income_id: +otherDetails?.familyIncome?.id! === 0 ? null : otherDetails?.familyIncome?.id!,
            family_type_id: otherDetails?.familyType?.id === 0 ? null : otherDetails?.familyType?.id!,
            gender: personalDeatails?.gender?.value!,
            house_ownership_id: otherDetails?.ownershipOfHouse?.id === 0 ? null : otherDetails?.ownershipOfHouse?.id!,
            landmark: locationDetails?.landmark!,
            lat: '',
            lon: '',
            marital_status_id: personalDeatails?.meritalStatus?.id === 0 ? null : personalDeatails?.meritalStatus?.id!,
            mobile_no: personalDeatails?.mobileNumber!,
            name: personalDeatails?.fullName!,
            occupation_id: otherDetails?.occupational?.id === 0 ? null : otherDetails?.occupational?.id!,
            personal_income_id: otherDetails?.personalIncome?.id === 0 ? null : otherDetails?.personalIncome?.id!,
            pincode: locationDetails?.pincode!,
            preferred_language: 'en',
            state_id: locationDetails?.state!?.id === 0 ? null : locationDetails?.state!?.id,
            working_status_id: otherDetails?.workingStatus?.id === 0 ? null : otherDetails?.workingStatus?.id!,
            is_subscribed: personalDeatails?.subscribeToNewsLatter?.value === 'Yes' ? true : false,
            id: +id!,
            systemComments: sysCom
        }).then((res) => {
            if (res.status === 200) {
                showSnackbar("User details updated successfully");
                queryClient.invalidateQueries({ queryKey: ["app-Users-details"] });
            }
        }).catch((e) => {
            showSnackbar("Something went Wrong");
        });

    }
    useEffect(() => {
        let gender: Value[] = masterDataList?.gender?.filter((i) =>
            appUsersDetails?.gender === i.value
        )!
        let subscribeNewletter: { id: number, value: string, b_value: boolean }[] = subscribeToNewsLetter?.filter((i) =>
            appUsersDetails?.isSubscribed === i.b_value
        )

        let personalDetails: PersonelDetails = {
            "fullName": appUsersDetails?.name!,
            uid: 0,
            dob: appUsersDetails?.dob.toString()!,
            gender: gender?.[0]!,
            mobileNumber: +appUsersDetails?.mobileNumber!,
            familyIncome: {
                id: +appUsersDetails?.familyIncomeId!,
                value: appUsersDetails?.familyIncome!
            },
            subscribeToNewsLatter: subscribeNewletter[0]!,
            newsLatterEmailId: '',
            meritalStatus: {
                id: appUsersDetails?.maritalStatusId!,
                value: appUsersDetails?.maritalStatus!
            },
            email: appUsersDetails?.emailId

        }
        let locationDetails: LocationDetails = {
            houseNumber: appUsersDetails?.address_line1,
            buildingName: appUsersDetails?.address_line2,
            streetName: appUsersDetails?.address_line3,
            landmark: appUsersDetails?.landmark,
            locality: appUsersDetails?.area!,
            state: {
                id: +appUsersDetails?.stateId!,
                value: appUsersDetails?.state!
            },
            district: {
                id: +appUsersDetails?.districtId!,
                value: appUsersDetails?.district!
            },
            pincode: +appUsersDetails?.pincode!
        }
        let otherDetails: OtherDetails = {
            educational: {
                id: +appUsersDetails?.educationId!,
                value: appUsersDetails?.education!
            },
            workingStatus: {
                id: +appUsersDetails?.workingStatusId!,
                value: appUsersDetails?.workingStatus!
            },
            occupational: {
                id: +appUsersDetails?.occupationId!,
                value: appUsersDetails?.occupation!
            },
            familyType: {
                id: +appUsersDetails?.familyTypeId!,
                value: appUsersDetails?.familyType!
            },
            ownershipOfHouse: {
                id: +appUsersDetails?.houseOwnershipId!,
                value: appUsersDetails?.houseOwnership!
            },
            personalIncome: {
                id: +appUsersDetails?.personalIncomeId!,
                value: appUsersDetails?.personalIncome!
            },
            familyIncome: {
                id: +appUsersDetails?.familyIncomeId!,
                value: appUsersDetails?.familyIncome!
            }
        }
        let durable: number[] = [];
        let areaOfInterest: number[] = [];
        appUsersDetails?.durableOwnership?.map((v) => durable.push(+v.id!))!;
        appUsersDetails?.areasOfInterest?.map((v) => areaOfInterest.push(+v.id!))!;
        setPersonalDetails(personalDetails);
        setLocationDetails(locationDetails);
        setOtherDetails(otherDetails);
        setDurableDetails(durable!);
        setAreaOfInterest(areaOfInterest!)
        setCurrentTab(0)
        console.log(personalDeatails);

    }, [appUsersDetails]);


    function loading() {
        return (
            <Box
                height={300}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Loading />
            </Box>
        );
    }

    function renderCompBasedOnStepper() {
        switch (currentTab) {
            case 0:
                return <PersonalDetails
                    onSubmit={async (data) => {
                        setPersonalDetails(data);
                        setConfirmationPopupOpen(true);
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    personalDeatails={personalDeatails!}
                    modifyUser={true}
                />
            case 1:
                return <LocationDetailsComp
                    onSubmit={async (data) => {
                        setLocationDetails(data);
                        setConfirmationPopupOpen(true);
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    locationDetails={locationDetails!}
                    modifyUser={true}
                />
            case 2:
                return <OtherDetailsComp
                    onSubmit={async (data) => {
                        setOtherDetails(data)
                        setConfirmationPopupOpen(true);
                    }}
                    setUid={setUid}
                    uid={uid}
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    otherDetails={otherDetails!}
                    modifyUser={true}
                />
            case 3:
                return <DurableDetailsComp
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    onSubmit={() => {
                        setConfirmationPopupOpen(true)
                    }}
                    durableDetails={durableDetails!}
                    setDurableDetails={setDurableDetails}
                    modifyUser={true}
                />
            case 4:
                return <AreaOfInterest
                    stepperCount={stepperCount}
                    setStepperCount={setStepperCount}
                    masterDataList={masterDataList!}
                    onSubmit={() => {
                        setConfirmationPopupOpen(true)
                    }}
                    areaOfInterest={areaOfInterest!}
                    setAreaOfInterest={setAreaOfInterest}
                    modifyUser={true}
                />
            default:
        }
    }

    const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
        setCurrentTab(currentTabVal);
    };


    return (
        <MainLayout>
            <Grid container spacing={3}>
                <Grid item md={12} lg={8} sm={12} xs={12}>
                    <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box display="flex" alignItems="center">
                                    <IconButton onClick={() => navigate(-1)}>
                                        <ArrowBack></ArrowBack>
                                    </IconButton>
                                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                                        Modify User Details
                                    </Typography>
                                </Box>
                                <Box
                                    flex={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                ></Box>
                            </Box>
                            <Box mt={2} mb={2}>
                                <Divider />
                            </Box>
                            <Box m={2}>
                                {false ? (
                                    loading()
                                ) : (
                                    <Box>
                                        <Box
                                            sx={{ borderBottom: 1, borderColor: "#F5E6F5", p: 2, pb: 0 }}
                                        >
                                            <Tabs
                                                value={currentTab}
                                                aria-label="basic tabs example"
                                                onChange={handleChange}
                                                sx={{
                                                    "MuiTabs-root css-112qdbf-MuiTabs-root": {
                                                        borderColor: "#F5E6F5",
                                                    },
                                                }}
                                            >
                                                <Tab
                                                    label="Personal Details"
                                                    sx={{
                                                        textTransform: "capitalize",
                                                        color: "#1B001B",
                                                    }}
                                                />
                                                <Tab
                                                    label="Location Details"
                                                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                                                />
                                                <Tab
                                                    label="Other Details"
                                                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                                                />
                                                <Tab
                                                    label="Durable Details"
                                                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                                                />
                                                <Tab
                                                    label="Area of interest"
                                                    sx={{ textTransform: "capitalize", color: "#1B001B" }}
                                                />
                                            </Tabs>
                                        </Box>
                                        {renderCompBasedOnStepper()}
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ConfirmationPopupForModifyingUser
                onDialogClose={() => {
                    setConfirmationPopupOpen(false)
                }}
                open={confirmationPopup}
                updateUserAccess={(comments) => {
                    modifyUserApiCall({ sysCom: comments })
                    setConfirmationPopupOpen(false)
                }}
            />
        </MainLayout>
    )

}
