import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TablePagination,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/loading/loading";
import { Edit, Search, Visibility } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getChoicesList } from "../../../../services/choices/choices_services";
import { useQuery } from "react-query";
import { create_choice } from "../../../../routes/routes_path";
import Copy from "../../../../assets/copy.svg";
import locales from "../../../../constants/locales";
import useUser from "../../../../providers/user/user_provider";
import styled from "@emotion/styled";

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function ChoicesListing() {
  const [searchParams, setUrlParam] = useSearchParams();
  const [choicesList, setChoicesList] = useState([]);
  const [page, setPage] = useState(
    searchParams.has("page") ? parseInt(searchParams?.get("page")!) - 1 : 0
  );
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const { data: choicesListData, isLoading } = useQuery(
    ["choicesListing", page + 1, searchValue],
    async () => {
      let response = await getChoicesList({ keyword: searchValue, page: page + 1, size: 8 });
      return response?.data?.data || [];
    }
  );
  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, choicesListData?.total || 0);

  const customText = `Showing ${startRange} to ${endRange} of ${choicesListData?.total} entries`;

  useEffect(() => {
    setUrlParam({ page: (page + 1).toString() });
  }, [page, setUrlParam]);

  function searchComponent() {
    return (
      <Box mt={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flex: 1,
        }}
      >

        <Grid flex={1} container>
          <Grid py={1} pl={1} item md={12} sm={12} xs={12} lg={12} xl={12}>
            {searchComponent()}
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Loading />
              </Box>
            ) : choicesListData?.list?.length !== 0 ? (
              <Box display={"flex"} flexDirection={"column"}>
                {choicesListData?.list?.map(
                  (
                    choice: {
                      id: number;
                      name: string;
                      languages: string[];
                      validFrom: string;
                      validTo: string;
                      updatedAt: string;
                      createdAt: string;
                      totalQuestions: number;
                      choiceId: number;
                    },
                    index: number
                  ) => {
                    return (
                      <>
                        <ListItem
                          sx={{
                            margin: 0,
                            padding: 0,
                            my: 2,
                          }}
                        >
                          <ListItemText
                            primary={choice?.name}
                            secondary={
                              <Box display="flex" flexDirection="row">
                                <Typography variant="body2">{`Questions:${choice.totalQuestions}`}</Typography>
                                {choice?.languages?.length !== 0 && (
                                  <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    ml={3}
                                  >
                                    <Typography variant="body2">{`Language:`}</Typography>
                                    {choice?.languages.map((item: string) => {
                                      return (
                                        <Typography
                                          variant="body2"
                                          ml={1}
                                          textTransform={"capitalize"}
                                        >
                                          {
                                            locales?.filter(
                                              (locale) => locale?.code === item
                                            )?.[0]?.name
                                          }
                                        </Typography>
                                      );
                                    })}
                                  </Box>
                                )}
                                {choice.validFrom && (
                                  <Typography
                                    variant="body2"
                                    ml={3}
                                  >{`Valid from :${choice.validFrom}`}</Typography>
                                )}
                                {choice.validTo && (
                                  <Typography
                                    variant="body2"
                                    ml={3}
                                  >{`Valid to :${choice.validTo}`}</Typography>
                                )}
                              </Box>
                            }
                            primaryTypographyProps={{
                              color: "#1B001B",
                            }}
                            secondaryTypographyProps={{
                              color: "#91278F",
                            }}
                          />
                          <ListSubheader sx={{ margin: 0, padding: 0 }}>
                            <IconButton
                              onClick={() => {
                                navigate(`/choices/viewChoices/${choice?.id}`);
                              }}
                              sx={{
                                backgroundColor: "#E9F0ED",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                            >
                              <Visibility></Visibility>
                            </IconButton>
                            <IconButton
                              disabled={!hasPermission("Choices", "Update")}
                              style={{ marginLeft: 16 }}
                              onClick={() => {
                                navigate(`/choices/editChoice/${choice?.id}`);
                              }}
                              sx={{
                                backgroundColor: "#F5E6F5",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                            >
                              <Edit></Edit>
                            </IconButton>
                            <IconButton
                              disabled={!hasPermission("Choices", "Create")}
                              style={{ marginLeft: 16 }}
                              onClick={() => {
                                navigate(create_choice, {
                                  state: { id: choice?.id },
                                });
                              }}
                              sx={{
                                backgroundColor: "#F5E6F5",
                                borderRadius: 4,
                                color: "#1B001B",
                              }}
                            >
                              <img src={Copy} alt="" height={24} width={24} />
                            </IconButton>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                navigate(`/choices/viewChoice/${choice?.id}`, {
                                  state: { id: choice?.choiceId },
                                })
                              }
                              sx={{ ml: 2 }}
                            >
                              View responses
                            </Button>
                          </ListSubheader>
                        </ListItem>
                        {index !== choicesList?.length - 1 && (
                          <Divider
                            sx={{
                              display: "flex",
                              backgroundColor: "#7C797C",
                              opacity: 0.1,
                            }}
                          />
                        )}
                      </>
                    );
                  }
                )}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
              >
                <Typography>No Data Available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mx: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            count={choicesListData?.total || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={8}
            rowsPerPageOptions={[]}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          />
        </Box>
        <Grid container>
          <Grid
            display="flex"
            width={"100%"}
            flexDirection="row"
            justifyContent="space-between"
            m={2}
            mt={4}
          >
            <Grid md={4} sm={4} xs={4} lg={2} xl={4}>
              <Button
                onClick={() => navigate("/reports", { replace: true })}
                fullWidth
                sx={{
                  border: "1px solid #91278F",
                  borderRadius: 2,
                  fontWeight: 600,
                  textTransform: "capitalize",
                  color: "#000000",
                }}
              >
                Back To Reports
              </Button>
            </Grid>
            <Grid md={2} sm={4} xs={4} lg={3} xl={4}>
              <Box>
                <Button
                  disabled={!hasPermission("Choices", "Create")}
                  variant="contained"
                  onClick={() => {
                    navigate("/choices/createNewChoice/");
                  }}
                  fullWidth
                  color={"primary"}
                >
                  + Create New Choice
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </>
  );
}
