import { Box, Grid, IconButton, InputAdornment, TextField as MuiTextField, Typography } from "@mui/material";
import FilterIcon from "../../../assets/filter_icon.svg";
import PdfIcon from "../../../assets/pdf_icon.svg";
import XlsIcon from "../../../assets/xls_icon.svg";
import MainLayout from "../../../layouts/main/main_layout";
import SchemeLayout from "../../../layouts/schemes/scheme_layout";
import FilterEnquiries from "../components/filter_enquiries/filter_enquiries";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataGridEnquiries from "../components/data_grid/data_grid_enquiries";
import {
  downloadEnquiries,
  getEnquiredSchemes,
} from "../../../services/schemes/schemes";
import { useQuery } from "react-query";
import { getMasterData } from "../../../services/master/master_service";
import { downloadFromBlob } from "../../../utils/utils";
import Loading from "../../../components/loading/loading";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../../constants";
import { useFeedback } from "../../../providers/feedback/feeedback";
import DownloadPopup from "../components/download_popup/download_popup";
import Sort from "../../../assets/sort.svg";
import { NeedFilters } from "../../needs/model/needs_filters";
import { getNeedsFilters } from "../../../services/needs/needs_service";
import { Search } from "@mui/icons-material";
import styled from "@emotion/styled";

export interface EnquiryLabels {
  label: string;
}

export interface PaginationState {
  page: number;
  rowsPerPage: number;
}

const enquiryLabels: EnquiryLabels[] = [
  {
    label: "Ref.No",
  },
  {
    label: "Full Name",
  },
  {
    label: "Contact Number",
  },
  {
    label: "Scheme Enquired",
  },
  {
    label: "State",
  },
  {
    label: "District",
  },
  {
    label: "Enquired On",
  },
];

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function Enquiries() {
  const { showSnackbar } = useFeedback();
  const [searchParams, setUrlParam] = useSearchParams();
  const [openEnquiriesModal, setOpenEnquiriesModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(
    searchParams?.has("page") ? parseInt(searchParams.get("page")!) - 1 : 0
  );
  const [schemeKeyword, setSchemeKeyword] = useState("");
  const [stateId, setStateId] = useState<number | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    page: 0,
    rowsPerPage: 8,
  });
  const [openDownloadUsersPopup, setOpenDownloadPopup] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("");
  const [sortInAcceding, setSortInAcceding] = useState(true);
  const [ageTo, setAgeTo] = useState<number | null>(null);
  const [gender, setGender] = useState("");
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [ageFrom, setAgeFrom] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [governmentType, setGovernmentType] = useState<number | null>(null);

  const { data: enquiryList, isLoading } = useQuery(
    ["enquiry",
      page,
      startDate,
      endDate,
      schemeKeyword,
      stateId,
      categoryId,
      sortInAcceding,
      gender,
      ageTo,
      districtId,
      searchValue,
      governmentType
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getEnquiredSchemes({
        type: "user",
        dateFrom: startDate,
        dateTo: endDate,
        page: page + 1,
        size: 8,
        schemeKeyword: schemeKeyword,
        state: stateId,
        category: categoryId,
        sortOrder: sortOrder,
        gender: gender,
        districtId: districtId,
        ageFrom: ageFrom,
        ageTo: ageTo,
        keyword: searchValue,
        governmentTypeId: governmentType
      });
      return response.data.data.data;
    }
  );

  useEffect(() => {
    setUrlParam({ page: page ? (page + 1)?.toString() : "1" });
  }, [page, pagination]);

  const { data: needsFilters } = useQuery<NeedFilters>(
    ["needs-filters"],
    async () => {
      let data = await getNeedsFilters();
      return data?.data?.data;
    }
  );

  const downloadEnquiriesApiCall = (
    format: string,
    fromPage?: number | null,
    toPage?: number | null
  ) => {
    if ((toPage! + 1 - fromPage!) * 10 <= 1000) {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      downloadEnquiries({
        type: "user",
        dateFrom: startDate,
        dateTo: endDate,
        page: fromPage!,
        size: (toPage! + 1 - fromPage!) * 10,
        schemeKeyword: schemeKeyword,
        state: stateId,
        category: categoryId,
        format: downloadFormat,
        sortOrder: sortOrder,
        gender: gender,
        districtId: districtId,
        ageFrom: ageFrom,
        ageTo: ageTo,
        governmentTypeId: governmentType
      })
        .then((res) => {
          let extension = downloadFormat === "pdf" ? ".pdf" : ".xlsx";
          downloadFromBlob(res, new Date().valueOf() + extension);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
    setOpenDownloadPopup(false);
  };

  const { data: masterDataList } = useQuery(["masterDataList"], async () => {
    let response = await getMasterData();
    return response.data.data;
  });

  function searchComponent() {
    return (
      <Box mx={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  return (


    <MainLayout>
      <SchemeLayout>
        <Box display="flex" flexDirection={"column"} mt={4} height="100%">
          {searchComponent()}
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            p={2}
          >
            <Box display={"flex"} flexDirection={"row"}>
              <Typography display="flex" alignItems={"center"}>
                Download as:
              </Typography>
              {IconComponent(XlsIcon, "#E9F0ED", () => {
                setOpenDownloadPopup(true);
                setDownloadFormat("excel");
              })}
              {IconComponent(PdfIcon, "#FAEDEA", () => {
                setOpenDownloadPopup(true);
                setDownloadFormat("pdf");
              })}
            </Box>
            <Box display={"flex"} flexDirection={"row"}>
              <Box
                ml={2}
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Typography>Sort By: </Typography>
                <Box
                  ml={1}
                  display="flex"
                  flexDirection={"row"}
                  alignItems="center"
                  mr={1}
                >
                  <Typography color={"#91278F"}>
                    {sortInAcceding ? "New to old" : "Old to New"}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSortInAcceding(!sortInAcceding);
                    }}
                  >
                    <img src={Sort} alt="" />
                  </IconButton>
                </Box>
              </Box>
              <Typography
                display="flex"
                alignItems={"center"}
                color="#91278F"
                fontWeight="600"
                sx={{ borderBottom: "3px solid #91278F" }}
              >
                Filters:
              </Typography>
              {IconComponent(FilterIcon, "#E9F0ED", () => {
                setOpenEnquiriesModal(true);
              })}
            </Box>
          </Box>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              height="100%"
            >
              <Loading />
            </Box>
          ) : (
            <DataGridEnquiries
              labels={enquiryLabels}
              data={enquiryList?.list || []}
              count={enquiryList?.total || 0}
              page={page}
              setPage={setPage}
              setPagination={setPagination}
              pagination={pagination}
            />
          )}

          <FilterEnquiries
            govermentList={masterDataList?.schemeGovernment || []}
            setGovernmentType={setGovernmentType}
            districtId={districtId}
            setDistrictId={setDistrictId}
            gender={gender}
            setGender={setGender}
            ageFrom={ageFrom}
            ageTo={ageTo}
            setAgeFrom={setAgeFrom}
            setAgeTo={setAgeTo}
            cities={needsFilters?.cityList || []}
            genderList={masterDataList?.gender}
            openEnquiriesModal={openEnquiriesModal}
            setOpenEnquiriesModal={setOpenEnquiriesModal}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            schemeKeyword={schemeKeyword}
            setSchemeKeyword={setSchemeKeyword}
            states={masterDataList?.state || []}
            category={masterDataList?.schemeCategory || []}
            stateId={stateId}
            setStateId={setStateId}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            resetFilter={() => {
              setCategoryId(null);
              setStateId(null);
              setSchemeKeyword("");
              setStartDate("");
              setEndDate("");
              setGovernmentType(null)
            }}
          />
        </Box>
        {openDownloadUsersPopup && (
          <DownloadPopup
            onDialogClose={() => {
              setOpenDownloadPopup(false);
            }}
            open={openDownloadUsersPopup}
            key={1}
            onSubmit={(fromPage: number | null, toPage: number | null) => {
              downloadEnquiriesApiCall("pdf", fromPage, toPage);
            }}
          />
        )}
      </SchemeLayout>
    </MainLayout>
  );
}
function IconComponent(
  imgSrc: string,
  backgroundColor: string,
  onTap?: () => void
) {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: backgroundColor,
        justifyContent: "center",
        alignItems: "center",
        p: 1,
        borderRadius: 3,
        ml: 2,
      }}
    >
      <IconButton
        onClick={onTap}
        sx={{
          height: 24,
          width: 24,
        }}
      >
        <img src={imgSrc} alt="" />
      </IconButton>
    </Box>
  );
}
