import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useScheme from "../../providers/scheme_provider";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  SchemeEligibilityInputs,
  SchemeOtherDetailsInputs,
} from "../../models/scheme_form";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import YesOrNoChips from "../../../../components/yes_or_no_chips/yes_or_no_chips";
import ChipsWithAggregation from "../../../../components/chips_with_aggregation/chips_with_aggregation";
import { Value } from "../../models/scheme_master";
import { useEffect } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SchemeEligibilityCriteria({
  goBack,
  onSubmit: _onSubmit,
  eligibilityDetails,
}: {
  eligibilityDetails: SchemeEligibilityInputs;
  goBack: () => void;
  onSubmit: (data: SchemeEligibilityInputs) => void;
}) {
  const { schemeMaster } = useScheme();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SchemeEligibilityInputs>({
    defaultValues: eligibilityDetails,
  });

  const onSubmit: SubmitHandler<SchemeEligibilityInputs> = (data) =>
    _onSubmit(data);

  function removeDuplicates(
    arr: Value[],
    isAggregate: boolean = false
  ): Value[] {
    let unique = arr.reduce(function (acc: Value[], curr) {
      if (!acc.map((x) => x.id).includes(curr.id)) acc.push(curr);
      return acc;
    }, []);
    if (isAggregate) {
      let isAggregateSelected: boolean = !!unique.find(
        (item) => item.is_aggregate
      );
      unique = unique.filter((item) => item.is_aggregate);
    } else {
      let isAggregateSelected: boolean = !!unique.find(
        (item) => item.is_aggregate
      );
      if (isAggregateSelected) {
        unique = unique.filter((item) => !item.is_aggregate);
      }
    }

    // let isAllSelected: boolean = !!(unique.find((item) => item.is_aggregate));
    // if(isAllSelected){
    //   unique = unique.filter(item => item.is_aggregate);
    // }
    return unique;
  }

  const toAge = watch("toAge");

  return (
    <Box p={2}>
      <Box mt={2} mb={2}>
        <Typography variant="body1" fontWeight={700}>
          Criteria for Scheme Eligibility
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Box>
              <Typography>Eligible Genders*</Typography>
              <Controller
                name="genders"
                control={control}
                rules={{ required: "Select eligible genders" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    items={
                      schemeMaster?.gender?.map((item, index) => ({
                        id: item.id,
                        isAggregate: item.is_aggregate,
                        value: item.value,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.genders}
                    helperText={errors.genders?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box display="flex">
              <Controller
                name="fromAge"
                control={control}
                rules={{
                  required: "Please select from age",
                  validate: (value: number) => {
                    if (+value >= +toAge) {
                      return "From age should be less than toAge";
                    }
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label="From age"
                    {...register("fromAge", {
                      valueAsNumber: true,
                      min: 1,
                      max: 120,
                    })}
                    error={!!errors.fromAge}
                    helperText={errors.fromAge?.message}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 120, // Optionally set a maximum value
                        step: 1, // Optionally set the step value
                        inputMode: "numeric", // Specify input mode as numeric
                      },
                      // Remove the spin buttons (increase and decrease buttons)
                      inputMode: "numeric",
                      onKeyDown: (e) => {
                        if (e.key === "-" || e.key === "-") {
                          e.preventDefault();
                        }
                      },
                    }}
                  />
                )}
              />
              <Box p={1}></Box>
              <Controller
                name="toAge"
                control={control}
                rules={{ required: "Please select To age" }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label="To age"
                    {...register("toAge", { valueAsNumber: true, max: 120 })}
                    error={!!errors.toAge}
                    helperText={errors.toAge?.message}
                    InputProps={{
                      inputProps: {
                        min: 1, // Optionally set a minimum value
                        max: 120, // Optionally set a maximum value
                        step: 1, // Optionally set the step value
                        inputMode: "numeric", // Specify input mode as numeric
                      },
                      inputComponent: "input",
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Controller
              name="state"
              control={control}
              rules={{ required: "select eligible state" }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  options={schemeMaster?.state || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  value={value}
                  onChange={(_, value, reason, details) => {
                    let isAggregate = details?.option.is_aggregate;
                    onChange(removeDuplicates(value, isAggregate));
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (item) => item.is_aggregate
                    );
                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {option.value}
                        </li>
                        {option.is_aggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select eligible states"
                      fullWidth
                      placeholder="Select states"
                      error={!!errors.state}
                      helperText={errors.state?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Eligible Castes*</Typography>
              <Controller
                name="caste"
                control={control}
                rules={{ required: "Select eligible castes" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    items={
                      schemeMaster?.caste?.map((item) => ({
                        id: item.id,
                        isAggregate: item.is_aggregate,
                        value: item.value,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.caste}
                    helperText={errors.caste?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Area of Residence*</Typography>
              <Controller
                name="residence"
                control={control}
                rules={{ required: "Select area of residence" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    items={[
                      { id: 1, isAggregate: true, value: "All" },
                      { id: 2, isAggregate: false, value: "Urban" },
                      { id: 3, isAggregate: false, value: "Rural" },
                    ]}
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.residence}
                    helperText={errors.residence?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Controller
              name="workingStatus"
              control={control}
              rules={{ required: "Select eligible working status" }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  options={schemeMaster?.workingStatus || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  value={value}
                  onChange={(_, value, reason, details) => {
                    let isAggregate = details?.option.is_aggregate;
                    onChange(removeDuplicates(value, isAggregate));
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (item) => item.is_aggregate
                    );
                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {option.value}
                        </li>
                        {option.is_aggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Eligible Employment Status*"
                      fullWidth
                      placeholder="Select employment Status*"
                      error={!!errors.workingStatus}
                      helperText={errors.workingStatus?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="occupation"
              control={control}
              rules={{ required: "Select eligible occupation" }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  options={schemeMaster?.occupation || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  value={value}
                  onChange={(_, value, reason, details) => {
                    let isAggregate = details?.option.is_aggregate;
                    onChange(removeDuplicates(value, isAggregate));
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (item) => item.is_aggregate
                    );
                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {option.value}
                        </li>
                        {option.is_aggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Eligible Occupation*"
                      fullWidth
                      placeholder="Select occupation*"
                      error={!!errors.occupation}
                      helperText={errors.occupation?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Eligible Marital Status*</Typography>
              <Controller
                name="maritalStatus"
                control={control}
                rules={{ required: "Select eligible marital status" }}
                render={({ field: { onChange, value } }) => (
                  <ChipsWithAggregation
                    items={
                      schemeMaster?.maritalStatus?.map((item) => ({
                        id: item.id,
                        isAggregate: item.is_aggregate,
                        value: item.value,
                      })) || []
                    }
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.maritalStatus}
                    helperText={errors.maritalStatus?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>
                Whether eligible for differently abled only?*
              </Typography>
              <Controller
                name="differentlyAbled"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.differentlyAbled}
                    helperText={errors.differentlyAbled?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Whether eligible for Minorities only?*</Typography>
              <Controller
                name="minority"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.minority}
                    helperText={errors.minority?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Whether eligible for students only?*</Typography>
              <Controller
                name="student"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.student}
                    helperText={errors.student?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Whether eligible for BPL category only?*</Typography>
              <Controller
                name="bplCategory"
                control={control}
                rules={{
                  validate: (value) =>
                    typeof value == "boolean"
                      ? undefined
                      : "This field is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <YesOrNoChips
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={!!errors.bplCategory}
                    helperText={errors.bplCategory?.message}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Controller
              name="familyIncome"
              control={control}
              rules={{ required: "Select family income" }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  multiple
                  options={schemeMaster?.familyIncome || []}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.value}
                  onChange={(_, value, reason, details) => {
                    let isAggregate = details?.option.is_aggregate;
                    onChange(removeDuplicates(value, isAggregate));
                  }}
                  value={value}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(
                    props,
                    option,
                    { selected, index },
                    { value }
                  ) => {
                    let isAllSelected: boolean = !!value?.find(
                      (item) => item.is_aggregate
                    );
                    return (
                      <>
                        <li {...props}>
                          <Checkbox
                            checked={selected || isAllSelected}
                            value={index}
                          />
                          {option.value}
                        </li>
                        {option.is_aggregate ? <Divider /> : null}
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Family Income*"
                      fullWidth
                      placeholder="Select family income*"
                      error={!!errors.familyIncome}
                      helperText={errors.familyIncome?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box mt={2} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" sx={{ pl: 6, pr: 6 }} onClick={goBack}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ pl: 6, pr: 6 }}>
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
