import { ArrowBack } from "@mui/icons-material";
import { Box, Card, IconButton, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import FilterIcon from "../../assets/filter_icon.svg";
import XLSIcon from "../../assets/ph_file-xls.svg";
import { useNavigate } from "react-router-dom";
import Sort from "../../assets/sort.svg";

interface ServiceLayoutProps {
  downloadExcel: () => void;
  setOpenModal: (val: boolean) => void;
  sortInAcceding: boolean;
  setSortInAcceding: (val: boolean) => void
}

export default function ServiceLayout({
  children,
  downloadExcel,
  setOpenModal,
  sortInAcceding,
  setSortInAcceding
}: PropsWithChildren<ServiceLayoutProps>) {
  const navigate = useNavigate();
  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
          height: "fit-content",
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        mb: 2,
        mt: 4,
        minHeight: 700,
      }}
    >
      <Card
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <Box flex={1} display="flex" flexDirection={"column"} mt={4}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            p={2}
          >
            <Box flexDirection="row" display="flex" alignItems="center">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack></ArrowBack>
              </IconButton>
              <Typography variant="h6" fontWeight="600">
                Services
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"row"} alignItems="center">
              <Box
                ml={2}
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Typography>Sort By: </Typography>
                <Box
                  ml={1}
                  display="flex"
                  flexDirection={"row"}
                  alignItems="center"
                >
                  <Typography color={"#91278F"}>
                    {sortInAcceding ? "New to old" : "Old to New"}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setSortInAcceding(!sortInAcceding);
                    }}
                  >
                    <img src={Sort} alt="" />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography>Generate Report:</Typography>
                <IconButton sx={{ ml: 2 }} onClick={downloadExcel}>
                  <img src={XLSIcon} alt="" />
                </IconButton>
              </Box>
              <Typography
                display="flex"
                alignItems={"center"}
                color="#91278F"
                fontWeight="600"
                sx={{ borderBottom: "3px solid #91278F" }}
              >
                Filters:
              </Typography>
              {IconComponent(FilterIcon, "#E9F0ED", () => {
                setOpenModal(true);
              })}
            </Box>
          </Box>

          {children}
        </Box>
      </Card>
    </Box>
  );
}
