import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import useScheme from "../../providers/scheme_provider";
import ChipsWithAggregation from "../../../../components/chips_with_aggregation/chips_with_aggregation";
import { SchemeOtherDetailsInputs } from "../../models/scheme_form";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import HTMLEditor from "../../../../components/html_editor/html_editor";

export default function OtherSchemeInformation({
  otherDetails,
  goBack,
  onSubmit: _onSubmit,
}: {
  otherDetails: SchemeOtherDetailsInputs;
  goBack: () => void;
  onSubmit: (data: SchemeOtherDetailsInputs) => void;
}) {
  const { schemeMaster } = useScheme();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SchemeOtherDetailsInputs>({
    defaultValues: otherDetails,
  });

  const onSubmit: SubmitHandler<SchemeOtherDetailsInputs> = (data) =>
    _onSubmit(data);

  return (
    <Box p={2}>
      <Box mt={2} mb={2}>
        <Typography variant="body1" fontWeight={700}>
          Other Scheme Information
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Controller
              name="summary"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter summary details(optional)"
                />
              )}
            />
            {!!errors.details && (
              <Typography variant="caption" color="error">
                {errors.summary?.message}
              </Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <Controller
              name="details"
              control={control}
              rules={{ required: "Enter scheme details" }}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter scheme details"
                />
              )}
            />
            {!!errors.details && (
              <Typography variant="caption" color="error">
                {errors.details?.message}
              </Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <Controller
              name="benefits"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Benefits (Optional)"
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="exclusions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Exclusions (Optional)"
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Scheme Application Process*</Typography>
              <Controller
                name="applicationProcess"
                control={control}
                rules={{ required: "Select the process mode*" }}
                render={({ field: { onChange, value } }) => (
                  <Box mt={1}>
                    {schemeMaster?.schemeProcessMode?.map((process) => (
                      <Chip
                        onClick={() => onChange(process.id)}
                        label={process.value}
                        variant={value === process.id ? "filled" : "outlined"}
                        color="primary"
                        sx={{ mr: 1 }}
                      />
                    ))}
                  </Box>
                )}
              />
              {!!errors.applicationProcess && (
                <Typography variant="caption" color="error">
                  {errors.applicationProcess?.message}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Controller
              name="applicationProcessDetails"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Application Process Details (Optional)"
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="eligibility"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Eligibility (Optional)"
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="documentRequired"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Documents Required (Optional)"
                />
              )}
            />
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button variant="outlined" sx={{ pl: 6, pr: 6 }} onClick={goBack}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ pl: 6, pr: 6 }}>
            Next
          </Button>
        </Box>
      </form>
    </Box>
  );
}
