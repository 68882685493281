import { CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import YesOrNoChips from "../../../../components/yes_or_no_chips/yes_or_no_chips";
import ChipsWithAggregation from "../../../../components/chips_with_aggregation/chips_with_aggregation";
import { SchemeEligibilityInputs } from "../../models/scheme_form";
import useScheme from "../../providers/scheme_provider";
import { Value } from "../../models/scheme_master";
import { SchemeDetailsResponse } from "../../models/scheme_details";

export default function SchemeEligilibility({
  data,
  isOpen,
  onClose,
  onSave,
}: {
  data: SchemeDetailsResponse | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: SchemeEligibilityInputs) => void;
}) {
  const { schemeMaster } = useScheme();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SchemeEligibilityInputs>({
    // defaultValues: eligibilityDetails,
  });

  useEffect(() => {
    if (data && schemeMaster) {
      let genders: number[] =
        data.eligibility.genders.map(
          (item) =>
            schemeMaster.gender.find(
              (x) => x.value.toLowerCase() === item.toLowerCase()
            )!.id
        ) || [];
      setValue("genders", genders);
      setValue("fromAge", data.eligibility.fromAge);
      setValue("toAge", data.eligibility.toAge);
      setValue(
        "state",
        schemeMaster.state.filter((item) =>
          data.eligibility.stateIds?.includes(item.id)
        )
      );
      setValue("caste", data.eligibility.casteIds);
      setValue("residence", data.eligibility.residenceIds);
      setValue(
        "workingStatus",
        schemeMaster.workingStatus.filter((item) =>
          data.eligibility.workingStatusIds?.includes(item.id)
        )
      );
      setValue(
        "occupation",
        schemeMaster.occupation.filter((item) =>
          data.eligibility.occupationIds?.includes(item.id)
        )
      );
      setValue("maritalStatus", data.eligibility.maritalStatusIds);
      setValue("differentlyAbled", data.eligibility.differentlyAbled);
      setValue("minority", data.eligibility.minority);
      setValue("student", data.eligibility.student);
      setValue("bplCategory", data.eligibility.bplCategory);
      setValue(
        "familyIncome",
        schemeMaster.familyIncome.filter((item) =>
          data.eligibility.familyIncomeIds?.includes(item.id)
        )
      );
    }
  }, [data, schemeMaster, setValue]);

  const onSubmit: SubmitHandler<SchemeEligibilityInputs> = (data) =>
    onSave(data);

  function removeDuplicates(
    arr: Value[],
    isAggregate: boolean = false
  ): Value[] {
    let unique = arr.reduce(function (acc: Value[], curr) {
      if (!acc.map((x) => x.id).includes(curr.id)) acc.push(curr);
      return acc;
    }, []);
    if (isAggregate) {
      let isAggregateSelected: boolean = !!unique.find(
        (item) => item.is_aggregate
      );
      unique = unique.filter((item) => item.is_aggregate);
    } else {
      let isAggregateSelected: boolean = !!unique.find(
        (item) => item.is_aggregate
      );
      if (isAggregateSelected) {
        unique = unique.filter((item) => !item.is_aggregate);
      }
    }
    console.log(arr, isAggregate);

    // let isAllSelected: boolean = !!(unique.find((item) => item.is_aggregate));
    // if(isAllSelected){
    //   unique = unique.filter(item => item.is_aggregate);
    // }
    return unique;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        ".css-1o50bed-MuiPaper-root-MuiDialog-paper ": {
          maxWidth: "80%",
          borderRadius: "15px",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" p={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={1}
          >
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={18}
              fontWeight={600}
            >
              Modify Scheme Eligibility Criteria
            </Typography>
            <IconButton onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Divider />
          <Box pt={2} flex={1} sx={{ overflowY: "auto" }}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Box>
                  <Typography>
                    Eligible Genders<span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="genders"
                    control={control}
                    rules={{ required: "Select eligible genders" }}
                    render={({ field: { onChange, value } }) => (
                      <ChipsWithAggregation
                        items={
                          schemeMaster?.gender?.map((item, index) => ({
                            id: item.id,
                            isAggregate: item.is_aggregate,
                            value: item.value,
                          })) || []
                        }
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.genders}
                        helperText={errors.genders?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box display="flex">
                  <TextField
                    type="number"
                    fullWidth
                    label="From age"
                    // rules={{
                    //   required: "Please select from age",
                    //   validate: (value: number) => {
                    //     if (+value >= +toAge) {
                    //       return "From age should be less than toAge";
                    //     }
                    //   },
                    // }}
                    {...register("fromAge", { valueAsNumber: true, min: 1 })}
                    error={!!errors.fromAge}
                    helperText={errors.fromAge?.message}
                    InputProps={{
                      inputProps: {
                        min: 1, // Optionally set a minimum value
                        max: 120, // Optionally set a maximum value
                        inputMode: "numeric", // Specify input mode as numeric
                      },
                      // Remove the spin buttons (increase and decrease buttons)
                      inputComponent: "input",
                      onKeyDown: (e) => {
                        if (e.key === "-" || e.key === "-") {
                          e.preventDefault();
                        }
                      },
                    }}
                  ></TextField>
                  <Box p={1}></Box>
                  <TextField
                    type="number"
                    fullWidth
                    label="To age"
                    {...register("toAge", { valueAsNumber: true, max: 120 })}
                    error={!!errors.toAge}
                    helperText={errors.toAge?.message}
                    InputProps={{
                      inputProps: {
                        min: 1, // Optionally set a minimum value
                        max: 120, // Optionally set a maximum value
                        step: 1, // Optionally set the step value
                        inputMode: "numeric", // Specify input mode as numeric
                      },
                      // Remove the spin buttons (increase and decrease buttons)
                      inputComponent: "input",
                    }}
                  ></TextField>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: "select eligible state " }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={schemeMaster?.state || []}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(_, value, reason, details) => {
                        let isAggregate = details?.option.is_aggregate;
                        onChange(removeDuplicates(value, isAggregate));
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(
                        props,
                        option,
                        { selected, index },
                        { value }
                      ) => {
                        let isAllSelected: boolean = !!value?.find(
                          (item) => item.is_aggregate
                        );
                        return (
                          <>
                            <li {...props}>
                              <Checkbox
                                checked={selected || isAllSelected}
                                value={index}
                              />
                              {option.value}
                            </li>
                            {option.is_aggregate ? <Divider /> : null}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select eligible states"
                          fullWidth
                          placeholder="Select states"
                          error={!!errors.state}
                          helperText={errors.state?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Eligible Castes<span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="caste"
                    control={control}
                    rules={{ required: "Select eligible castes" }}
                    render={({ field: { onChange, value } }) => (
                      <ChipsWithAggregation
                        items={
                          schemeMaster?.caste?.map((item) => ({
                            id: item.id,
                            isAggregate: item.is_aggregate,
                            value: item.value,
                          })) || []
                        }
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.caste}
                        helperText={errors.caste?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Area of Residence<span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="residence"
                    control={control}
                    rules={{ required: "Select area of residence" }}
                    render={({ field: { onChange, value } }) => (
                      <ChipsWithAggregation
                        items={[
                          { id: 1, isAggregate: true, value: "All" },
                          { id: 2, isAggregate: false, value: "Urban" },
                          { id: 3, isAggregate: false, value: "Rural" },
                        ]}
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.caste}
                        helperText={errors.caste?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="workingStatus"
                  control={control}
                  rules={{ required: "Select eligible working status" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={schemeMaster?.workingStatus || []}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(_, value, reason, details) => {
                        let isAggregate = details?.option.is_aggregate;
                        onChange(removeDuplicates(value, isAggregate));
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(
                        props,
                        option,
                        { selected, index },
                        { value }
                      ) => {
                        let isAllSelected: boolean = !!value?.find(
                          (item) => item.is_aggregate
                        );
                        return (
                          <>
                            <li {...props}>
                              <Checkbox
                                checked={selected || isAllSelected}
                                value={index}
                              />
                              {option.value}
                            </li>
                            {option.is_aggregate ? <Divider /> : null}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Eligible Employment Status*"
                          fullWidth
                          placeholder="Select employment Status"
                          error={!!errors.workingStatus}
                          helperText={errors.workingStatus?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="occupation"
                  control={control}
                  rules={{ required: "Select eligible occupation" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={schemeMaster?.occupation || []}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      value={value}
                      onChange={(_, value, reason, details) => {
                        let isAggregate = details?.option.is_aggregate;
                        onChange(removeDuplicates(value, isAggregate));
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(
                        props,
                        option,
                        { selected, index },
                        { value }
                      ) => {
                        let isAllSelected: boolean = !!value?.find(
                          (item) => item.is_aggregate
                        );
                        return (
                          <>
                            <li {...props}>
                              <Checkbox
                                checked={selected || isAllSelected}
                                value={index}
                              />
                              {option.value}
                            </li>
                            {option.is_aggregate ? <Divider /> : null}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Eligible Occupation*"
                          fullWidth
                          placeholder="Select occupation*"
                          error={!!errors.occupation}
                          helperText={errors.occupation?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Eligible Marital Status
                    <span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="maritalStatus"
                    control={control}
                    rules={{ required: "Select eligible marital status" }}
                    render={({ field: { onChange, value } }) => (
                      <ChipsWithAggregation
                        items={
                          schemeMaster?.maritalStatus?.map((item) => ({
                            id: item.id,
                            isAggregate: item.is_aggregate,
                            value: item.value,
                          })) || []
                        }
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.maritalStatus}
                        helperText={errors.maritalStatus?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Whether eligible for differently abled only?
                    <span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="differentlyAbled"
                    control={control}
                    rules={{
                      validate: (value) =>
                        typeof value == "boolean"
                          ? undefined
                          : "This field is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <YesOrNoChips
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.differentlyAbled}
                        helperText={errors.differentlyAbled?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Whether eligible for Minorities only?
                    <span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="minority"
                    control={control}
                    rules={{
                      validate: (value) =>
                        typeof value == "boolean"
                          ? undefined
                          : "This field is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <YesOrNoChips
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.minority}
                        helperText={errors.minority?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Whether eligible for students only?
                    <span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="student"
                    control={control}
                    rules={{
                      validate: (value) =>
                        typeof value == "boolean"
                          ? undefined
                          : "This field is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <YesOrNoChips
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.student}
                        helperText={errors.student?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Typography>
                    Whether eligible for BPL category only?
                    <span style={{ color: "#D14C2E" }}>*</span>
                  </Typography>
                  <Controller
                    name="bplCategory"
                    control={control}
                    rules={{
                      validate: (value) =>
                        typeof value == "boolean"
                          ? undefined
                          : "This field is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <YesOrNoChips
                        value={value}
                        onChange={(value) => onChange(value)}
                        error={!!errors.bplCategory}
                        helperText={errors.bplCategory?.message}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Controller
                  name="familyIncome"
                  control={control}
                  rules={{ required: "Select family income" }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={schemeMaster?.familyIncome || []}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.value}
                      onChange={(_, value, reason, details) => {
                        let isAggregate = details?.option.is_aggregate;
                        onChange(removeDuplicates(value, isAggregate));
                      }}
                      value={value}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(
                        props,
                        option,
                        { selected, index },
                        { value }
                      ) => {
                        let isAllSelected: boolean = !!value?.find(
                          (item) => item.is_aggregate
                        );
                        return (
                          <>
                            <li {...props}>
                              <Checkbox
                                checked={selected || isAllSelected}
                                value={index}
                              />
                              {option.value}
                            </li>
                            {option.is_aggregate ? <Divider /> : null}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Family Income*"
                          fullWidth
                          placeholder="Select family income*"
                          error={!!errors.familyIncome}
                          helperText={errors.familyIncome?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box pb={2}>
            <Typography variant="body2" color="#D14C2E">
              Please note: These eligibility criteria will be carried across all
              languages
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" sx={{ pl: 6, pr: 6 }} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" sx={{ pl: 6, pr: 6 }}>
              Save Changes
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
}
