import {
  Box,
  IconButton,
  Drawer as MUIDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PropsWithChildren } from "react";
import AppLogo from "../../assets/Logo.svg";
import { purpleLight } from "../../theme/colors";
import BaseLayout from "../base/base_layout";
import Drawer from "./components/drawer";
import Navbar from "./components/navbar";

export default function MainLayout({
  children,
  dense = false,
}: PropsWithChildren<{
  dense?: boolean;
}>) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <BaseLayout>
      <Box
        display="flex"
        flexDirection="row"
        height="100vh"
        width="100vw"
        sx={{}}
      >
        <Box width={dense ? 92 : 100}>
          <MUIDrawer
            variant="permanent"
            open={true}
            PaperProps={{
              sx: {
                width: 100,
                position: "relative",
                bgcolor: purpleLight,
              },
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton sx={{ height: 72, width: 72 }}>
                  <img src={AppLogo} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Drawer />
              </Box>
            </Box>
          </MUIDrawer>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
            bgcolor={"#FFFFFF"}
            pl={dense ? 4 : 0}
          >
            <Navbar />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflow: "auto",
                paddingRight: dense ? 0 : isSmallScreen ? 4 : 16,
                paddingBottom: dense ? 0 : 6,
                width: "100%",
              }}
              ml={4}
              mt={4}
            >
              {children}
            </Box>
            {/* <Box sx={{ width: 128 }}></Box> */}
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  );
}
