import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { Scheme } from "../models/schemes";

interface Props {
  deleteScheme: boolean;
  selectedSchemeDetails: Scheme;
  deleteSchemeApiCall: () => void;
  setDeleteScheme: (val: boolean) => void;
}

export default function DeleteScheme({
  deleteScheme,
  selectedSchemeDetails,
  deleteSchemeApiCall,
  setDeleteScheme,
}: Props) {
  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });

  return (
    <Dialog open={deleteScheme} sx={{ borderRadius: 16 }}>
      <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
        <Typography variant="h5" fontWeight={600} textAlign={"center"} mt={3}>
          Are you sure you want to delete <br /> this Scheme?
        </Typography>
        <Typography variant="h6" fontWeight={600} textAlign={"center"} mt={3}>
          {selectedSchemeDetails.name}
        </Typography>
        <Box
          display="flex"
          flexDirection={"row"}
          flexWrap={"wrap"}
          mt={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {selectedSchemeDetails?.categories?.map((category) => {
            return (
              <Box display="flex" flexDirection={"row"}>
                <Typography
                  fontWeight={600}
                  mr={1}
                  color="#91278F"
                  variant="subtitle2"
                  textAlign={"center"}
                >
                  {category}
                  {" | "}
                </Typography>
              </Box>
            );
          })}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          mt={3}
        >
          <Button
            onClick={() => {
              setDeleteScheme(false);
            }}
            variant="outlined"
            sx={{
              minWidth: 150,
              height: 50,
              color: "#000000",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteSchemeApiCall?.();
            }}
            variant="contained"
            sx={{
              display: "flex",
              color: "#ffffff",
              minWidth: 150,
            }}
          >
            Delete
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
