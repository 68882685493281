import { Close } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function UpdateJobsStatus({
  open,
  onDialogClose,
  updateServiceStatus,
  title,
  subtitle,
}: {
  open: boolean;
  onDialogClose: () => void;
  updateServiceStatus: (remarks: string) => void;
  title: string;
  subtitle: string;
}) {
  const [comments, setComments] = React.useState("");

  function submit() {
    updateServiceStatus(comments);
    setComments("");
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"space-between"}
          >
            <Typography fontWeight="600">{title}</Typography>
            <Close onClick={onDialogClose} sx={{ cursor: "pointer" }} />
          </Box>
          <Box pb={2}>
            <Typography
              id="transition-modal-title"
              variant="body1"
              fontSize={14}
              fontWeight={600}
              mt={3}
            >
              {subtitle}
            </Typography>
          </Box>
          <TextField
            multiline
            minRows="5"
            fullWidth
            value={comments}
            onChange={({ target: { value } }) => {
              setComments(value);
            }}
          ></TextField>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={onDialogClose}>Cancel</Button>
            <Button variant="contained" onClick={submit} disabled={!comments}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
