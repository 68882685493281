import { useCallback } from 'react';

import 'survey-core/modern.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';


function SurveyUI() {

  const {id} = useParams<{id: string}>();
  let survey;
  if(typeof id !== "undefined"){
      // @ts-ignore: Unreachable code error
    survey = new Model(surveys[id]);
  }
  const alertResults = useCallback((sender: any) => {
    const results = JSON.stringify(sender.data);
    alert(results);
  }, []);

  survey?.onComplete.add(alertResults);
  if(survey){

  }

  return <Survey model={survey}   />;
}

export default SurveyUI;



let surveys = {
  "1": {
    "completedHtmlOnCondition": [
     {
      "expression": "{nps-score} <= 6 or {rebuy} = false",
      "html": {
       "default": "Thanks for your feedback! We highly value all ideas and suggestions from our customers, whether they're positive or critical. In the future, our team might reach out to you to learn more about how we can further improve our product so that it exceeds your expectations.",
       "fr": "Merci pour vos commentaires! Nous accordons une grande importance à toutes les idées et suggestions de nos clients, qu'elles soient positives ou critiques. À l'avenir, notre équipe pourrait vous contacter pour en savoir plus sur la façon dont nous pouvons encore améliorer notre produit afin qu'il dépasse vos attentes."
      }
     },
     {
      "expression": "{nps-score} = 6 or {nps-score} = 7",
      "html": {
       "default": "Thanks for your feedback. Our goal is to create the best possible product, and your thoughts, ideas, and suggestions play a major role in helping us identify opportunities to improve.",
       "fr": "Merci pour vos commentaires. Notre objectif est de créer le meilleur produit possible, et vos réflexions, idées et suggestions jouent un rôle majeur pour nous aider à identifier les opportunités d'amélioration."
      }
     },
     {
      "expression": "{nps-score} >= 8",
      "html": {
       "default": "Thanks for your feedback. It's great to hear that you're a fan of our product. Your feedback helps us discover new opportunities to improve it and make sure you have the best possible experience.",
       "fr": "Merci pour vos commentaires. Nous sommes ravis d'entendre que vous avez apprécié notre produit. Vos commentaires nous aident à découvrir de nouvelles opportunités pour l'améliorer et vous assurer la meilleure expérience possible."
      }
     }
    ],
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "rating",
        "name": "nps-score",
        "title": {
         "default": "On a scale from 0 to 10, how likely are you to recommend us to a friend or colleague?",
         "fr": "Sur une échelle de 0 à 10, quelle est la probabilité que vous recommandiez notre produit à un ami ou à un collègue?"
        },
        "renderAs": "dropdown",
        "autoGenerate": false,
        "rateCount": 11,
        "rateValues": [
         0,
         1,
         2,
         3,
         4,
         5,
         6,
         7,
         8,
         9,
         10
        ],
        "rateMin": 0,
        "rateMax": 10,
        "minRateDescription": {
         "default": "Very unlikely",
         "fr": "Très improbable"
        },
        "maxRateDescription": {
         "default": "Very likely",
         "fr": "Très probable"
        }
       },
       {
        "type": "comment",
        "name": "disappointing-experience",
        "visible": false,
        "visibleIf": "{nps-score} <= 5",
        "title": {
         "default": "How did we disappoint you and what can we do to make things right?",
         "fr": "Nous n'avons pas été a la hauteur de vos attentes, comment pouvons-nous améliorer?"
        },
        "maxLength": 300
       },
       {
        "type": "comment",
        "name": "improvements-required",
        "visibleIf": "{nps-score} >= 6",
        "title": {
         "default": "What can we do to make your experience more satisfying?",
         "fr": "Que pouvons-nous faire pour rendre votre expérience plus satisfaisante?"
        },
        "maxLength": 300
       },
       {
        "type": "checkbox",
        "name": "promoter-features",
        "visible": false,
        "visibleIf": "{nps-score} >= 9",
        "title": {
         "default": "Which of the following features do you value the most?",
         "fr": "Laquelle des fonctionnalités suivantes appréciez-vous le plus ?"
        },
        "description": {
         "default": "Please select no more than three features.",
         "fr": "Veuillez ne pas sélectionner plus de trois fonctionnalités."
        },
        "isRequired": true,
        "choices": [
         {
          "value": "performance",
          "text": "Performance"
         },
         {
          "value": "stability",
          "text": {
           "default": "Stability",
           "fr": "Stabilité"
          }
         },
         {
          "value": "ui",
          "text": {
           "default": "User interface",
           "fr": "Interface utilisateur"
          }
         },
         {
          "value": "complete-functionality",
          "text": {
           "default": "Complete functionality",
           "fr": "Ensemble des fonctionnalités"
          }
         },
         {
          "value": "learning-materials",
          "text": {
           "default": "Learning materials (documentation, demos, code examples)",
           "fr": "Matériel d'apprentissage (documentation, démos, exemples de code)"
          }
         },
         {
          "value": "support",
          "text": {
           "default": "Quality support",
           "fr": "Accompagnement de qualité"
          }
         }
        ],
        "showOtherItem": true,
        "otherPlaceholder": {
         "default": "Please specify...",
         "fr": "Veuillez préciser..."
        },
        "otherText": {
         "default": "Other features",
         "fr": "Autres fonctionnalités"
        },
        "colCount": 2,
        "maxSelectedChoices": 3
       }
      ]
     },
     {
      "name": "page2",
      "elements": [
       {
        "type": "boolean",
        "name": "rebuy",
        "title": {
         "default": "Would you buy our product again?",
         "fr": "Achèteriez-vous à nouveau notre produit?"
        }
       }
      ]
     },
     {
      "name": "page3",
      "elements": [
       {
        "type": "radiogroup",
        "name": "testimonial",
        "title": {
         "default": "Would you mind providing us a brief testimonial for the website?",
         "fr": "Accepteriez-vous de rédiger un bref commentaire pour notre site Internet?"
        },
        "choices": [
         {
          "value": "yes",
          "text": {
           "default": "Sure!",
           "fr": "Bien sur!"
          }
         },
         {
          "value": "no",
          "text": {
           "default": "No",
           "fr": "Non merci."
          }
         }
        ]
       },
       {
        "type": "text",
        "name": "email",
        "visible": false,
        "visibleIf": "{testimonial} = 'yes'",
        "title": {
         "default": "What is your email address?",
         "fr": "Quelle est votre adresse e-mail?"
        },
        "validators": [
         {
          "type": "email"
         }
        ],
        "placeholder": {
         "default": "Enter your email here",
         "fr": "Veuillez saisir votre adresse e-mail ici"
        }
       }
      ]
     }
    ],
    // // "showPrevButton": false,
    // // "showQuestionNumbers": "off",
    // // "completeText": {
    // //  "fr": "Envoyer"
    // // },
    // // "widthMode": "static",
    // "width": "100%"
   },

   "2": {
    "title": "COVID-19 Screening Form",
    "description": "All fields with an asterisk (*) are required fields and must be filled out in order to process the information in strict confidentiality.",
    "focusFirstQuestionAutomatic": false,
    "pages": [
     {
      "name": "patient-info",
      "elements": [
       {
        "type": "panel",
        "name": "full-name",
        "elements": [
         {
          "type": "text",
          "name": "first-name",
          "title": "First name",
          "isRequired": true,
          "maxLength": 25
         },
         {
          "type": "text",
          "name": "last-name",
          "startWithNewLine": false,
          "title": "Last name",
          "isRequired": true,
          "maxLength": 25
         }
        ],
        "title": "Full name"
       },
       {
        "type": "panel",
        "name": "personal-info",
        "elements": [
         {
          "type": "text",
          "name": "ssn",
          "title": "Social Security number",
          "isRequired": true,
          "validators": [
           {
            "type": "regex",
            "text": "Your SSN must be a 9-digit number",
            "regex": "^\\d{9}$"
           }
          ],
          "maxLength": 9
         },
         {
          "type": "text",
          "name": "birthdate",
          "startWithNewLine": false,
          "title": "Date of Birth",
          "isRequired": true,
          "inputType": "date"
         }
        ]
       }
      ],
      "title": "Patient Information"
     },
     {
      "name": "symptoms-and-contacts",
      "elements": [
       {
        "type": "checkbox",
        "name": "symptoms",
        "title": "Have you experienced any of the following symptoms of COVID-19 within the last 48 hours?",
        "isRequired": true,
        "choices": [
         "Fever or chills",
         "New and persistent cough",
         "Shortness of breath or difficulty breathing",
         "Fatigue",
         "Muscle or body aches",
         "New loss of taste or smell",
         "Sore throat"
        ],
        "showNoneItem": true,
        "noneText": "No symptoms"
       },
       {
        "type": "boolean",
        "name": "contacted-person-with-symptoms",
        "title": "Have you been in contact with anyone in the last 14 days who is experiencing these symptoms?"
       },
       {
        "type": "radiogroup",
        "name": "contacted-covid-positive",
        "title": "Have you been in contact with anyone who has since tested positive for COVID-19?",
        "choices": [
         "Yes",
         "No",
         "Not sure"
        ]
       },
       {
        "type": "boolean",
        "name": "travelled",
        "title": "Have you travelled abroad in the last 14 days?"
       },
       {
        "type": "text",
        "name": "travel-destination",
        "visibleIf": "travelled = true",
        "title": "Where did you go?"
       },
       {
        "type": "boolean",
        "name": "tested-covid-positive",
        "title": "Have you tested positive for COVID-19 in the past 10 days?"
       },
       {
        "type": "boolean",
        "name": "awaiting-covid-test",
        "title": "Are you currently awaiting results from a COVID-19 test?"
       },
       {
        "type": "paneldynamic",
        "name": "emergency-contacts",
        "visibleIf": "(({tested-covid-positive} = true or {contacted-covid-positive} = 'Yes') or ({symptoms} notempty and {symptoms} notcontains 'none'))",
        "title": "Emergency Contacts",
        "description": "If possible, it's best to specify at least TWO emergency contacts.",
        "isRequired": true,
        "templateElements": [
         {
          "type": "text",
          "name": "emergency-first-name",
          "title": "First name"
         },
         {
          "type": "text",
          "name": "emergency-last-name",
          "startWithNewLine": false,
          "title": "Last name"
         },
         {
          "type": "text",
          "name": "emergency-relationship",
          "title": "Relationship"
         },
         {
          "type": "text",
          "name": "emergency-phone",
          "startWithNewLine": false,
          "title": "Phone number",
          "inputType": "tel"
         }
        ],
        "panelsState": "firstExpanded",
        "confirmDelete": true,
        "panelAddText": "Add a new contact person"
       },
       {
        "type": "comment",
        "name": "additional-info",
        "title": "Additional information"
       },
       {
        "type": "text",
        "name": "date",
        "title": "Date",
        "inputType": "date"
       },
       {
        "type": "signaturepad",
        "name": "signature",
        "startWithNewLine": false,
        "title": "Signature"
       }
      ],
      "title": "Current Symptoms"
     }
    ],
    "showQuestionNumbers": "off",
    "questionErrorLocation": "bottom",
    "completeText": "Submit",
    "questionsOnPageMode": "singlePage",
    "showPreviewBeforeComplete": "showAnsweredQuestions",
   },

   "3": {
    "title": "Product/Market Fit Survey Template",
    "logoPosition": "right",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "radiogroup",
        "name": "customer_role",
        "title": "What best describes your role?",
        "choices": [
         "Engineering Lead",
         "Project Manager",
         "Software Developer",
         "Designer",
         "Product Manager",
         "CEO / Founder",
         "Customer Support"
        ],
        "showOtherItem": true,
        "otherText": "Other",
        "colCount": 3
       },
       {
        "type": "radiogroup",
        "name": "start_using",
        "title": "How did you start using the product?",
        "choices": [
         {
          "value": "created",
          "text": "I created my account"
         },
         {
          "value": "invited",
          "text": "I was invited to an account"
         }
        ]
       },
       {
        "type": "radiogroup",
        "name": "product_discovering",
        "title": "How did you first discover the product? ",
        "choices": [
         "Friend or colleague",
         "Search engine",
         "Facebook",
         "Twitter",
         "Blog"
        ],
        "showOtherItem": true,
        "otherText": "Other",
        "colCount": 3
       },
       {
        "type": "radiogroup",
        "name": "paid_customer",
        "title": "Do you currently pay for the product? ",
        "isRequired": true,
        "choices": [
         "Yes",
         "No"
        ]
       }
      ]
     },
     {
      "name": "page2",
      "elements": [
       {
        "type": "radiogroup",
        "name": "product_fit",
        "title": "How would you feel if you could no longer use the product?",
        "isRequired": true,
        "choices": [
         {
          "value": "3",
          "text": "Very disappointed"
         },
         {
          "value": "2",
          "text": "Somewhat disappointed"
         },
         {
          "value": "1",
          "text": "Not disappointed"
         }
        ]
       },
       {
        "type": "comment",
        "name": "product_fit_comment",
        "visibleIf": "{product_fit} notempty",
        "title": "Please help us understand why you selected the answer above"
       }
      ]
     },
     {
      "name": "page3",
      "elements": [
       {
        "type": "radiogroup",
        "name": "product_alternative",
        "title": "What would you use as an alternative if [the product] were no\nlonger available?",
        "choices": [
         "Alternative 1",
         "Alternative 2",
         "Alternative 3",
         "Alternative 4",
         "Alternative 5",
         "Alternative 6"
        ],
        "showOtherItem": true,
        "otherText": "Other (please name)",
        "colCount": 3
       },
       {
        "type": "radiogroup",
        "name": "product_benefit",
        "title": "What is the primary benefit that you have received from the\nproduct?",
        "choices": [
         "Benefit 1",
         "Benefit 2",
         "Benefit 3",
         "Benefit 4",
         "Benefit 5",
         "Benefit 6"
        ],
        "showOtherItem": true,
        "colCount": 3
       },
       {
        "type": "radiogroup",
        "name": "product_recommend",
        "title": "Have you recommended the product to anyone?",
        "choices": [
         "Yes",
         "No"
        ]
       }
      ]
     },
     {
      "name": "page4",
      "elements": [
       {
        "type": "rating",
        "name": "nps_score",
        "title": "How likely are you to recommend the product to a friend or\ncolleague? ",
        "isRequired": true,
        "rateCount": 11,
        "rateMin": 0,
        "rateMax": 10,
        "minRateDescription": "Most unlikely",
        "maxRateDescription": "Most likely"
       },
       {
        "type": "radiogroup",
        "name": "favorite_functionality",
        "title": "What's your favorite functionality / add-on for the product?",
        "choices": [
         "Feature 1",
         "Feature 2",
         "Feature 3",
         "Feature 4",
         "Feature 5",
         "Feature 6"
        ],
        "showOtherItem": true,
        "colCount": 3
       },
       {
        "type": "comment",
        "name": "product_improvement",
        "title": "How could the product be improved to better meet your\nneeds?"
       }
      ]
     },
     {
      "name": "page5",
      "elements": [
       {
        "type": "multipletext",
        "name": "contact_customer",
        "title": "Want us to follow-up? Leave your name and email here:",
        "items": [
         {
          "name": "Name"
         },
         {
          "name": "E-mail",
          "inputType": "email",
          "validators": [
           {
            "type": "email"
           }
          ]
         }
        ]
       }
      ]
     }
    ]
   },
   
   
  "4": {
    // "title": "Product Feedback Survey",
    // "description": "Your opinion matters to us!",
    // "logo": "https://api.surveyjs.io/private/Surveys/files?name=df89f942-7e47-48e0-9fc0-b64608584b4c",
    // "logoHeight": "100px",
    // "logoFit": "cover",
    // "logoPosition": "right",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "radiogroup",
        "name": "discovery-source",
        "title": "How did you first hear about us?",
        "choices": [
         "Search engine (Google, Bing, etc.)",
         "Online newsletter",
         "Blog post",
         "Word of mouth",
         "Social media"
        ],
        "showOtherItem": true,
        "otherPlaceholder": "Please specify...",
        "otherText": "Other"
       },
       {
        "type": "radiogroup",
        "name": "social-media-platform",
        "visibleIf": "{discovery-source} = 'Social media'",
        "title": "Which platform?",
        "choices": [
         "YouTube",
         "Facebook",
         "Instagram",
         "TikTok",
         "LinkedIn"
        ],
        "showOtherItem": true,
        "otherPlaceholder": "Please specify...",
        "otherText": "Other"
       },
       {
        "type": "matrix",
        "name": "quality",
        "title": "To what extent do you agree with the following statements?",
        // "columnMinWidth": "40px",
        // "rowTitleWidth": "300px",
        "columns": [
         {
          "value": 1,
          "text": "Strongly disagree"
         },
         {
          "value": 2,
          "text": "Disagree"
         },
         {
          "value": 3,
          "text": "Undecided"
         },
         {
          "value": 4,
          "text": "Agree"
         },
         {
          "value": 5,
          "text": "Strongly agree"
         }
        ],
        "rows": [
         {
          "value": "needs-are-met",
          "text": "The product meets my needs"
         },
         {
          "value": "satisfaction",
          "text": "Overall, I am satisfied with the product"
         },
         {
          "value": "improvements-required",
          "text": "Some product features require improvement"
         }
        ]
       },
       {
        "type": "rating",
        "name": "buying-experience",
        "title": "How would you rate the buying experience?",
        "minRateDescription": "Hated it!",
        "maxRateDescription": "Loved it!"
       },
       {
        "type": "comment",
        "name": "low-score-reason",
        "visibleIf": "{buying-experience} <= 3",
        "titleLocation": "hidden",
        "hideNumber": true,
        "maxLength": 500,
        "placeholder": "What's the main reason for your score?"
       },
       {
        "type": "boolean",
        "name": "have-used-similar-products",
        "title": "Have you used similar products before?"
       },
       {
        "type": "text",
        "name": "similar-products",
        "visibleIf": "{have-used-similar-products} = true",
        "titleLocation": "hidden",
        "hideNumber": true,
        "placeholder": "Please specify the similar products..."
       },
       {
        "type": "ranking",
        "name": "product-aspects-ranked",
        "title": "These are some important aspects of the product. Rank them in terms of your priority.",
        "description": "From the highest (the most important) to the lowest (the least important).",
        "choices": [
         "Technical support",
         "Price",
         "Delivery option",
         "Quality",
         "Ease of use",
         "Product warranties"
        ]
       },
       {
        "type": "text",
        "name": "missing-feature",
        "title": "What's the ONE thing our product is missing?"
       },
       {
        "type": "dropdown",
        "name": "price-accuracy",
        "title": "Do you feel our product is worth the cost?",
        "choices": [
         {
          "value": 5,
          "text": "Definitely"
         },
         {
          "value": 4,
          "text": "Probably"
         },
         {
          "value": 3,
          "text": "Maybe"
         },
         {
          "value": 2,
          "text": "Probably not"
         },
         {
          "value": 1,
          "text": "Definitely not"
         }
        ],
        "allowClear": false
       },
       {
        "type": "boolean",
        "name": "have-additional-thoughts",
        "title": "Is there anything you'd like to add?"
       },
       {
        "type": "comment",
        "name": "additional-thoughts",
        "visibleIf": "{have-additional-thoughts} = true",
        "titleLocation": "hidden",
        "placeholder": "Please share your thoughts..."
       }
      ]
     }
    ],
   },

   "5":{
    "pages": [
     {
      "name": "Name",
      "elements": [
       {
        "type": "text",
        "name": "FirstName",
        "title": {
         "default": "Enter your first name:",
         "Kannada": "ś ಗಾಗಿ z ಅಥವಾ ç ಅಥವಾ sh ಎಂದು ಟೈಪ್ ಮಾಡಿ",
         "Hindi": "For example, typing \"Aap kasai hai?\" becomes \"आप कैसे हैं?\"."
        }
       },
       {
        "type": "text",
        "name": "LastName",
        "title": "Enter your last name:"
       },
       {
        "type": "dropdown",
        "name": "question1",
        "title": "Search engine",
        "choices": [
         {
          "value": "Item 2",
          "text": {
           "default": "Google",
           "Kannada": "ಅಥವಾ",
           "Hindi": "आप"
          }
         },
         {
          "value": "Item 3",
          "text": "Bing"
         }
        ]
       }
      ]
     }
    ],
    "calculatedValues": [
     {
      "name": "var1"
     }
    ]
   }

  }