import HttpClient from "../../utils/http";
import {
  cityMaster,
  districtMaster,
  masterSchemes,
  stateMaster,
} from "../constants/api_endpoints";

export function getMasterData() {
  return HttpClient.get(masterSchemes);
}

export function getStateMasterData() {
  return HttpClient.get(stateMaster, {
    headers: {
      locale: "en",
    },
  });
}

export function getDistrictMasterData(state: number | null) {
  let url = `${districtMaster}?state=${state}`;
  return HttpClient.get(url, {
    headers: {
      locale: "en",
    },
  });
}
export function getCityMasterData(state: number | null) {
  let url = `${cityMaster}?state=${state}`;
  return HttpClient.get(url, {
    headers: {
      locale: "en",
    },
  });
}
