import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import MainLayout from "../../../layouts/main/main_layout";
import { ArrowBack, Download } from "@mui/icons-material";
import Loading from "../../../components/loading/loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XlsIcon from "../../../assets/xls_icon.svg";
import {
  downloadChoiceFamilyDetails,
  getChoiceFamilyDetails,
} from "../../../services/choices/choices_services";
import { useQuery } from "react-query";
import { downloadFromBlob } from "../../../utils/utils";
import { ResponseFamilyDetails } from "../model/response_family_Details";
import SurveyMedia from "../../../components/survey_media/survey_media";

export default function FamilyResponseDetails() {
  let { id } = useParams();
  let { state } = useLocation();

  const { data: choicesFamilyDetails, isLoading } =
    useQuery<ResponseFamilyDetails>(["viewResponseDetails"], async () => {
      let response = await getChoiceFamilyDetails(+id!, +state?.id);
      return response.data.data || [];
    });
  const downloadViewChoiceApiCall = () => {
    downloadChoiceFamilyDetails(+id!, +state?.id!)
      .then((res) => {
        let extension = ".xlsx";
        downloadFromBlob(res, extension);
      })
      .catch((e) => {});
  };

  const navigate = useNavigate();

  function divider() {
    return (
      <Divider
        sx={{
          display: "flex",
          opacity: 0.5,
          borderBottom: "1px dotted #7C797C",
        }}
      />
    );
  }

  function IconComponent(
    imgSrc: string,
    backgroundColor: string,
    onTap?: () => void
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAEDEA",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          borderRadius: 3,
          ml: 2,
        }}
      >
        <IconButton
          onClick={onTap}
          sx={{
            height: 24,
            width: 24,
          }}
        >
          <img src={imgSrc} alt="" />
        </IconButton>
      </Box>
    );
  }
  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  function answerType({ type, response }: { type: string; response: any }) {
    switch (type) {
      case null:
        return (
          (typeof response === "string" || typeof response === "boolean") && (
            <Box>
              <Typography>{response?.toString()}</Typography>
            </Box>
          )
        );
      case "text":
      case "comment":
      case "radiogroup":
      case "rating":
      case "checkbox":
      case "dropdown":
      case "tagbox":
      case "ranking":
      case "boolean":
        return (
          <Box>
            <Typography>{response?.toString()}</Typography>
          </Box>
        );
      case "multipletext":
        const objectValuesArray = Object.values(response);
        return (
          <Box>
            {objectValuesArray.map((value: any, index: any) => (
              <Typography>{value}</Typography>
            ))}
          </Box>
        );
      case "matrix":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Typography>{`${qsn} : ${response[`${qsn}`]}`}</Typography>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdropdown":
        return (
          <Box>
            {Object.keys(response).map((qsn) => {
              return (
                <Box display={"flex"}>
                  <Typography>{`${qsn} : `}</Typography>
                  {Object.keys(response[qsn]).map((item) => (
                    <Typography mx={1}>
                      {` ${item} -> ${response[qsn][item]}`}
                    </Typography>
                  ))}
                </Box>
              );
            })}
            <Typography></Typography>
          </Box>
        );
      case "matrixdynamic":
        return (
          <Box>
            {response.map((item: any) => {
              return Object.keys(item).map((qsnKey: any) => {
                return (
                  <Typography>{`${qsnKey} : ${item[`${qsnKey}`]}`}</Typography>
                );
              });
            })}
            <Typography></Typography>
          </Box>
        );
        case "file":
          return (
            <Box>
              {response.map((item: any) => {
                return (
                  <Box py={1}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2">{item.name}</Typography>
                      &nbsp;&nbsp;{" "}
                      <a href={item.content} download style={{textDecoration: 'none'}} target="_blank">
                        <IconButton size="small">
                          <Download />
                        </IconButton>
                      </a>
                    </Box>
  
                    {/* <SurveyMedia url={item.content} type={item.type} /> */}
                  </Box>
                );
              })}
              <Typography></Typography>
            </Box>
          );
    }
  }

  return (
    <MainLayout>
      <Grid container mt={4}>
        <Grid item lg={8} md={12} sm={12} xs={12} xl={8}>
          {isLoading ? (
            <Card
              sx={{
                display: "flex",
                minHeight: 500,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Loading />
              </Box>
            </Card>
          ) : (
            <Card sx={{ px: 2, pb: 2 }}>
              <Box display={"flex"} alignItems={"center"} py={2}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight={"600"} m={1}>
                  Choice Family Details
                </Typography>
              </Box>
              {divider()}
              <Grid container spacing={3} mt={3}>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Primary User Name",
                    value: choicesFamilyDetails?.userDetails.name,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Primary User Contact Number",
                    value: choicesFamilyDetails?.userDetails.mobileNumber,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "State",
                    value: choicesFamilyDetails?.userDetails.state,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "District",
                    value: choicesFamilyDetails?.userDetails.district,
                  })}
                </Grid>
              </Grid>
              <Box mt={4}>{divider()}</Box>
              <Box mt={3}>
                <Typography variant="subtitle1" fontWeight={"600"}>
                  Family Member Details
                </Typography>
              </Box>
              <Grid container spacing={3} mt={3}>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Relationship with Primary User",
                    value: choicesFamilyDetails?.userDetails.relationship,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Family Member Name",
                    value: choicesFamilyDetails?.userDetails.memberName,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Family Member Date of Birth",
                    value: choicesFamilyDetails?.userDetails.memberDOB,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Family Member Gender",
                    value: choicesFamilyDetails?.userDetails.memberGender,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Family Member Occupation",
                    value: choicesFamilyDetails?.userDetails.memberOccupation,
                  })}
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  {listItem({
                    title: "Family Member Contact Number",
                    value: choicesFamilyDetails?.userDetails.memberMobileNumber,
                  })}
                </Grid>
              </Grid>
              <Box mt={4}>{divider()}</Box>
              <Box mt={2}>
                <Typography variant="subtitle1" fontWeight={"600"}>
                  Question and Answers
                </Typography>
              </Box>
              <Box>
                <Card
                  sx={{
                    height: 350,
                    overflowY: "scroll",
                    p: 2,
                    borderRadius: 5,

                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#F5E6F5",
                      borderRadius: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#DFB0DF",
                      borderRadius: "6px",
                    },
                  }}
                >
                  {choicesFamilyDetails?.choiceDetails?.answerList?.map(
                    (items: any, index: number) => {
                      return (
                        items.response && (
                          <Box mb={1} key={index}>
                            <Typography color="#91278F" variant="body2">
                              {index + 1}. {items?.question}
                            </Typography>
                            {answerType({
                              type: items.questionType,
                              response: items.response,
                            })}
                          </Box>
                        )
                      );
                    }
                  )}
                </Card>
              </Box>
              <Box
                display="flex"
                flexDirection={"row"}
                mt={1}
                alignItems={"center"}
              >
                <Typography variant="subtitle2">Generate Report: </Typography>
                {IconComponent(XlsIcon, "#FAEDEA", () => {
                  downloadViewChoiceApiCall();
                })}
              </Box>
              <Box mt={3}>
                <Button onClick={() => navigate(-1)} variant="outlined">
                  Back To Responses
                </Button>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}
