import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function UpdateNeedStatus({
  open,
  onDialogClose,
  updateNeedStatusToClosed,
}: {
  open: boolean;
  onDialogClose: () => void;
  updateNeedStatusToClosed: (remarks: string) => void;
}) {
  const [comments, setComments] = React.useState("");
  const [error, setError] = React.useState("");

  function submit() {
    setError("");
    if (comments.length == 0) {
      setError("Enter comments");
    } else {
      updateNeedStatusToClosed(comments);
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box pb={2}>
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={18}
              fontWeight={600}
            >
              Are you sure you want to mark this needs as closed?
            </Typography>
          </Box>
          <Box position={"relative"}>
            <TextField
              multiline
              minRows="9"
              fullWidth
              value={comments}
              onChange={({ target: { value } }) => {
                setComments(value);
              }}
              error={Boolean(error)}
              helperText={error}
              label="Closing Feedback"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 300,
              }}
            ></TextField>
            <Typography
              variant="caption"
              sx={{ position: "absolute", bottom: 8, left: 12 }}
            >{`${300 - comments.length} characters left`}</Typography>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Box onClick={onDialogClose}>
              <Typography
                variant="button"
                color="#91278F"
                sx={{
                  borderBottom: "1px solid #990099",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
              >
                Close
              </Typography>
            </Box>
            <Button variant="contained" onClick={submit} disabled={!comments}>
              Mark as closed
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
