import {
  ArrowBack,
  EditOutlined,
  KeyboardArrowRight,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  TextField as MuiTextField,
  InputAdornment
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import FilterIcon from "../../assets/filter_list.svg";
import PDFIcon from "../../assets/ph_file-pdf.svg";
import Sort from "../../assets/sort.svg";
import XLSIcon from "../../assets/ph_file-xls.svg";
import Loading from "../../components/loading/loading";
import MainLayout from "../../layouts/main/main_layout";
import { downloadNeeds, getNeeds } from "../../services/needs/needs_service";
import NeedFilters from "./components/needs_filter/needs_filter";
import { downloadFromBlob } from "../../utils/utils";
import { orange, purpleLightActive } from "../../theme/colors";
import useUser from "../../providers/user/user_provider";
import AccessDenied from "../../components/access_denied/access_denied";
import { RECORDS_EXCEEDS_ALERT_MSG } from "../../constants";
import { useFeedback } from "../../providers/feedback/feeedback";
import { getMasterData } from "../../services/choices/choices_services";
import { getStateMasterData } from "../../services/master/master_service";
import styled from "@emotion/styled";

const columns: GridColDef[] = [
  {
    field: "needId",
    headerName: "Ref. No.",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "name",
    headerName: "Full Name",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "mobileNumber",
    headerName: "Contact Number",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "subCategory",
    headerName: "Sub Category",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "area",
    headerName: "Area",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "city",
    headerName: "City",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "district",
    headerName: "District",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Submitted Date",
    width: 160,
    sortable: false,
    filterable: false,
    renderCell: (e) => {
      return dayjs(e.value).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    field: "systemCategory",
    headerName: "SysGen Category",
    width: 160,
    sortable: false,
    filterable: false,
  },
  {
    field: "systemSubCategory",
    headerName: "SysGen Sub Category",
    width: 160,
    sortable: false,
    filterable: false,
  },
  {
    field: "systemSubCompany",
    headerName: "SysGen Company",
    width: 160,
    sortable: false,
    filterable: false,
  },
  {
    field: "systemBrand",
    headerName: "SysGen Sub Brand",
    width: 160,
    sortable: false,
    filterable: false,
  },
];

const TextField = styled(MuiTextField)(({ theme }) => {
  return {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "transparent", // Border color when focused
      },
      "&:hover fieldset": {
        borderColor: "transparent", // Border color on hover
      },
      "& fieldset": {
        borderColor: "transparent", // Default border color
      },
    },
  };
});

export default function Needs() {
  const { userPermissions, hasPermission } = useUser();
  const [searchParams, setUrlParam] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: searchParams.has("page")
      ? parseInt(searchParams?.get("page")!) - 1
      : 0,
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
  const [concerningToId, setConcerningToId] = useState<number | null>(null);
  const [subcategoryId, setSubcategoryId] = useState<number | null>(null);
  const [areasId, setAreasId] = useState<number | null>(null);
  const [brandId, setBrandId] = useState<number | null>(null);
  const [status, setStatus] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [sortInAcceding, setSortInAcceding] = useState(true);
  const [stateId, setStateId] = useState<number | null>(null);
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const { showSnackbar } = useFeedback();

  useEffect(() => {
    setUrlParam({ page: (paginationModel.page + 1).toString() });
  }, [paginationModel, setUrlParam]);

  const { data: masterDataList } = useQuery(["masterDataList",], async () => {
    let response = await getStateMasterData();
    return response?.data?.data?.list;
  });

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    [
      "needs",
      paginationModel,
      concerningToId,
      subcategoryId,
      areasId,
      status,
      cityId,
      startDate,
      endDate,
      sortInAcceding,
      stateId,
      searchValue,
      districtId
    ],
    async () => {
      let sortOrder = sortInAcceding ? "DESC" : "ASC";
      let response = await getNeeds({
        paginationModel,
        concerningToId,
        subcategoryId,
        areasId,
        status,
        cityId,
        startDate,
        endDate,
        sortOrder,
        state: stateId,
        keyword: searchValue,
        district: districtId
      });
      return response?.data?.data;
    }
  );

  let needsList = data?.data?.list;
  // if (needsList) {
  //   needsList = [...needsList].reverse();
  // }
  let rowCount = data?.data?.total;

  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loading />
      </Box>
    );
  }

  function searchComponent() {
    return (
      <Box mt={2}>
        <TextField
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          size="small"
          fullWidth
          sx={{
            backgroundColor: "#F5E6F5",
            borderRadius: 3,
            borderColor: "transparent",
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "text.primary" }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  }

  function resetFilters() {
    setFilterDialogOpen(false);
    setConcerningToId(null);
    setSubcategoryId(null);
    setAreasId(null);
    setStatus(null);
    setCityId(null);
    setStartDate(null);
    setEndDate(null);
    setStateId(null);
    setDistrictId(null);
  }

  function downloadPdf() {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";
    if (data?.data?.total <= 1000) {
      downloadNeeds({
        page: 1,
        size: 1000,
        concerningToId,
        subcategoryId,
        areasId,
        status,
        cityId,
        startDate,
        endDate,
        format: "pdf",
        sortOrder: sortOrder,
        state: stateId,
        // keyword: searchValue
      }).then((response) => {
        downloadFromBlob(response, new Date().valueOf() + ".pdf");
      });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
  }

  function downloadExcel() {
    let sortOrder = sortInAcceding ? "DESC" : "ASC";
    if (data?.data?.total <= 1000) {
      downloadNeeds({
        page: 1,
        size: 1000,
        concerningToId,
        subcategoryId,
        areasId,
        status,
        cityId,
        startDate,
        endDate,
        format: "excel",
        sortOrder: sortOrder,
        state: stateId,
      }).then((response) => {
        downloadFromBlob(response, new Date().valueOf() + ".xlsx");
      });
    } else {
      showSnackbar(RECORDS_EXCEEDS_ALERT_MSG);
    }
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} xs={12} lg={12} xl={12}>
          {hasPermission("Needs", "Read") ? (
            <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
              <CardContent>
                <Box display="flex" flexDirection="row">
                  <Box width={200} display="flex" alignItems="center">
                    <IconButton
                      onClick={() => navigate("/reports", { replace: true })}
                    >
                      <ArrowBack></ArrowBack>
                    </IconButton>
                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                      Needs
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <Typography>Download as:</Typography>
                      <IconButton onClick={downloadExcel}>
                        <img src={XLSIcon} alt="" />
                      </IconButton>
                      <IconButton onClick={downloadPdf}>
                        <img src={PDFIcon} alt="" />
                      </IconButton>
                    </Box>
                    <Box
                      ml={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <Typography>Sort By: </Typography>
                      <Box
                        ml={1}
                        display="flex"
                        flexDirection={"row"}
                        alignItems="center"
                      >
                        <Typography color={"#91278F"}>
                          {sortInAcceding ? "New to old" : "Old to New"}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            setSortInAcceding(!sortInAcceding);
                          }}
                        >
                          <img src={Sort} alt="" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      ml={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <Typography
                        display="flex"
                        alignItems={"center"}
                        color="#91278F"
                        fontWeight="600"
                      >
                        Filters:
                      </Typography>
                      <IconButton onClick={() => setFilterDialogOpen(true)}>
                        <img src={FilterIcon} alt="" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                {searchComponent()}
                <Box overflow="auto" flex={1}>
                  {isLoading ? (
                    loading()
                  ) : (
                    <Box display="flex" width="100%" alignItems="stretch">
                      <DataGrid
                        paginationModel={paginationModel}
                        paginationMode="server"
                        rowCount={rowCount || 50}
                        onPaginationModelChange={setPaginationModel}
                        filterMode="client"
                        sx={{
                          border: "none",
                          flex: 1,
                          "& .MuiDataGrid-columnHeaders": {
                            color: "#91278F",
                          },
                          "& .MuiDataGrid-row": {
                            border: "1px dotted rgba(0, 0, 0, 0.42)", // Change the border style here
                            borderLeft: "none",
                          },
                        }}
                        rows={needsList || []}
                        autoHeight
                        getRowId={(row) => row?.needId}
                        onRowClick={(row) => {
                          navigate(`/needs/${row.id}`);
                        }}
                        columns={columns.map((column) =>
                          column.field === "systemSubCompany" ||
                            column.field === "systemSubCategory"
                            ? {
                              ...column,
                              renderCell: (params: GridRenderCellParams) => {
                                if (
                                  params.row.systemCategory === "Corporate" &&
                                  column.field === "systemSubCompany"
                                ) {
                                  return (
                                    <Box>
                                      <Typography variant="subtitle2">
                                        {params.row.systemSubCategory}
                                      </Typography>
                                    </Box>
                                  );
                                } else if (
                                  params.row.systemCategory !== "Corporate" &&
                                  column.field === "systemSubCategory"
                                ) {
                                  return (
                                    <Box>
                                      <Typography variant="subtitle2">
                                        {params.row.systemSubCategory}
                                      </Typography>
                                    </Box>
                                  );
                                } else {
                                  return (
                                    <Box>
                                      <Typography variant="subtitle2">
                                        NA
                                      </Typography>
                                    </Box>
                                  );
                                }
                              },
                            } // Apply the custom component for the 'name' column
                            : column
                        )}
                      />
                      <Box
                        width={250}
                        position="relative"
                        borderLeft={"1px solid red"}
                        borderColor={purpleLightActive}
                        overflow="hidden"
                      >
                        <DataGrid
                          hideFooter={true}
                          filterMode="client"
                          sx={{
                            zIndex: 9999,
                            border: "none",
                            flex: 1,
                            overflow: "hidden",
                            "& .MuiDataGrid-columnHeaders": {
                              color: "#91278F",
                            },
                            "& .MuiDataGrid-row": {
                              border: "1px dotted rgba(0, 0, 0, 0.42)", // Change the border style here
                              borderLeft: "none",
                            },
                          }}
                          rows={needsList || []}
                          getRowId={(row) => row?.needId}
                          components={{
                            NoResultsOverlay: (props) => {
                              return <Box></Box>;
                            },
                          }}
                          columns={[
                            {
                              field: "status",
                              headerName: "Status",
                              width: 200,
                              sortable: false,
                              filterable: false,
                              renderCell: (row) => {
                                return (
                                  <Box
                                    display="flex"
                                    width="100%"
                                    alignItems="center"
                                  >
                                    <Typography
                                      textAlign={"center"}
                                      flex={1}
                                      color={
                                        row.value === "Closed"
                                          ? "success.main"
                                          : row.value == "In Progress"
                                            ? orange
                                            : "text.primary"
                                      }
                                      variant="body2"
                                      fontWeight={600}
                                    >
                                      {row.value}
                                      <EditOutlined
                                        sx={{ width: 15, height: 15, ml: 1 }}
                                      />
                                    </Typography>
                                    <IconButton>
                                      <KeyboardArrowRight
                                        sx={{ color: "#D14C2E" }}
                                      />
                                    </IconButton>
                                  </Box>
                                );
                              },
                            },
                          ]}
                          autoHeight
                          onRowClick={(row) => {
                            navigate(`/needs/${row.id}`);
                          }}
                        />
                        <Box
                          position="absolute"
                          bottom={0}
                          left={0}
                          width="100%"
                          height={40}
                          bgcolor="white"
                        ></Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={600}
            >
              <AccessDenied />
            </Box>
          )}
        </Grid>
      </Grid>
      {filterDialogOpen ? (
        <NeedFilters
          districtId={districtId}
          states={masterDataList || []}
          setStateId={setStateId}
          stateId={stateId}
          open={filterDialogOpen}
          onDialogClose={() => {
            setFilterDialogOpen(false);
            setPaginationModel({ ...paginationModel, page: 0 });
          }}
          setAreasId={setAreasId}
          setConcerningToId={setConcerningToId}
          areasId={areasId}
          setStatus={setStatus}
          cityId={cityId}
          setCityId={setCityId}
          setSubcategoryId={setSubcategoryId}
          status={status}
          subcategoryId={subcategoryId}
          concerningToId={concerningToId}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          resetFilters={resetFilters}
          brandId={brandId}
          setBrandId={setBrandId}
          setDistrictId={setDistrictId}
        />
      ) : null}
    </MainLayout>
  );
}
