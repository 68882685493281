import { surveyLocalization } from "survey-core";

let dict = {
   "pagePrevText": "మునుపటి",
   "pageNextText": "తరువాత",
   "completeText": "పూర్తి",
   "previewText": "ప్రివ్యూ",
   "editText": "సవరించు",
   "startSurveyText": "ప్రారంభించండి",
   "otherItemText": "ఇతర (వివరించండి)",
   "noneItemText": "ఏదీ లేదు",
   "selectAllItemText": "అన్ని ఎంచుకోండి",
   "progressText": "{1}లో {0}వ పేజీ",
   "indexText": "{1}లో {0}",
   "panelDynamicProgressText": "{1}లో {0}",
   "panelDynamicTabTextFormat": "ప్యానెల్ {panelIndex}",
   "questionsProgressText": "{0}/{1} ప్రశ్నలకు సమాధానం ఇచ్చారు",
   "emptySurvey": "సర్వేలో కనిపించే అంశాలు ఏవీ లేవు.",
   "completingSurvey": "సర్వేను పూర్తి చేసినందుకు ధన్యవాదాలు",
   "completingSurveyBefore": "మీరు ఇప్పటికే ఈ సర్వేను పూర్తి చేసినట్లు మా రికార్డులు చూపిస్తున్నాయి.",
   "loadingSurvey": "సర్వే లోడ్ అవుతోంది...",
   "placeholder": "ఎంచుకోండి...",
   "ratingOptionsCaption": "ఎంచుకోండి...",
   "value": "విలువ",
   "requiredError": "ప్రతిస్పందన అవసరం.",
   "requiredErrorInPanel": "ప్రతిస్పందన అవసరం: కనీసం ఒక ప్రశ్నకు సమాధానం ఇవ్వండి.",
   "requiredInAllRowsError": "ప్రతిస్పందన అవసరం: అన్ని అడ్డు వరుసలలో ప్రశ్నలకు సమాధానం ఇవ్వండి.",
   "numericError": "విలువ సంఖ్యాపరంగా ఉండాలి.",
   "minError": "విలువ {0} కంటే తక్కువ ఉండకూడదు",
   "maxError": "విలువ {0} కంటే ఎక్కువ ఉండకూడదు",
   "textMinLength": "దయచేసి కనీసం {0} అక్షరాలు(లు) నమోదు చేయండి.",
   "textMaxLength": "దయచేసి {0} అక్షర(లు) కంటే ఎక్కువ నమోదు చేయవద్దు.",
   "textMinMaxLength": "దయచేసి కనీసం {0} మరియు {1} కంటే ఎక్కువ అక్షరాలను నమోదు చేయండి.",
   "minRowCountError": "దయచేసి కనీసం {0} అడ్డు వరుస(లు)ని పూరించండి.",
   "minSelectError": "దయచేసి కనీసం {0} ఎంపిక(లు) ఎంచుకోండి.",
   "maxSelectError": "దయచేసి {0} ఎంపిక(లు) కంటే ఎక్కువ ఎంచుకోవద్దు.",
   "numericMinMax": "&#39;{0}&#39; కనీసం {1} మరియు గరిష్టంగా {2} ఉండాలి",
   "numericMin": "&#39;{0}&#39; కనీసం {1} అయి ఉండాలి",
   "numericMax": "&#39;{0}&#39; గరిష్టంగా {1} ఉండాలి",
   "invalidEmail": "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామాను నమోదు చేయండి.",
   "invalidExpression": "వ్యక్తీకరణ: {0} &#39;true&#39;ని అందించాలి.",
   "urlRequestError": "అభ్యర్థన &#39;{0}&#39; లోపాన్ని అందించింది. {1}",
   "urlGetChoicesError": "అభ్యర్థన ఖాళీ డేటాను అందించింది లేదా &#39;పాత్&#39; ప్రాపర్టీ తప్పు",
   "exceedMaxSize": "ఫైల్ పరిమాణం {0}ని మించకూడదు.",
   "noUploadFilesHandler": "ఫైల్‌లు అప్‌లోడ్ చేయబడవు. దయచేసి &#39;onUploadFiles&#39; ఈవెంట్ కోసం హ్యాండ్లర్‌ను జోడించండి.",
   "otherRequiredError": "ప్రతిస్పందన అవసరం: మరొక విలువను నమోదు చేయండి.",
   "uploadingFile": "మీ ఫైల్ అప్‌లోడ్ అవుతోంది. దయచేసి కొన్ని సెకన్లు వేచి ఉండి, మళ్లీ ప్రయత్నించండి.",
   "loadingFile": "లోడ్...",
   "chooseFile": "ఫైల్(ల)ని ఎంచుకోండి...",
   "noFileChosen": "ఫైల్ ఎంపిక చెయ్యలేదు",
   "filePlaceholder": "ఫైల్‌ను ఇక్కడకు లాగి వదలండి లేదా అప్‌లోడ్ చేయడానికి ఫైల్‌ను ఎంచుకోవడానికి దిగువ బటన్‌ను క్లిక్ చేయండి.",
   "confirmDelete": "మీరు రికార్డ్‌ను తొలగించాలనుకుంటున్నారా?",
   "keyDuplicationError": "ఈ విలువ ప్రత్యేకంగా ఉండాలి.",
   "addColumn": "కాలమ్ జోడించండి",
   "addRow": "అడ్డు వరుసను జోడించండి",
   "removeRow": "తొలగించు",
   "emptyRowsText": "వరుసలు లేవు.",
   "addPanel": "కొత్తది జత పరచండి",
   "removePanel": "తొలగించు",
   "showDetails": "వివరాలు చుపించండి",
   "hideDetails": "వివరాలను దాచండి",
   "choices_Item": "అంశం",
   "matrix_column": "కాలమ్",
   "matrix_row": "వరుస",
   "multipletext_itemname": "వచనం",
   "savingData": "ఫలితాలు సర్వర్‌లో సేవ్ చేయబడుతున్నాయి...",
   "savingDataError": "లోపం సంభవించింది మరియు మేము ఫలితాలను సేవ్ చేయలేకపోయాము.",
   "savingDataSuccess": "ఫలితాలు విజయవంతంగా సేవ్ చేయబడ్డాయి!",
   "saveAgainButton": "మళ్లీ ప్రయత్నించండి",
   "timerMin": "నిమి",
   "timerSec": "సెకను",
   "timerSpentAll": "మీరు ఈ పేజీలో {0} మరియు మొత్తం {1} ఖర్చు చేసారు.",
   "timerSpentPage": "మీరు ఈ పేజీలో {0} ఖర్చు చేసారు.",
   "timerSpentSurvey": "మీరు మొత్తం {0} ఖర్చు చేసారు.",
   "timerLimitAll": "మీరు ఈ పేజీలో {1}లో {0} మరియు మొత్తం {3}లో {2} ఖర్చు చేసారు.",
   "timerLimitPage": "మీరు ఈ పేజీలో {1}లో {0} ఖర్చు చేసారు.",
   "timerLimitSurvey": "మీరు మొత్తం {1}లో {0} ఖర్చు చేసారు.",
   "clearCaption": "క్లియర్",
   "signaturePlaceHolder": "ఇక్కడ సంతకం పెట్టండి",
   "chooseFileCaption": "ఫైల్‌ని ఎంచుకోండి",
   "takePhotoCaption": "ఫోటో తీసుకో",
   "photoPlaceholder": "కెమెరాను ఉపయోగించి ఫోటో తీయడానికి క్రింది బటన్‌ను క్లిక్ చేయండి.",
   "fileOrPhotoPlaceholder": "కెమెరాను ఉపయోగించి అప్‌లోడ్ చేయడానికి లేదా ఫోటో తీయడానికి ఫైల్‌ను లాగండి మరియు వదలండి లేదా ఎంచుకోండి.",
   "replaceFileCaption": "ఫైల్‌ను భర్తీ చేయండి",
   "removeFileCaption": "ఈ ఫైల్‌ని తీసివేయండి",
   "booleanCheckedLabel": "అవును",
   "booleanUncheckedLabel": "నం",
   "confirmRemoveFile": "మీరు ఖచ్చితంగా ఈ ఫైల్‌ని తీసివేయాలనుకుంటున్నారా: {0}?",
   "confirmRemoveAllFiles": "మీరు ఖచ్చితంగా అన్ని ఫైల్‌లను తీసివేయాలనుకుంటున్నారా?",
   "questionTitlePatternText": "ప్రశ్న శీర్షిక",
   "modalCancelButtonText": "రద్దు చేయండి",
   "modalApplyButtonText": "దరఖాస్తు చేసుకోండి",
   "filterStringPlaceholder": "శోధించడానికి టైప్ చేయండి...",
   "emptyMessage": "ప్రదర్శించడానికి డేటా లేదు",
   "noEntriesText": "ఇంకా ఎంట్రీలు లేవు.కొత్త ఎంట్రీని జోడించడానికి దిగువ బటన్‌ను క్లిక్ చేయండి.",
   "noEntriesReadonlyText": "ఎంట్రీలు లేవు.",
   "more": "మరింత",
   "tagboxDoneButtonCaption": "అలాగే",
   "selectToRankEmptyRankedAreaText": "అన్ని ఎంపికలు ర్యాంక్ చేయబడ్డాయి",
   "selectToRankEmptyUnrankedAreaText": "ర్యాంక్ చేయడానికి ఎంపికలను ఇక్కడకు లాగండి మరియు వదలండి",
   "ok": "అలాగే",
   "cancel": "రద్దు చేయండి"
};


surveyLocalization.locales["te"] = dict;