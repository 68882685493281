import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { RoleList } from "../../model/admin_roles";

interface Props {
  deleteRole: boolean;
  selectedRoleDetails: RoleList;
  deleteAdminRolesApiCall: () => void;
  setDeleteRole: (val: boolean) => void;
}

export default function DeleteRole({
  deleteRole,
  selectedRoleDetails,
  deleteAdminRolesApiCall,
  setDeleteRole,
}: Props) {
  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });

  return (
    <Dialog open={deleteRole} sx={{ borderRadius: 16 }}>
      <DialogContent style={{ minWidth: "500px", borderRadius: 20 }}>
        <Typography variant="h5" fontWeight={600} textAlign={"center"} mt={3}>
          Are you sure you want to delete <br /> this role?
        </Typography>
        <Typography variant="h6" fontWeight={600} textAlign={"center"} mt={3}>
          {selectedRoleDetails.role}
        </Typography>
        <Box
          display="flex"
          flexDirection={"column"}
          mt={1}
          alignItems={"center"}
        >
          {selectedRoleDetails?.features?.map((features) => {
            return (
              <Box display="flex" flexDirection={"row"}>
                <Typography fontWeight={600} mr={1} color="#91278F">
                  {features.name}
                  {" - "}
                </Typography>
                {features?.permissions?.map((permission) => {
                  return (
                    <Typography mr={1} color="#91278F">
                      {permission.name}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          mt={3}
        >
          <Button
            onClick={() => {
              setDeleteRole(false);
            }}
            sx={{
              border: "1px solid #91278F",
              borderRadius: 2,
              fontWeight: 600,
              textTransform: "capitalize",
              minWidth: 150,
              height: 50,
              color: "#000000",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteAdminRolesApiCall?.();
            }}
            sx={{
              display: "flex",
              textTransform: "capitalize",
              backgroundColor: "#D14C2E",
              color: "#ffffff",
              fontWeight: 600,
              borderRadius: 2,
              "&:hover": {
                backgroundColor: "#D14C2E", // Remove hover background color
              },
              minWidth: 150,
            }}
          >
            Delete
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
