import { Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

export default function BlockAppUser({
  open,
  onDialogClose,
  updateUserAccess,
  title,
  submitBtnLabel,
}: {
  open: boolean;
  onDialogClose: () => void;
  updateUserAccess: (remarks: string) => void;
  title: string;
  submitBtnLabel: string;
}) {
  const [comments, setComments] = React.useState("");
  const [error, setError] = React.useState("");
  function submit() {
    setError("");
    if (comments.length === 0) {
      setError("Enter comments");
    } else {
      updateUserAccess(comments);
    }
  }

  useEffect(() => {
    if (open) {
      setComments("");
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onDialogClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box pb={2}>
            <Typography
              id="transition-modal-title"
              align="center"
              variant="body1"
              fontSize={18}
              fontWeight={600}
            >
              {title}
            </Typography>
          </Box>
          <TextField
            multiline
            minRows="5"
            fullWidth
            value={comments}
            onChange={({ target: { value } }) => {
              setComments(value);
            }}
            error={Boolean(error)}
            helperText={error}
          ></TextField>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button onClick={onDialogClose}>Close</Button>
            <Button variant="contained" onClick={submit}>
              {submitBtnLabel}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
