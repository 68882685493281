import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useScheme from "../../providers/scheme_provider";
import { SchemeOtherDetailsInputs } from "../../models/scheme_form";
import { SchemeDetailsResponse } from "../../models/scheme_details";
import Loading from "../../../../components/loading/loading";
import { useNavigate } from "react-router-dom";
import HTMLEditor from "../../../../components/html_editor/html_editor";

export default function SchemeDetails({
  data,
  isError,
  onSave,
  openEligibility,
  readOnly,
}: {
  data: SchemeDetailsResponse | undefined;
  isError: boolean;
  onSave: (data: SchemeOtherDetailsInputs) => void;
  openEligibility: () => void;
  readOnly: boolean;
}) {
  const { schemeMaster } = useScheme();
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SchemeOtherDetailsInputs>({});

  useEffect(() => {
    if (data) {
      setValue("details", data?.details?.details);
      setValue("benefits", data?.details?.benefits);
      setValue("exclusions", data?.details?.exclusions);
      setValue("applicationProcess", data?.details?.processModeId);
      setValue("applicationProcessDetails", data?.details?.process_details);
      setValue("eligibility", data?.details?.eligibility);
      setValue("documentRequired", data?.details?.documents_required);
    }
  }, [data]);

  const onSubmit: SubmitHandler<SchemeOtherDetailsInputs> = (data) =>
    onSave(data);

  function fieldValue({ title, value }: { title: string; value: string }) {
    return (
      <Box>
        <Typography variant="body2" color="primary.main">
          {title}
        </Typography>
        <Typography variant="body1" fontWeight={600}>
          {value}
        </Typography>
      </Box>
    );
  }

  if (isError)
    return (
      <Box
        height={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>No data found.</Typography>
      </Box>
    );
  if (!data)
    return (
      <Box
        height={300}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Loading />
      </Box>
    );

  return (
    <Box p={2} pt={3}>
      <Box pb={3}>
        <Typography variant="subtitle1" fontWeight="600">
          Scheme Details
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems={"flex-start"}>
          <Grid item md={6}>
            {fieldValue({
              title: "Scheme Name",
              value: data.details.name,
            })}
          </Grid>
          <Grid item md={6}>
            {fieldValue({
              title: "Type of Government",
              value: data.details.governmentType,
            })}
          </Grid>
          <Grid item md={12}>
            {fieldValue({
              title: "Type of Category",
              value: data.details.categories.join(", "),
            })}
          </Grid>
          {/* <Grid item md={12}>
            <FormControlLabel
              label="Mark scheme as top scheme"
              control={<Checkbox checked readOnly />}
            />
          </Grid> */}
          {!readOnly && (
            <Grid item md={12}>
              <Button variant="outlined" size="large" onClick={openEligibility}>
                Modify Eligibility Criteria
              </Button>
            </Grid>
          )}
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="details"
              control={control}
              rules={{ required: "Enter scheme details" }}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter scheme details"
                  readOnly={readOnly}
                />
              )}
            />
            {!!errors.details && (
              <Typography variant="caption" color="error">
                {errors.details?.message}
              </Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <Controller
              name="benefits"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Benefits (Optional)"
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="exclusions"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Exclusions (Optional)"
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Box>
              <Typography>Scheme Application Process</Typography>
              <Controller
                name="applicationProcess"
                control={control}
                disabled={readOnly}
                rules={{ required: "Select the process mode*" }}
                render={({ field: { onChange, value } }) => (
                  <Box mt={1}>
                    {schemeMaster?.schemeProcessMode?.map((process) => (
                      <Chip
                        disabled={readOnly}
                        onClick={() => onChange(process.id)}
                        label={process.value}
                        variant={value === process.id ? "filled" : "outlined"}
                        color="primary"
                        sx={{ mr: 1 }}
                      />
                    ))}
                  </Box>
                )}
              />
              {!!errors.applicationProcess && (
                <Typography variant="caption" color="error">
                  {errors.applicationProcess?.message}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Controller
              name="applicationProcessDetails"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Enter Scheme Application Process Details (Optional)"
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="eligibility"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Eligibility (Optional)"
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              name="documentRequired"
              control={control}
              render={({ field: { onChange, value } }) => (
                <HTMLEditor
                  onChange={onChange}
                  value={value}
                  placeholder="Documents Required (Optional)"
                  readOnly={readOnly}
                />
              )}
            />
          </Grid>
        </Grid>
        {!readOnly && (
          <Box pt={4} display="flex" justifyContent="space-between">
            <Box onClick={() => navigate(-1)}>
              <Typography
                variant="button"
                color="#91278F"
                sx={{
                  borderBottom: "3px solid #91278F",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                Cancel
              </Typography>
            </Box>

            <Button variant="contained" type="submit">
              Save changes (This language)
            </Button>
          </Box>
        )}
      </form>
    </Box>
  );
}
