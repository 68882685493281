import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PermissionLabel,
  SelectedRoleDetails,
} from "../add_admin_role/add_admin_role";
import { PermissionList } from "../../model/permission_list";

interface RoleDetailsInterface {
  selectedRoleDetails: SelectedRoleDetails[];
  role: PermissionLabel;
  setSelectedRoleDetails: (newVal: SelectedRoleDetails[]) => void;
  permissionLabel: PermissionLabel[];
  selectEntireRoles: boolean;
  selectedLabelsDetails: any;
}

export default function RoleDetails({
  role,
  selectedRoleDetails,
  setSelectedRoleDetails,
  permissionLabel,
  selectEntireRoles,
  selectedLabelsDetails,
}: RoleDetailsInterface) {
  const [selectAllPermissions, setSelectAllPermissions] = useState(false);
  function setPermission(permissionId: number) {
    let updatedPermissionState: SelectedRoleDetails[] = [];
    if (selectedRoleDetails.length === 0) {
      updatedPermissionState = [
        { featureId: role.id, permission: [permissionId] },
      ];
    } else {
      const hasFeature: boolean = !!selectedRoleDetails?.find(
        (i) => i.featureId === role.id
      );
      if (hasFeature) {
        updatedPermissionState = selectedRoleDetails.map((item) => {
          if (item.featureId === role.id) {
            let permission = [...item.permission, permissionId];
            return { ...item, permission };
          }
          return item;
        });
      } else {
        updatedPermissionState = [
          ...selectedRoleDetails,
          { featureId: role.id, permission: [permissionId] },
        ];
      }
    }

    setSelectedRoleDetails(updatedPermissionState);
  }

  function removePermission(permissionId: number) {
    let updatedPermissionState: SelectedRoleDetails[] = selectedRoleDetails.map(
      (item) => {
        if (item.featureId === role.id) {
          let permission = item.permission.filter(
            (i: number) => i !== permissionId
          );
          return { ...item, permission };
        }
        return item;
      }
    );
    setSelectedRoleDetails(updatedPermissionState);
  }
  let selectedRole: boolean = !!selectedRoleDetails?.find(
    (permission) => permission?.featureId === role?.id
  );

  function selectAllRolePermission() {
    let checkIsRoleSelected: boolean = !!selectedRoleDetails.some(
      (permission) => role.id === permission.featureId
    );
    if (checkIsRoleSelected) {
      if (!selectAllPermissions) {
        let permission: number[] = [];
        permissionLabel?.map((permissionItem) =>
          permission?.push(permissionItem.id)
        );
        let updatedPermissionState = selectedRoleDetails.map((item) => {
          if (item.featureId === role.id) {
            return { ...item, permission };
          }
          return item;
        });
        setSelectedRoleDetails(updatedPermissionState);
      } else {
        let filteredPermission: SelectedRoleDetails[] =
          selectedRoleDetails.filter((item) => {
            return item.featureId !== role.id;
          });
        setSelectedRoleDetails([
          ...filteredPermission,
          {
            featureId: role.id,
            permission: [],
          },
        ]);
      }
      setSelectAllPermissions(!selectAllPermissions);
    }
  }

  let roleChecked: boolean = selectedRoleDetails?.some(
    (feature) => role.id === feature.featureId
  );

  useEffect(() => {
    if (selectedLabelsDetails?.allPermission && selectEntireRoles) {
      setSelectAllPermissions(true);
    } else {
      setSelectAllPermissions(false);
    }
  }, [selectedLabelsDetails, selectEntireRoles]);

  useEffect(() => {
    let filterFeature = selectedRoleDetails.filter(
      (featureItem) => featureItem.featureId === role.id
    );
    if (filterFeature?.[0]?.permission?.length === permissionLabel?.length) {
      setSelectAllPermissions(true);
    }
  }, []);

  return (
    <Box display={"flex"} mt={4}>
      <Box display={"flex"} flex={1} sx={{ alignItems: "center" }}>
        <Checkbox
          checked={roleChecked}
          onChange={() => {
            let filterRoles;
            if (selectedRole) {
              filterRoles = selectedRoleDetails.filter(
                (roleVal) => role.id !== roleVal.featureId
              );
              setSelectedRoleDetails(filterRoles);
              setSelectAllPermissions(false);
            } else {
              setSelectedRoleDetails([
                ...selectedRoleDetails,
                {
                  featureId: role.id,
                  permission: [],
                },
              ]);
            }
          }}
          sx={{
            padding: 0,
            margin: 0,
            color: "#990099",
          }}
        />
        <Typography ml={1}>{role.name}</Typography>
      </Box>
      <Box ml={1} display={"flex"} flex={2} sx={{}}>
        <Button
          onClick={() => {
            selectAllRolePermission();
          }}
          fullWidth
          sx={{
            marginLeft: 1,
            color: selectAllPermissions ? "#ffffff" : "#000000",
            border: selectAllPermissions ? "none" : "1px solid #7C797C",
          }}
          variant={selectAllPermissions ? "contained" : "outlined"}
        >
          {"All"}
        </Button>
        {permissionLabel.map((permissions) => {
          let selected: boolean = !!selectedRoleDetails?.find((roleState) => {
            return (
              roleState?.permission?.includes(permissions.id) &&
              roleState.featureId === role.id
            );
          });
          return (
            <Box display="flex" width="100%">
              <Button
                fullWidth
                sx={{
                  marginLeft: 1,
                  color: selected ? "#ffffff" : "#000000",
                  opacity: selectAllPermissions ? 0.5 : 1,
                }}
                variant={selected ? "contained" : "outlined"}
                onClick={() => {
                  let checkIsRoleSelected = selectedRoleDetails.some(
                    (permission) => role.id === permission.featureId
                  );
                  if (checkIsRoleSelected) {
                    if (!selectAllPermissions)
                      if (selected) {
                        removePermission(permissions.id);
                      } else {
                        setPermission(permissions.id);
                      }
                  }
                }}
              >
                {permissions.name}
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
