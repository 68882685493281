import HttpClient from "../../utils/http";
import {
  jobs,
  job_filter,
  job_application,
  job,
  jobs_status,
  jobs_application_filter,
  jobs_application_status,
  job_application_details,
} from "../constants/api_endpoints";

export function getJobList({
  page,
  size,
  state,
  city,
  district,
  status,
  keyword,
  experience,
  jobType,
  category,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender
}: {
  page: number;
  size: number;
  state: number | null;
  city: number | null;
  district: number | null;
  status: number | null;
  keyword: string | null;
  jobType: number | null;
  experience: number | null;
  category: number | null;
  startDate: string | null;
  endDate: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;

}) {
  let url = jobs + `?size=${size}&page=${page}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (status) url += `&status=${status}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (experience) url += `&experience=${experience}`;
  if (jobType) url += `&jobType=${jobType}`;
  if (category) url += `&category=${category}`;
  if (startDate !== "" && endDate !== "") {
    url += `&dateFrom=${startDate}&dateTo=${endDate}`
  }
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }
  return HttpClient.get(url);
}

export function getJobApplicationList({
  page,
  size,
  state,
  city,
  district,
  status,
  keyword,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender
}: {
  page: number;
  size: number;
  state: number | null;
  city: number | null;
  district: number | null;
  status: number | null;
  keyword: string | null;
  startDate: string | null;
  endDate: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
}) {
  let url = job_application + `?size=${size}&page=${page}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (status) url += `&status=${status}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (startDate !== "" && endDate !== "") {
    url += `&dateFrom=${startDate}&dateTo=${endDate}`
  }
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }

  return HttpClient.get(url);
}

export function getFilters() {
  return HttpClient.get(job_filter, {
    headers: {
      locale: "en",
    },
  });
}

export function getJobApplicationFilters() {
  return HttpClient.get(jobs_application_filter, {
    headers: {
      locale: "en",
    },
  });
}

export function getJobDetails({ jobId }: { jobId: number }) {
  let url = job + `${jobId}`;
  return HttpClient.get(url, {
    headers: {
      locale: "en",
    },
  });
}

export function getJobApplicationDetails({ jobId }: { jobId: number }) {
  let url = job_application_details + `${jobId}`;
  return HttpClient.get(url, {
    headers: {
      locale: "en",
    },
  });
}

export function downloadJobListingService({
  page,
  size,
  state,
  city,
  district,
  status,
  keyword,
  experience,
  jobType,
  format,
  category,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender
}: {
  state: number | null;
  city: number | null;
  district: number | null;
  status: number | null;
  keyword: string | null;
  jobType: number | null;
  experience: number | null;
  format: string | null;
  category: number | null;
  page: number | null;
  size: number | null;
  startDate: string | null;
  endDate: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
}) {
  let url = jobs + `?format=${format}&download=true&page=${page}&size=${size}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (status) url += `&status=${status}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (jobType) url += `&jobType=${jobType}`;
  if (experience) url += `&experience=${experience}`;
  if (category) url += `&category=${category}`;
  if (startDate !== "" && endDate !== "") {
    url += `&dateFrom=${startDate}&dateTo=${endDate}`
  }
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }

  return HttpClient.get(url, {
    responseType: "blob",
  });
}

export function downloadJobApplicationService({
  page,
  size,
  state,
  city,
  district,
  status,
  keyword,
  format,
  startDate,
  endDate,
  ageFrom,
  ageTo,
  gender
}: {
  state: number | null;
  city: number | null;
  district: number | null;
  status: number | null;
  keyword: string | null;
  format: string | null;
  page: number | null;
  size: number | null;
  startDate: string | null;
  endDate: string | null;
  ageFrom: number | null;
  ageTo: number | null;
  gender: string | null;
}) {
  let url =
    job_application +
    `?format=${format}&download=true&page=${page}&size=${size}`;
  if (state) url += `&state=${state}`;
  if (city) url += `&city=${city}`;
  if (district) url += `&district=${district}`;
  if (status) url += `&status=${status}`;
  if (keyword) url += `&keyword=${keyword}`;
  if (startDate !== "" && endDate !== "") {
    url += `&dateFrom=${startDate}&dateTo=${endDate}`
  }
  if (ageFrom !== null && ageTo !== null) {
    url += `&ageFrom=${ageFrom}&ageTo=${ageTo}`
  }
  if (gender !== "") {
    url += `&gender=${gender}`
  }

  return HttpClient.get(url, {
    responseType: "blob",
  });
}

export function updateJobStatus({
  statusId,
  comment,
  jobId,
}: {
  statusId: number | null;
  comment: string | null;
  jobId: number | null;
}) {
  let url = jobs_status + `${jobId}`;
  return HttpClient.patch(url, {
    statusId: statusId?.toString(),
    comment: comment,
  });
}

export function updateJobApplicationStatus({
  statusId,
  comment,
  jobId,
}: {
  statusId: number | null;
  comment: string | null;
  jobId: number | null;
}) {
  let url = jobs_application_status + `${jobId}`;
  return HttpClient.patch(url, {
    statusId: statusId?.toString(),
    remarks: comment,
  });
}

export function deleteJob({
  jobId,
  comment,
}: {
  jobId: number | null;
  comment: string;
}) {
  let url = job + `${jobId}`;
  return HttpClient.delete(url, {
    data: { comment: comment },
  });
}

export function downloadJobApplicationDetails(id: number, format: string) {
  return HttpClient.get(
    `${job_application_details}${id}?download=true&format=${format}`,
    { responseType: "blob" }
  );
}

export function downloadJobDetails(id: number, format: string) {
  return HttpClient.get(`${job}${id}?download=true&format=${format}`, {
    responseType: "blob",
  });
}

export function downloadResume(id: number) {
  return HttpClient.get(`${job_application_details}${id}/resume`, {
    responseType: "blob",
  });
}
