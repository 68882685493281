import "@fontsource/ibm-plex-sans-devanagari";
import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient } from "react-query";
import { RouterProvider } from "react-router-dom";
import "./assets/css/index.css";
import FeedbackProvider from "./providers/feedback/feeedback";
import { HttpProvider } from "./providers/http/http_provider";
import QueryProvider from "./providers/query/query";
import reportWebVitals from "./reportWebVitals";
import routes from "./routes";
import theme from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UserProvider } from "./providers/user/user_provider";
import './utils/survey';


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <FeedbackProvider>
      <QueryProvider>
        <UserProvider>
          <HttpProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={theme}>
                <RouterProvider router={routes} />
              </ThemeProvider>
            </LocalizationProvider>
          </HttpProvider>
        </UserProvider>
      </QueryProvider>
    </FeedbackProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
