import { Close } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Divider,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

interface usersDownloadFilters {
    status: null | number;
    setStatus: (val: null | number) => void;
    openModal: boolean;
    setOpenModal: (val: boolean) => void;
    startDate: string;
    setStartDate: (value: string) => void;
    endDate: string;
    setEndDate: (value: string) => void;
    resetFilter: () => void;
    statusList: { id: number, value: string }[]
}

export default function UsersDownloadFilters({
    openModal,
    setOpenModal,
    setStartDate,
    endDate,
    setEndDate,
    resetFilter,
    statusList,
    setStatus
}: usersDownloadFilters) {
    const [_endDate, _setEndDate] = useState<string>(endDate);
    const [_startDate, _setStartDate] = useState<string>(endDate);
    const [_selectedGender, _setSelectedGender] = useState<string>("");
    const [_status, _setStatus] = useState<number | null>(null)

    return (
        <Dialog open={openModal}>
            <Box sx={{ width: 400 }}>
                <Card sx={{ px: 2 }}>
                    <CardHeader
                        title="Users"
                        titleTypographyProps={{ variant: "body1", fontWeight: 600 }}
                        action={
                            <IconButton onClick={() => setOpenModal(false)}>
                                <Close sx={{ color: "#323232" }} />
                            </IconButton>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardHeader
                        title="Filter By"
                        titleTypographyProps={{ variant: "body2", fontWeight: 600 }}
                        action={
                            <Button
                                variant="text"
                                sx={{ p: 0 }}
                                onClick={() => {
                                    resetFilter();
                                    _setEndDate("");
                                    _setStartDate("");
                                    setOpenModal(false);
                                    _setStatus(null)
                                }}
                            >
                                Reset
                            </Button>
                        }
                    >
                        <Typography></Typography>
                    </CardHeader>
                    <CardContent sx={{ maxHeight: 400, overflow: "auto scroll" }}>
                        <Typography fontSize={16} fontWeight="600" mb={1}>
                            Status
                        </Typography>
                        <Box>
                            {statusList?.map((item) => {
                                return (
                                    <Button
                                        // disabled={_selectedGender === "All" ? true : false}
                                        onClick={() => {
                                            if (_status === item?.id) {
                                                _setSelectedGender("");
                                            } else {
                                                _setStatus(item?.id);
                                            }
                                        }}
                                        variant={
                                            _status === item?.id ? "contained" : "outlined"
                                        }
                                        sx={{ mr: 1, mb: 1 }}
                                    >
                                        {item?.value}
                                    </Button>
                                );
                            })}
                        </Box>

                        <Divider sx={{ mt: 2, mb: 1 }} />
                        {_dateRange({
                            title: "Date Range",
                            setEndDate: _setEndDate,
                            setStartDate: _setStartDate,
                            startDate: _startDate,
                            endDate: _endDate,
                        })}
                        <Box mt={2} display="flex" justifyContent="space-between">
                            <Button onClick={() => setOpenModal(false)}>Close</Button>
                            <Button
                                sx={{ minWidth: 200 }}
                                variant="contained"
                                onClick={() => {
                                    setStartDate(_startDate);
                                    setEndDate(_endDate);
                                    setOpenModal(false);
                                    setStatus(_status);
                                }}
                            >
                                Apply Filters
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Dialog>
    );



    function _dateRange({
        title,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    }: {
        title: string;
        startDate: string;
        setStartDate: (value: string) => void;
        endDate: string;
        setEndDate: (value: string) => void;
    }) {
        return (
            <Box pt={2} pb={1}>
                <Typography fontSize={16} fontWeight="600">
                    {title}
                </Typography>
                <Box pt={1} pb={2} display="flex" flexDirection={"column"}>
                    <DatePicker
                        label="Start Date"
                        format="DD-MM-YYYY"
                        disableFuture
                        value={startDate ? dayjs(startDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setStartDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                    <Box p={1}></Box>
                    <DatePicker
                        label="End Date"
                        format="DD-MM-YYYY"
                        disableFuture
                        value={endDate ? dayjs(endDate, "YYYY-MM-DD") : null}
                        onChange={(value: Dayjs | null) => {
                            setEndDate(value?.format("YYYY-MM-DD") || "");
                        }}
                        slotProps={{ desktopPaper: { elevation: 3 } }}
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fill: "#990099", // Replace with your desired color
                            },
                        }}
                    />
                </Box>
            </Box>
        );
    }
}
