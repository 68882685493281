import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import BaseLayout from "../base/base_layout";
import Ellipse2 from "./../../assets/background/Ellipse 2.svg";
import Rectangle from "./../../assets/background/Rectangle 5.svg";
import Vector1 from "./../../assets/background/Vector 1.svg";
import Vector2 from "./../../assets/background/Vector 2.svg";

export default function AuthLayout({ children }: PropsWithChildren) {
  return (
    <BaseLayout>
      <Box
        width="100vw"
        height="100vh"
        sx={{
          position: "relative",
          background: `linear-gradient(180deg, #FEFAFE 0%, #FFF 100%)`,
          
        }}
        zIndex={999}
        overflow="auto"
      >
        <Box zIndex={0} sx={{pointerEvents: 'none',backdropFilter: "none"}}>
          <img src={Rectangle} style={{height: '80vh',position: "absolute",bottom: 0,left: 0,zIndex: 1}} />
          <img src={Vector1} style={{position: "absolute",bottom: -30,right: 0,height: '80vh',objectFit: "contain",zIndex: 1}}  />
          <img src={Vector2} style={{position: "absolute",bottom: -30,right: 0,height: '70vh',objectFit: "contain",zIndex: 1}}  />
          <img src={Ellipse2} style={{position: "absolute",bottom: '70vh',right: '10vw',height: '10vh',objectFit: "contain",zIndex: 1}}  />
          <img src={Ellipse2} style={{position: "absolute",bottom: '30vh',left: '10vw',height: '10vh',objectFit: "contain",zIndex: 1}}  />
        </Box>
    
        <Box zIndex={1} position="relative">{children}</Box>
      </Box>
    </BaseLayout>
  );
}
