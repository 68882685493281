import HttpClient from "../../utils/http";
import { downloadExcel, getExcelUploadedList, uploadexcelFileEndPoint } from "../constants/api_endpoints";

export function getExcelUploadData({
    page,
    size,
    type,
    startDate,
    endDate,
    status
  }: {
    page: number | null;
    size: number | null;
    type: string | null;
    startDate: string;
    endDate: string;
    status: number | null;
  }) {
     
    let url = `${getExcelUploadedList}/?type=${type}&page=${page}&size=${size}`;
    if(startDate!=="" && endDate!==""){
        url +=`&fromDate=${startDate}&toDate=${endDate}`
    }
    if(status){
      url+=`&status=${status}`
    }
    return HttpClient.get(url);
  }
  export function UploadExcelFile({
    file,
    type,
  }: {
    file: File;
    type: string | null;
  }) {
    var formData = new FormData();
    formData.append('type', type!);
    formData.append('files', file);
  
  
    let url = `${uploadexcelFileEndPoint}`;
    return HttpClient.post(url, formData,
      {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  export function downloadExcelApiCall({taskId}:{taskId:number}){
      let url=`${downloadExcel}/?taskId=${taskId}`
      return HttpClient.get(url, {
        responseType: "blob",
      });
  }