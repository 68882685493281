import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Stepper from "../../../components/stepper/stepper";
import MainLayout from "../../../layouts/main/main_layout";
import useUser from "../../../providers/user/user_provider";
import {
  SchemeDetailsInputs,
  SchemeEligibilityInputs,
  SchemeOtherDetailsInputs,
} from "../models/scheme_form";
import { SchemeProvider } from "../providers/scheme_provider";
import OtherSchemeInformation from "./components/other_scheme_information";
import SchemeDetails from "./components/scheme_details";
import SchemeEligibilityCriteria from "./components/scheme_eligibility_criteria";
import Loading from "../../../components/loading/loading";
import { addScheme } from "../../../services/schemes/schemes";
import { useFeedback } from "../../../providers/feedback/feeedback";
import { useNavigate } from "react-router-dom";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export default function AddNewSchemes() {
  const { hasPermission } = useUser();
  const { showSnackbar } = useFeedback();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [schemeDetails, setSchemeDetails] = useState<SchemeDetailsInputs>({
    schemeName: "",
    governmentTypeId: null,
    typeOfCategory: [],
    topScheme: false,
  });
  const [otherDetails, setOtherDetails] = useState<SchemeOtherDetailsInputs>({
    applicationProcess: null,
    applicationProcessDetails: "",
    benefits: "",
    details: "",
    documentRequired: "",
    eligibility: "",
    exclusions: "",
    summary: "",
  });
  const [eligibilityDetails, setEligibilityDetails] =
    useState<SchemeEligibilityInputs>({
      bplCategory: null,
      differentlyAbled: null,
      fromAge: 1,
      toAge: 120,
      genders: [],
      caste: [],
      maritalStatus: [],
      minority: null,
      occupation: [],
      residence: [],
      state: [],
      student: null,
      workingStatus: [],
      familyIncome: [],
    });

  async function submit(eligibilityDetails: SchemeEligibilityInputs) {
    console.log(schemeDetails, otherDetails);

    setIsLoading(true);
    await addScheme(schemeDetails, otherDetails, eligibilityDetails)
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          showSnackbar("Scheme saved successfully");
          navigate("/schemes");
        } else {
          showSnackbar("Something went wrong");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.response.status === 400) {
          showSnackbar("Scheme Already Exist!!");
        } else {
          showSnackbar("Something went wrong");
        }
      });
  }
  return (
    <MainLayout>
      <SchemeProvider>
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12} lg={10} xl={10}>
            {/* {hasPermission("Schemes", "Read") ? ( */}
            <Card sx={{ width: "100%", borderRadius: 2, m: 1 }} elevation={1}>
              <CardContent>
                <Box display="flex" flexDirection="row">
                  <Box width={400} display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBack></ArrowBack>
                    </IconButton>
                    <Typography variant="h6" fontWeight="600" paddingLeft={1}>
                      Add New Schemes
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                  ></Box>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box overflow="auto" flex={1} position="relative">
                  <Box pt={2} pb={2}>
                    <Stepper
                      activeStep={activeStep}
                      steps={[
                        "Scheme Details",
                        "Other Scheme Information",
                        "Scheme Eligibility Criteria",
                      ]}
                    />
                  </Box>
                  {activeStep === 0 && (
                    <SchemeDetails
                      schemeDetails={schemeDetails}
                      onSubmit={(data: SchemeDetailsInputs) => {
                        setSchemeDetails(data);
                        setActiveStep(1);
                      }}
                    />
                  )}
                  {activeStep === 1 && (
                    <OtherSchemeInformation
                      otherDetails={otherDetails}
                      onSubmit={(data: SchemeOtherDetailsInputs) => {
                        setOtherDetails(data);
                        setActiveStep(2);
                      }}
                      goBack={() => setActiveStep(0)}
                    />
                  )}
                  {activeStep === 2 && (
                    <SchemeEligibilityCriteria
                      onSubmit={async (data) => {
                        setEligibilityDetails(data);
                        submit(data);
                      }}
                      eligibilityDetails={eligibilityDetails}
                      goBack={() => setActiveStep(1)}
                    />
                  )}

                  {isLoading && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      position="absolute"
                      top={0}
                      left={0}
                      width={"100%"}
                      height={"100%"}
                      zIndex={999}
                      sx={{ bgcolor: "#ffffffaf" }}
                    >
                      <Loading />
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
            {/* ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={600}
            >
              <AccessDenied />
            </Box>
          )} */}
          </Grid>
        </Grid>
      </SchemeProvider>
    </MainLayout>
  );
}
