import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeedback } from "../../../../providers/feedback/feeedback";
import { PermissionList } from "../../model/permission_list";
import {
  getFeaturesAndPermissions,
  postRoles,
  updateRole,
} from "../../../../services/admin/admin_services";
import MainLayout from "../../../../layouts/main/main_layout";
import RoleDetails from "../../components/modify_role_Details/role_details";
import { useQuery } from "react-query";
import Loading from "../../../../components/loading/loading";

export interface PermissionLabel {
  id: number;
  name: string;
}

export interface SelectedRoleDetails {
  featureId: number;
  permission: number[];
}

export default function ModifyRolePermissions() {
  const { state } = useLocation();
  const [selectedRoleDetails, setSelectedRoleDetails] = useState<
    SelectedRoleDetails[]
  >(state?.features ?? []);
  const [roleName, setRoleName] = useState(state?.roleName);
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();
  const [selectedLabelsDetails, setSelectedLabelsDetails] = useState<any>({});
  const [selectEntireRoles, setSelectEntireRoles] = useState(false);

  const { data: permissionLabel, isLoading } = useQuery(
    ["modifyRoles"],
    async () => {
      let response = await getFeaturesAndPermissions();
      return response.data.data;
    }
  );

  const updateRoleApiCall = () => {
    let features: Object[] = [];
    selectedRoleDetails.map((item: SelectedRoleDetails) => {
      if (item?.permission?.length > 0) {
        features?.push({
          id: item.featureId,
          permissionIds: item.permission,
        });
      }
    });
    let payload = {
      name: roleName,
      features: features,
    };
    updateRole(state?.roleId, payload)
      .then((res) => {
        if (res?.status === 200) {
          showSnackbar(res?.data?.data?.message);
        } else {
          showSnackbar("Something went wrong");
        }
        navigate(-1);
        console.log(res, "res");
      })
      .catch((e) => {
        if (e?.response?.data?.error?.message)
          showSnackbar(e.response.data.error.message);
        else showSnackbar("Something went wrong");
      });
  };

  function addAndRemoveEntirePermissions(item: PermissionLabel) {
    let updatedPermissions = selectedRoleDetails.map(
      (feature: SelectedRoleDetails) => {
        if (feature?.permission?.includes(item.id)) {
          feature.permission = feature?.permission?.filter(
            (permissions) => permissions !== item.id
          );
          setSelectedLabelsDetails({
            ...selectedLabelsDetails,
            [item.name]: false,
          });
        } else {
          feature.permission = [...feature.permission, item.id];
          setSelectedLabelsDetails({
            ...selectedLabelsDetails,
            [item.name]: true,
          });
        }
        return { ...feature };
      }
    );
    setSelectedRoleDetails(updatedPermissions);
  }

  function selectAndRemoveAllPermissions(remove: boolean) {
    let roleDetails: SelectedRoleDetails[] = [];
    let permissionList: number[] = [];
    if (remove) {
      permissionLabel?.features?.map?.((feature: PermissionList) => {
        roleDetails.push({ featureId: feature.id, permission: permissionList });
      });
    } else {
      permissionLabel?.features?.map?.((feature: PermissionList) => {
        permissionList = [];
        permissionLabel?.features?.map((permissions: PermissionLabel) => {
          permissionList.push(permissions.id);
        });
        roleDetails.push({ featureId: feature.id, permission: permissionList });
      });
    }
    setSelectedRoleDetails(roleDetails);
  }

  useEffect(() => {
    if (permissionLabel?.features?.length === selectedRoleDetails?.length) {
      let labelDetails: any = [];
      permissionLabel?.permissions?.map?.((permission: any) => {
        labelDetails[permission.name] = true;
      });

      setSelectEntireRoles(true);
      setSelectedLabelsDetails({ allPermission: true, ...labelDetails });
    }
  }, []);

  return (
    <MainLayout>
      <Grid container spacing={3} mt={3}>
        <Grid item md={12} sm={12} xs={12} lg={7} xl={8}>
          {isLoading ? (
            <Card
              sx={{
                display: "flex",
                width: "100%",
                borderRadius: 4,
                m: 1,
                minHeight: 500,
              }}
              elevation={1}
            >
              <Box
                display="flex"
                flex={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Loading />
              </Box>
            </Card>
          ) : (
            <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
              <CardContent>
                <Box display="flex" flexDirection="column" pl={1}>
                  <Box flexDirection="row" display="flex" alignItems="center">
                    <IconButton onClick={() => navigate(-1)}>
                      <ArrowBack></ArrowBack>
                    </IconButton>
                    <Typography variant="h6" fontWeight="600">
                      Modify Admin Role
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <TextField
                      value={roleName}
                      onChange={(e) => setRoleName(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      label="Role Name"
                      placeholder="Enter Role name"
                    />
                  </Box>
                  <Box mt={3}>
                    <Typography fontWeight="600">Selectional Access</Typography>
                  </Box>
                  <Box>
                    <Box display={"flex"} mt={4}>
                      <Box
                        display={"flex"}
                        flex={1}
                        sx={{ alignItems: "center" }}
                      >
                        <Checkbox
                          checked={selectEntireRoles}
                          onChange={(val) => {
                            let pushAllFeatures: SelectedRoleDetails[] = [];
                            if (!selectEntireRoles) {
                              permissionLabel.features?.map?.(
                                (features: PermissionList) => {
                                  pushAllFeatures.push({
                                    featureId: features.id,
                                    permission: [],
                                  });
                                }
                              );
                              setSelectedRoleDetails(pushAllFeatures);
                            } else {
                              setSelectedRoleDetails(pushAllFeatures);
                              setSelectedLabelsDetails({});
                            }
                            setSelectEntireRoles(!selectEntireRoles);
                          }}
                          sx={{ padding: 0, margin: 0, color: "#990099" }}
                        />
                        <Typography ml={1}>All</Typography>
                      </Box>

                      <Box ml={1} display={"flex"} flex={2} sx={{}}>
                        <Button
                          onClick={() => {
                            if (selectEntireRoles) {
                              let permissions: any;
                              if (selectedLabelsDetails?.allPermission) {
                                selectAndRemoveAllPermissions(true);
                              } else {
                                permissions = { allPermission: true };
                                permissionLabel?.permissions?.map?.(
                                  (item: PermissionLabel) => {
                                    permissions[item.name] = true;
                                  }
                                );
                                selectAndRemoveAllPermissions(false);
                              }

                              setSelectedLabelsDetails(permissions);
                            }
                          }}
                          fullWidth
                          sx={{
                            textTransform: "capitalize",
                            marginLeft: 1,
                            color: selectedLabelsDetails?.allPermission
                              ? "#ffffff"
                              : "#000000",
                          }}
                          variant={
                            selectedLabelsDetails?.allPermission
                              ? "contained"
                              : "outlined"
                          }
                        >
                          {"All"}
                        </Button>
                        {permissionLabel?.permissions?.map?.(
                          (item: PermissionLabel) => {
                            return (
                              <Box display="flex" width="100%">
                                <Button
                                  onClick={() => {
                                    if (
                                      selectEntireRoles &&
                                      !selectedLabelsDetails?.allPermission
                                    )
                                      addAndRemoveEntirePermissions(item);
                                  }}
                                  fullWidth
                                  sx={{
                                    marginLeft: 1,
                                    opacity:
                                      selectedLabelsDetails?.allPermission
                                        ? 0.3
                                        : 1,
                                    color: selectedLabelsDetails?.[item?.name]
                                      ? "#ffffff"
                                      : "#000000",
                                  }}
                                  variant={
                                    selectedLabelsDetails?.[item?.name]
                                      ? "contained"
                                      : "outlined"
                                  }
                                >
                                  {item.name}
                                </Button>
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    position={"relative"}
                  >
                    {permissionLabel?.features?.map?.(
                      (item: PermissionLabel) => {
                        return (
                          <RoleDetails
                            role={item}
                            selectedRoleDetails={selectedRoleDetails}
                            setSelectedRoleDetails={setSelectedRoleDetails}
                            permissionLabel={permissionLabel?.permissions || []}
                            selectedLabelsDetails={selectedLabelsDetails}
                            selectEntireRoles={selectEntireRoles}
                          />
                        );
                      }
                    )}
                    {selectEntireRoles && (
                      <Box
                        display={"flex"}
                        position={"absolute"}
                        sx={{
                          backgroundColor: "#ffffff",
                          opacity: 0.5,
                          height: "100%",
                          width: "100%",
                          zIndex: 99999,
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    mt={5}
                  >
                    <Button
                      onClick={() => navigate(-1)}
                      variant="outlined"
                      sx={{
                        minWidth: 200,
                        color: "#000000",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (roleName) updateRoleApiCall();
                      }}
                      variant="contained"
                      sx={{
                        opacity: roleName ? 1 : 0.5,
                        minWidth: 200,
                        color: "#ffffff",
                      }}
                    >
                      Modify Admin Role
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}
