import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";

interface PopupProps {
  openPopup: boolean;
  setOpenPopup: (val: boolean) => void;
  deleteSurveyor: () => void;
}

export default function DeleteSuryevorPopup({
  openPopup,
  deleteSurveyor,
  setOpenPopup,
}: PopupProps) {
  return (
    <Dialog open={openPopup}>
      <Box m={3} sx={{ width: 300 }}>
        <Typography variant="body1" fontWeight={"600"} textAlign={"center"}>
          Are you sure you want to delete <br /> this surveyor?
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"} mt={5}>
          <Button variant="text" onClick={() => setOpenPopup(false)}>
            Close
          </Button>
          <Button variant="contained" onClick={deleteSurveyor}>
            Delete surveyor
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
