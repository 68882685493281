import React, { PropsWithChildren } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function HTMLEditor({
  children,
  onChange,
  value,
  ...props
}: PropsWithChildren<{
  onChange: (value: string) => void;
  value: any;
  placeholder?: string;
  readOnly?: boolean;
}>) {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      style={{ minHeight: 100, marginBottom: 5 }}
      {...props}
    />
  );
}
