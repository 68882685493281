import { PropsWithChildren, createContext, useEffect, useState } from "react";
import HttpClient from "../../utils/http";
import Session from "../../utils/session";
import Loading from "../../components/loading/loading";
import { useFeedback } from "../feedback/feeedback";
import { useNavigate } from "react-router-dom";
import useUser from "../user/user_provider";

// const HttpContext = createContext();

export function HttpProvider({ children }: PropsWithChildren) {
  const { accessToken } = useUser();
  const { showSnackbar } = useFeedback();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    HttpClient.interceptors.request.clear();
    HttpClient.interceptors.response.clear();
    HttpClient.interceptors.request.use(
      function (config) {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (!config.headers["locale"]) {
          config.headers["locale"] = "en";
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    HttpClient.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        return response;
      },
      function (error) {
        if (error.response.status === 446) {
          showSnackbar("Session timed out, Please relogin");
          sessionStorage.clear();
          localStorage.clear();
          window.location.pathname = "/";
          // } else if (error.response.status === 436) {
          // window.location.pathname = "/access-disabled";
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        return Promise.reject(error);
      }
    );
    setInitialized(true);
  }, [accessToken, showSnackbar]);

  return <>{initialized ? children : null}</>;
}
