import { ArrowBack } from "@mui/icons-material";
import { Box, Card, IconButton, Tab, Tabs } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SchemeLayout({ children }: PropsWithChildren) {
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const [currentTab, setCurrentTab] = React.useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, currentTabVal: number) => {
    setCurrentTab(currentTabVal);
    routeBasedOnTabChange(currentTabVal);
  };
  function routeBasedOnTabChange(currentTabVal: number) {
    if (currentTabVal === 0) {
      navigate("/schemes");
    } else if (currentTabVal === 1) {
      navigate("/schemes/enquiries");
    } else {
      navigate("/schemes/familyEnquiries");
    }
  }

  function tabChangeBasedOnRoute() {
    if (pathname === "/schemes/enquiries") {
      setCurrentTab(1);
    } else if (pathname === "/schemes") {
      setCurrentTab(0);
    } else {
      setCurrentTab(2);
    }
  }

  useEffect(() => {
    tabChangeBasedOnRoute();
  }, []);

  return (
    <Box display={"flex"} mt={4}>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          minHeight: 600,
          mb: 2,
          minWidth: 1200,
        }}
      >
        <Box display="flex" flexDirection={"column"} sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "#F5E6F5",
              p: 2,
              pb: 0,
            }}
          >
            <IconButton onClick={() => navigate("/reports", { replace: true })}>
              <ArrowBack />
            </IconButton>
            <Tabs
              value={currentTab}
              aria-label="basic tabs example"
              onChange={handleChange}
              sx={{
                "MuiTabs-root css-112qdbf-MuiTabs-root": {
                  borderColor: "#F5E6F5",
                },
              }}
            >
              <Tab
                label="Schemes"
                sx={{
                  textTransform: "capitalize",
                  color: "#1B001B",
                }}
              />
              <Tab
                label="Enquiries"
                sx={{ textTransform: "capitalize", color: "#1B001B" }}
              />
              <Tab
                label="Family Enquiries"
                sx={{ textTransform: "capitalize", color: "#1B001B" }}
              />
            </Tabs>
          </Box>
          {children}
        </Box>
      </Card>
    </Box>
  );
}
