import React from "react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { RoleList } from "../../model/admin_roles";
import { useNavigate } from "react-router-dom";

interface Props {
  viewSelectedRoleDetails: boolean;
  setViewSelectedRoleDetails: (newVal: boolean) => void;
  selectedRoleDetails: RoleList; // Replace with the actual type
}

export default function ViewRoleDetails({
  viewSelectedRoleDetails,
  setViewSelectedRoleDetails,
  selectedRoleDetails,
}: Props) {
  const navigate = useNavigate();
  const Dialog = styled(MuiDialog)(({ theme }) => {
    return {
      ".css-1o50bed-MuiPaper-root-MuiDialog-paper": {
        borderRadius: 16,
      },
    };
  });
  return (
    <Dialog open={viewSelectedRoleDetails}>
      <DialogContent
        style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}
      >
        <ListItem>
          <ListItemText
            primary="Admin Details"
            primaryTypographyProps={{ fontWeight: "800" }}
          />
          <ListSubheader sx={{ margin: 0, padding: 0 }}>
            <IconButton
              onClick={() => setViewSelectedRoleDetails(false)}
              sx={{
                color: "#323232",
              }}
            >
              <Close />
            </IconButton>
          </ListSubheader>
        </ListItem>
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ListItemText
            primary="Role name"
            secondary={selectedRoleDetails?.role}
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
            secondaryTypographyProps={{
              color: "#1B001B",
              fontSize: 18,
            }}
          />

          <ListItemText
            primary="Sectional Access"
            secondary={
              selectedRoleDetails?.features?.[0].name !== ""
                ? selectedRoleDetails?.features?.[0].name
                : "-"
            }
            primaryTypographyProps={{
              fontSize: 14,
              color: "#91278F",
            }}
            secondaryTypographyProps={{
              color: "#1B001B",
              fontSize: 18,
            }}
          />
          <Typography fontSize={14} color={"#91278F"}>
            Access Type
          </Typography>
          <Box mt={1}>
            {selectedRoleDetails?.features?.map((features) => {
              return (
                <Box display="flex" flexDirection={"row"}>
                  <Typography fontWeight={600} mr={1}>
                    {features.name}
                    {" - "}
                  </Typography>
                  {features.permissions.map((permission) => {
                    return <Typography mr={1}>{permission.name}</Typography>;
                  })}
                </Box>
              );
            })}
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"100%"}
            mt={3}
          >
            <Button
              onClick={() => setViewSelectedRoleDetails(false)}
              sx={{ border: "none", textTransform: "capitalize" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                let featuresAndPermissionObject: any = [];
                let permissionObject: any = [];
                selectedRoleDetails?.features?.map((featureItem) => {
                  permissionObject = [];
                  featureItem?.permissions?.map((permissions) => {
                    permissionObject.push(permissions.id);
                  });
                  featuresAndPermissionObject.push({
                    featureId: featureItem.id,
                    permission: permissionObject,
                  });
                });
                navigate("/admins/manageRolesAndAccess/modifyRole", {
                  state: {
                    features: featuresAndPermissionObject,
                    roleName: selectedRoleDetails.role,
                    roleId: selectedRoleDetails.id,
                  },
                });
              }}
              sx={{
                minWidth: 200,
                color: "#ffffff",
              }}
            >
              Modify
            </Button>
          </Box>
        </ListItem>
      </DialogContent>
    </Dialog>
  );
}
