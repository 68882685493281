import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@mui/icons-material";
import useUser from "../../../../providers/user/user_provider";
import { ExcelUploadedList } from "../../model/excel_upload_model";
import dayjs from "dayjs";

interface DataGridInterface {
  label: { label: string }[];
  data: ExcelUploadedList[];
  count: number;
  page: number;
  setPage: (val: number) => void;
  setSelectedUserId: (val: number) => void;
  downloadExcel: (val: number) => void
}

export default function DatGridJFileUpload({
  label,
  data,
  page,
  setPage,
  count,
  setSelectedUserId,
  downloadExcel
}: DataGridInterface) {
  const navigate = useNavigate();
  const { hasPermission } = useUser();

  const startRange = page * 8 + 1;
  const endRange = Math.min((page + 1) * 8, count);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => { };

  const customText = `Showing ${startRange} to ${endRange} of ${count} entries`;


  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={14} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <CardContent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid container direction="row" p={2}>
          {label?.map((item, index) => (
            <>
              {index === 0 ? <Grid
                item
                flex={0.5}
              >
                <Typography color="#91278F" fontSize={14}>
                  {item.label}
                </Typography>
              </Grid>
                : <Grid
                  display={'flex'}
                  item
                  flex={index === label?.length - 2 ? 2 : 1}
                  justifyContent={index === label?.length - 1 ? 'center' : 'flex-start'}

                >
                  <Typography color="#91278F" fontSize={14}>
                    {item.label}
                  </Typography>
                </Grid>}
            </>

          ))}
        </Grid>
        {data?.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <Typography>No Data Available</Typography>
          </Box>
        ) : (
          <Grid container direction="row" mt={3}>
            {data?.map?.((item, index) => {
              const originalDate = dayjs(item?.uploadedAt);
              const formattedDate = originalDate?.format?.("DD MMM YYYY");
              console.log();
              let details: any = item?.remark?.details;
              let message: string = ''
              let value: string = ''
              if (details !== undefined) {
                message = JSON.parse(details)?.[0]?.message;
                value = JSON.parse(details)?.[0]?.value;
                console.log("inside", details);
              }
              if (details !== undefined && JSON?.parse(details)?.length === 0) {
                message = item?.remark?.message
              }
              return (
                <Box
                  key={index}
                  // onClick={(e) => {
                  //   // navigate(`/jobs/jobsDetails/${item.id}`);
                  // }}
                  display={"flex"}
                  flexDirection={"column"}
                  width="100%"
                  sx={{
                    ":hover": {
                      // backgroundColor: "#FAEDEA",
                    },
                    cursor: "pointer",
                  }}
                >
                  <Divider
                    sx={{
                      display: "flex",
                      opacity: 0.5,
                      borderBottom: "1px dotted #7C797C",
                      mx: 2,
                    }}
                  />
                  <Grid
                    display="flex"
                    item
                    flex={1}
                    flexDirection={"row"}
                    my={3}
                    px={2}
                  >
                    <Box
                      display="flex"
                      flex={0.5}
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        textOverflow={"ellipsis"}
                        overflow="hidden"
                      >
                        {index + 1}
                      </Typography>
                    </Box>
                    {gridTypography({ text: item?.documentName })}
                    {gridTypography({ text: formattedDate })}
                    {gridTypography({ text: item?.adminName })}

                    <Box
                      display="flex"
                      flex={2}
                      flexDirection={'column'}
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        fontSize={14}
                        textOverflow={"ellipsis"}
                        overflow="hidden"
                        fontWeight={'800'}
                        sx={{ color: item?.status === 'Failed' ? '#D14C2E' : item?.status === 'Completed' ? '#1E6446' : '#1B001B' }}>
                        {item?.status}
                      </Typography>
                      <Typography
                        textOverflow={"ellipsis"}
                        overflow="hidden"
                        color={'#646464'}
                        variant="caption"
                      >
                        {message} {value}
                      </Typography>
                    </Box>
                    <Box display="flex" flex={1} alignItems={"center"} justifyContent={'center'} >
                      <Box sx={{ backgroundColor: '#F5E6F5' }} px={0} pt={0} borderRadius={2} >
                        <IconButton onClick={() => { downloadExcel(item?.id) }}>
                          <DownloadOutlined sx={{ color: "#1B001B" }} />
                        </IconButton>
                      </Box>
                    </Box>

                  </Grid>
                </Box>
              );
            })}
          </Grid>
        )}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pl: 3,
            pr: 2,
          }}
        >
          <Typography variant="body2">{customText}</Typography>
          <TablePagination
            component="div"
            labelRowsPerPage={customText}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </CardActions>
    </Box>
  );
}
